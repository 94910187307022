<template>
  <div class="h-100" @scroll.stop>
    <ErrorPage v-if="isLoadingError" />
    <LoadingPage v-else-if="isLoading"/>
    <MobileErrorPage v-else-if="isMobile && !forceMobileAccess"/>
    <IncompatibleVersion v-else-if="!isCompatibleBuilderContent"/>
    <div v-else-if="isWebsiteContent" class="vh-100 position-relative container-fluid" :style="websiteBackgroundColor">
      <div class="row h-100">
        <div class="col-auto h-100 px-0">
          <left-bar class="h-100"/>
        </div>
        <div class="col px-0 h-100 position-relative overflow-hidden">
          <perfect-scrollbar class="h-100" :options="{ wheelPropagation: false }">
            <div class="container-fluid central_layout pt-5 pe-5" :style="computedStyle">
              <template v-if="Object.keys(pageList).length > 0">
                <website-preview v-if="currentEditorMode === 'normal'"/>
                <AdvancedWebsitePreview v-else/>
              </template>
            </div>
          </perfect-scrollbar>
          <SynchroWidget class="position-absolute" style="left: 20px; bottom: 10px;"/>
          <BottomBar class="position-absolute" style="right: 50%; bottom: 0;"/>
        </div>
      </div>
      <Topbar class="position-absolute" style="right: 22px; top: 0;"/>
      <BottomRightBar class="position-absolute bottom-0" style="right: 22px;"/>
    </div>
    <div v-else class="h-100 position-relative container-fluid p-0">
      <NewWebsiteWizzard/>
    </div>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import Topbar from './components/bars/TopBar/Topbar.vue';
import WebsitePreview from './components/NormalModeContent/websitePreview.vue';
import LeftBar from './components/bars/LeftBar/LeftBar.vue';
import BottomBar from './components/bars/BottomBar.vue';
import LoadingPage from './components/LoadingPage.vue';
import ErrorPage from './components/ErrorPage.vue';
import SynchroWidget from './components/Utilities/SynchroWidget.vue';
import NewWebsiteWizzard from './components/NewWebsiteWizzard/NewWebsiteWizzard.vue';
import MobileErrorPage from './components/MobileErrorPage.vue';
import BottomRightBar from './components/bars/BottomRightBar.vue';
import AdvancedWebsitePreview from './components/AdvancedModeContent/AdvancedWebsitePreview.vue';
import IncompatibleVersion from './components/IncompatibleVersion.vue';

export default {
  name: 'App',
  components: {
    IncompatibleVersion,
    AdvancedWebsitePreview,
    MobileErrorPage,
    NewWebsiteWizzard,
    SynchroWidget,
    ErrorPage,
    BottomBar,
    LeftBar,
    WebsitePreview,
    Topbar,
    LoadingPage,
    BottomRightBar,
  },
  mounted()
  {
    this.$store.dispatch('decodeUrlParams');
    this.$store.commit('setEditorMode', { newMode: this.$cookies.get('cptg_editor_mode') || 'normal', dbSync: false });
  },
  created()
  {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type.includes('vuexfire'))
        return;
      if (mutation.payload.dbSync === undefined)
      {
        console.warn(`Mutation ${mutation.type} called and does not specify database synchronisation`);
      }
      if (mutation.payload.dbSync === true) {
        this.$store.dispatch('setWebsiteContent');
        this.$store.dispatch('setWorkerData');
      }
    });
    this.$store.watch((state) => state.websiteContent, (newContent, oldContent) => {
      if (newContent.rows !== undefined && newContent.pages === undefined)
        this.$refs.pageUpdateModal.open();
    });
    this.$store.watch((state) => state.liveCommit, (newCommit, oldCommit) => {
      if (oldCommit.commitId !== undefined && oldCommit.commitId !== newCommit.commitId && this.newCommitNotif)
      {
        this.newCommitNotif();
      }
    });
    this.$store.watch((state) => state.currentEditorMode, (newValue) => {
      this.$cookies.set('cptg_editor_mode', newValue);
    });
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'isLoadingError',
      'dictionary',
      'googleFonts',
      'customCSS',
      'customJS',
      'isWebsiteContent',
      'bodyConfig',
      'styleFromObject',
      'isMobile',
      'forceMobileAccess',
      'pageList',
      'currentPage',
      'currentEditorMode',
      'isCompatibleBuilderContent',
    ]),
    websiteBackgroundColor()
    {
      const out = { backgroundColor: this.styleFromObject(this.bodyConfig).backgroundColor || '' };
      return out;
    },
    computedStyle()
    {
      if (this.deploymentMode)
        return {};
      const out = this.styleFromObject(this.bodyConfig || {});
      return out || {};
    },
  },
  notifications: {
    newCommitNotif: {
      type: VueNotifications.types.warn,
      title: 'Nouvelle version deployé !',
      message: 'Une nouvelle version viens d\'être deployé en production.',
    },
  },
};

</script>

<style>

</style>
