<template>
  <div :style="computedStyle">
    <a :style="computedStyle" :href="policyLink" target="_blank">{{ policyText }}</a>
    <span class="mx-2">{{ separator }}</span>
    <a :style="computedStyle" href="#" data-bs-toggle="modal" :data-bs-target="`#modal-${legalMentionModal}`">{{ legalMentionText }}</a>
    <span class="mx-2">{{ separator }}</span>
    <a href="#" data-bs-toggle="modal" :data-bs-target="`#modal-${cookiesModal}`" :style="computedStyle">{{ cookiesText }}</a>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'LegalInformationsContent',
  props: {
    content: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['styleFromObject']),
    computedStyle()
    {
      const out = this.styleFromObject(this.content || {});
      return out || {};
    },
    policyText() {
      return this.content.policyText || '';
    },
    legalMentionText() {
      return this.content.legalMentionText || '';
    },
    cookiesText() {
      return this.content.cookiesText || '';
    },
    separator() {
      return this.content.separator || '|';
    },
    policyLink() {
      return this.content.policyLink || '';
    },
    legalMentionModal() {
      return this.content.legalMentionModal || '';
    },
    cookiesModal() {
      return this.content.cookiesModal || '';
    },
  },
};

</script>

<style scoped>

</style>
