<template>
  <context-menu @ctx-close="onClose" :id="`${this.contextId}-newAlert-context-menu`" :ref="`${this.contextId}-newAlert-ctxMenu`" >
    <form class="p-2" style="width: 300px;" @submit.stop.prevent>
      <div class="mb-3">
        <div class="row">
          <div class="col">
            <label for="AlertNameInput" class="form-label required">Code de l'alerte</label>
          </div>
          <div class="col-auto px-4">
            <i v-tooltip="'Ne rentrez pas une valeur au hasard, contactez votre interlocuteur Captag'" class="far fa-question-circle text-warning"></i>
          </div>
        </div>
        <input required type="text" class="form-control required" id="AlertNameInput" v-model="name">
      </div>
      <div class="w-100 text-center">
        <button @click="submit" class="btn btn-success btn-raised text-white">Valider</button>
      </div>
    </form>
  </context-menu>
</template>

<script>

import contextMenu from 'vue-context-menu';
import { mapGetters } from 'vuex';

export default {
  name: 'NewAlertContext',
  components: {
    contextMenu,
  },
  data()
  {
    return {
      contextId: '',
      name: '',
    };
  },
  created()
  {
    this.contextId = (this.id === 'unset') ? this.contextId = this.getNewId() : this.id;
  },
  computed: {
    ...mapGetters(['stringToSlug']),
  },
  methods: {
    submit()
    {
      this.$emit('addAlert', this.name);
      this.closeContext();
    },
    getNewId()
    {
      return this.$chance.string(this.$newIDParams);
    },
    onClose()
    {
      this.$refs[`${this.contextId}-newAlert-ctxMenu`].ctxVisible = true;
    },
    openContext()
    {
      this.$refs[`${this.contextId}-newAlert-ctxMenu`].open();
    },
    closeContext()
    {
      this.$refs[`${this.contextId}-newAlert-ctxMenu`].ctxVisible = false;
    },
  },
};

</script>

<style scoped>

</style>
