<template>
  <div class="row my-2 ms-1">
    <div class="col-auto">
      <div class="row text-center d-block">
        <div class="col-12">
          <i class="fas fa-grip-lines text-dark formItemDragHandle p-2 cursor-grab" v-tooltip.top="dictionary.formModule.moveInputTooltip"></i>
        </div>
        <div class="col-12">
          <button @click.prevent="$emit('editItem', item.id)" class="btn btn-sm btn-success py-0 text-center text-success" v-tooltip="dictionary.formModule.editInputTooltip">
            <i class="fas fa-pen text-light" ></i>
          </button>
        </div>
        <div class="col-12">
          <button @click.prevent="$refs.confirmationDeleteField.openContext();" class="btn btn-sm btn-danger py-0 text-center" v-tooltip="dictionary.formModule.deleteInputTooltip">
            <i class="fas fa-trash text-light"></i>
          </button>
          <confirmationContext
              @confirm="$emit('removeItem', item.id)"
              :introMessage="`<h6>${dictionary.formModule.deleteInputMessage}</h6>`"
              :confirmButtonContent="dictionary.confirm"
              :deniButtonContent="dictionary.no"
              ref="confirmationDeleteField"/>
        </div>
      </div>
    </div>
    <div class="col previewItem selectedCol">
      <slot/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import confirmationContext from '../contexts/ConfirmationContext.vue';

export default {
  name: 'FormItemOverlay',
  components: {
    confirmationContext,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
};

</script>

<style scoped>

.selectedCol {
  border-left: 1px solid var(--bs-primary);
}

</style>
