import { firebaseAction } from 'vuexfire';
import { version } from '../../../package.json';
import firebaseReference from '../FirebaseReference';
import FirebaseBindings from '../FirebaseBindings';
import landingsActions from './landingsActions';
import mediasActions from './mediasActions';
import coWorkerActions from './coWorkerActions';
import deploymentAction from './deploymentAction';
import styleActions from './styleActions';
import gitlabActions from './gitlabActions';
import databaseAccessorActions from './databaseAccessorActions';

const actions = {
  ...FirebaseBindings,
  ...landingsActions,
  ...mediasActions,
  ...coWorkerActions,
  ...deploymentAction,
  ...styleActions,
  ...gitlabActions,
  ...databaseAccessorActions,

  /**
   * Set the website content from the vue actual state
   * Only called by a subscriber located in App.vue when a mutation is called with payload.dbSync = true
   */
  setWebsiteContent: firebaseAction(async function callback({ state, getters, commit })
  {
    const fbRef = await firebaseReference(getters.tokenID);
    commit('toggleFirebaseSpinner', { value: true, dbSync: false });
    fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('websiteContent')
      .update(state.websiteContent)
      .then(() => {
        console.log('Website modification send to database');
        commit('toggleFirebaseSpinner', { value: false, dbSync: false });
      });
  }),

  /**
   * Set the website settings to the base
   * @param getters
   * @param settings the whole settings
   * you can also pass the urlInsc and unicity field to be set in the right place
   */
  setWebsiteSettings({ getters }, { settings })
  {
    console.log('Save website settings');
    firebaseReference(getters.tokenID).then((fbRef) => {
      const ref = fbRef.ref('animations')
        .child(getters.path)
        .child('config');
      ref.child('settings').set({
        ...settings,
      });
      if (settings.unicity !== undefined)
        ref.child('unicity').set(settings.unicity);
    });
  },

  /**
   * Bind some firebase parts to the vuex store
   * by calling these actions
   * bindWebsiteContent
   * initWorkerData (this is a mutation)
   * bindEventData
   * bindPreferredUrl
   * bindFormFilters
   * bindLiveCommit
   * bindCoworkers
   *
   * when coworkers are bound these 2 actions are called inside an interval :
   * removeOldWorker every 6000ms
   * setWorkerData every 3000ms
   *
   * finally, toggleLoading is called with "false"
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  async bindToFirebase({ dispatch, commit })
  {
    dispatch('bindWebsiteContent').then(() => {
      commit('initWorkerData', { dbSync: false });
      dispatch('bindEventData');
      dispatch('bindPreferredUrl');
      dispatch('bindFormFilters');
      dispatch('bindLiveCommit');
      dispatch('bindHistoryLimit');
      dispatch('bindCoworkers').then(() => {
        setInterval(() => dispatch('removeOldWorker'), 6000);
        setInterval(() => dispatch('setWorkerData'), 3000);
      });
      commit('toggleLoading', { value: false, dbSync: false });
    });
  },

  /**
   * Decode the TK parameter expected in the URL
   * once the token is decrypted the action loadTokenParams is dispatched
   * @param dispatch
   * @param commit
   * @param getters
   */
  async decodeUrlParams({ dispatch, commit, getters })
  {
    function getUrlParam(variable)
    {
      const query = decodeURIComponent(window.location.search.substring(1));
      const vars = query.split('&');
      for (let i = 0; i < vars.length; i += 1)
      {
        const pair = vars[i].split('=');
        if (pair[0] === variable)
          return pair[1];
      }
      return '';
    }
    const token = getUrlParam('tk');
    if (token === '' || !await firebaseReference(token, dispatch))
    {
      commit('toggleLoadingError', { error: true, content: { code: 403, message: getters.dictionary.tokenError } });
      return;
    }
    commit('setTokenId', { tokenID: getUrlParam('tk'), dbSync: false });
  },

  /**
   * Process all the information stored in the parameters param.
   * this action is dispatched by decodeUrlParam when the tk parameter is decoded
   * when parameters are set to vuex through multiple mutation, the bindToFirebase action is dispatched
   *
   * also, at the beginning, the setVersionNumber is committed with the package.json version number
   * @param commit
   * @param dispatch
   * @param getters
   * @param lang: can be 'fr' or 'en', used to translate the interface [default: fr]
   * @param name: the worker name shared with the other co-workers [default: '']
   * @param tokenID: the firebase token ID [no default: mandatory]
   * @param path: the website path [no default: mandatory]
   */
  loadTokenParams({ commit, dispatch, getters }, { lang, name, path })
  {
    commit('setVersionNumber', { version, dbSync: false });

    commit('setLang', { lang: (lang !== '') ? lang : 'fr', dbSync: false });

    if (name !== undefined && name !== '')
      commit('setWorkerName', { name, dbSync: false });

    if (path === '') {
      console.log('No path provided, stopping');
      commit('toggleLoadingError', { error: true, content: { code: 404, message: getters.dictionary.pathError } });
      return;
    }
    commit('setPath', { path, dbSync: false });

    dispatch('bindToFirebase');
  },

  /**
   * @brief This is a temporary action, used in the transition time between the old and newly page featured builder
   * @param commit
   * @param state
   */
  convertOldWebsiteContent({ commit, state })
  {
    const ret = {};
    commit('addPage',
      {
        pageName: 'home',
        content: { rows: state.websiteContent.rows },
        newID: ret,
        dbSync: false,
      });
    commit('setDefaultPage', { pageID: ret.id, dbSync: true });
  },
};

export default actions;
