<template>
  <div class="editorUIStyle">
    <div class="mb-3">
      <label for="linkType">Call to action</label>
      <select id="linkType" class="form-control" v-model="targetType">
        <option value="">Choisissez ...</option>
        <option v-for="opt in availableOptions" :key="`opt-${opt.key}`" :value="opt.key">{{ opt.title }}</option>
      </select>
    </div>
    <template v-if="targetType === 'url'">
      <label for="imageLinkInput" class="form-label">{{ dictionary.mediaConfig.linkLabel }}</label>
      <div class="input-group mb-3">
        <input placeholder="https://" v-model="targetUrl" type="url" class="form-control" id="imageLinkInput">
        <select class="form-select" v-model="newTab">
          <option value="false" selected>Meme onglet</option>
          <option value="true">Nouvelle onglet</option>
        </select>
      </div>
    </template>
    <template v-if="targetType === 'row'">
      <div class="mb-3">
        <label for="rowSelect" class="form-label">{{ dictionary.mediaConfig.anchorLabel }}</label>
        <select v-model="targetRow" type="url" class="form-control" id="rowSelect">
          <option value="" class="disabled" disabled>Choisissez ...</option>
          <option :value="col" v-for="(col, colId) in hrefList" :key="`${colId}-hrefoption`">{{ col }}</option>
        </select>
      </div>
    </template>
    <template v-if="targetType === 'page'">
      <div class="mb-3">
        <label for="pageSelect" class="form-label">{{ dictionary.mediaConfig.pageLabel }}</label>
        <select v-model="targetPage" type="url" class="form-control" id="pageSelect">
          <option value="" class="disabled" disabled>Choisissez ...</option>
          <option :value="pageID" v-for="(page, pageID) in pages" :key="`${pageID}-pageoption`">{{ page.name || pageID }}</option>
        </select>
      </div>
    </template>
    <template v-if="targetType === 'filter'">
      <div class="mb-3">
        <label for="filterSelect" class="form-label">{{ dictionary.mediaConfig.filterLabel }}</label>
        <select v-model="targetFilter" type="url" class="form-control" id="filterSelect">
          <option value="" class="disabled" disabled>Choisissez ...</option>
          <option v-for="(filter, filterID) in websiteFilters" :key="`${filterID}-optionFilter`" :value="filterID"> {{ filter.name }}</option>
        </select>
      </div>
    </template>
    <template v-if="targetType === 'modal'">
      <div class="mb-3">
        <label for="modalSelect" class="form-label">{{ dictionary.mediaConfig.modalLabel }}</label>
        <select v-model="targetModal" type="url" class="form-control" id="modalSelect">
          <option value="" class="disabled" disabled>Choisissez ...</option>
          <option v-for="(modal, modalID) in modalList" :key="`${modalID}-optionModal`" :value="modalID"> {{ modal.name }}</option>
        </select>
      </div>
    </template>
    <template v-if="targetType === 'javascript'">
      <div class="mb-3">
        <label for="modalSelect" class="form-label">{{ dictionary.mediaConfig.modalLabel }}</label>
        <textarea class="form-control" v-model="targetJavascript"></textarea>
      </div>
    </template>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ActionSelector',
  model: {
    prop: 'action',
    event: 'change',
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
    availableActions: {
      type: Array,
      required: false,
      default: () => [
        'url',
        'row',
        'filter',
        'modal',
        'javascript',
        'page',
      ],
    },
  },
  computed: {
    ...mapGetters([
      'dictionary',
      'rowIDList',
      'footerRowList',
      'headerRowList',
      'websiteFilters',
      'modalList',
      'pageList',
    ]),
    hrefList()
    {
      const out = [];
      this.rowIDList.forEach((rowID) => { out.push(`#${rowID}`); });
      (this.headerRowList || []).forEach((row) => { out.push(`#${row.id}`); });
      (this.footerRowList || []).forEach((row) => { out.push(`#${row.id}`); });
      return out;
    },
    pages()
    {
      const out = { ...this.pageList };
      delete out.default;
      return out;
    },
    availableOptions()
    {
      const out = [];
      if (this.availableActions.includes('url'))
        out.push({ key: 'url', title: 'URL' });
      if (this.availableActions.includes('row'))
        out.push({ key: 'row', title: 'Ancre du site' });
      if (this.availableActions.includes('filter'))
        out.push({ key: 'filter', title: 'Appliquer un filtre' });
      if (this.availableActions.includes('page'))
        out.push({ key: 'page', title: 'Aller vers une page' });
      if (this.availableActions.includes('modal'))
        out.push({ key: 'modal', title: 'Ouvrir une modale' });
      if (this.availableActions.includes('javascript'))
        out.push({ key: 'javascript', title: 'Exécuter du Javascript' });
      return out;
    },
    targetType: {
      get()
      {
        return this.action.targetType || '';
      },
      set(value)
      {
        this.$emit('change', { ...this.action, targetType: value });
      },
    },
    newTab: {
      get()
      {
        return this.action.newTab || false;
      },
      set(value)
      {
        if (typeof value === 'string')
          value = value === 'true';
        this.$emit('change', { ...this.action, newTab: value });
      },
    },
    targetUrl: {
      get()
      {
        return this.action.targetUrl || '';
      },
      set(url)
      {
        this.$emit('change', { ...this.action, targetUrl: url });
      },
    },
    targetRow: {
      get() {
        return this.action.targetRow || '';
      },
      set(row)
      {
        this.$emit('change', { ...this.action, targetRow: row });
      },
    },
    targetPage: {
      get() {
        return this.action.targetPage || '';
      },
      set(page)
      {
        this.$emit('change', { ...this.action, targetPage: page, targetPageName: this.pages[page].name || '' });
      },
    },
    targetFilter: {
      get() {
        return this.action.targetFilter || '';
      },
      set(filter)
      {
        this.$emit('change', { ...this.action, targetFilter: filter });
      },
    },
    targetModal: {
      get() {
        return this.action.targetModal || '';
      },
      set(modal)
      {
        this.$emit('change', { ...this.action, targetModal: modal });
      },
    },
    targetJavascript: {
      get() {
        return this.action.targetJavascript || '';
      },
      set(javascript)
      {
        this.$emit('change', { ...this.action, targetJavascript: javascript });
      },
    },
  },
  methods: {
    test()
    {
      console.log('new tab change!');
    },
  },
};

</script>

<style scoped>

</style>
