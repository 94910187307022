<template>
  <div class="editorUIStyle">
    <hr>
    <div class="row d-flex align-items-center">
      <div class="col pe-0">
        <div class="form-check form-switch">
          <label class="form-check-label" for="enableAnimationCheck">Animation d'apparition</label>
          <input v-model="enabled" class="form-check-input" type="checkbox" id="enableAnimationCheck">
        </div>
      </div>
      <div class="col-auto ps-0">
        <a v-tooltip.top="'Besoin d\'aide ?'" class="btn btn-info" href="http://michalsnik.github.io/aos/" target="_blank">
          <i class="fas fa-question text-light"></i>
        </a>
      </div>
    </div>
    <template v-if="enabled">
      <div class="mb-3 mt-2">
        <label class="form-label">Type d'animations</label>
        <select class="form-select" v-model="type">
          <option selected value="">Pas d'animation</option>
          <option disabled class="disabled">Fade animations</option>
          <option value="fade">fade</option>
          <option value="fade-up">fade-up</option>
          <option value="fade-down">fade-down</option>
          <option value="fade-left">fade-left</option>
          <option value="fade-right">fade-right</option>
          <option value="fade-up-right">fade-up-right</option>
          <option value="fade-up-left">fade-up-left</option>
          <option value="fade-down-right">fade-down-right</option>
          <option value="fade-down-left">fade-down-left</option>
          <option disabled class="disabled">Flip animations</option>
          <option value="flip-up">flip-up</option>
          <option value="flip-down">flip-down</option>
          <option value="flip-left">flip-left</option>
          <option value="flip-right">flip-right</option>
          <option disabled class="disabled">Slide animations</option>
          <option value="slide-up">slide-up</option>
          <option value="slide-down">slide-down</option>
          <option value="slide-left">slide-left</option>
          <option value="slide-right">slide-right</option>
          <option disabled class="disabled">Zoom animations</option>
          <option value="zoom-in">zoom-in</option>
          <option value="zoom-in-up">zoom-in-up</option>
          <option value="zoom-in-down">zoom-in-down</option>
          <option value="zoom-in-left">zoom-in-left</option>
          <option value="zoom-in-right">zoom-in-right</option>
          <option value="zoom-out">zoom-out</option>
          <option value="zoom-out-up">zoom-out-up</option>
          <option value="zoom-out-down">zoom-out-down</option>
          <option value="zoom-out-left">zoom-out-left</option>
          <option value="zoom-out-righ">zoom-out-right</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="durationInput">Durée de l'animation <span class="text-muted small"> - en ms</span></label>
        <input v-model="duration" type="number" id="durationInput" class="form-control" placeholder="Default: 400">
      </div>
      <div class="mb-3">
        <div class="form-check form-switch">
          <label class="form-check-label" for="enableMirrorCheck">Animation miroir<br><span class="text-muted small">L'animation est appliqué à la sortie</span></label>
          <input v-model="mirror" class="form-check-input" type="checkbox" id="enableMirrorCheck">
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">Courbe d'accelération <a v-tooltip="'Voir la liste des courbes'" class="text-muted" href="https://easings.net/fr" target="_blank"><i class="far fa-question-circle"></i></a></label>
        <select class="form-select" v-model="easing">
          <option value="" selected>Defaut</option>
          <option value="linear">linear</option>
          <option value="ease-in">ease-in</option>
          <option value="ease-out">ease-out</option>
          <option value="ease-in-out">ease-in-out</option>
          <option value="ease-in-back">ease-in-back</option>
          <option value="ease-out-back">ease-out-back</option>
          <option value="ease-in-out-back">ease-in-out-back</option>
          <option value="ease-in-sine">ease-in-sine</option>
          <option value="ease-out-sine">ease-out-sine</option>
          <option value="ease-in-out-sine">ease-in-out-sine</option>
          <option value="ease-in-quad">ease-in-quad</option>
          <option value="ease-out-quad">ease-out-quad</option>
          <option value="ease-in-out-quad">ease-in-out-quad</option>
          <option value="ease-in-cubic">ease-in-cubic</option>
          <option value="ease-out-cubic">ease-out-cubic</option>
          <option value="ease-in-out-cubic">ease-in-out-cubic</option>
          <option value="ease-in-quart">ease-in-quart</option>
          <option value="ease-out-quart">ease-out-quart</option>
          <option value="ease-in-out-quart">ease-in-out-quart</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label">Ancre de l'animation</label>
        <select class="form-select" v-model="anchorPlacement">
          <option value="" selected>Defaut</option>
          <option value="top-bottom">top-bottom</option>
          <option value="center-bottom">center-bottom</option>
          <option value="bottom-bottom">bottom-bottom</option>
          <option value="top-center">top-center</option>
          <option value="center-center">center-center</option>
        </select>
      </div>
    </template>
    <hr>
  </div>
</template>

<script>

export default {
  name: 'AnimationEditor',
  model: {
    prop: 'animation',
    event: 'change',
  },
  props: {
    animation: {
      required: true,
      type: Object,
    },
  },
  computed: {
    enabled: {
      get()
      {
        return this.animation.enabled || false;
      },
      set(newEnabled)
      {
        this.$emit('change', { ...this.animation, enabled: newEnabled });
      },
    },
    type: {
      get()
      {
        return this.animation.type || '';
      },
      set(newType)
      {
        this.$emit('change', { ...this.animation, type: newType });
      },
    },
    duration: {
      get()
      {
        return this.animation.duration || '';
      },
      set(newDuration)
      {
        this.$emit('change', { ...this.animation, duration: parseInt(newDuration, 10) || '' });
      },
    },
    mirror: {
      get()
      {
        return this.animation.mirror || '';
      },
      set(newMirror)
      {
        this.$emit('change', { ...this.animation, mirror: newMirror });
      },
    },
    easing: {
      get()
      {
        return this.animation.easing || '';
      },
      set(newEasing)
      {
        this.$emit('change', { ...this.animation, easing: newEasing });
      },
    },
    anchorPlacement: {
      get()
      {
        return this.animation.anchorPlacement || '';
      },
      set(newAnchorPlacement)
      {
        this.$emit('change', { ...this.animation, anchorPlacement: newAnchorPlacement });
      },
    },
  },
};

</script>

<style scoped>

</style>
