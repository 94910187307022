<template>
  <div :class="`${pageName} website_body`">
    <template v-if="isHeaderForPage">
      <div class="w-100" :class="headerStickyClass">
        <div id="header" class="header" :class="headerComputedClasses" :style="headerStyle">
          <draggable v-if="!deploymentMode" v-model="draggableHeaderRowList" group="rows" handle=".rowDragHandle">
            <template v-if="draggableHeaderRowList.length <= 0" v-slot:header>
              <div class="row">
                <div class="col-12">
                  <div class="text-center d-flex align-items-center">
                    <p class="text-muted mx-auto my-1"><i class="far fa-2x fa-caret-square-up"></i><br>Le header est vide, déplacez une ligne ici</p>
                  </div>
                </div>
              </div>
            </template>
            <Row v-for="(row, index) in draggableHeaderRowList" :key="index" :row="row" :index="index" :row-index="index"/>
          </draggable>
          <Row v-else :deploymentMode="deploymentMode" v-for="(row, index) in draggableHeaderRowList" :key="index" :row="row" :row-index="index"/>
        </div>
      </div>
      <div v-if="!deploymentMode" class="row text-muted my-4">
        <div class="col"><hr></div>
        <div class="col-auto"><i class="fas fa-arrow-up me-4"></i>Header<i class="fas fa-arrow-up ms-4"></i></div>
        <div class="col"><hr></div>
      </div>
    </template>
    <div class="w-100">
      <div :id="pageName" :class="computedClasses" ref="websiteContent" class="websiteContent">
        <draggable v-if="!deploymentMode" v-model="draggableRowList" group="rows" handle=".rowDragHandle">
          <Row v-for="(row, index) in draggableRowList" :key="index" :row="row" :row-index="index"/>
        </draggable>
        <Row v-else :deploymentMode="deploymentMode" v-for="(row, index) in draggableRowList" :key="index" :row="row" :row-index="index"/>
        <template v-if="deploymentMode">
          <ModalContent v-for="(modal, modalID) in modalList" :key="`modal-${modalID}`" :modal="modal" :modalID="modalID"/>
        </template>
      </div>
    </div>
    <template v-if="isFooterForPage">
      <div v-if="!deploymentMode" class="row text-muted my-4">
        <div class="col"><hr></div>
        <div class="col-auto"><i class="fas fa-arrow-down me-4"></i>Footer<i class="fas fa-arrow-down ms-4"></i></div>
        <div class="col"><hr></div>
      </div>
      <div class="w-100">
        <div id="footer" class="footer" :class="footerComputedClasses" :style="footerStyle">
          <draggable v-if="!deploymentMode" v-model="draggableFooterRowList" group="rows" handle=".rowDragHandle">
            <template v-if="draggableFooterRowList.length <= 0" v-slot:header>
              <div class="row">
                <div class="col-12">
                  <div class="text-center d-flex align-items-center">
                    <p class="text-muted mx-auto my-1"><i class="far fa-2x fa-caret-square-down"></i><br>Le Footer est vide, déplacez une ligne ici</p>
                  </div>
                </div>
              </div>
            </template>
            <Row v-for="(row, index) in draggableFooterRowList" :key="index" :row="row" :index="index" :row-index="index"/>
          </draggable>
          <Row v-else :deploymentMode="deploymentMode" v-for="(row, index) in draggableFooterRowList" :key="index" :row="row" :row-index="index"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import Row from './Row.vue';
import ModalContent from './ModalContent.vue';

export default {
  name: 'websitePreview',
  components: {
    ModalContent,
    Row,
    draggable,
  },
  props: {
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    page: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      controlOnStart: false,
    };
  },
  mounted() {
    this.$store.watch((_, getters) => getters.customCSS, (newCSS) => {
      this.updateCustomCSS(newCSS);
    });
    this.$store.watch((_, getters) => getters.bodyConfig.linkColor, () => {
      this.updateCustomCSS(this.customCSS);
    });
    this.updateCustomCSS(this.customCSS);
    this.$store.watch((_, getters) => getters.googleFonts, () => {
      this.updateFonts();
    });
    this.updateFonts();
  },
  computed: {
    ...mapGetters([
      'rowList',
      'bodyConfig',
      'customCSS',
      'googleFonts',
      'styleFromObject',
      'modalList',
      'currentPage',
      'pageRowList',
      'headerRowList',
      'footerRowList',
      'headerConfig',
      'footerConfig',
      'pageList',
    ]),
    draggableRowList: {
      get()
      {
        return (this.page) ? this.pageRowList(this.page) : this.rowList;
      },
      set(rowList)
      {
        this.$store.commit('updateRowList', { pageID: this.currentPage, rowList, dbSync: true });
      },
    },
    draggableHeaderRowList: {
      get()
      {
        return this.headerRowList || [];
      },
      set(headerRowList)
      {
        this.$store.commit('updateHeaderRows', { rowList: headerRowList, dbSync: true });
      },
    },
    draggableFooterRowList: {
      get()
      {
        return this.footerRowList || [];
      },
      set(footerRowList)
      {
        this.$store.commit('updateFooterRows', { rowList: footerRowList, dbSync: true });
      },
    },
    pageObject()
    {
      if (this.page)
        return this.pageList[this.page];
      return this.pageList[this.currentPage];
    },
    // Computed Styles and Classes coming from bodyConfig
    computedClasses()
    {
      let out = '';
      if (this.pageObject.fullWidth === 'true')
        out += 'container-fluid';
      else if (this.pageObject.fullWidth === 'false')
        out += 'container';
      else if (!this.pageObject.fullWidth && this.bodyConfig.fullWidth)
        out += 'container-fluid';
      else
        out += 'container';
      return out;
    },
    headerComputedClasses()
    {
      let out = '';
      out += (this.headerConfig.fullWidth) ? 'container-fluid' : 'container';
      return out;
    },
    footerComputedClasses()
    {
      let out = '';
      out += (this.footerConfig.fullWidth) ? 'container-fluid' : 'container';
      return out;
    },
    headerStickyClass()
    {
      return (this.headerConfig.stickyHeader && this.deploymentMode) ? ' sticky-top' : '';
    },
    isHeaderForPage()
    {
      if (this.pageObject.header !== undefined)
      {
        console.log(typeof this.pageObject.header, this.pageObject.header);
        if (this.pageObject.header === false)
          return false;
        return this.pageObject.header;
      }
      return true;
    },
    isFooterForPage()
    {
      if (this.pageObject.footer !== undefined)
      {
        if (this.pageObject.footer === false)
          return false;
        return this.pageObject.footer;
      }
      return true;
    },
    headerStyle()
    {
      const out = this.styleFromObject(this.headerConfig || {});
      return out || {};
    },
    footerStyle()
    {
      const out = this.styleFromObject(this.footerConfig || {});
      return out || {};
    },
    pageName()
    {
      if (this.pageObject)
        return this.pageObject.name || '';
      return '';
    },
  },
  methods: {
    updateFonts()
    {
      if (this.deploymentMode)
        return;
      this.$store.dispatch('getImportedFontCSS').then((fonts) => {
        const head = this.$refs.websiteContent;
        if (!head)
          return;
        const oldNode = document.getElementById('fontsList');
        if (oldNode && head.contains(oldNode))
          head.removeChild(oldNode);
        if (fonts)
        {
          const style = document.createElement('style');
          style.id = 'fontsList';
          head.appendChild(style);
          style.type = 'text/css';
          style.appendChild(document.createTextNode(fonts));
        }
      });
    },
    updateCustomCSS(customCSS)
    {
      if (this.deploymentMode)
        return;
      let css = customCSS.replace('\n', '');
      const head = this.$refs.websiteContent;
      if (head === undefined || head === null)
        return;
      const style = document.createElement('style');
      style.id = 'customCSS';
      // remove the current customCSS if present
      const oldNode = document.getElementById('customCSS');
      if (oldNode !== null && head.contains(oldNode))
        head.removeChild(oldNode);
      css += `a { color: ${(this.bodyConfig.linkColor) ? this.bodyConfig.linkColor.hex || '#0000EE' : '#0000EE'}; }`;
      head.appendChild(style);
      style.type = 'text/css';
      style.appendChild(document.createTextNode(css));
    },
  },
};

</script>

<style>

.dragged {
  border: 2px solid orange;
}

</style>
