<template>
  <div class="bottom-right-bar row p-2 d-flex align-items-center bg-secondary">
    <div class="col-auto">
      <button class="btn btn-light dropdown-toggle " type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <template v-if="currentEditorMode === 'normal'"><i class="fas fa-child me-2"></i>Mode Normal</template>
        <template v-if="currentEditorMode === 'advanced'"><i class="fas fa-flask me-2"></i>Mode Avancé</template>
      </button>
      <ul class="dropdown-menu">
        <li><a @click="editorMode = 'normal'" class="dropdown-item" href="#"><i class="fas fa-child me-2"></i>Mode Normal</a></li>
        <li><a @click="editorMode = 'advanced'" class="dropdown-item" href="#" ><i class="fas fa-flask me-2"></i>Mode Avancé</a></li>
      </ul>
    </div>
    <div class="col-auto">
      <a :href="pageUrl" target="_blank" class="btn btn-outline-light"><i class="fas fa-expand"></i></a>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'BottomRightBar',
  computed: {
    ...mapGetters(['currentEditorMode']),
    pageUrl()
    {
      return window.location.href;
    },
    editorMode: {
      get()
      {
        return this.currentEditorMode || 'normal';
      },
      set(newMode)
      {
        this.$store.commit('setEditorMode', { newMode, dbSync: true });
      },
    },
  },
};

</script>

<style scoped>

.bottom-right-bar .ghost {
    display: none;
}

.bottom-right-bar {
    z-index: 1000;
    border-top-left-radius: 10px;
}

</style>
