import Chance from 'chance';
import Vue from 'vue';

const pageMutations = {
  /**
   * Set the current selected column identified by he's ID
   * @param state state Vuex state (do not pass for users)
   * @param page: The newly selected column's id
   */
  setCurrentPage(state, { page })
  {
    state.workerData.currentPage = page;
  },

  /**
   * Set the current selected column identified by he's ID
   * @param state state Vuex state (do not pass for users)
   * @param pageID The selected page's id
   * @param pageName The new name of the selected page
   */
  setPageName(state, { pageID, pageName })
  {
    if (!state.websiteContent.pages[pageID])
      return;
    state.websiteContent.pages[pageID].name = pageName;
  },

  enablePage(state, { pageID, disabled })
  {
    state.websiteContent.pages[pageID].disabled = disabled;
  },
  setPageFooterAndHeader(state, { pageID, footer, header })
  {
    state.websiteContent.pages[pageID].footer = footer;
    state.websiteContent.pages[pageID].header = header;
  },
  setPageFullWidth(state, { pageID, value })
  {
    state.websiteContent.pages[pageID].fullWidth = value;
  },
  /**
   * Set the current selected column identified by he's ID
   * @param state state Vuex state (do not pass for users)
   * @param pageID The selected page's id
   * @param pageName The new name of the selected page
   */
  setPageMetas(state, { pageID, metas })
  {
    if (!state.websiteContent.pages[pageID])
      return;
    state.websiteContent.pages[pageID].metas = metas;
  },

  /**
   * Set the current selected column identified by he's ID
   * @param state state Vuex state (do not pass for users)
   * @param content the new page content [optional]
   * @param pageName The new page name
   * @param newID DON'T PASS - used to return the newly created page id
   */
  addPage(state, { pageName, content, newID })
  {
    const id = new Chance().string({
      length: 5,
      casing: 'lowers',
      alpha: true,
      numeric: false,
    });
    if (!state.websiteContent.pages)
      state.websiteContent.pages = {};
    if (pageName)
    {
      Object.values(state.websiteContent.pages).forEach((page) => {
        if (page.name === pageName)
        {
          console.warn('A page with this name already exist');
          pageName = `${pageName}-${id}`;
        }
      });
      state.websiteContent.pages[id] = { name: pageName, ...(content || {}) };
      state.websiteContent.pages[id].name = pageName;
    }
    else
    {
      if (!content.name)
        content.name = `page-${id}`;
      state.websiteContent.pages[id] = { ...(content || {}) };
    }
    if (newID)
      newID.id = id;
  },
  /**
   * Set the default page to the one given in parameters
   * @param state
   * @param pageID the new default page id
   */
  setDefaultPage(state, { pageID })
  {
    if (state.websiteContent.pages[pageID])
      state.websiteContent.pages.default = state.websiteContent.pages[pageID].name || '';
  },
  /**
   * Remove a page
   * @param state
   * @param pageID the id of the page you want to remove
   */
  removePage(state, { pageID })
  {
    Vue.delete(state.websiteContent.pages, pageID);
  },
  /**
   * Set multiples page in one call
   * @param state
   * @param pages the pages content
   */
  setAllPages(state, { pages })
  {
    Vue.set(state.websiteContent, 'pages', pages);
  },
};

export default pageMutations;
