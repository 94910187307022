<template>
  <StyleEditor v-model="style" :properties="[ 'fontFamily', 'fontSize', 'color' ]">
    <template v-slot:form-end>
      <hr>
      <div class="mb-3">
        <label for="privacyPolicyLinkInput">Text des politique de confidentialité</label>
        <input :value="getPropertyValue('policyText')" @change="setPropertyValue('policyText', $event.target.value)" class="form-control" id="privacyPolicyLinkInput">
      </div>
      <div class="mb-3">
        <label for="policyLinkInput">Lien des politique de confidentialité</label>
        <input :value="getPropertyValue('policyLink')" @change="setPropertyValue('policyLink', $event.target.value)" class="form-control" id="policyLinkInput">
      </div>
      <hr>
      <div class="mb-3">
        <label for="legalMentionTextInput">Text des politique de confidentialité</label>
        <input :value="getPropertyValue('legalMentionText')" @change="setPropertyValue('legalMentionText', $event.target.value)" class="form-control" id="legalMentionTextInput">
      </div>
      <div class="mb-3">
        <label for="legalMentionModalSelect">Modale des politique de confidentialité</label>
        <select class="form-control" :value="getPropertyValue('legalMentionModal')" @change="setPropertyValue('legalMentionModal', $event.target.value)" id="legalMentionModalSelect">
          <option value="">Choisissez ...</option>
          <option :value="modalID" v-for="(modal, modalID) in modalList" :key="`${modalID}-modalOption`">{{ modal.name }}</option>
        </select>
      </div>
      <hr>
      <div class="mb-3">
        <label for="cookiesTextInput">Text des cookies</label>
        <input :value="getPropertyValue('cookiesText')" @change="setPropertyValue('cookiesText', $event.target.value)" class="form-control" id="cookiesTextInput">
      </div>
      <div class="mb-3">
        <label for="cookiesModalSelect">Modale des cookies</label>
        <select class="form-control" :value="getPropertyValue('cookiesModal')" @change="setPropertyValue('cookiesModal', $event.target.value)" id="cookiesModalSelect">
          <option value="">Choisissez ...</option>
          <option :value="modalID" v-for="(modal, modalID) in modalList" :key="`${modalID}-modalOption-ck`">{{ modal.name }}</option>
        </select>
      </div>
    </template>
  </StyleEditor>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import StyleEditor from '../../../../Utilities/StyleEditor.vue';

export default {
  name: 'LegalInformationsConfig',
  components: { StyleEditor },
  model: {
    prop: 'content',
    event: 'change',
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['modalList']),
    style: {
      get()
      {
        return this.content;
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.content, ...newStyle });
      },
    },
  },
  methods: {
    getPropertyValue(key)
    {
      return this.content[key] || '';
    },
    setPropertyValue(key, value)
    {
      const content = _.cloneDeep(this.content);
      content[key] = value;
      this.$emit('change', content);
    },
  },
};

</script>

<style scoped>

</style>
