<template>
  <div class="row editorUIStyle">
    <form class="col-12" @submit.stop.prevent>
      <div class="mb-3">
        <label class="small">{{ dictionary.mediaConfig.mediaType }}</label>
        <select v-model="mediaType" class="form-control">
          <option value="image">{{ dictionary.mediaConfig.imageLabel }}</option>
          <option value="embed-video">{{ dictionary.mediaConfig.iframeLabel }}</option>
        </select>
      </div>
      <template v-if="content.mediaType === 'image'">
        <div class="mb-3">
          <div class="form-check form-switch">
            <input v-model="isMobileImage" class="form-check-input" type="checkbox" id="isMobileImageSwitch">
            <label class="form-check-label" for="isMobileImageSwitch">{{ dictionary.mediaConfig.isMobileVersion }}</label>
          </div>
        </div>
        <div class="mb-3">
          <template v-if="isMobileImage">
            <InputImageUpload :title="`<i class='fas fa-desktop me-2'></i>${dictionary.mediaConfig.desktopLabel} <span class='text-muted'> >768px</span>`" v-model="source"/>
            <InputImageUpload class="mt-2" :title="`<i class='fas fa-mobile-alt me-3'></i>${dictionary.mediaConfig.mobileLabel} <span class='text-muted'> <768px</span>`" v-model="sourceXS"/>
          </template>
          <template v-else>
            <InputImageUpload :title="dictionary.mediaConfig.imageLabel" v-model="source"/>
          </template>
        </div>
        <div class="mb-3">
          <label for="altTextInput">Description <span class="text-muted small">(alt)</span></label>
          <span v-tooltip.right="'Le texte de description n\'est pas visible et sert a l\'accessibilité et au référencement '" class="badge rounded-pill bg-info text-white ms-2 small"><i class="fas fa-question small"></i></span>
          <input v-model="alt" class="form-control" id="altTextInput">
        </div>
        <hr>
        <div class="form-check form-switch mb-3">
          <input v-model="isTargetUrl" class="form-check-input" type="checkbox" id="targetUrlSwitch">
          <label class="form-check-label" for="targetUrlSwitch">{{ dictionary.mediaConfig.imageLinkLabel }}</label>
        </div>
        <ActionSelector v-if="isTargetUrl" v-model="action" :availableActions="['url', 'row', 'page', 'filter', 'modal']"/>
      </template>
      <div v-if="content.mediaType === 'embed-video'">
        <div class="mb-3">
          <label class="small">{{ dictionary.mediaConfig.videoLabel }}</label>
          <input class="form-control" type="text" v-model="source">
        </div>
        <template v-if="isVideoCaptag">
          <card title="Video Captag">
            <template v-slot:header-right>
              <i class="fas fa-question text-white" v-tooltip.top="'Ces parametres sont disponibles pour les video Captag'"></i>
            </template>
            <input-image-upload class="mb-3" title="Miniature" v-model="poster"/>
            <div class="form-check form-switch">
              <input v-model="backgroundMode" class="form-check-input" type="checkbox" id="autoLaunchSwitch">
              <label class="form-check-label" for="autoLaunchSwitch" aria-describedby="autoLaunchHelp">Lancement automatique</label>
              <div id="autoLaunchHelp" class="form-text">Pas de son si lancement automatique</div>
            </div>
          </card>
        </template>
        <div class="mb-3">
          <label class="small">{{ dictionary.mediaConfig.iframeRatioLabel }}</label>
          <select class="form-control" v-model="ratio">
            <option value="">{{ dictionary.mediaConfig.noRatioLabel }}</option>
            <option value="ratio-1x1">1 X 1</option>
            <option value="ratio-4x3">4 X 3</option>
            <option value="ratio-16x9">16 X 9</option>
            <option value="ratio-21x9">21 X 9</option>
          </select>
        </div>
        <div class="mb-3">
          <label :class="ratio !== '' ? 'text-muted' : ''">{{ dictionary.mediaConfig.iframeHeightLabel }}</label>
          <input v-model="iframeHeight" class="form-control" type="number" :class="ratio === '' ? 'disabled' : ''" :disabled="ratio !== ''">
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import InputImageUpload from '../../../../Utilities/InputImageUpload.vue';
import ActionSelector from '../../../../Utilities/ActionSelector.vue';
import Card from '../../../../Utilities/Card.vue';

export default {
  name: 'MediaConfig',
  components: {
    Card,
    InputImageUpload,
    ActionSelector,
  },
  model: {
    prop: 'content',
    event: 'change',
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    action: {
      get()
      {
        return this.content || {};
      },
      set(newActionObj)
      {
        this.$emit('change', { ...this.content, ...newActionObj });
      },
    },
    mediaType: {
      get()
      {
        return (this.content.mediaType !== undefined) ? this.content.mediaType : '';
      },
      set(value)
      {
        this.$emit('change', { ...this.content, mediaType: value });
      },
    },
    source: {
      get()
      {
        return this.content.src || '';
      },
      set(value)
      {
        this.$emit('change', { ...this.content, src: value });
      },
    },
    alt: {
      get()
      {
        return this.content.alt || '';
      },
      set(newAlt)
      {
        this.$emit('change', { ...this.content, alt: newAlt });
      },
    },
    sourceXS: {
      get()
      {
        return (this.content.sourceXS !== undefined) ? this.content.sourceXS : '';
      },
      set(value)
      {
        this.$emit('change', { ...this.content, sourceXS: value });
      },
    },
    isMobileImage: {
      get()
      {
        return this.content.isMobileImage || false;
      },
      set(value)
      {
        this.$emit('change', { ...this.content, isMobileImage: value });
      },
    },
    isTargetUrl: {
      get()
      {
        return (this.content.isTargetUrl !== undefined) ? this.content.isTargetUrl : false;
      },
      set(value)
      {
        this.$emit('change', { ...this.content, isTargetUrl: value });
      },
    },
    multipleSize: {
      get()
      {
        return (this.content.multipleSize !== undefined) ? this.content.multipleSize : false;
      },
      set(value)
      {
        this.$emit('change', { ...this.content, multipleSize: value });
      },
    },
    iframeHeight: {
      get()
      {
        return this.content.iframeHeight || '';
      },
      set(newHeight)
      {
        this.$emit('change', { ...this.content, iframeHeight: newHeight });
      },
    },
    poster: {
      get()
      {
        return this.content.poster || '';
      },
      set(newPoster)
      {
        this.$emit('change', { ...this.content, poster: newPoster });
      },
    },
    backgroundMode: {
      get()
      {
        return this.content.backgroundMode || false;
      },
      set(newBackgroundMode)
      {
        this.$emit('change', { ...this.content, backgroundMode: newBackgroundMode });
      },
    },
    ratio: {
      get()
      {
        return this.content.ratio || '';
      },
      set(newRatio)
      {
        this.$emit('change', { ...this.content, ratio: newRatio });
      },
    },
    isVideoCaptag()
    {
      return this.source && this.source.includes('video.captag.events');
    },
  },
};

</script>

<style scoped>

</style>
