<template>
  <div :style="computedStyle" :class="separatorClass" class="my-4"></div>
</template>

<script>

export default {
  name: 'SeparatorContent',
  props: {
    content: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    separatorClass()
    {
      if (this.content.style)
        switch (this.content.style.alignment)
        {
          case 'default': return 'mx-auto';
          case 'text-center': return 'mx-auto';
          case 'text-end': return 'ms-auto';
          case 'text-start': return 'me-auto';
          default: return 'mx-auto';
        }
      return 'mx-auto';
    },
    computedStyle()
    {
      const out = { opacity: 1 };

      const border = this.content.style.border;
      if (border)
      {
        out.borderBottomWidth = border.thickness !== undefined ? `${border.thickness}px` : '';
        out.borderBottomStyle = border.style || '';
        out.borderBottomColor = border.color.hex8 || '';
      }

      if (this.content.style.width)
        out.width = `${this.content.style.width}%`;
      return out;
    },
  },
};

</script>

<style scoped>

</style>
