import { firebaseAction } from 'vuexfire';
import firebaseReference from './FirebaseReference';

/**
 * Firebase -> store bindings
 * it's defined through vuexFire and called by the bindToFirebase action
 */
export default {
  /**
   * Bind the website content to state.websiteContent
   */
  bindWebsiteContent: firebaseAction(async function callback({ getters, bindFirebaseRef, commit })
  {
    console.log('Binding Website content');
    const fbRef = await firebaseReference(getters.tokenID);
    if (fbRef !== undefined)
      return bindFirebaseRef('websiteContent', fbRef.ref('Storage')
        .child('WebsiteBuilder')
        .child(getters.path)
        .child('websiteContent'));
    commit('toggleLoadingError', { error: true, content: { code: 404, message: getters.dictionary.pathError } });
    console.log('Error while binding to base');
    return undefined;
  }),

  /**
   * Bind the website content to state coworkers
   */
  bindCoworkers: firebaseAction(async function callback({ getters, bindFirebaseRef })
  {
    console.log('Binding coworkers');
    const fbRef = await firebaseReference(getters.tokenID);
    if (fbRef !== undefined)
      return bindFirebaseRef('coworkers', fbRef.ref('Storage')
        .child('WebsiteBuilder')
        .child(getters.path)
        .child('coworkers'));
    console.log('Error while binding to base : coworker');
    return undefined;
  }),

  /**
   * Bind the website content to state.liveCommit
   */
  bindLiveCommit: firebaseAction(async function callback({ getters, bindFirebaseRef })
  {
    console.log('Binding live commit');
    const fbRef = await firebaseReference(getters.tokenID);
    if (fbRef !== undefined)
      return bindFirebaseRef('liveCommit', fbRef.ref('Storage')
        .child('WebsiteBuilder')
        .child(getters.path)
        .child('live'));
    console.log('Error while binding to base : live commit');
    return undefined;
  }),
  /**
   * Bind the website content to state.liveCommit
   */
  bindHistoryLimit: firebaseAction(async function callback({ getters, bindFirebaseRef })
  {
    console.log('Binding history limit');
    const fbRef = await firebaseReference(getters.tokenID);
    if (fbRef !== undefined)
      return bindFirebaseRef('historyLimit', fbRef.ref('Storage')
        .child('WebsiteBuilder')
        .child(getters.path)
        .child('historyLimit'));
    console.log('Error while binding to base : history limit');
    return undefined;
  }),

  /**
   * Bind the preferred url to state.preferredUrl
   */
  bindPreferredUrl: firebaseAction(async function callback({ getters, bindFirebaseRef })
  {
    console.log('Binding preferred Url');
    const fbRef = await firebaseReference(getters.tokenID);
    if (fbRef !== undefined)
      return bindFirebaseRef('preferredUrl', fbRef.ref('Storage')
        .child('WebsiteBuilder')
        .child(getters.path)
        .child('preferredUrl'));
    console.log('Error while binding to base : preferred Url');
    return undefined;
  }),

  /**
   * Bind the website content to state.websiteContent
   */
  bindEventData: firebaseAction(async function callback({ getters, bindFirebaseRef })
  {
    console.log('Binding event data');
    const fbRef = await firebaseReference(getters.tokenID);
    if (fbRef !== undefined && getters.eventID)
      return bindFirebaseRef('eventData', fbRef.ref('datas').child(getters.eventID));
    console.log('Error while binding to base : datas');
    return undefined;
  }),

  /**
   * Bind the website content to state.formFilters
   */
  bindFormFilters: firebaseAction(async function callback({ getters, bindFirebaseRef })
  {
    console.log('Binding form filters');
    const fbRef = await firebaseReference(getters.tokenID);
    if (fbRef !== undefined && getters.eventID)
      return bindFirebaseRef('formFilters',
        fbRef.ref('animations')
          .child(getters.path)
          .child('config')
          .child('settings')
          .child('forms'));
    console.log('Error while binding to base : form filters');
    return undefined;
  }),
};
