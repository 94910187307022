<template>
  <div class="row editorUIStyle">
    <label v-html="label"></label>
    <div class="col-4 text-center">
      <button
          type="button"
          @click="changeAlignment('text-start')"
          class="w-100 btn btn-raised text-light"
          :class="value === 'text-start' ? 'btn-success': 'btn-secondary'">
        <i class="fas fa-align-left"></i>
      </button>
    </div>
    <div class="col-4 text-center">
      <button
          type="button"
          @click="changeAlignment('text-center')"
          class="w-100 btn btn-raised text-light"
          :class="value === 'text-center' ? 'btn-success': 'btn-secondary'">
        <i class="fas fa-align-center"></i>
      </button>
    </div>
    <div class="col-4 text-center">
      <button
          type="button"
          @click="changeAlignment('text-end')"
          class="w-100 btn btn-raised  text-light"
          :class="value === 'text-end' ? 'btn-success': 'btn-secondary'">
        <i class="fas fa-align-right"></i>
      </button>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'alignmentProperties',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    noDefault: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
  methods: {
    changeAlignment(newValue)
    {
      if (this.value === newValue && !this.noDefault)
      {
        this.$emit('change', 'default');
        return;
      }
      this.$emit('change', newValue);
    },
  },
};
</script>

<style scoped>

</style>
