<template>
  <div class="row">
    <div class="col px-0 worker-icon-col">
      <button
          class="worker-icon btn btn-sm btn-outline-primary text-light btn-raised rounded-circle px-0"
          @click="$refs.youWorkerInfo.openContext()">
        {{ workerData.initial }}
      </button>
    </div>
    <div v-for="w in limitedWorkerList.slice(-2)" :key="w.id" class="col px-0 worker-icon-col">
      <button
          v-if="!(workerData.id === w.id)"
          :style="`background-color: ${w.color.primary};border: unset;`"
          @click="openWorkerContext(w)"
          class="worker-icon btn btn-sm btn-secondary rounded-circle px-0">{{ w.initial }}</button>
      <WorkerInfoContext :ref="`worker-info-${w.id}`" :worker="w" :isYou="false"/>
    </div>
    <div v-if="this.coworkers.length > 3" class="col px-0 worker-icon-col">
      <button class="worker-icon btn btn-sm btn-secondary btn-raised rounded-circle bg-primary px-0"><i class="fas fa-ellipsis-h"></i></button>
    </div>
    <WorkerInfoContext ref="youWorkerInfo" :worker="workerData" :isYou="true"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import WorkerInfoContext from '../../contexts/WorkerInfoContext.vue';

export default {
  name: 'CoworkerIcons',
  components: { WorkerInfoContext },
  computed: {
    ...mapGetters(['coworkers', 'workerData']),
    limitedWorkerList()
    {
      const list = Object.values(this.coworkers);
      return list.filter((worker) => !(worker.id === this.workerData.id) && (Date.now() - worker.lastUpdate < 6000));
    },
  },
  methods: {
    openWorkerContext(worker)
    {
      this.$refs[`worker-info-${worker.id}`][0].openContext();
    },
  },
};
</script>

<style scoped>

.worker-icon-col {
  margin-left: -5px;
}

.worker-icon {
  height:32px;
  width:32px;
}

</style>
