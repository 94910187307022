<template>
  <form @submit.stop.prevent>
    <div class="mb-2">
      <label>{{ dictionary.formModule.dataLabel }}</label>
      <EventDataSelect v-model="data" :allowed-types="['String', 'StringMultiline']"/>
    </div>
    <div class="mb-2">
      <label for="FormItemLabelInput" class="form-label">{{ dictionary.formModule.inputLabelLabel }}<CodeCompatibleBadge class="ms-2" position="top"/></label>
      <div class="input-group">
        <input :value="formItem.label || ''" @change="$emit('change', { ...formItem, label: $event.target.value })" type="text" class="form-control" id="FormItemLabelInput">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{ layoutDisplayButton }}</button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" @click="layout = 'top'">{{ dictionary.formModule.labelTopPositionLabel }}</a></li>
          <li><a class="dropdown-item" @click="layout = 'left'">{{ dictionary.formModule.labelLeftPositionLabel }}</a></li>
          <li><a class="dropdown-item" @click="layout = 'floating'">Floating</a></li>
        </ul>
      </div>
    </div>
    <div class="mb-2">
      <label for="FormItemPlaceholderInput" class="form-label">{{ dictionary.formModule.placeholderLabel }}</label>
      <input :value="formItem.placeholder || ''" @change="$emit('change', { ...formItem, placeholder: $event.target.value })" type="text" class="form-control" id="FormItemPlaceholderInput">
    </div>
    <div class="mb-2">
      <label for="FormItemSmallLabelInput" class="form-label">{{ dictionary.formModule.descriptionLabel }}</label>
      <div class="input-group">
        <input :value="formItem.smallLabel || ''" @change="$emit('change', { ...formItem, smallLabel: $event.target.value })" type="text" class="form-control" id="FormItemSmallLabelInput">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="true">{{ descriptionLayout === 'bottom' ? dictionary.formModule.descBottomPositionLabel : dictionary.formModule.descTopPositionLabel }}</button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" @click="descriptionLayout = 'top'">{{ dictionary.formModule.descTopPositionLabel }}</a></li>
          <li><a class="dropdown-item" @click="descriptionLayout = 'bottom'">{{ dictionary.formModule.descBottomPositionLabel }}</a></li>
        </ul>
      </div>
    </div>
    <div class="mb-2">
      <label for="FormItemMinimumInput" class="form-label">{{ dictionary.formModule.maxCharactersLabel }}</label>
      <input placeholder="no max" v-model="charMax" type="number" class="form-control" id="FormItemMinimumInput" min="0">
    </div>
    <div class="mb-2 row">
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="required" class="form-check-input" type="checkbox" id="FormItemRequiredSwitch">
          <label class="form-check-label" for="FormItemRequiredSwitch">{{ dictionary.formModule.requiredLabel }}</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="readonly" class="form-check-input" type="checkbox" id="FormItemReadonlySwitch">
          <label class="form-check-label" for="FormItemReadonlySwitch">{{ dictionary.formModule.readonlyLabel }}</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="hidden" class="form-check-input" type="checkbox" id="FormItemHideSwitch">
          <label class="form-check-label" for="FormItemHideSwitch">Masquer ce champ</label>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import { mapGetters } from 'vuex';
import CodeCompatibleBadge from '../../Utilities/CodeCompatibleBadge.vue';
import EventDataSelect from '../../Utilities/EventDataSelect.vue';

export default {
  name: 'FormTextareaConfig',
  components: {
    CodeCompatibleBadge,
    EventDataSelect,
  },
  props: {
    formItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    readonly: {
      get()
      {
        return this.formItem.readonly || '';
      },
      set(newReadonly)
      {
        this.$emit('change', { ...this.formItem, readonly: newReadonly });
      },
    },
    hidden: {
      get()
      {
        return this.formItem.hidden || false;
      },
      set(newHidden)
      {
        this.$emit('change', { ...this.formItem, hidden: newHidden });
      },
    },
    required: {
      get()
      {
        return this.formItem.required || '';
      },
      set(newRequired)
      {
        this.$emit('change', { ...this.formItem, required: newRequired });
      },
    },
    data: {
      get()
      {
        return this.formItem.data || '';
      },
      set(newData)
      {
        this.$emit('change', { ...this.formItem, data: newData });
      },
    },
    charMax: {
      get()
      {
        return this.formItem.max || '';
      },
      set(newMaximum)
      {
        this.$emit('change', { ...this.formItem, max: newMaximum });
      },
    },
    descriptionLayout: {
      get()
      {
        return this.formItem.descriptionLayout || 'bottom';
      },
      set(newDescriptionLayout)
      {
        this.$emit('change', { ...this.formItem, descriptionLayout: newDescriptionLayout });
      },
    },
    layout: {
      get()
      {
        return this.formItem.layout || '';
      },
      set(newLayout)
      {
        this.$emit('change', { ...this.formItem, layout: newLayout });
      },
    },
    layoutDisplayButton()
    {
      if (typeof this.layout === 'boolean')
        return this.layout ? this.dictionary.formModule.labelTopPositionLabel : this.dictionary.formModule.labelLeftPositionLabel;
      switch (this.layout)
      {
        case 'top': return this.dictionary.formModule.labelTopPositionLabel;
        case 'left': return this.dictionary.formModule.labelLeftPositionLabel;
        case 'floating': return 'Floating';
        default: return this.dictionary.formModule.labelTopPositionLabel;
      }
    },
  },
};

</script>

<style scoped>

</style>
