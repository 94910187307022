<template>
  <textarea
      :id="`${id}-input`"
      :aria-describedby="`help-${id}`"
      :required="!!required"
      :readonly="!!readonly"
      :placeholder="placeholder"
      :maxlength="(formItem.max) ? formItem.max : ''"
      :name="dataName"
      value=""
      class="form-control"/>
</template>

<script>
export default {
  name: 'FormTextArea',
  props: {
    formItem: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    isAccField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    placeholder()
    {
      return this.formItem.placeholder || '';
    },
    required()
    {
      return this.formItem.required || false;
    },
    readonly()
    {
      return this.formItem.readonly || false;
    },
    dataName()
    {
      if (this.isAccField)
        return `${this.formItem.data || ''}_acc_1`;
      return this.formItem.data || '';
    },
  },
};
</script>

<style scoped>

</style>
