import { render, staticRenderFns } from "./NewModalContext.vue?vue&type=template&id=549312e0&scoped=true"
import script from "./NewModalContext.vue?vue&type=script&lang=js"
export * from "./NewModalContext.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "549312e0",
  null
  
)

export default component.exports