/**
 * All these variables can be accessed through mutation and/or actions
 * do not access nor modify these directly!
 * If you don't find your way with mutation/actions it's because you're not supposed to edit the value
 */
const state = {
  lang: 'en',
  /// //////////////////// FIREBASE DATA
  tokenID: '',
  path: '',
  /// //////////////////// EDITOR STATE
  currentEditorMode: 'normal',
  currentPage: '',
  versionNumber: '',
  loading: true,
  loadingError: false,
  forceMobileAccess: false,
  lastError: {
    code: 0,
    message: '',
  },
  workerData: {
    name: '',
    id: '',
    lastUpdate: '',
    selectedColumnID: '',
    currentPage: '',
  },
  coworkers: [],
  eventData: {},
  firebaseSpinner: false,
  liveCommit: {},
  formFilters: {},
  activeWebsiteFilter: '',
  historyLimit: 5,
  /// //////////////////// WEBSITE JSON DATA
  websiteContent: {},
  preferredUrl: '',
};

export default state;
