/* eslint-disable no-shadow */// No shadow due to the getters with parameter

import ContentDefaultValues from '../configuration/ContentDefaultValues.json';
import DictionaryFrench from '../Dictionary/DictionaryFrench.json';
import DictionaryEnglish from '../Dictionary/DictionaryEnglish.json';
import StyleGetters from './StyleGetters';

const getters = {
  ...StyleGetters,
  /// The website path in firebase
  path: (state) => state.path,
  // The eventID
  eventID: (state) => state.path.substring(0, state.path.indexOf('/')),
  // the Animation ID
  animID: (state) => state.path.substring(state.path.indexOf('/') + 1),
  // All the event datas
  eventData: (state) => {
    const out = state.eventData || {};
    if (!out.rgpd)
      return { ...out, rgpd: {} };
    return out;
  },
  // Is the page loading
  isLoading: (state) => state.loading,
  // Is there error on the website loading
  isLoadingError: (state) => state.loadingError,
  // Last loading error
  lastError: (state) => state.lastError,
  // Firebase token ID
  tokenID: (state) => state.tokenID,
  // Is there a website content
  isWebsiteContent: (state) => !(state.websiteContent === undefined || Object.keys(state.websiteContent).length <= 0),
  // Website body configuration (mainly style)
  bodyConfig: (state) => state.websiteContent.body || {},
  // Website form filters
  formFilters: (state) => state.formFilters || {},
  // Website rows filters
  websiteFilters: (state) => state.websiteContent.filters || {},
  // Modal list
  modalList: (state) => state.websiteContent.modals || {},
  // Curent active website filter
  activeWebsiteFilter: (state) => state.activeWebsiteFilter || '',
  // User defined Style
  customCSS: (state) => state.websiteContent.customCSS || '',
  // User defined Javascript
  customJS: (state) => state.websiteContent.customJS || '',
  // Editor current mode, can be 'normal' or 'advanced'
  currentEditorMode: (state) => state.currentEditorMode || 'normal',
  // the default/fallback page
  defaultPage: (state) => {
    let out = '';
    if (!state.websiteContent.pages)
      return '';
    Object.entries(state.websiteContent.pages).forEach(([pageID, page]) => {
      if (state.websiteContent.pages.default === page.name)
        out = pageID;
    });
    return out;
  },
  // the currently edited/selected page
  currentPage: (state, getters) => {
    if (!state.workerData.currentPage || !state.websiteContent.pages[state.workerData.currentPage])
      return getters.defaultPage;
    return state.workerData.currentPage || '';
  },
  pageList: (state) => state.websiteContent.pages || {},
  // website rows array
  rowList: (state, getters) => {
    if (getters.currentPage && state.websiteContent.pages[getters.currentPage])
      return state.websiteContent.pages[getters.currentPage].rows || [];
    return [];
  },
  pageRowList: (state) => (pageID) => {
    if (state.websiteContent.pages[pageID])
      return state.websiteContent.pages[pageID].rows || [];
    return [];
  },
  headerRowList: (state) => (state.websiteContent.header ? state.websiteContent.header.rows || [] : []),
  footerRowList: (state) => (state.websiteContent.footer ? state.websiteContent.footer.rows || [] : []),
  // Return a list of rows IDs
  rowIDList: (state, getters) => {
    const out = [];
    if (getters.rowList === undefined)
      return [];
    getters.rowList.forEach((row) => { out.push(row.id); });
    return out;
  },
  // User defined fonts
  googleFonts: (state) => state.websiteContent.googleFonts || {},
  // The color palette
  colorPalette: (state) => state.websiteContent.colorPalette || [],
  isJSObfuscation: (state) => ((state.websiteContent.JSObfuscation !== undefined) ? state.websiteContent.JSObfuscation : true),
  // This user datas
  workerData: (state) => state.workerData,
  // datas of all the peoples working on the website
  coworkers: (state) => state.coworkers,
  // Builder current language
  language: (state) => state.lang,
  // Selected Column ID
  selectedColID: (state) => state.workerData.selectedColumnID,
  // Selected Column
  selectedColumn: (state, getters) => {
    function getColInList(list)
    {
      let found = undefined;
      Object.values(list || []).forEach((r) => {
        Object.values(r.columns || []).forEach((c) => {
          if (c.id === getters.selectedColID)
            found = c;
        });
      });
      return found;
    }
    let found = getColInList(getters.rowList);
    if (!found)
      found = getColInList(getters.headerRowList);
    if (!found)
      found = getColInList(getters.footerRowList);
    return found;
  },
  // The row containing the selected column
  selectedRow: (state, getters) => {
    function getRowInList(list)
    {
      let found = undefined;
      Object.values(list || []).forEach((r) => {
        Object.values(r.columns || []).forEach((c) => {
          if (c.id === getters.selectedColID)
            found = r;
        });
      });
      return found;
    }
    let found = getRowInList(getters.rowList);
    if (!found)
      found = getRowInList(getters.headerRowList);
    if (!found)
      found = getRowInList(getters.footerRowList);
    return found;
  },
  headerConfig: (state) => state.websiteContent.header || {},
  footerConfig: (state) => state.websiteContent.footer || {},
  preferredUrl: (state) => state.preferredUrl.url || '',
  isRowFirst: (state, getters) => (row) => {
    if (getters.rowList.indexOf(row) >= 0)
      return getters.rowList.indexOf(row) === 0;
    if (getters.headerRowList.indexOf(row) >= 0)
      return getters.headerRowList.indexOf(row) === 0;
    return false;
  },
  isRowLast: (state, getters) => (row) => {
    if (getters.rowList.indexOf(row) >= 0)
      return getters.rowList.indexOf(row) === getters.rowList.length - 1;
    if (getters.headerRowList.indexOf(row) >= 0)
      return getters.headerRowList.indexOf(row) === getters.headerRowList.length - 1;
    return false;
  },
  isRowInHeader: (state) => (row) => {
    if (row && state.websiteContent.header && state.websiteContent.header.rows)
      return state.websiteContent.header.rows.indexOf(row) >= 0;
    return false;
  },
  isRowInFooter: (state) => (row) => {
    if (row && state.websiteContent.footer && state.websiteContent.footer.rows)
      return state.websiteContent.footer.rows.indexOf(row) >= 0;
    return false;
  },
  // Return the default content for a column type (ex: Text, form ... )
  getDefaultContentForType: () => (type) => ((ContentDefaultValues[type] !== undefined) ? ContentDefaultValues[type] : {}),
  // Return true if the given row contain the current selected col
  isRowSelected: (state, getters) => (row) => {
    let found = false;
    if (!row.columns)
      return false;
    Object.values(row.columns).forEach((c) => {
      if (c.id === getters.selectedColID)
        found = true;
    });
    return found;
  },
  // Return true if the given col is selected by a worker
  isColSelectedByUser: (state) => (col) => {
    let found = undefined;
    Object.values(state.coworkers).forEach((worker) => {
      if (worker.selectedColumnID === col.id)
        found = worker;
    });
    return found;
  },
  getFilterUsage: (state) => (filterID) => {
    function getUsageInList(list)
    {
      const out = { rows: 0, cols: 0 };
      list.forEach((row) => {
        if (row.filter === filterID)
          out.rows = out.rows + 1;
        if (row.columns)
          row.columns.forEach((col) => {
            if (col.filter === filterID)
              out.cols = out.cols + 1;
          });
      });
      return out;
    }
    let headerVal = {};
    let footerVal = {};
    const out = { rows: 0, cols: 0, total: 0 };
    Object.values(state.websiteContent.pages).forEach((page) => {
      if (!page.rows)
        return;
      const tmp = getUsageInList(page.rows);
      out.cols = out.cols + tmp.cols;
      out.rows = out.rows + tmp.rows;
    });
    if (state.websiteContent.header && state.websiteContent.header.rows)
      headerVal = getUsageInList(state.websiteContent.header.rows);
    if (state.websiteContent.footer && state.websiteContent.footer.rows)
      footerVal = getUsageInList(state.websiteContent.footer.rows);
    out.cols = out.cols + (headerVal.cols || 0) + (footerVal.cols || 0);
    out.rows = out.rows + (headerVal.rows || 0) + (footerVal.rows || 0);
    out.total = out.rows + out.cols;
    return out;
  },
  // return the dictionary for the current builder language, used to translate the interface
  dictionary: (state) => {
    if (state.lang === 'fr')
      return DictionaryFrench;
    if (state.lang === 'en')
      return DictionaryEnglish;
    return DictionaryFrench;
  },
  // Is the builder opened on mobile device
  isMobile: () => (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)),
  // Usually when the builder is opened in mobile device there is an error message,
  // but you can force the access through this boolean
  forceMobileAccess: (state) => state.forceMobileAccess,
  // Builder version number
  versionNumber: (state) => state.versionNumber,
  // eslint-disable-next-line no-underscore-dangle
  isCompatibleBuilderContent: (state) => !(state.websiteContent && state.websiteContent.config && state.websiteContent.config.__builder_version__),
  // if true the firebase sync widget is displayed
  firebaseSpinner: (state) => state.firebaseSpinner,
  // Live version of the website
  liveCommit: (state) => state.liveCommit,
  // History limitation variable
  historyLimit: (state) => {
    // For unknown reason firebase bind the value under ['.value']
    const out = state.historyLimit['.value'];
    if (out && !Number.isNaN(parseInt(out, 10)))
      return parseInt(out, 10);
    return 5;
  },
  // Convert a string to Slug
  stringToSlug: () => (str, isLowerCase = true) => {
    if (str === null || str === undefined)
      return '';
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    if (isLowerCase)
      str = str.toLowerCase();

    const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeiiiioooouuuunc-----';

    [...from].forEach((c, idx) => {
      str = str.replace(new RegExp(c, 'g'), to.charAt(idx));
    });

    str = str
      .replace(/\./g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');

    return str;
  },
};

export default getters;

/* eslint-enable no-shadow */
