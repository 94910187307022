<template>
  <div class="row me-0 editorUIStyle">
    <div class="col-12">
      <label class='small' for='idEditor'>{{ title }}</label>
    </div>
    <div :class="readonly ? 'col-12' : 'col-10'">
      <input :readonly="readonly" id='idEditor' :value="id" :placeholder="dictionary.utilities.noIDDefined" @change="emitter" type='text' class="form-control">
    </div>
    <button v-if="!readonly" class='col-2 btn btn-info btn-raised text-light' @click.prevent='generateID'><i class='fas fa-cogs'></i></button>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'IdEditor',
  model: {
    prop: 'id',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    id: String,
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['dictionary', 'stringToSlug']),
  },
  methods: {
    emitter(event)
    {
      this.$emit('change', this.stringToSlug(event.target.value, false));
    },
    generateID()
    {
      this.$emit('change', this.stringToSlug(this.$chance.string(this.$newIDParams)));
    },
  },
};

</script>

<style scoped>

</style>
