<template>
  <StyleEditor v-model="style" :properties="['alignment', 'border', 'borderRadius']"  :noButtons="true" class="mx-3">
    <template v-slot:form-start>
      <div class="form-check form-switch mb-3">
        <input v-model="hidden" class="form-check-input" type="checkbox" id="FormItemHideSwitch">
        <label class="form-check-label" for="FormItemHideSwitch">Masquer ce séparateur</label>
      </div>
      <div class="mb-3">
        <label class='small'>{{ dictionary.formModule.dividerThicknessLabel }}</label>
        <vue-slider class="mt-2" :min="0" :max="100" v-model="width"/>
      </div>
    </template>
  </StyleEditor>
</template>

<script>

import VueSlider from 'vue-slider-component';
import { mapGetters } from 'vuex';
import StyleEditor from '../../Utilities/StyleEditor.vue';

export default {
  name: 'FormSeparatorConfig',
  components: {
    StyleEditor,
    VueSlider,
  },
  props: {
    formItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    hidden: {
      get()
      {
        return this.formItem.hidden || false;
      },
      set(newHidden)
      {
        this.$emit('change', { ...this.formItem, hidden: newHidden });
      },
    },
    style: {
      get()
      {
        return this.formItem.style || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.formItem, style: newStyle });
      },
    },
    width: {
      get()
      {
        return this.formItem.style.width || 0;
      },
      set(newWidth)
      {
        this.$emit('change', { ...this.formItem, style: { ...this.formItem.style, width: newWidth } });
      },
    },
  },
};

</script>

<style scoped>

</style>
