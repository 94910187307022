<template>
  <div class="colOverlay">
    <confirmationContext
        :introMessage="`<h6>${dictionary.columnOverlay.deleteColumnConfirm}</h6>`"
        :confirmButtonContent="dictionary.content"
        :deniButtonContent="dictionary.column"
        @deni="onDeleteColumn"
        @confirm="onDeleteContent"
        ref="confirmationDeleteCol"/>
    <!-- Top left buttons start -->
    <div class="position-absolute row bg-secondary rounded-top pb-1 m-0 start-0 top-0" style="-webkit-transform: translateY(-100%); transform: translateY(-100%);">
      <div class="col-auto">
        <i class="cursor-grab fas fa-arrows-alt dragHandle m-0 text-light pt-2"></i>
      </div>
      <div v-if="!isEmpty" class="col-auto">
        <button @click="onEditClick" v-tooltip.top="dictionary.columnOverlay.editContentTooltip" class="btn btn-sm btn-success w-100 py-0 mt-1 text-center"><i class="fas fa-pen text-light"></i></button>
      </div>
      <div class="col-auto">
        <button @click="$refs.confirmationDeleteCol.openContext()" v-tooltip.top="dictionary.columnOverlay.deleteContentTooltip" class="btn btn-sm btn-danger w-100 py-0 mt-1 text-center"><i class="fas fa-eraser text-light"></i></button>
      </div>
      <div v-if="hasFilter" class="col-auto">
        <button style="cursor: default" v-tooltip.top="dictionary.columnOverlay.filteredColumnTooltip" class="btn btn-sm w-100 py-0 mt-1 text-center btn-info"><i class="fas fa-filter text-white"></i></button>
      </div>
      <div v-if="hasSpacing" class="col-auto">
        <button @click="onToggleSpacing" :class="toggleSpacing ? 'btn-success' : 'btn-primary'" v-tooltip.top="dictionary.columnOverlay.displayPadMarTooltip" class="btn btn-sm btn-primary w-100 py-0 mt-1 text-center"><i class="fas fa-object-group text-white"></i></button>
      </div>
    </div>
    <!-- Top left buttons End -->
    <!-- Empty column buttons Start -->
    <div v-if="isEmpty" class="row h-100 mx-0 d-flex align-items-center">
      <div class="col-12 text-center">
        <ColumnContentContext @contentClicked="setColumnContent" ref="addContentContext"/>
        <button v-tooltip.top="'Sélectionner un contenus'" @click="openContentContextClick" class="btn btn-secondary btn-raised "><i class="fas fa-box"></i></button>
        <button v-tooltip.top="'Importer une colonne'" @click="importButtonClick" class="btn btn-success btn-raised ms-2 text-light"><i class="fas fa-download"></i></button>
      </div>
      <input accept="application/JSON" ref="importHiddenInput" type="file" @change="importCol" class="invisible">
    </div>
    <!-- Empty column buttons End -->
    <!-- Start a form buttons Start -->
    <div v-else-if="column.content.type === 'form' && !isFormCreated" class="row h-100 mx-0 d-flex align-items-end pb-3">
      <div class="col-12 text-center">
        <button class="btn btn-outline-success fw-bold" @click="onEditClick">{{ dictionary.columnOverlay.startAFormButton }} <i class="fas fa-pen ms-2"></i></button>
      </div>
    </div>
    <!-- Start a form buttons End -->
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import ColumnContentContext from '../contexts/ColumnContentContext.vue';
import confirmationContext from '../contexts/ConfirmationContext.vue';

export default {
  name: 'ColumnOverlay',
  components: {
    ColumnContentContext,
    confirmationContext,
  },
  props: {
    column: Object,
    row: Object,
  },
  data()
  {
    return {
      layoutEdition: false,
      toggleSpacing: false,
    };
  },
  mounted()
  {
    this.$emit('showTextEditor', false);
  },
  computed: {
    ...mapGetters(['dictionary', 'getDefaultContentForType', 'currentPage']),
    isEmpty()
    {
      if (!this.column.content)
        return true;
      return !this.column.content.type;
    },
    isFormCreated()
    {
      return !this.isEmpty &&
          this.column.content.type === 'form' &&
          this.column.content.data &&
          this.column.content.data.formType;
    },
    hasFilter()
    {
      return !!this.column.filter;
    },
    hasSpacing()
    {
      if (!this.column.values || !this.column.values.spacing)
        return false;
      if (this.column.values.spacing.margin)
        return this.column.values.spacing.margin.top ||
          this.column.values.spacing.margin.down ||
          this.column.values.spacing.margin.left ||
          this.column.values.spacing.margin.right;
      if (this.column.values.spacing.padding)
        return this.column.values.spacing.padding.top ||
          this.column.values.spacing.padding.down ||
          this.column.values.spacing.padding.left ||
          this.column.values.spacing.padding.right;
      return false;
    },
  },
  methods: {
    importButtonClick()
    {
      this.$refs.importHiddenInput.click();
    },
    importCol(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (!object)
            return;
          if (object.content)
            this.$store.commit('setColumnContent', { column: this.column, content: object.content, dbSync: true });
          if (object.values)
            this.$store.commit('setColumnValues', { col: this.column, values: object.values, dbSync: true });
          if (object.layout)
            this.$store.commit('setColumnSize', { col: this.column, layout: object.layout, dbSync: true });
          this.importColumnNotif();
        } catch (ex)
        {
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
    onEditClick()
    {
      this.$EventBus.$emit('editColumn', this.column);
    },
    setColumnContent(contentType)
    {
      this.$store.commit('setColumnContent', { column: this.column, content: this.getDefaultContentForType(contentType), dbSync: true });
      this.setColumnContentNotif({ message: `Type <span class="text-primary">${contentType}</span> appliqué a la colonne` });
    },
    openContentContextClick()
    {
      this.$refs.addContentContext.openContext();
    },
    onDeleteColumn()
    {
      this.$store.commit('removeColumn', {
        pageID: this.currentPage,
        row: this.row,
        col: this.column,
        dbSync: true,
      });
      this.deleteColumn();
    },
    onDeleteContent()
    {
      this.$store.commit('setColumnContent', { column: this.column, content: {}, dbSync: true });
      this.deleteColumnContentNotif();
    },
    onToggleSpacing()
    {
      this.toggleSpacing = !this.toggleSpacing;
      this.$emit('toggleSpacing', this.toggleSpacing);
    },
  },
  notifications: {
    setColumnContentNotif: {
      type: VueNotifications.types.success,
      title: 'Colonne Modifié !',
      message: 'Contenu de la colonne appliqué.',
    },
    deleteColumnContentNotif: {
      type: VueNotifications.types.success,
      title: 'Colonne Modifié !',
      message: 'Contenus de la colonne supprimé.',
    },
    deleteColumn: {
      type: VueNotifications.types.success,
      title: 'Colonne Supprimé !',
      message: 'Colonne supprimé avec succès.',
    },
    importColumnNotif: {
      type: VueNotifications.types.success,
      title: 'Colonne importé !',
      message: 'La colonne à été importé avec succès.',
    },
  },
};
</script>

<style>

.colOverlay {
  border: 2px solid rgba(var(--bs-secondary-rgb));
}

:root {
  --ck-spacing-large: 0px;
  --ck-focus-ring: unset;
  --ck-inner-shadow: unset;
  --ck-spacing-standard: 0;
}

</style>
