<template>
  <div class="h-100 d-flex justify-content-center align-items-center align-middle">
    <div class="row w-100 text-center">
      <div class="col-12">
        <h1 class="title">{{ dictionary.pageTitle }}</h1>
      </div>
      <div class="col-12 mb-5">
      </div>
      <div class="col-12 mt-5 mb-3 d-flex justify-content-center align-items-center align-middle">
        <breeding-rhombus-spinner :animation-duration="2000" :size="150" color="#F16253"/>
      </div>
      <div class="col-12 mt-5">
        <h3 class="text-secondary">{{ dictionary.loadingMessage }}</h3>
      </div>
    </div>
  </div>
</template>

<script>

import { BreedingRhombusSpinner } from 'epic-spinners';
import { mapGetters } from 'vuex';

export default {
  name: 'LoadingPage',
  components: {
    BreedingRhombusSpinner,
  },
  data() {
    return {
      showTimeoutMessage: false,
    };
  },
  created()
  {
    setInterval(() => { this.showTimeoutMessage = true; }, 5000);
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
};

</script>

<style scoped>

.title {
  font-size: 70px;
}

.subtitle {
  font-size: 30px;
}
</style>
