<template>
  <div class="h-100">
    <div v-if="!loaded" class="row">
      <div class="col-auto mx-auto mt-4">
        <h3 class="text-muted">{{ dictionary.loadingMessage }}</h3>
      </div>
    </div>
    <perfect-scrollbar v-else class="pe-3 h-100" :options="{ suppressScrollX: true, wheelPropagation: false }">
      <div class="row my-3">
        <div class="col-auto">
          <h5 class="text-secondary">{{ dictionary.MediasList.websiteMedias }}</h5>
        </div>
        <div class="col">
          <hr>
        </div>
      </div>
      <div class="row">
        <div class="col-3 mb-3" v-for="(media, mediaIdx) in websiteMedias" :key="`doc-${mediaIdx}`">
          <card style="max-height: 300px">
            <template v-slot:header-center><p class="text-white mb-0 text-center fw-bold">{{ media.title }}</p></template>
            <div class="row mb-2">
              <div class="col-12 text-center mb-3">
                <img class="img-fluid" style="max-height: 100px" :src="media.thumbnail">
              </div>
              <div class="col-6">
                <button v-tooltip.top="'Ajouter à la liste'" @click="addImage(media.link)" class="btn btn-raised btn-success text-white w-100 py-0"><i class="fas fa-plus"></i></button>
              </div>
              <div class="col-6">
                <a v-tooltip.top="'Ouvrir dans un nouvel onglet'" :href="media.link" target="_blank" class="btn btn-raised btn-secondary text-white w-100 py-0"><i class="fas fa-external-link-alt"></i></a>
              </div>
            </div>
          </card>
        </div>
        <div class="row my-3">
          <div class="col-auto">
            <h5 class="mb-0 text-secondary">{{ dictionary.MediasList.eventDocuments }}</h5>
          </div>
          <div class="col">
            <hr>
          </div>
        </div>
        <div class="row">
          <div class="col-3 mb-3" v-for="(doc, docIdx) in filteredServerDocuments" :key="`doc-${docIdx}`">
            <card style="max-height: 300px">
              <template v-slot:header-center><p class="text-white mb-0 text-center fw-bold">{{ doc.title }}</p></template>
              <div class="row mb-2">
                <div class="col-12 text-center mb-3">
                  <img class="img-fluid" style="max-height: 100px" :src="doc.thumb">
                </div>
                <div class="col-6">
                  <button v-tooltip.top="'Ajouter à la liste'" @click="addImage(doc.link)" class="btn btn-raised btn-success text-white w-100 py-0"><i class="fas fa-plus"></i></button>
                </div>
                <div class="col-6">
                  <a v-tooltip.top="'Ouvrir dans un nouvel onglet'" :href="doc.link" target="_blank" class="btn btn-raised btn-secondary text-white w-100 py-0"><i class="fas fa-external-link-alt"></i></a>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import Card from '../Utilities/Card.vue';

export default {
  name: 'websiteImagesList',
  components: {
    Card,
  },
  model: {
    prop: 'images',
    event: 'change',
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      serverDocuments: {},
      websiteMedias: {},
      loaded: false,
    };
  },
  mounted() {
    this.$store.dispatch('getDocumentsFromBase').then((docs) => {
      this.serverDocuments = docs;
      this.$store.dispatch('getWebsiteMedias').then((medias) => {
        this.websiteMedias = medias;
        this.loaded = true;
      });
    });
  },
  computed: {
    ...mapGetters(['dictionary']),
    filteredServerDocuments() {
      return Object.values(this.serverDocuments).filter((element) => element.type === 'JPG' || element.type === 'PNG');
    },
    imagesList: {
      get()
      {
        return this.images || [];
      },
      set(newImagesList)
      {
        this.$emit('change', newImagesList);
      },
    },
  },
  methods: {
    addImage(mediaLink)
    {
      const newImages = _.cloneDeep(this.imagesList);
      newImages.push({
        src: mediaLink,
      });
      this.imagesList = newImages;
    },
  },
};
</script>

<style scoped>

</style>
