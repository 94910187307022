<template>
  <form @submit.stop.prevent class="editorUIStyle">
    <slot name="form-start"></slot>
    <div class="mb-2" v-for="(prop, propIdx) in this.properties" :key="`property${propIdx}`">
      <InputColorPicker
          v-if="prop === 'backgroundColor'"
          :label="dictionary.styleEditor.backgroundColor"
          :value="propertyGetValue('backgroundColor', {})"
          @change="propertySetValue('backgroundColor', $event)"/>
      <InputColorPicker
          v-else-if="prop === 'color'"
          :label="dictionary.styleEditor.fontColor"
          :value="propertyGetValue('color', { hex: '#000000' })"
          @change="propertySetValue('color', $event)"/>
      <hr v-else-if="prop === 'hr'">
      <InputImageUpload
          v-else-if="prop === 'backgroundImage'"
          :title="dictionary.styleEditor.backgroundImage"
          :value="propertyGetValue('backgroundImage', '')"
          @change="propertySetValue('backgroundImage', $event)"/>
      <BackgroundProperties
          v-else-if="prop === 'backgroundImageConfig'"
          :label="dictionary.styleEditor.bodyBackgroundProperties"
          :value="propertyGetValue('backgroundImageProperties', {})"
          @change="propertySetValue('backgroundImageProperties', $event)"/>
      <AlignmentProperties
          v-else-if="prop === 'alignment'"
          :label="dictionary.styleEditor.alignment"
          :value="propertyGetValue('alignment', '')"
          @change="propertySetValue('alignment', $event)"/>
      <SpacingEditor
          v-else-if="prop === 'spacing'"
          :value="propertyGetValue('spacing', {})"
          @change="propertySetValue('spacing', $event)"/>
      <div class="row" v-else-if="prop === 'borderRadius'">
        <div class="col-auto">
          <label class='small'>{{ dictionary.styleEditor.borderRadius }}</label>
        </div>
        <div class="col justify-content-center">
          <VueSlider
              :min="0"
              :max="100"
              :value="propertyGetValue('borderRadius', 0)"
              @change="propertySetValue('borderRadius', $event)"/>
        </div>
      </div>
      <BorderEditor
          v-else-if="prop === 'border'"
          label="Bords"
          :value="propertyGetValue('border', {})"
          @change="propertySetValue('border', $event)"/>
      <FontSelector
          v-else-if="prop === 'fontFamily' && properties.includes('fontWeight')"
          :label="dictionary.font"
          :disable-weight="!properties.includes('fontWeight')"
          :family="propertyGetValue('fontFamily', '')"
          :weight="propertyGetValue('fontWeight', '')"
          @changeFamily="propertySetValue('fontFamily', $event)"
          @changeWeight="propertySetValue('fontWeight', $event)"/>
      <FontSizeInput
          v-else-if="prop === 'fontSize'"
          :label="dictionary.styleEditor.fontSize"
          :value="propertyGetValue('fontSize', '0')"
          @change="propertySetValue('fontSize', $event)"/>
    </div>
    <slot name="form-end"></slot>
    <div v-if="!noButtons" class="row justify-content-end my-2 mb-0">
      <div class="col-auto">
        <div class="dropdown">
          <button class="btn btn-sm btn-secondary btn-raised dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa pull-right" :class="`fa-cog`"></i></button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" @click.prevent="exportStyle"><i class="fas fa-download me-3"></i>{{ dictionary.styleEditor.exportStyle }}</a></li>
            <li><a class="dropdown-item" @click.prevent="importButtonClick"><i class="fas fa-upload me-3"></i>{{ dictionary.styleEditor.importStyle }}</a></li>
            <li><a class="dropdown-item text-danger" @click.prevent="deleteStyle"><i class="fas fa-trash me-3"></i>{{ dictionary.styleEditor.deleteStyle }}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <input accept="application/JSON" ref="importHiddenInput" type="file" @change="importStyle" class="invisible">
      </div>
    </div>
  </form>
</template>

<script>

import { mapGetters } from 'vuex';
import 'vue-slider-component/theme/material.css';
import VueSlider from 'vue-slider-component';
import InputColorPicker from './InputColorPicker.vue';
import InputImageUpload from './InputImageUpload.vue';
import AlignmentProperties from './AlignmentProperties.vue';
import SpacingEditor from './SpacingEditor.vue';
import FontSelector from './FontSelector.vue';
import FontSizeInput from './FontSizeInput.vue';
import BackgroundProperties from './BackgroundProperties.vue';
import BorderEditor from './BorderEditor.vue';

export default {
  name: 'StyleEditor',
  components: {
    FontSizeInput,
    FontSelector,
    SpacingEditor,
    AlignmentProperties,
    InputColorPicker,
    InputImageUpload,
    BackgroundProperties,
    VueSlider,
    BorderEditor,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    noButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    properties: {
      type: Array,
      required: false,
      default: () => [
        'fontFamily',
        'fontWeight',
        'fontSize',
        'color',
        'hr',
        'backgroundColor',
        'backgroundImage',
        'backgroundImageConfig',
        'hr',
        'alignment',
        'spacing',
        'hr',
        'border',
        'borderRadius',
      ],
    },
    enableCustomProperties: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
  methods: {
    deleteStyle()
    {
      this.$emit('change', {});
    },
    importButtonClick()
    {
      this.$refs.importHiddenInput.click();
    },
    importStyle(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object)
            this.$emit('change', { ...this.value, ...object });
        } catch (ex)
        {
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
    exportStyle()
    {
      const jsonData = JSON.stringify(this.value || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `style_${this.$chance.string(this.$newIDParams)}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    propertyGetValue(propertyName, defaultValue)
    {
      return this.value[propertyName] || defaultValue;
    },
    propertySetValue(propertyName, value)
    {
      const out = { ...this.value };
      out[propertyName] = value;
      this.$emit('change', out);
    },
  },
};

</script>

<style scoped>

</style>
