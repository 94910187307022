import { firebaseAction } from 'vuexfire';
import firebase from 'firebase/app';
import 'firebase/database';
import firebaseReference from '../FirebaseReference';

export default {
  /**
   * Update the worker data from the state
   * called by a setInterval located in bindToFirebase
   */
  setWorkerData: firebaseAction(async function callback({ getters })
  {
    if (getters.workerData.id !== '')
    {
      const fbRef = await firebaseReference(getters.tokenID);
      fbRef.ref('Storage')
        .child('WebsiteBuilder')
        .child(getters.path)
        .child('coworkers')
        .child(getters.workerData.id)
        .update({ ...getters.workerData, lastUpdate: firebase.database.ServerValue.TIMESTAMP });
    }
  }),

  /**
   * Remove a worker in co-worker list based on he's ID
   * @param workerID: the ID of the removed worker
   */
  removeCoWorker: firebaseAction(async function callback({ getters }, { workerID })
  {
    const fbRef = await firebaseReference(getters.tokenID);
    return fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('coworkers')
      .child(workerID)
      .remove();
  }),

  /**
   * Remove all the worker not updating the base since more than 5000ms
   * @param dispatch
   * @param state
   */
  removeOldWorker({ dispatch, state })
  {
    Object.entries(state.coworkers).forEach(([, value]) => {
      if (Date.now() - value.lastUpdate > 5000)
        dispatch('removeCoWorker', { workerID: value.id });
    });
  },
};
