<template>
  <draggable
      v-if="!deploymentMode && !isRowHiddenByFilters"
      :style="computedStyle"
      :class="computedClasses"
      :id="rowID"
      :group="{ name: 'cols' }"
      @end="dragEnd"
      @click.native="onClick"
      handle=".dragHandle"
      v-model="draggableColList">
    <template v-slot:header>
      <RowOverlay
          v-if="isRowSelected(row) || isEmpty"
          :row="row"
          :row-index="rowIndex"
          @toggleSpacing="displaySpacing = $event"
          class="h-100 w-100 position-absolute rowOverlay top-0 start-0"/>
    </template>
    <Column v-for="(Col, index) in draggableColList" :key="index" :column="Col" :Row="row"/>
    <div slot="footer" v-if="isEmpty" class="position-absolute w-100 row text-center pt-2">
      <div class="col-12">
        <h6  class=" text-muted" v-html="dictionary.row.emptyRow"></h6>
      </div>
      <div class="col-auto ms-auto "><button @click="importButtonClick" class="btn btn-success text-light "><i class="fas fa-upload me-2"></i>Importer une ligne</button></div>
      <input accept="application/JSON" ref="importHiddenInput" type="file" @change="importRow" class="invisible">
    </div>
    <div slot="footer" v-else-if="isRowHidden && !deploymentMode" class="position-absolute w-100 h-100 text-danger" style="pointer-events: none;">
      <h6 v-if="!isRowSelected(row)" class="text-center mt-2"><i class="fas fa-eye-slash me-4"></i>{{ dictionary.row.hiddenRowInProduction }}</h6>
      <p v-else class="text-end w-100 fas fa-eye-slash"></p>
    </div>
  </draggable>
  <div
      v-else-if="deploymentMode && !this.isRowHidden"
      :data-filter="filterId"
      :id="rowID"
      :style="computedStyle"
      :class="computedClasses"
      :data-aos="aos_enabled === true ? aos_type : undefined"
      :data-aos-duration="aos_enabled === true ? aos_duration : undefined"
      :data-aos-easing="aos_enabled === true ? aos_easing : undefined"
      :data-aos-anchor-placement="aos_enabled === true ? anchorPlacement : undefined"
      :data-aos-mirror="aos_enabled === true ? aos_mirror : undefined">
    <Column :deploymentMode="deploymentMode" v-for="(Col, index) in draggableColList" :key="index" :column="Col" :Row="row"/>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import RowOverlay from '../Overlays/RowOverlay.vue';
import Column from './Column.vue';

export default {
  name: 'Row',
  components: {
    RowOverlay,
    Column,
    draggable,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
    },
    rowIndex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      displaySpacing: false,
    };
  },
  computed: {
    ...mapGetters([
      'styleFromObject',
      'isRowSelected',
      'activeWebsiteFilter',
      'selectedColID',
      'dictionary',
    ]),
    rowID()
    {
      if (!this.row.id)
        return this.$chance.string(this.$newIDParams);
      return this.row.id;
    },
    filterId()
    {
      return this.row.filter || '';
    },
    isEmpty()
    {
      return (!this.row.columns || this.row.columns.length <= 0);
    },
    isRowHiddenByFilters()
    {
      if (!this.activeWebsiteFilter)
        return false;
      return (this.row.filter && this.row.filter !== this.activeWebsiteFilter);
    },
    isRowHidden()
    {
      if (this.row.values)
        return this.row.values.hidden || false;
      return false;
    },
    computedClasses()
    {
      let out = '';
      out += 'row';
      if (this.row.values && this.row.values.alignment)
      {
        switch (this.row.values.alignment)
        {
          case 'text-end': out += ' justify-content-end'; break;
          case 'text-center': out += ' justify-content-center'; break;
          case 'text-start': out += ' justify-content-start'; break;
          default: break;
        }
      }
      if (this.row.values && this.row.values.customClasses)
        this.row.values.customClasses.forEach((cls) => { out += ` ${cls}`; });
      if (this.deploymentMode && this.row.values && this.row.values.sticky)
        out += ' sticky-top';
      else if (!this.deploymentMode)
        out += ' position-relative';
      if (this.row.values && this.row.values.hidden === true)
        out += ' hide';
      if (this.deploymentMode && this.row.values && this.row.values.deviceFilter)
      {
        const deviceFilter = this.row.values.deviceFilter;
        if (deviceFilter.displayDesktop || deviceFilter.displayMobile) {
          if ((deviceFilter.displayDesktop || deviceFilter.displayDesktop === undefined) && !deviceFilter.displayMobile)
            out += ' d-none d-md-flex';
          if (!deviceFilter.displayDesktop && (deviceFilter.displayMobile || deviceFilter.displayMobile === undefined))
            out += ' d-flex d-md-none';
        }
      }
      return out;
    },
    computedStyle()
    {
      const out = this.styleFromObject(this.row.values || {}) || {};
      if (this.isEmpty && !this.deploymentMode)
      {
        out.minHeight = '100px';
        out.outline = '#2FAADE dashed 1px';
        out.background = 'linear-gradient(45deg, white 25%, #2faade88 25%, #2faade88 50%, white 50%, white 75%, #2faade88 75%);';
        out.backgroundSize = '100px 100px;';
      }
      if (this.isRowHidden && !this.isRowSelected(this.row) && !this.deploymentMode)
        out.opacity = '0.5';
      return out;
    },
    aos_enabled() {
      if (this.row.animations)
        return this.row.animations.enabled || false;
      return false;
    },
    aos_type() {
      if (this.row.animations)
        return this.row.animations.type || '';
      return '';
    },
    aos_mirror() {
      if (this.row.animations)
        return this.row.animations.mirror || false;
      return false;
    },
    aos_easing() {
      if (this.row.animations)
        return this.row.animations.easing || '';
      return '';
    },
    anchorPlacement() {
      if (this.row.animations)
        return this.row.animations.anchorPlacement || '';
      return '';
    },
    aos_duration() {
      if (this.row.animations)
        return this.row.animations.duration || '';
      return '';
    },
    // Model getter / setters
    draggableColList: {
      get()
      {
        return this.row.columns || [];
      },
      set(cols)
      {
        this.$store.commit('updateColumnsForRow', { row: this.row, cols, dbSync: false });
      },
    },
  },
  methods: {
    dragEnd()
    {
      this.$store.dispatch('setWebsiteContent');
    },
    importButtonClick() {
      this.$refs.importHiddenInput.click();
    },
    importRow(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (!object)
            return;
          if (object.columns)
          {
            object.columns.forEach((col) => {
              if (col.id)
                col.id = this.$chance.string(this.$newIDParams);
            });
            this.$store.commit('updateColumnsForRow', { row: this.row, cols: object.columns, dbSync: true });
          }
          if (object.values)
            this.$store.commit('setRowValues', { row: this.row, values: object.values, dbSync: true });
          this.importRowNotif();
        } catch (ex)
        {
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
    onClick()
    {
      if (!this.deploymentMode && !this.isEmpty)
      {
        const found = this.row.columns.every((col) => col.id !== this.selectedColID);
        if (found)
          this.$store.commit('setSelectedColumnID', { selectedCol: this.row.columns[0].id, dbSync: true });
      }
    },
  },
  notifications: {
    importRowNotif: {
      type: VueNotifications.types.success,
      title: 'Ligne importé !',
      message: 'La ligne à été importé avec succès.',
    },
  },
};

</script>

<style lang="scss">

:root {
  --paddingOverlayColor: $orange-300;
}

</style>
