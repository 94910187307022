<template>
  <!-- Separator -->
  <div v-if="navbarItem.type === 'separator'"></div>
  <!-- Image -->
  <a
      v-else-if="navbarItem.type === 'image' && !deploymentMode"
      :data-page="pageData"
      style="pointer-events: none">
    <img
        :src="navbarItem.content"
        :style="computedStyle"
        :class="computedClasses"
        style="height: 50px;"
        alt="navbar image">
  </a>
  <a
      v-else-if="navbarItem.type === 'image' && deploymentMode"
      :data-page="pageData"
      :target="navbarItem.newTab ? '_blank' : ''"
      :onclick="onClickAction"
      :href="href && href !== '' ? href : '#'"
      :rel="navbarItem.targetType === 'page' ? 'keep-params' : undefined">
    <img
        :src="navbarItem.content"
        :style="computedStyle"
        :class="computedClasses"
        style="height: 50px;"
        alt="navbar image">
  </a>
  <!-- Text -->
  <a
      v-else-if="navbarItem.type === 'text' && deploymentMode"
      v-html="navbarItem.content"
      :target="navbarItem.newTab ? '_blank' : ''"
      :onclick="onClickAction"
      :href="href !== '' ? href : '#'"
      :style="computedStyle"
      :rel="navbarItem.targetType === 'page' || navbarItem.targetType ===  'row' ? 'keep-params' : undefined"
      :data-page="pageData"
      :class="computedClasses"
      role="button"
      class="nav-link scroll-smooth"
      type="button"></a>
  <!-- DropDown -->
  <div v-else-if="navbarItem.type === 'dropdown'">
    <a
        v-html="navbarItem.content || ''"
        :style="computedStyle"
        :class="computedClasses"
        :id="itemID"
        class="nav-link dropdown-toggle"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="true"></a>
    <ul class="dropdown-menu" :class="previewMode ? 'show mt-4' : ''" :style="dropdownStyle">
      <li v-if="previewMode && (!dropdownItems || dropdownItems.length <= 0)" class="dropdown-item disabled" href="#">Pas d'elément dans le menu</li>
      <template v-for="(subItem, subIdx) in dropdownItems">
        <li :key="subIdx">
          <a v-if="subItem.type !== 'separator'"
              v-html="subItem.content"
              :target="subItemAction(subItem).newTab ? '_blank' : ''"
              :onclick="subItemOnClick(subItem)"
              :href="subItemHref(subItem) || '#'"
              :rel="subItemAction(subItem).targetType === 'page' ? 'keep-params' : undefined"
              :data-page="subItemPageData(subItem)"
              :style="subItemStyle(subItem)"
              class="dropdown-item"></a>
          <hr v-else class="dropdown-divider">
        </li>
      </template>
    </ul>
  </div>
  <!-- other -->
  <a
      v-else
      :target="navbarItem.newTab ? '_blank' : ''"
      :style="computedStyle"
      :class="computedClasses"
      v-html="navbarItem.content"
      style="cursor: pointer; pointer-events: none"
      class="nav-link"></a>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'NavbarItem',
  props: {
    navbarItem: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    previewMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data()
  {
    return {
      id: '',
    };
  },
  computed: {
    ...mapGetters(['preferredUrl', 'pageList', 'styleFromObject']),
    itemID()
    {
      if (!this.id)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.id = `navbarItem-${this.$chance.string(this.$newIDParams)}`;
      return this.id || '';
    },
    dropdownItems()
    {
      return this.navbarItem.items || [];
    },
    externalUrl() {
      return this.preferredUrl || '';
    },
    onClickAction()
    {
      if (this.navbarItem.targetType === 'filter')
        return `forceRowsFilter('${this.navbarItem.targetFilter || ''}')`;
      if (this.navbarItem.targetType === 'modal')
        return `$('#modal-${this.navbarItem.targetModal}').modal('show')`;
      if (this.navbarItem.linkType === 'filter')
        return `forceRowsFilter('${this.navbarItem.filterTarget || ''}')`;
      if (this.navbarItem.linkType === 'modal')
        return `$('#modal-${this.navbarItem.modalTarget}').modal('show')`;
      return '';
    },
    href()
    {
      if (this.navbarItem.targetType === 'url')
        return this.navbarItem.targetUrl || '';
      if (this.navbarItem.targetType === 'row')
        return this.navbarItem.targetRow || '#';
      if (this.navbarItem.targetType === 'page')
      {
        let pageName = '';
        if (this.pageList[this.navbarItem.targetPage])
          pageName = this.pageList[this.navbarItem.targetPage].name || '';
        return `${this.externalUrl}/${pageName}`;
      }
      if (this.navbarItem.href)
        return this.navbarItem.href;
      return '';
    },
    pageData()
    {
      if (this.navbarItem.targetType !== 'page')
        return undefined;
      return this.pageList[this.navbarItem.targetPage].name || '';
    },
    computedStyle()
    {
      const out = this.styleFromObject(this.navbarItem.style || {});
      return out || {};
    },
    computedClasses()
    {
      let out = '';
      const classes = this.navbarItem.style ? this.navbarItem.style.customClasses || [] : [];
      classes.forEach((cls) => { out += ` ${cls}`; });
      if (this.navbarItem.linkType === 'row')
        out += ' nav-anchor';
      if (this.navbarItem.targetType === 'page')
        out += ' page-link-c';
      return out;
    },
    dropdownStyle()
    {
      const out = { ...this.computedStyle };
      if (this.navbarItem.menuBackgroundColor)
      {
        if (this.navbarItem.menuBackgroundColor.hex8)
          out.backgroundColor = this.navbarItem.menuBackgroundColor.hex8;
        else if (this.navbarItem.menuBackgroundColor.hex)
          out.backgroundColor = this.navbarItem.menuBackgroundColor.hex;
        else if (this.navbarItem.menuBackgroundColor.rgba)
          out.backgroundColor = `rgba(
            ${this.navbarItem.menuBackgroundColor.rgba.r},
            ${this.navbarItem.menuBackgroundColor.rgba.g},
            ${this.navbarItem.menuBackgroundColor.rgba.b},
            ${this.navbarItem.menuBackgroundColor.rgba.a})`;
      }
      const border = this.navbarItem.border;
      if (border)
      {
        out.borderWidth = border.thickness ? `${border.thickness}px` : undefined;
        out.borderStyle = border.style || '';
        out.borderColor = border.color.hex8 || '';
      }
      if (this.navbarItem.borderRadius)
        out.borderRadius = `${this.navbarItem.borderRadius}px`;
      return out;
    },
  },
  methods: {
    subItemStyle(subItem)
    {
      return {
        ...this.computedStyle,
        marginTop: undefined,
        marginBottom: undefined,
        marginRight: undefined,
        marginLeft: undefined,
        paddingTop: undefined,
        paddingBottom: undefined,
        paddingRight: undefined,
        paddingLeft: undefined,
        ...this.styleFromObject(subItem.style || {}),
      };
    },
    subItemAction(subItem)
    {
      return subItem.action || {};
    },
    subItemHref(subItem)
    {
      if (this.subItemAction(subItem).targetType === 'url')
        return this.subItemAction(subItem).targetUrl || '';
      if (this.subItemAction(subItem).targetType === 'row')
        return this.subItemAction(subItem).targetRow || '#';
      if (this.subItemAction(subItem).targetType === 'page')
      {
        let pageName = '';
        if (this.pageList[this.subItemAction(subItem).targetPage])
          pageName = this.pageList[this.subItemAction(subItem).targetPage].name || '';
        return `${this.externalUrl}/${pageName}`;
      }
      if (this.subItemAction(subItem).href)
        return this.subItemAction(subItem).href;
      return '';
    },
    subItemPageData(subItem)
    {
      if (this.subItemAction(subItem).targetType !== 'page' || !this.subItemAction(subItem).targetPage)
        return undefined;
      return this.pageList[this.subItemAction(subItem).targetPage].name;
    },
    subItemOnClick(subItem)
    {
      if (this.subItemAction(subItem).targetType === 'filter')
        return `forceRowsFilter('${this.subItemAction(subItem).targetFilter || ''}')`;
      if (this.subItemAction(subItem).targetType === 'modal')
        return `$('#modal-${this.subItemAction(subItem).targetModal}').modal('show')`;
      if (this.subItemAction(subItem).linkType === 'filter')
        return `forceRowsFilter('${this.subItemAction(subItem).filterTarget || ''}')`;
      if (this.subItemAction(subItem).linkType === 'modal')
        return `$('#modal-${this.subItemAction(subItem).modalTarget}').modal('show')`;
      return '';
    },
  },
};
</script>

<style scoped>

</style>
