<template>
  <perfect-scrollbar class="h-100 pe-3" :options="{ wheelPropagation: false, suppressScrollX: true }">
    <StyleEditor v-model="styleData" :properties="[
        'hr',
        'fontFamily',
        'fontWeight',
        'fontSize',
        'color',
        'hr',
        'border',
        'borderRadius',
        'hr']">
      <template v-slot:form-start>
        <div class="row">
          <div class="col-12">
            <div class="mb-3 mt-2">
              <label>Largeur de la modale</label>
              <select class="form-select" v-model="modalSize">
                <option value="small">Small</option>
                <option value="" selected>Defaut</option>
                <option value="large">Large</option>
                <option value="xlarge">Extra large</option>
              </select>
            </div>
          </div>
          <div class="col d-flex align-items-center">
            <div class="form-check form-switch">
              <input v-model="closeButton" class="form-check-input" type="checkbox" id="toggle-hidden">
              <label class="form-check-label" for="toggle-hidden">Bouton fermer la modale</label>
            </div>
          </div>
          <div class="col d-flex align-items-center">
            <div class="form-check form-switch">
              <input v-model="automaticOpen" class="form-check-input" type="checkbox" id="toggle-hidden">
              <label class="form-check-label" for="toggle-hidden">Ouverture automatique si première navigation</label>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:form-end>
        <InputColorPicker
            class="mb-3"
            :label="'fond du titre'"
            v-model="titleBackground"/>
        <InputColorPicker
            class="mb-3"
            :label="'fond du corp'"
            v-model="bodyBackground"/>
        <InputColorPicker
            :label="'fond du footer'"
            v-model="footerBackground"/>
      </template>
    </StyleEditor>
  </perfect-scrollbar>
</template>

<script>

import { mapGetters } from 'vuex';
import StyleEditor from '../../Utilities/StyleEditor.vue';
import InputColorPicker from '../../Utilities/InputColorPicker.vue';

export default {
  name: 'ModalStyleEditor',
  components: { StyleEditor, InputColorPicker },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['googleFonts']),
    styleData: {
      get()
      {
        return this.value || {};
      },
      set(newStyle)
      {
        this.$emit('change', newStyle);
      },
    },
    titleBackground: {
      get()
      {
        if (this.value)
          return this.value.titleBackgroundColor || {};
        return {};
      },
      set(newColor)
      {
        this.$emit('change', { ...this.value, titleBackgroundColor: newColor });
      },
    },
    bodyBackground: {
      get()
      {
        if (this.value)
          return this.value.bodyBackgroundColor || {};
        return {};
      },
      set(newColor)
      {
        this.$emit('change', { ...this.value, bodyBackgroundColor: newColor });
      },
    },
    footerBackground: {
      get()
      {
        if (this.value)
          return this.value.footerBackgroundColor || {};
        return {};
      },
      set(newColor)
      {
        this.$emit('change', { ...this.value, footerBackgroundColor: newColor });
      },
    },
    modalSize: {
      get()
      {
        if (this.value)
          return this.value.modalSize || '';
        return '';
      },
      set(newValue)
      {
        this.$emit('change', { ...this.value, modalSize: newValue });
      },
    },
    closeButton: {
      get()
      {
        if (this.value)
          return this.value.closeButton || false;
        return false;
      },
      set(newCloseButtonValue)
      {
        this.$emit('change', { ...this.value, closeButton: newCloseButtonValue });
      },
    },
    automaticOpen: {
      get()
      {
        if (this.value)
          return this.value.automaticOpen || false;
        return false;
      },
      set(newAutomaticOpenValue)
      {
        this.$emit('change', { ...this.value, automaticOpen: newAutomaticOpenValue });
      },
    },
  },
};

</script>

<style scoped>

</style>
