<template>
  <div class="editorUIStyle">
    <Tabs :tabs="[`<span class='text-secondary'>${dictionary.styleEditor.padding}</span>`, `<span class='text-secondary'>${dictionary.styleEditor.margin}</span>`]">
      <template v-slot:slot-0>
        <div class="row">
          <div class="col pe-0">
            <label class="small" for="padding-top">{{ dictionary.styleEditor.up }}</label>
            <input :value="padding.top" @change="$emit('change', { ...value, padding: { ...padding, top: $event.target.value } })" id="padding-top" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="padding-bottom">{{ dictionary.styleEditor.down }}</label>
            <input :value="padding.down" @change="$emit('change', { ...value, padding: { ...padding, down: $event.target.value } })" id="padding-bottom" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="padding-left">{{ dictionary.styleEditor.left }}</label>
            <input :value="padding.left" @change="$emit('change', { ...value, padding: { ...padding, left: $event.target.value }})" id="padding-left" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="padding-right">{{ dictionary.styleEditor.right }}</label>
            <input :value="padding.right" @change="$emit('change', { ...value, padding: { ...padding, right: $event.target.value } })" id="padding-right" type="number" class="form-control pe-1" placeholder="">
          </div>
        </div>
      </template>
      <template v-slot:slot-1>
        <div class="row">
          <div class="col pe-0">
            <label class="small" for="margin-top">{{ dictionary.styleEditor.up }}</label>
            <input :value="margin.top" @change="$emit('change', { ...value, margin: { ...margin, top: $event.target.value } })" id="margin-top" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="margin-bottom">{{ dictionary.styleEditor.down }}</label>
            <input :value="margin.down" @change="$emit('change', { ...value, margin: { ...margin, down: $event.target.value } })" id="margin-bottom" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="margin-left">{{ dictionary.styleEditor.left }}</label>
            <input :value="margin.left" @change="$emit('change', { ...value, margin: { ...margin, left: $event.target.value }})" id="margin-left" type="number" class="form-control pe-1" placeholder="">
          </div>
          <div class="col p-0">
            <label class="small" for="margin-right">{{ dictionary.styleEditor.right }}</label>
            <input :value="margin.right" @change="$emit('change', { ...value, margin: { ...margin, right: $event.target.value } })" id="margin-right" type="number" class="form-control pe-1" placeholder="">
          </div>
        </div>
      </template>
    </Tabs>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Tabs from './Tabs.vue';

export default {
  name: 'SpacingEditor',
  components: {
    Tabs,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Marges',
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    margin()
    {
      return this.value.margin || {};
    },
    padding()
    {
      return this.value.padding || {};
    },
  },
};
</script>

<style scoped>

</style>
