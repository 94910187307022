<template>
  <div class="row">
    <div v-if="!isARowSelected" class="col text-center text-muted">
      <p ><i>{{ dictionary.leftBar.selectRowToEdit }}</i></p>
    </div>
    <StyleEditor
        v-else class="col-12"
        v-model="style"
        :properties="[
            'spacing',
            'alignment',
            'fontFamily',
            'fontWeight',
            'hr',
            'backgroundColor',
            'backgroundImage',
            'backgroundImageConfig',
            'hr',
            'borderRadius',
            'border',
        ]">
      <template v-slot:form-start>
        <IdEditor v-model="rowID" :title="dictionary.leftBar.id" class="mb-2"/>
        <DeviceFilterSelector v-model="deviceFilter" title="Filtrage Device" class="mb-2"/>
        <FilterSelector v-model="filter" :label="dictionary.leftBar.rowFilterLabel" class="mb-2"/>
        <div class="row mb-2">
          <div class="col-auto">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="toggle-hidden" v-model="hidden">
              <label class="form-check-label small" for="toggle-hidden">{{ dictionary.leftBar.hideRow }}</label>
            </div>
          </div>
          <div class="col">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="toggle-sticky" v-model="sticky">
              <label class="form-check-label small" for="toggle-sticky">{{ dictionary.leftBar.stickyRow }}</label>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:form-end>
        <animationEditor class="mt-3" v-model="animation"/>
        <div class="mb-4">
          <label class="small">{{ dictionary.customClass }}</label>
          <Multiselect
              id="customClasses"
              v-model="customClasses"
              :tag-placeholder="dictionary.addThisClass"
              :placeholder="dictionary.selectOrTypeAClass"
              :options="defaultCustomClasses"
              :multiple="true"
              :taggable="true"
              @tag="onNewClass"/>
        </div>
        <div class="row">
          <div class="col m-0 pe-1">
            <button @click.stop.prevent="exportRow" class="w-100 btn btn-success text-light"><i class="fas fa-download text-light me-2"></i>Exporter la ligne</button>
          </div>
        </div>
      </template>
    </StyleEditor>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import IdEditor from '../../../Utilities/IdEditor.vue';
import defaultCustomClasses from '../../../../configuration/defaultCustomClasses.json';
import StyleEditor from '../../../Utilities/StyleEditor.vue';
import FilterSelector from '../../../Utilities/FilterSelector.vue';
import AnimationEditor from '../../../Utilities/AnimationEditor.vue';
import DeviceFilterSelector from '../../../Utilities/DeviceFilterSelector.vue';

export default {
  name: 'RowConfig',
  components: {
    DeviceFilterSelector,
    StyleEditor,
    IdEditor,
    Multiselect,
    FilterSelector,
    AnimationEditor,
  },
  computed: {
    ...mapGetters(['selectedRow', 'dictionary']),
    isARowSelected()
    {
      return !(this.selectedRow === undefined);
    },
    defaultCustomClasses()
    {
      return defaultCustomClasses;
    },
    rowID: {
      get()
      {
        if (this.isARowSelected)
          return this.selectedRow.id || '';
        return '';
      },
      set(newID)
      {
        this.$store.commit('setRowID', { row: this.selectedRow, id: newID, dbSync: true });
      },
    },
    style: {
      get()
      {
        return this.selectedRow.values || {};
      },
      set(style)
      {
        this.$store.commit('setRowValues', { row: this.selectedRow, values: style, dbSync: true });
      },
    },
    animation: {
      get()
      {
        return this.selectedRow.animations || {};
      },
      set(newAnimations)
      {
        this.$store.commit('setRowAnimations', { row: this.selectedRow, animations: { ...this.selectedRow.animations, ...newAnimations }, dbSync: true });
      },
    },
    customClasses: {
      set(newValue)
      {
        this.$store.commit('setRowValues', { row: this.selectedRow, values: { ...this.selectedRow.values, customClasses: newValue }, dbSync: true });
      },
      get()
      {
        if (this.isARowSelected && this.selectedRow.values)
          return this.selectedRow.values.customClasses || [];
        return [];
      },
    },
    hidden: {
      get()
      {
        if (this.isARowSelected && this.selectedRow.values)
          return this.selectedRow.values.hidden || false;
        return false;
      },
      set(newValue)
      {
        this.$store.commit('setRowValues', { row: this.selectedRow, values: { ...this.selectedRow.values, hidden: newValue }, dbSync: true });
      },
    },
    sticky: {
      get()
      {
        if (this.isARowSelected && this.selectedRow.values)
          return this.selectedRow.values.sticky || false;
        return false;
      },
      set(newValue)
      {
        this.$store.commit('setRowValues', { row: this.selectedRow, values: { ...this.selectedRow.values, sticky: newValue }, dbSync: true });
      },
    },
    filter: {
      get()
      {
        if (this.isARowSelected)
          return this.selectedRow.filter || '';
        return '';
      },
      set(newFilter)
      {
        this.$store.commit('setRowFilter', { row: this.selectedRow, filter: newFilter, dbSync: true });
      },
    },
    deviceFilter: {
      get()
      {
        if (this.isARowSelected && this.selectedRow.values)
          return this.selectedRow.values.deviceFilter || {};
        return {};
      },
      set(newFilter)
      {
        this.$store.commit('setRowValues', { row: this.selectedRow, values: { ...this.selectedRow.values, deviceFilter: newFilter }, dbSync: true });
      },
    },
  },
  methods: {
    onNewClass(newTag)
    {
      this.customClasses = [...this.customClasses, newTag];
    },
    exportRow()
    {
      const jsonData = JSON.stringify(this.selectedRow || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `row_${this.rowID || 'unknown'}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
  },
};

</script>

<style scoped>

</style>
