<template>
  <span v-if="position === 'auto'" v-tooltip="computedMessage" class="badge bg-success small"><i class="fas fa-code"></i></span>
  <span v-else-if="position === 'top'" v-tooltip.top="computedMessage" class="badge bg-success small"><i class="fas fa-code"></i></span>
  <span v-else-if="position === 'bottom'" v-tooltip.bottom="computedMessage" class="badge bg-success small"><i class="fas fa-code"></i></span>
  <span v-else-if="position === 'left'" v-tooltip.left="computedMessage" class="badge bg-success small"><i class="fas fa-code"></i></span>
  <span v-else-if="position === 'right'" v-tooltip.right="computedMessage" class="badge bg-success small"><i class="fas fa-code"></i></span>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'CodeCompatibleBadge',
  props: {
    message: {
      type: String,
      required: false,
    },
    position: {
      type: String,
      required: false,
      default: 'auto',
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    computedMessage()
    {
      return this.message || this.dictionary.codeCompatibleBadgeMessage;
    },
  },
};

</script>

<style scoped>

</style>
