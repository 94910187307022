<template>
  <a  v-html="content.text"
      :onclick="targetOnClick"
      :style="computedStyle"
      :target="newTab ? '_blank' : undefined"
      :href="targetUrl"
      :rel="content.targetType === 'page' ? 'keep-params' : undefined"
      class="btn btn-primary scroll-smooth"></a>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ButtonContent',
  props: {
    content: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['preferredUrl', 'pageList', 'styleFromObject']),
    externalUrl() {
      return this.preferredUrl || '';
    },
    targetUrl() {
      let pageName = '';
      if (this.content.targetType === 'page' && this.pageList[this.content.targetPage])
        pageName = this.pageList[this.content.targetPage].name || '';
      switch (this.content.targetType)
      {
        case 'page': return `${this.externalUrl}/${pageName}`;
        case 'url': return this.content.targetUrl;
        case 'row': return this.content.targetRow;
        case 'filter': return undefined;
        case 'modal': return undefined;
        default: return undefined;
      }
    },
    targetOnClick() {
      switch (this.content.targetType)
      {
        case 'url': return '';
        case 'row': return '';
        case 'filter': return `forceRowsFilter('${this.content.targetFilter || ''}')`;
        case 'modal': return `$('#modal-${this.content.targetModal}').modal('show')`;
        default: return '#';
      }
    },
    newTab() {
      return (this.content.newTab && this.content.targetType === 'url') ? this.content.newTab : false;
    },
    computedStyle()
    {
      const out = this.styleFromObject(this.content || {});
      if (!this.deploymentMode)
        out.pointerEvents = 'none';
      out.cursor = 'pointer';
      return out || {};
    },
  },
};

</script>

<style scoped>

</style>
