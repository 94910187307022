<template>
  <StyleEditor v-model="style" :properties="['alignment', 'borderRadius', 'border']">
    <template v-slot:form-start>
      <div class="mb-3">
        <label class='small'>{{ dictionary.leftBar.separatorLabel }}</label>
        <vue-slider class="mt-2 ms-2" :min="1" :max="100" v-model="width"/>
      </div>
    </template>
  </StyleEditor>
</template>

<script>

import VueSlider from 'vue-slider-component';
import { mapGetters } from 'vuex';
import StyleEditor from '../../../../Utilities/StyleEditor.vue';

export default {
  name: 'SeparatorConfig',
  components: {
    StyleEditor,
    VueSlider,
  },
  model: {
    prop: 'content',
    event: 'change',
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    style: {
      get()
      {
        return this.content.style || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.content, style: newStyle });
      },
    },
    width: {
      get()
      {
        return this.style.width || 0;
      },
      set(newWidth)
      {
        this.$emit('change', { ...this.content, style: { ...(this.content.style || {}), width: newWidth } });
      },
    },
  },
};

</script>

<style scoped>

</style>
