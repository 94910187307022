<template>
  <ckeditor ref="editor" class="h-100" :type="type" v-model="editorData" :config="configuration" @blur="$emit('blur', $event)"></ckeditor>
</template>

<script>

import { mapGetters } from 'vuex';
import { baseFont, serverFont } from '../../configuration/defaultFont.json';

export default {
  name: 'CkEditor5',
  model: {
    value: 'value',
    event: 'change',
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'inline',
    },
    value: {
      type: String,
      required: true,
    },
    importedFonts: Object,
  },
  data()
  {
    return {
      fontListString: '',
    };
  },
  created()
  {
    baseFont.forEach((font) => { this.fontListString += `${font.name}/${font.value};`; });
    serverFont.forEach((font) => { this.fontListString += `${font.name}/${font.value};`; });
    Object.values(this.importedFonts || []).forEach((font) => { this.fontListString += `${font.family};`; });
  },
  computed: {
    ...mapGetters(['colorPalette']),
    editorData: {
      get()
      {
        return this.value || '';
      },
      set(newValue)
      {
        this.$emit('change', newValue);
      },
    },
    configuration()
    {
      let colorString = '';
      this.colorPalette.forEach((col) => { colorString += `${col.slice(1)},`; });
      return {
        versionCheck: false,
        startupFocus: true,
        extraAllowedContent: '*[*]{*}(*)',
        extraPlugins: 'colorbutton, colordialog, basicstyles, stylescombo, justify, sourcedialog, font',
        toolbarGroups: [
          { name: 'clipboard', groups: ['clipboard', 'undo'] },
          { name: 'styles', groups: ['styles'] },
          { name: 'colors', groups: ['colors'] },
          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },

          { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
          { name: 'links', groups: ['links'] },
          { name: 'insert', groups: ['insert'] },
          { name: 'document', groups: ['mode', 'document', 'doctools'] },
        ],
        colorButton_colors:
            '000,800000,8B4513,2F4F4F,008080,000080,4B0082,696969,' +
            'B22222,A52A2A,DAA520,006400,40E0D0,0000CD,800080,808080,' +
            'F00,FF8C00,FFD700,008000,0FF,00F,EE82EE,A9A9A9,' +
            'FFA07A,FFA500,FFFF00,00FF00,AFEEEE,ADD8E6,DDA0DD,D3D3D3,' +
            'FFF0F5,FAEBD7,FFFFE0,F0FFF0,F0FFFF,F0F8FF,E6E6FA,FFF,Orange Officiel/FF7900,' + colorString,
        removeButtons: 'Cut,Copy,Paste,PasteText,PasteFromWord,Form,Checkbox,HiddenField,Radio,TextField,Textarea,Select,Button,ImageButton,Find,Replace,SelectAll,Scayt,Indent,Outdent,Blockquote,CreateDiv,BidiRtl,BidiLtr,Language,Anchor,PageBreak,Iframe,Save,Templates,NewPage,ExportPdf,Preview,Print,Maximize,About,ShowBlocks',
        font_names: this.fontListString,
      };
    },
  },
};

</script>

<style>

.cke_textarea_inline {
  height: 100%;
  border: 2px dashed var(--bs-secondary);
}

</style>
