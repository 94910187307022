<template>
  <modal :name="id" width="90%" height="90%" @scroll.prevent>
    <Card class="h-100">
      <template v-slot:header-left><p class="text-white mb-0" v-html="title"></p></template>
      <template v-slot:header-right>
        <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button>
      </template>
      <MappedImageEditor :mappedImageContent="mappedImageContent" @change="$emit('change', $event)"/>
    </Card>
  </modal>
</template>

<script>

import Card from '../../Utilities/Card.vue';
import MappedImageEditor from './MappedImageEditor.vue';

export default {
  name: 'MappedImageModal',
  components: {
    MappedImageEditor,
    Card,
  },
  model: {
    prop: 'mappedImageContent',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Editeur de D\'Image dynamique',
    },
    mappedImageContent: {
      type: Object,
      required: true,
    },
  },
  data()
  {
    return {
      id: '',
    };
  },
  mounted()
  {
    if (!this.id) {
      this.id = `mappedImageEditorModal${this.$chance.string(this.$newIDParams)}`;
    }
  },
  methods: {
    open()
    {
      this.$modal.show(this.id);
    },
    close()
    {
      this.$modal.hide(this.id);
    },
  },
};

</script>

<style scoped>

</style>
