<template>
  <div class="row">
    <div v-if="!isAColSelected" class="col text-center text-muted">
      <p><i>{{ dictionary.leftBar.selectColToEdit }}</i></p>
    </div>
    <StyleEditor
        v-else class="col-12"
        v-model="style"
        :properties="[
            'spacing',
            'alignment',
            'fontFamily',
            'fontWeight',
            'hr',
            'backgroundColor',
            'backgroundImage',
            'backgroundImageConfig',
            'hr',
            'borderRadius',
            'border',
        ]">
      <template v-slot:form-start>
        <FilterSelector class="mb-3" v-model="filter" label="Filtrer la colonne"/>
        <div class="mb-3">
          <label class='small'>{{ dictionary.leftBar.columnWidth }}<span class="badge bg-success ms-3">{{ columnLayout }}</span></label>
          <vue-slider class="mt-2" :min="1" :max="12" v-model="columnLayout"/>
        </div>
      </template>
      <template v-slot:form-end>
        <animationEditor class="mt-3" v-model="animation"/>
        <div class="mb-3">
          <label class="small">{{ dictionary.customClass }}</label>
          <Multiselect
              id="customClasses"
              v-model="customClass"
              :tag-placeholder="dictionary.addThisClass"
              :placeholder="dictionary.selectOrTypeAClass"
              :options="defaultCustomClasses"
              :multiple="true"
              :taggable="true"
              @tag="onNewClass"/>
        </div>
        <div class="mb-4">
          <IdEditor :readonly="true" v-model="colID" :title="dictionary.leftBar.id"/>
        </div>
        <div class="row">
          <div class="col m-0 pe-1">
            <button @click.stop.prevent="exportCol" class="w-100 btn btn-success text-light"><i class="fas fa-download text-light me-2"></i>Exporter la colonne</button>
          </div>
        </div>
      </template>
    </StyleEditor>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import 'vue-slider-component/theme/material.css';
import VueSlider from 'vue-slider-component';
import IdEditor from '../../../Utilities/IdEditor.vue';
import defaultCustomClasses from '../../../../configuration/defaultCustomClasses.json';
import StyleEditor from '../../../Utilities/StyleEditor.vue';
import AnimationEditor from '../../../Utilities/AnimationEditor.vue';
import FilterSelector from '../../../Utilities/FilterSelector.vue';

export default {
  name: 'ColumnConfig',
  components: {
    AnimationEditor,
    StyleEditor,
    Multiselect,
    VueSlider,
    IdEditor,
    FilterSelector,
  },
  computed: {
    ...mapGetters(['selectedColumn', 'dictionary']),
    defaultCustomClasses()
    {
      return defaultCustomClasses;
    },
    isAColSelected()
    {
      return this.selectedColumn !== undefined;
    },
    columnLayout: {
      set(newValue)
      {
        this.$store.commit('setColumnSize', { col: this.selectedColumn, layout: newValue, dbSync: true });
      },
      get()
      {
        return this.selectedColumn.layout || 12;
      },
    },
    colID: {
      get()
      {
        if (this.isAColSelected)
          return this.selectedColumn.id || '';
        return '';
      },
      set(newID)
      {
        this.$store.commit('setColumnID', { col: this.selectedColumn, id: newID, dbSync: true });
        this.$store.commit('setSelectedColumnID', { selectedCol: newID, dbSync: true });
      },
    },
    /**
     * The column filtering is done but disabled because I think it's a bad practice to filter column
     */
    filter: {
      get()
      {
        return this.selectedColumn.filter || '';
      },
      set(newFilter)
      {
        this.$store.commit('setColumnFilter', { col: this.selectedColumn, filter: newFilter, dbSync: true });
      },
    },
    style: {
      get()
      {
        return this.selectedColumn.values || {};
      },
      set(newStyle)
      {
        this.$store.commit('setColumnValues', { col: this.selectedColumn, values: { ...this.selectedColumn.values, ...newStyle }, dbSync: true });
      },
    },
    animation: {
      get()
      {
        return this.selectedColumn.animations || {};
      },
      set(newAnimations)
      {
        this.$store.commit('setColumnAnimations', { col: this.selectedColumn, animations: { ...this.selectedColumn.animations, ...newAnimations }, dbSync: true });
      },
    },
    customClass: {
      set(newValue)
      {
        this.$store.commit('setColumnValues', { col: this.selectedColumn, values: { ...this.selectedColumn.values, customClasses: newValue }, dbSync: true });
      },
      get()
      {
        if (this.isAColSelected)
          return (this.selectedColumn.values !== undefined && this.selectedColumn.values.customClasses !== undefined) ? this.selectedColumn.values.customClasses : [];
        return [];
      },
    },
  },
  methods: {
    onNewClass(newTag)
    {
      this.customClass = [...this.customClass, newTag];
    },
    exportCol()
    {
      const jsonData = JSON.stringify(this.selectedColumn || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `column_${this.colID || 'unknown'}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
  },
};

</script>

<style scoped>

</style>
