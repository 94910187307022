<template>
  <div class="alertsBlock">
    <template v-for="(alert, alertCode) in alerts" >
      <div v-if="alertCode !== 'register_error_exists'" :key="alertCode" :class="`d-none formAlert ${alertCode} ${alert.type || ''} ${alertClass(alert)}`" v-html="alert.content || ''" :style="alertStyle(alert)">
      </div>
      <div v-else :key="alertCode" :class="`d-none row formAlert ${alertCode} ${alert.type || ''} ${alertClass(alert)}`" :style="alertStyle(alert)">
        <div class="col-12">
          <p v-html="alert.content || ''"></p>
        </div>
        <div v-if="registrationMessageID !== ''" class="col-12">
          <hr>
        </div>
        <div v-if="registrationMessageID !== ''" class="col-auto alertButton" :class="alignment">
          <button onclick="alreadyRegister()" :messageID="registrationMessageID" type="button" :style="registrationButtonStyle" class="btn btn-primary" v-html="registrationButtonText">{{ registrationButtonText }}</button>
        </div>
        <div v-if="registrationMessageID !== ''" class="col-auto alertConfirmationMessage d-none" :class="alignment">
          <p v-html="confirmationMessage"></p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'FormAlertsContent',
  props: {
    alerts: {
      type: Object,
      required: true,
    },
    alreadyRegisteredConfig: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['styleFromObject']),
    confirmationMessage()
    {
      return this.alreadyRegisteredConfig.confirmationMessage || '';
    },
    registrationMessageID()
    {
      let out = '';
      if (this.alreadyRegisteredConfig.messages)
        this.alreadyRegisteredConfig.messages.forEach((message) => {
          out = out + message.id + ';';
        });
      if (out[out.length - 1] === ';')
        out = out.substring(0, out.length - 1);
      return out;
    },
    registrationButtonStyle()
    {
      const out = this.styleFromObject(this.alreadyRegisteredConfig || {});
      return out || {};
    },
    alignment()
    {
      switch (this.alreadyRegisteredConfig.alignment)
      {
        case 'text-start':
          return 'me-auto';
        case 'text-center':
          return 'mx-auto';
        case 'text-end':
          return 'ms-auto';
        default:
          return 'mx-auto';
      }
    },
    registrationButtonText()
    {
      return this.alreadyRegisteredConfig.label || 'Recevoir mon lien d\'accès par email';
    },
  },
  methods: {
    alertClass()
    {
      let out = '';
      out += 'text-center ';
      out += 'my-4 ';
      out += 'px-5 py-4';
      return out;
    },
    alertStyle(alert)
    {
      const out = {};
      out.border = 'solid 2px';
      out.borderRadius = '5px';
      switch (alert.type)
      {
        case 'success':
          out.backgroundColor = '#d1e7dd';
          out.color = '#0f5132';
          out.borderColor = '#badbcc';
          break;
        case 'info':
          out.backgroundColor = '#cff4fc';
          out.color = '#055160';
          out.borderColor = '#b6effb';
          break;
        case 'warning':
          out.backgroundColor = '#fff3cd';
          out.color = '#664d03';
          out.borderColor = '#ffecb5';
          break;
        case 'error':
          out.backgroundColor = '#f8d7da';
          out.color = '#842029';
          out.borderColor = '#f5c2c7';
          break;
        case 'fatal':
          out.backgroundColor = '#d3d3d4';
          out.color = '#141619';
          out.borderColor = '#bcbebf';
          break;
        default: break;
      }
      return out;
    },
  },
};

</script>

<style scoped>

</style>
