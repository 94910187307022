<template>
  <context-menu @ctx-close="onClose" :id="`${this.contextId}-newModal-context-menu`" :ref="`${this.contextId}-newModal-ctxMenu`" >
    <form class="p-3" style="width: 300px;" @submit.stop.prevent>
      <div class="mb-3">
        <label for="filterNameInput" class="form-label required">Nommez votre modale</label>
        <input required type="text" class="form-control required" id="filterNameInput" v-model="modalName">
      </div>
      <div class="w-100 text-center">
        <button @click="$emit('AddFilter', modalName); closeContext()" class="btn btn-success btn-raised text-white">Ajouter la modale</button>
      </div>
    </form>
  </context-menu>
</template>

<script>

import contextMenu from 'vue-context-menu';

export default {
  name: 'NewModalContext',
  components: {
    contextMenu,
  },
  props: {
    id: {
      required: false,
      type: String,
      default: 'unset',
    },
  },
  data()
  {
    return {
      modalName: '',
      contextId: '',
    };
  },
  created()
  {
    this.contextId = (this.id === 'unset') ? this.contextId = this.getNewId() : this.id;
  },
  methods: {
    getNewId()
    {
      return this.$chance.string(this.$newIDParams);
    },
    onClose()
    {
      this.$refs[`${this.contextId}-newModal-ctxMenu`].ctxVisible = true;
    },
    openContext()
    {
      this.$refs[`${this.contextId}-newModal-ctxMenu`].open();
      this.modalName = '';
    },
    closeContext()
    {
      this.$refs[`${this.contextId}-newModal-ctxMenu`].ctxVisible = false;
    },
  },
};

</script>

<style scoped>

</style>
