<template>
  <context-menu id="context-menu" ref="ctxMenu" style="width: 440px;">
    <div class="m-4" id="addContentPanel">
      <div class="row">
        <div class="col-md-12 text-center mb-2">
          <h5>{{ dictionary.columnContentContext.contextTitle }}</h5></div>
        <div class="col-md-4 text-center">
          <button @click="emitClick('navbar-v2')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="fas fa-bars fa-2x"></i><br>{{ dictionary.columnContentContext.menuButton }}</button>
        </div>
        <div class="col-md-4 text-center">
          <button @click="emitClick('text')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="fas fa-font fa-2x"></i><br>{{ dictionary.columnContentContext.textButton }}</button>
        </div>
        <div class="col-md-4 text-center">
          <button @click="emitClick('media')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="fas fa-photo-video fa-2x"></i><br>{{ dictionary.columnContentContext.mediaButton }}</button>
        </div>
        <div class="col-md-4 text-center">
          <button @click="emitClick('form')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="fab fa-wpforms fa-2x"></i><br>{{ dictionary.columnContentContext.formButton }}</button>
        </div>
        <div class="col-md-4 text-center">
          <button @click="emitClick('clock')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="fas fa-clock fa-2x"></i><br>{{ dictionary.columnContentContext.timerButton }}</button>
        </div>
        <div class="col-md-4 text-center">
          <button @click="emitClick('separator')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="fas fa-arrows-alt-v fa-2x"></i><br>{{ dictionary.columnContentContext.dividerButton }}</button>
        </div>
        <div class="col-md-4 text-center">
          <button @click="emitClick('code')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="fas fa-code fa-2x"></i><br>{{ dictionary.columnContentContext.codeButton }}</button>
        </div>
        <div class="col-md-4 text-center">
          <button @click="emitClick('carousel')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="fas fa-horse fa-2x"></i><br>{{ dictionary.columnContentContext.carouselButton }}</button>
        </div>
        <div class="col-md-4 text-center">
          <button @click="emitClick('legalInformations')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="fas fa-balance-scale fa-2x"></i><br>{{ dictionary.columnContentContext.legalInfoButton }}</button>
        </div>
        <div class="ms-auto col-md-4 text-center">
          <button @click="emitClick('button')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="far fa-square fa-2x"></i><br>{{ dictionary.columnContentContext.buttonContentButton }}</button>
        </div>
        <div class="me-auto col-md-4 text-center">
          <button @click="emitClick('mappedImage')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="fas fa-vr-cardboard fa-2x"></i><br>{{ dictionary.columnContentContext.mappedImageButton }}</button>
        </div>
        <div class="me-auto col-md-4 text-center">
          <button @click="emitClick('payment')" class="btn btn-secondary btn-raised mt-2 me-1 w-100"><i class="fas fa-dollar-sign fa-2x"></i><br>{{ dictionary.columnContentContext.paymentContentButton }}</button>
        </div>
      </div>
    </div>
  </context-menu>
</template>

<script>

import contextMenu from 'vue-context-menu';
import { mapGetters } from 'vuex';

export default {
  name: 'ColumnContentContext',
  components: {
    contextMenu,
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
  methods: {
    openContext()
    {
      this.$refs.ctxMenu.open();
    },
    emitClick(type)
    {
      this.$emit('contentClicked', type);
    },
  },
};

</script>

<style scoped>

</style>
