<template>
  <!-- Image non cliquable -->
  <div v-if="!isEmpty && content.mediaType === 'image' && !isTargetUrl" class="w-100 h-100">
    <!-- AVEC image mobile -->
    <template v-if="isMobileImage">
      <template v-if="deploymentMode">
        <img class="d-inline d-md-none w-100" :src="srcXS" :alt="alt">
        <img class="d-none d-md-inline w-100" :src="src" :alt="alt">
      </template>
      <template v-else>
        <img class="w-100 d-inline d-md-none" :src="isMobileImage ? srcXS : src" :alt="alt">
        <img class="w-100 d-none d-md-inline" :src="src" :alt="alt">
      </template>
    </template>
    <!-- SANS image mobile -->
    <template v-else>
      <img v-if="deploymentMode" :src="src" class="w-100" :alt="alt">
      <img v-else class="w-100" :src="src" :alt="alt">
    </template>
  </div>
  <!-- Image cliquable -->
  <a v-else-if="!isEmpty && content.mediaType === 'image' && isTargetUrl"
     class="scroll-smooth"
     :onclick="targetOnClick"
     :style="!deploymentMode ? 'pointer-events: none;' : ''"
     :target="newTab ? '_blank' : ''"
     :rel="content.targetType === 'page' ? 'keep-params' : undefined"
     :href="targetUrl">
    <!-- AVEC image mobile -->
    <template v-if="isMobileImage">
      <template v-if="deploymentMode">
        <img class="d-inline d-md-none w-100" :src="srcXS" :alt="alt">
        <img class="d-none d-md-inline w-100" :src="src" :alt="alt">
      </template>
      <template v-else>
        <img class="w-100 d-inline d-md-none" :src="isMobileImage ? srcXS : src" :alt="alt">
        <img class="w-100 d-none d-md-inline" :src="src" :alt="alt">
      </template>
    </template>
    <!-- SANS image mobile -->
    <template v-else>
      <template v-if="deploymentMode">
        <img :src="src" class="w-100" :alt="alt">
      </template>
      <img v-else class="w-100" :src="src" :alt="alt">
    </template>
  </a>
  <!-- Video -->
  <div v-else-if="!isEmpty && content.mediaType === 'embed-video'"
       :class="computedClass">
    <iframe :src="src" allowfullscreen allow="autoplay ; fullscreen" :style="computedStyle" class="w-100"></iframe>
  </div>
  <!-- Rien a afficher -->
  <div v-else-if="isEmpty && !deploymentMode">
    <div class="row">
      <div class="col-12 text-center my-5 text-muted">
        <i class="fas fa-photo-video fa-2x mb-2"></i>
        <h6>{{ dictionary.noMedia }}</h6>
        <p v-html="dictionary.useEditButton"></p>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ImageContent',
  props: {
    content: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['dictionary', 'pageList', 'pageList', 'preferredUrl']),
    externalUrl()
    {
      return this.preferredUrl || '';
    },
    isEmpty()
    {
      return !(this.src);
    },
    src()
    {
      if (this.content.mediaType === 'embed-video' && this.content.src && this.content.src.includes('video.captag.events'))
      {
        let out = this.content.src || '';
        if (this.content.poster)
          out += `?poster=${this.content.poster}`;
        if (this.content.backgroundMode)
          out += `${this.content.poster ? '&' : '?'}background=1`;
        return out;
      }
      return this.content.src || '';
    },
    alt()
    {
      return this.content.alt;
    },
    srcXS()
    {
      if (!this.isMobileImage)
        return this.src || '';
      return this.content.sourceXS || '';
    },
    isMobileImage()
    {
      return this.content.isMobileImage || false;
    },
    targetUrl()
    {
      if (!this.content.isTargetUrl)
        return '#';
      let pageName = '';
      if (this.content.targetType === 'page' && this.pageList[this.content.targetPage])
        pageName = this.pageList[this.content.targetPage].name || '';
      switch (this.content.targetType)
      {
        case 'page': return `${this.externalUrl}/${pageName}`;
        case 'url': return this.content.targetUrl;
        case 'row': return this.content.targetRow;
        case 'filter': return '#';
        case 'modal': return '#';
        default: return '#';
      }
    },
    targetOnClick()
    {
      if (!this.content.isTargetUrl)
        return '#';
      switch (this.content.targetType)
      {
        case 'url': return '';
        case 'row': return '';
        case 'filter': return `forceRowsFilter('${this.content.targetFilter || ''}')`;
        case 'modal': return `$('#modal-${this.content.targetModal}').modal('show')`;
        default: return '#';
      }
    },
    isTargetUrl()
    {
      return this.content.isTargetUrl || false;
    },
    newTab()
    {
      return this.content.newTab || false;
    },
    computedClass()
    {
      let out = 'w-100 ';
      if (this.content.ratio)
        out += `ratio ${this.content.ratio} `;
      return out;
    },
    computedStyle()
    {
      const out = {};
      if (!this.content.ratio && this.content.iframeHeight)
        out.height = `${this.content.iframeHeight}px`;
      if (!this.deploymentMode)
        out.pointerEvents = 'none';
      return out;
    },
  },
};

</script>

<style scoped>

</style>
