<template>
  <div>
    <draggable
        :list="list"
        :value="value"
        :group="{ name: 'formItems' }"
        handle=".formItemDragHandle"
        :style="groupStyle"
        @input="emitter"
        :class="groupClasses">
      <div v-for="(item, itemIdx) in itemList" :key="`PreviewFormItem-${itemIdx}`" :class="itemClasses(item)">
        <div class="row px-0 mx-0">
          <!-- if group : recursive -->
          <formBlockOverlay
              class="col-12 px-0"
              v-if="item.type === 'group'"
              :item="item"
              @removeItem="$emit('removeItem', $event)"
              @editItem="$emit('editItem', $event)">
            <FormItemListWithOverlay
                style="min-height: 50px;"
                :depth-level="depthLevel + 1"
                class="mx-4"
                :selectedItem="selectedItem"
                :previewStyle="groupStyle"
                :list="innerItems(item)"
                @itemClick="$emit('itemClick', $event)"
                @removeItem="$emit('removeItem', $event)"
                @editItem="$emit('editItem', $event)"
                :groupItem="item"/>
          </formBlockOverlay>
          <!-- else if item and selected : item with overlay -->
          <FormItemOverlay
              v-else-if="item.id === selectedItem"
              :item="item"
              :selectedItem="selectedItem"
              @removeItem="$emit('removeItem', $event)"
              @editItem="$emit('editItem', $event)"
              class="col px-0">
            <FormItem
                :class="item.hidden ? 'hidden' : ''"
                :isAccField="isAccMode"
                :formItem="item"
                @itemClick="$emit('itemClick', item.id || '')"/>
          </FormItemOverlay>
          <!-- else just item -->
          <div v-else class="col px-0" @click="$emit('itemClick', item.id || '')">
            <FormItem
                :isAccField="isAccMode"
                :formItem="item"
                :class="item.hidden ? 'hidden' : ''"/>
          </div>
          <div class="col-auto ms-1" v-if="item.type !== 'group' && (itemHasError(item) || itemHasFilters(item) || item.hidden)">
            <div class="row mt-2 h-100 text-center">
              <div v-if="itemHasError(item)" class="col px-0" v-tooltip.top="dictionary.formModule.noDataTooltip">
                <i class="fas fa-exclamation-triangle text-danger"></i>
              </div>
              <div v-if="itemHasFilters(item)" class="col px-0" v-tooltip.top="dictionary.formModule.filteredInputTooltip">
                <i class="fas fa-filter text-info"></i>
              </div>
              <div v-if="item.hidden" class="col px-0" v-tooltip.top="'Ce champ est caché'">
                <i class="fas fa-eye-slash text-warning"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer v-if="itemList.length <= 0">
        <div class="emptyBlockMessage row flex-fill py-4">
          <div class="col-auto mx-auto text-center">
            <h6 class="fw-bold d-flex align-items-center text-secondary"><i class="fas fa-border-style fa-2x me-4"></i>{{ dictionary.formModule.emptyGroupMessage }}</h6>
          </div>
        </div>
      </template>
    </draggable>
    <div v-if="showAccButton" class="row justify-content-center">
      <div v-if="removeButtonType === 'bottom'" class="col-auto">
        <button :style="accButtonStyle" class="btn btn-primary btn-raised disabled" disabled v-html="removeAccLabel"></button>
      </div>
      <div class="col-auto">
        <button :style="accButtonStyle" class="btn btn-primary btn-raised" v-html="addAccLabel"></button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
import draggable from 'vuedraggable';
import FormItem from '../NormalModeContent/FormItem/FormItem.vue';
import FormItemOverlay from './FormItemOverlay.vue';
import formBlockOverlay from './FormBlockOverlay.vue';

export default {
  name: 'FormItemListWithOverlay',
  components: {
    draggable,
    FormItem,
    FormItemOverlay,
    formBlockOverlay,
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null,
    },
    list: {
      required: false,
      type: Array,
      default: null,
    },
    previewStyle: {
      type: Object,
      required: true,
    },
    selectedItem: {
      type: String,
      required: true,
    },
    depthLevel: {
      type: Number,
      required: false,
      default: 0,
    },
    groupItem: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters(['dictionary', 'styleFromObject', 'eventData']),
    itemList()
    {
      return this.value || (this.list || []);
    },
    isAccMode()
    {
      if (this.depthLevel > 0 && this.groupItem)
        return this.groupItem.accMode || false;
      return false;
    },
    showAccButton()
    {
      return this.isAccMode && this.groupItem.maxAcc !== 1;
    },
    addAccLabel()
    {
      return this.groupItem.addButtonLabel || '';
    },
    removeAccLabel()
    {
      return this.groupItem.deleteButtonLabel || '';
    },
    removeButtonType()
    {
      return this.groupItem.removeBtnType || 'bottom';
    },
    accButtonStyle()
    {
      const getterStyle = this.styleFromObject(this.groupItem.buttonStyle || {});
      return getterStyle || {};
    },
    groupStyle()
    {
      if (this.depthLevel === 0)
        return this.previewStyle;
      const getterStyle = this.styleFromObject(this.groupItem.style || {});
      return getterStyle || {};
    },
    groupClasses()
    {
      let out = '';
      if (this.groupItemsDisposition === 'column')
      {
        out += this.groupItem.mobileItemsDisposition === 'row' ? 'd-md-flex' : 'd-flex';
        out += ' align-items-end';
        switch (this.groupItem.columnAlignment)
        {
          case 'text-start': out += ' justify-content-start'; break;
          case 'text-center': out += ' justify-content-center'; break;
          case 'text-end': out += ' justify-content-end'; break;
          default: break;
        }
      }
      return out;
    },
    groupItemsDisposition()
    {
      if (this.depthLevel === 0)
        return '';
      return this.groupItem.itemsDisposition || '';
    },
  },
  methods: {
    itemClasses(item)
    {
      let out = 'formItemHover ';
      if (item.hidden)
        out += 'hidden';
      if (this.groupItem)
      {
        switch (this.groupItem.itemsWidth)
        {
          case 'grow': out += ' flex-grow-1'; break;
          case 'shrink': out += ' flex-shrink-1'; break;
          default: out += 'flex-shrink-1 flex-grow-1 flex-basis-1'; break;
        }
      }
      return out;
    },
    initializeInnerItemsForBlocks()
    {
      const deepCopy = _.cloneDeep(this.itemList);
      let edited = false;
      deepCopy.forEach((item) => {
        if (item.type === 'group' && !item.innerItems)
        {
          edited = true;
          Vue.set(item, 'innerItems', []);
          item.innerItems = [];
        }
      });
      this.emitter(deepCopy);
    },
    innerItems(block)
    {
      if (!block.innerItems)
        this.initializeInnerItemsForBlocks();
      return block.innerItems;
    },
    emitter(value)
    {
      this.$emit('input', value);
    },
    itemHasFilters(item)
    {
      return (item.filters && item.filters.length > 0);
    },
    itemHasError(item)
    {
      if (item.type !== 'input' ||
          item.type !== 'textarea' ||
          item.type !== 'multipleChoices' ||
          item.type !== 'file')
        return false;
      if (item.data === 'rgpd' || item.data === '__status__' || item.data === 'access_code')
        return false;
      return (!item.data || !this.eventData[item.data]);
    },
  },
};

</script>

<style scoped>

.formItemHover:hover {
  outline: 1px dashed var(--bs-primary);
}

.formItem.hidden, .formItemHover.hidden {
    opacity: 0.5;
}

</style>
