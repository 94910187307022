<template>
  <div v-if="!editorMode">
    <nav class="navbar navbar-light navbar-expand-lg">
      <div class="container-fluid">
        <a
            v-if="content.websiteLogo && content.websiteLogo !== ''"
            :style="!deploymentMode ? 'pointer-events: none;' : 'cursor: pointer;'"
            :target="content.logoAction && content.logoAction.newTab === 'true' ? '_blank' : ''"
            :onclick="brandOnClickAction"
            :href="brandHref"
            :rel="content.logoAction && content.logoAction.targetType === 'page' ? 'keep-params' : undefined"
            class="me-4 nav-link navbarLogo">
          <img :src="content.websiteLogo || ''" style="height:50px" alt="Website logo">
        </a>
        <!-- Desktop burger button-->
        <template v-if="desktopMenuType === 'sidebar'">
          <a v-if="mobileMenuType === 'sidebar'" type="button" data-bs-toggle="offcanvas" :style="`border-color: ${burgerColorStyle}`" :data-bs-target="`#${computedID}NavbarOffCanvasContent`" class="navbar-toggler d-block collapsed mx-auto">
            <i class="fas fa-bars" :style="`color: ${burgerColorStyle};`" style="font-size:28px;"></i>
          </a>
        </template>
        <!-- Mobile burger button-->
        <template v-else>
          <button v-if="!mobileMenuType || mobileMenuType === 'drop'" type="button" data-bs-toggle="collapse" :style="`border-color: ${burgerColorStyle}`" :data-bs-target="`#${computedID}NavbarCollapseContent`" class="navbar-toggler collapsed">
          <span class="navbar-toggler-icon">
            <i class="fas fa-bars" :style="`color: ${burgerColorStyle};`" style="font-size:28px;"></i>
          </span>
          </button>
          <button v-if="mobileMenuType === 'sidebar'" type="button" data-bs-toggle="offcanvas" :style="`border-color: ${burgerColorStyle}`" :data-bs-target="`#${computedID}NavbarOffCanvasContent`" class="navbar-toggler collapsed">
          <span class="navbar-toggler-icon">
            <i class="fas fa-bars" :style="`color: ${burgerColorStyle};`" style="font-size:28px;"></i>
          </span>
          </button>
          <div :id="`${computedID}NavbarCollapseContent`" class="navbar-collapse collapse">
            <ul class="navbar-nav d-flex w-100" :class="elementsAlignment">
              <li
                  v-for="(item, id) in navbarItems"
                  :key="`navbarItem${id}`"
                  :class="navItemClasses(item)"
                  class="mb-3 my-md-auto">
                <NavbarItem :deploymentMode="deploymentMode" :navbarItem="item"/>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </nav>
    <div :style="`background-color: ${sidebarBackgroundColor}`" :class="offCanvasPositionClass" class="offcanvas" tabindex="-1" :id="`${computedID}NavbarOffCanvasContent`" :data-bs-backdrop="content.backdropEffect === 'darken' ? 'true' : 'false'">
      <div class="offcanvas-header">
        <div class="row w-100">
          <div v-if="content.websiteLogo && content.websiteLogo !== ''" class="col"><a target="" href="" class="me-4 nav-link"><img :src="content.websiteLogo || ''" style="height:50px" alt="Website logo"></a></div>
          <div class="col-auto"><button type="button" class="btn-close ms-auto" data-bs-dismiss="offcanvas"></button></div>
        </div>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav" :class="elementsAlignment">
          <li
              v-for="(item, id) in navbarItems"
              :key="`navbarItem${id}`"
              :class="navItemClasses(item) + (item.type === 'separator' ? 'd-none' : '')"
              class="w-auto mb-3"
              data-bs-dismiss="offcanvas">
            <NavbarItem :deploymentMode="deploymentMode" :navbarItem="item"/>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else>
    <!-- preview dans l'editeur -->
    <nav class="navbar navbar-light navbar-expand-lg">
      <div class="container-fluid">
        <a  v-if="content.websiteLogo && content.websiteLogo !== ''" target="" href="" class="me-4 nav-link navbarLogo" style="pointer-events: none;"><img :src="content.websiteLogo || ''" style="height:50px;" alt="Website logo"></a>
        <div :id="`${computedID}NavbarCollapseContent`" class="navbar-collapse collapse">
          <draggable
              v-model="navbarItems"
              group="navbarItemsDnD"
              tag="ul"
              class="navbar-nav d-flex align-items-center w-100 justify-content-start" :class="elementsAlignment"
              draggable=".dragItem">
            <li
                style="border-radius: 5px;"
                v-for="(item, id) in navbarItems"
                :key="`navbarItem${id}`"
                @click.prevent.stop="selectItem(id)"
                :style="selectedItemID === id ? 'border: dashed 1px var(--bs-success)': ''"
                :class="navItemClasses(item) + (item.type === 'separator' ? 'mx-auto px-5' : '')"
                class="nav-item mb-3 my-md-auto dragItem cursor-grab">
              <NavbarItem v-if="item.type !== 'separator'" :deploymentMode="deploymentMode" :navbarItem="item" :preview-mode="editorMode" style="pointer-events: none;"/>
              <div v-else><i class="fas fa-arrows-alt-h"></i></div>
            </li>
            <p slot="header" v-if="navbarItems.length <= 0" class="w-100 text-center text-muted mt-3 mx-2" style="top: 20%;">Ajoutez des éléments avec le menu de gauche</p>
          </draggable>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import NavbarItem from './NavbarItem.vue';

export default {
  name: 'Navbar2Content',
  components: {
    NavbarItem,
    draggable,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    editorMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedItemID: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      navbarID: '',
    };
  },
  computed: {
    ...mapGetters(['dictionary', 'pageList', 'preferredUrl']),
    externalUrl() {
      return this.preferredUrl || '';
    },
    brandHref()
    {
      if (!this.content.logoAction)
        return undefined;
      if (this.content.logoAction.targetType === 'url')
        return this.content.logoAction.targetUrl || '';
      if (this.content.logoAction.targetType === 'row')
        return this.content.logoAction.targetRow || '#';
      if (this.content.logoAction.targetType === 'page')
      {
        let pageName = '';
        if (this.content.logoAction.targetPage && this.pageList[this.content.logoAction.targetPage])
          pageName = this.pageList[this.content.logoAction.targetPage].name || '';
        return `${this.externalUrl}/${pageName}`;
      }
      return undefined;
    },
    brandOnClickAction()
    {
      if (!this.content.logoAction)
        return undefined;
      if (this.content.logoAction.targetType === 'filter')
        return `forceRowsFilter('${this.content.logoAction.targetFilter || ''}')`;
      if (this.content.logoAction.targetType === 'modal')
        return `$('#modal-${this.content.logoAction.targetModal}').modal('show')`;
      if (this.content.logoAction.linkType === 'filter')
        return `forceRowsFilter('${this.content.logoAction.filterTarget || ''}')`;
      if (this.content.logoAction.linkType === 'modal')
        return `$('#modal-${this.content.logoAction.modalTarget}').modal('show')`;
      return undefined;
    },
    computedID() {
      if (!this.navbarID)
      {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.navbarID = this.id ? this.$chance.string(this.$newIDParams) : this.id;
      }
      return this.navbarID;
    },
    navbarItems: {
      get()
      {
        return this.content.items || [];
      },
      set(newItems)
      {
        if (!newItems)
          return;
        this.selectItem(-1);
        this.$emit('change', { ...this.content, items: newItems });
      },
    },
    mobileMenuType()
    {
      return this.content.mobileMenuType || 'drop';
    },
    desktopMenuType()
    {
      return this.content.desktopMenuType || '';
    },
    offCanvasPositionClass()
    {
      switch (this.content.sidebarPosition)
      {
        case 'left': return 'offcanvas-start';
        case 'right': return 'offcanvas-end';
        case 'top': return 'offcanvas-top';
        case 'bottom': return 'offcanvas-bottom';
        default: return 'offcanvas-end';
      }
    },
    elementsAlignment()
    {
      switch (this.content.elementsAlignment)
      {
        case 'text-start': return `justify-content-start ${this.content.elementsAlignment}`;
        case 'text-center': return `justify-content-center ${this.content.elementsAlignment}`;
        case 'text-end': return `justify-content-end ${this.content.elementsAlignment}`;
        default: return 'justify-content-start text-start';
      }
    },
    sidebarBackgroundColor()
    {
      if (!this.content.sidebarBackgroundColor)
        return '';
      if (this.content.sidebarBackgroundColor.hex8)
        return this.content.sidebarBackgroundColor.hex8;
      if (this.content.sidebarBackgroundColor.rgba)
        return `rgba(${this.content.sidebarBackgroundColor.rgba.r},${this.content.sidebarBackgroundColor.rgba.g},${this.content.sidebarBackgroundColor.rgba.b},${this.content.sidebarBackgroundColor.rgba.a},) `;
      return '';
    },
    burgerColorStyle()
    {
      if (!this.content.burgerColor)
        return '';
      if (this.content.burgerColor.hex8)
        return this.content.burgerColor.hex8;
      if (this.content.burgerColor.rgba)
        return `rgba(${this.content.burgerColor.rgba.r},${this.content.burgerColor.rgba.g},${this.content.burgerColor.rgba.b},${this.content.burgerColor.rgba.a},) `;
      return '';
    },
  },
  methods: {
    selectItem(itemID)
    {
      this.$emit('selectItem', itemID);
    },
    navItemClasses(item)
    {
      let out = 'nav-item';
      if (item.type === 'separator')
        out = out + ' mx-auto d-none d-md-block nav-separator';
      else if (item.type === 'dropdown')
        out = out + ' dropdown';
      return out;
    },
  },
};

</script>

<style scoped>

</style>
