<template>
  <StyleEditor
      v-model="FooterStyle"
      :properties="[
            'spacing',
            'border',
            'borderRadius',
          ]">
    <template v-slot:form-start>
      <div class="mb-3">
        <label>Largeur du footer</label>
        <select v-model="footerFullWidth" class="form-control">
          <option :value="false">Normal</option>
          <option :value="true">Pleine largeur</option>
        </select>
      </div>
    </template>
  </StyleEditor>
</template>

<script>

import { mapGetters } from 'vuex';
import StyleEditor from '../../../Utilities/StyleEditor.vue';

export default {
  name: 'FooterConfig',
  components: { StyleEditor },
  computed: {
    ...mapGetters(['footerConfig']),
    FooterStyle: {
      get()
      {
        return this.footerConfig || {};
      },
      set(newConfig)
      {
        this.$store.commit('setFooterConfig', { config: newConfig, dbSync: true });
      },
    },
    footerFullWidth: {
      get()
      {
        return this.footerConfig.fullWidth || false;
      },
      set(newFullWidth)
      {
        this.$store.commit('setFooterConfig', { config: { ...this.footerConfig, fullWidth: newFullWidth }, dbSync: true });
      },
    },
  },
};

</script>

<style scoped>

</style>
