<template>
  <div class="clockConfig editorUIStyle">
    <div class="mb-3">
      <label class="form-label">{{ dictionary.clockConfig.dateLabel }}</label>
      <flat-pickr
          v-model="date"
          :config="config"
          :placeholder="dictionary.clockConfig.datePlaceholder"
          name="date"
          class="h-100">
      </flat-pickr>
    </div>
    <div class="form-check form-switch mb-3">
      <label class="form-check-label" for="ClockConfigWrappingSwitch"><span class="small text-muted">{{ dictionary.clockConfig.responsiveLabel }}</span><br>{{ dictionary.clockConfig.multipleClockLabel }}</label>
      <input v-model="allowWrapping" class="form-check-input" type="checkbox" id="ClockConfigWrappingSwitch">
    </div>
    <div class="mb-3">
      <label class="form-label">{{ dictionary.clockConfig.elementsLabel }}</label>
      <multiselect v-model="displayedValues" :options="displayedValuesOptions" :multiple="true"/>
    </div>
    <div class="mb-3">
      <label class="form-label">{{ dictionary.clockConfig.endActionLabel }}</label>
      <select class="form-select" v-model="endAction">
        <option value="stay">{{ dictionary.clockConfig.keepClockLabel }}</option>
        <option value="hide">{{ dictionary.clockConfig.hideClockLabel }}</option>
      </select>
    </div>
    <div>
      <FontSizeInput :label="dictionary.clockConfig.numberSizeLabel" v-model="numbersSize"></FontSizeInput>
    </div>
    <div>
      <FontSizeInput :label="dictionary.clockConfig.textSizeLabel" v-model="textSize"></FontSizeInput>
    </div>
    <div class="mb-2">
      <InputColorPicker :label="dictionary.clockConfig.numberColorLabel" v-model="numbersColor"/>
    </div>
    <div class="mb-2">
      <InputColorPicker :label="dictionary.clockConfig.textColorLabel" v-model="wordingColor"/>
    </div>
    <div class="mb-3">
      <div class="row mb-1">
        <label class="col-4 col-form-label">{{ dictionary.clockConfig.daysLabel }}</label>
        <div class="col">
          <input :value="clockWordingGetValue('days')"
                 @change="clockWordingSetValue('days', $event)"
                 type="text" class="form-control" />
        </div>
        <div class="col-auto d-flex align-items-center" v-if="clockWordingGetValue('days') === ''"><span v-tooltip="dictionary.clockConfig.hideIfEmptyTooltip" class="badge rounded-pill bg-info text-white ms-2 small"><i class="fas fa-eye-slash small"></i></span></div>
      </div>
      <div class="row mb-1">
        <label class="col-4 col-form-label">{{ dictionary.clockConfig.hoursLabel }}</label>
        <div class="col">
          <input :value="clockWordingGetValue('hours')"
                 @change="clockWordingSetValue('hours', $event)"
                 type="text" class="form-control" />
        </div>
        <div class="col-auto d-flex align-items-center" v-if="clockWordingGetValue('hours') === ''"><span v-tooltip="dictionary.clockConfig.hideIfEmptyTooltip" class="badge rounded-pill bg-info text-white ms-2 small"><i class="fas fa-eye-slash small"></i></span></div>
      </div>
      <div class="row mb-1">
        <label class="col-4 col-form-label">{{ dictionary.clockConfig.minutesLabel }}</label>
        <div class="col">
          <input :value="clockWordingGetValue('minutes')"
                 @change="clockWordingSetValue('minutes', $event)"
                 type="text" class="form-control" />
        </div>
        <div class="col-auto d-flex align-items-center" v-if="clockWordingGetValue('minutes') === ''"><span v-tooltip="dictionary.clockConfig.hideIfEmptyTooltip" class="badge rounded-pill bg-info text-white ms-2 small"><i class="fas fa-eye-slash small"></i></span></div>
      </div>
      <div class="row mb-1">
        <label class="col-4 col-form-label">{{ dictionary.clockConfig.secondsLabel }}</label>
        <div class="col">
          <input :value="clockWordingGetValue('seconds')"
                 @change="clockWordingSetValue('seconds', $event)"
                 type="text" class="form-control" />
        </div>
        <div class="col-auto d-flex align-items-center" v-if="clockWordingGetValue('seconds') === ''"><span v-tooltip="dictionary.clockConfig.hideIfEmptyTooltip" class="badge rounded-pill bg-info text-white ms-2 small"><i class="fas fa-eye-slash small"></i></span></div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import flatPickr from 'vue-flatpickr-component';
import Multiselect from 'vue-multiselect';
import FontSizeInput from '../../../../Utilities/FontSizeInput.vue';
import InputColorPicker from '../../../../Utilities/InputColorPicker.vue';

export default {
  name: 'ClockConfig',
  components: {
    FontSizeInput,
    flatPickr,
    Multiselect,
    InputColorPicker,
  },
  model: {
    prop: 'content',
    event: 'change',
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data()
  {
    return {
      config: {
        altInput: true,
        altFormat: 'd-m-Y H:i',
        dateFormat: 'Z',
        inline: true,
        enableTime: true,
        time_24hr: true,
        monthSelectorType: 'static',
      },
      configItemHeight: {},
      styleItemHeight: {},
      displayedValuesOptions: ['Days', 'Hours', 'Minutes', 'Seconds'],
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
    date: {
      get()
      {
        return this.content.date || '';
      },
      set(newDate) {
        this.$emit('change', { ...this.content, date: newDate });
      },
    },
    allowWrapping: {
      get()
      {
        return this.content.allowWrapping || true;
      },
      set(newState) {
        this.$emit('change', { ...this.content, allowWrapping: newState });
      },
    },
    displayedValues: {
      get()
      {
        return this.content.displayedValues || ['Days', 'Hours', 'Minutes', 'Seconds'];
      },
      set(newState) {
        this.$emit('change', { ...this.content, displayedValues: newState });
      },
    },
    endAction: {
      get()
      {
        return this.content.endAction || 'stay';
      },
      set(newState) {
        this.$emit('change', { ...this.content, endAction: newState });
      },
    },
    numbersSize: {
      get()
      {
        return this.content.numbersSize || '';
      },
      set(newSize) {
        this.$emit('change', { ...this.content, numbersSize: newSize });
      },
    },
    textSize: {
      get()
      {
        return this.content.textSize || '';
      },
      set(newSize) {
        this.$emit('change', { ...this.content, textSize: newSize });
      },
    },
    numbersColor: {
      get()
      {
        return this.content.numbersColor || { hex8: '#000000FF' };
      },
      set(newNumbersColor)
      {
        this.$emit('change', { ...this.content, numbersColor: newNumbersColor });
      },
    },
    wordingColor: {
      get()
      {
        return this.content.wordingColor || { hex8: '#000000FF' };
      },
      set(newWordingColor)
      {
        this.$emit('change', { ...this.content, wordingColor: newWordingColor });
      },
    },
  },
  methods: {
    clockWordingGetValue(clockWording) {
      if (!this.content.clockWording) {
        this.content.clockWording = {};
      }
      return this.content.clockWording[clockWording] || '';
    },
    clockWordingSetValue(clockWording, event) {
      const out = { ...this.content.clockWording };
      out[clockWording] = event.target.value;
      this.$emit('change', { ...this.content, clockWording: out });
    },
  },
};
</script>

<style>

.flatpickr-calendar {
  width: unset!important;
}

.cur-month {
  font-size: 80%;
}

</style>
