<template>
  <div>
    <StyleEditor
        v-if="bodyConfig"
        v-model="style"
        :properties="[
          'backgroundImageConfig',
          'backgroundColor',
          'fontFamily',
          'fontWeight',
          'color',
      ]">
      <template v-slot:form-start>
        <div class="mb-3">
          <label>{{ dictionary.leftBar.fullWidthWebsite }}</label>
          <select v-model="fullWidth" class="form-control">
            <option :value="false">Normal</option>
            <option :value="true">Pleine largeur</option>
          </select>
        </div>
        <div class="my-3">
          <div class="form-check form-switch">
            <input v-model="isMobileImage" class="form-check-input" type="checkbox" id="isMobileImageSwitch">
            <label class="form-check-label" for="isMobileImageSwitch">{{ dictionary.leftBar.isMobileVersion }}</label>
          </div>
        </div>
        <template v-if="isMobileImage">
          <InputImageUpload :title="`<i class='fas fa-desktop me-2'></i>${dictionary.mediaConfig.desktopLabel} <span class='text-muted'> >768px</span>`" v-model="backgroundImage"/>
          <InputImageUpload class="mt-2 mb-3" :title="`<i class='fas fa-mobile-alt me-3'></i>${dictionary.mediaConfig.mobileLabel} <span class='text-muted'> <768px</span>`" v-model="mobileBackgroundImage"/>
        </template>
        <template v-else>
          <InputImageUpload class="mb-3" :title="dictionary.styleEditor.backgroundImage" v-model="backgroundImage"/>
        </template>
      </template>
      <template v-slot:form-end>
        <InputColorPicker
            :label="dictionary.leftBar.colorLinks"
            v-model="linkColor"/>
      </template>
    </StyleEditor>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import StyleEditor from '../../../Utilities/StyleEditor.vue';
import InputColorPicker from '../../../Utilities/InputColorPicker.vue';
import InputImageUpload from '../../../Utilities/InputImageUpload.vue';

export default {
  name: 'BodyConfig',
  components: {
    InputImageUpload,
    StyleEditor,
    InputColorPicker,
  },
  computed: {
    ...mapGetters(['bodyConfig', 'googleFonts', 'dictionary']),
    style: {
      get()
      {
        return this.bodyConfig || {};
      },
      set(newStyle)
      {
        this.$store.commit('setBodyConfig', { config: { ...this.bodyConfig, ...newStyle }, dbSync: true });
      },
    },
    backgroundImage: {
      get()
      {
        return this.bodyConfig.backgroundImage || '';
      },
      set(newBackgroundImage)
      {
        this.$store.commit('setBodyConfig', { config: { ...this.bodyConfig, backgroundImage: newBackgroundImage }, dbSync: true });
      },
    },
    mobileBackgroundImage: {
      get()
      {
        return this.bodyConfig.mobileBackgroundImage || '';
      },
      set(newMobileBackgroundImage)
      {
        this.$store.commit('setBodyConfig', { config: { ...this.bodyConfig, mobileBackgroundImage: newMobileBackgroundImage }, dbSync: true });
      },
    },
    isMobileImage: {
      get()
      {
        return this.bodyConfig.isMobileImage || false;
      },
      set(newIsMobileImage)
      {
        this.$store.commit('setBodyConfig', { config: { ...this.bodyConfig, isMobileImage: newIsMobileImage }, dbSync: true });
      },
    },
    fullWidth: {
      set(value)
      {
        this.$store.commit('setBodyConfig', { config: { ...this.bodyConfig, fullWidth: value }, dbSync: true });
      },
      get()
      {
        return this.bodyConfig.fullWidth || false;
      },
    },
    linkColor: {
      set(value)
      {
        this.$store.commit('setBodyConfig', { config: { ...this.bodyConfig, linkColor: value }, dbSync: true });
      },
      get()
      {
        return this.bodyConfig.linkColor || {
          hex: '#0000EE',
          rgb: {
            r: 0,
            g: 0,
            b: 238,
          },
          source: 'hex',
        };
      },
    },
  },
};

</script>

<style scoped>

</style>
