export default {
  styleFromObject: (state) => (styleObject) => {
    const out = {};

    const spacing = styleObject.spacing;
    if (spacing && spacing.margin)
    {
      if (spacing.margin.top)
        out.marginTop = `${spacing.margin.top}px`;
      if (spacing.margin.down)
        out.marginBottom = `${spacing.margin.down}px`;
      if (spacing.margin.left)
        out.marginLeft = `${spacing.margin.left}px`;
      if (spacing.margin.right)
        out.marginRight = `${spacing.margin.right}px`;
    }
    if (spacing && spacing.padding)
    {
      if (spacing.padding.top)
        out.paddingTop = `${spacing.padding.top}px`;
      if (spacing.padding.down)
        out.paddingBottom = `${spacing.padding.down}px`;
      if (spacing.padding.left)
        out.paddingLeft = `${spacing.padding.left}px`;
      if (spacing.padding.right)
        out.paddingRight = `${spacing.padding.right}px`;
    }
    if (styleObject.backgroundColor)
    {
      if (styleObject.backgroundColor.hex8)
        out.backgroundColor = styleObject.backgroundColor.hex8;
      else if (styleObject.backgroundColor.hex)
        out.backgroundColor = styleObject.backgroundColor.hex;
      else if (styleObject.backgroundColor.rgba)
        out.backgroundColor = `rgba(
            ${styleObject.backgroundColor.rgba.r},
            ${styleObject.backgroundColor.rgba.g},
            ${styleObject.backgroundColor.rgba.b},
            ${styleObject.backgroundColor.rgba.a})`;
    }
    if (styleObject.color)
    {
      if (styleObject.color.hex8)
        out.color = styleObject.color.hex8;
      else if (styleObject.color.hex)
        out.color = styleObject.color.hex;
      else if (styleObject.color.rgba)
        out.color = `rgba(
            ${styleObject.color.rgba.r},
            ${styleObject.color.rgba.g},
            ${styleObject.color.rgba.b},
            ${styleObject.color.rgba.a}
          )`;
    }
    const border = styleObject.border;
    if (border)
    {
      out.borderWidth = border.thickness ? `${border.thickness}px` : undefined;
      out.borderStyle = border.style || '';
      // out.borderColor = border.color.hex8 || '';
      if (border.color)
      {
        if (border.color.hex8)
          out.borderColor = border.color.hex8;
        else if (border.color.hex)
          out.borderColor = border.color.hex;
        else if (border.color.rgba)
          out.borderColor = `rgba(
            ${border.color.rgba.r},
            ${border.color.rgba.g},
            ${border.color.rgba.b},
            ${border.color.rgba.a}
          )`;
      }
    }
    if (styleObject.fontFamily)
      out.fontFamily = styleObject.fontFamily || undefined;
    if (styleObject.fontWeight)
      out.fontWeight = styleObject.fontWeight || undefined;
    if (styleObject.fontSize)
      out.fontSize = styleObject.fontSize;
    if (styleObject.borderRadius)
      out.borderRadius = `${styleObject.borderRadius}px`;
    const backgroundImage = styleObject.backgroundImage;
    if (backgroundImage)
      out.backgroundImage = `url(${backgroundImage})`;

    const backgroundImageProperties = styleObject.backgroundImageProperties;
    if (backgroundImageProperties)
    {
      if (backgroundImageProperties.cover)
        out.backgroundSize = backgroundImageProperties.cover;
      if (backgroundImageProperties.repetition)
        out.backgroundRepeat = backgroundImageProperties.repetition;
      if (backgroundImageProperties.scroll)
        out.backgroundAttachment = backgroundImageProperties.scroll;
    }
    if (styleObject.width)
      out.width = `${styleObject.width}%`;
    return out;
  },
};
