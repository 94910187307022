import clientInfo from 'client-info';

const GitlabAPIToken = 'NKb_gwHXpC4dAtDPSEfv';

export default {
  /**
   * Create an issue on gitlab,
   * the issue data are sent along the user agent information
   * @param getters
   * @param state
   * @param issue : {
   *   title: the title of the issue
   *   message: the body of the issue
   *   sender: the creator of the issue
   *   tags: an array of tags
   *   the type of the issue (just a string like bug/improvement)
   * }
   */
  createGitlabIssue({ getters, state }, issue)
  {
    let url = `https://gitlab.com/api/v4/projects/28443521/issues?access_token=${GitlabAPIToken}`;
    url += `&title=[Automatic Report] ${issue.title}`;
    if (issue.message === undefined)
      issue.message = '';
    url += `&description=### *Message*\n${issue.message.replaceAll('\n', '<br>')}`;
    url += `\n\n### *From*:\n${(issue.sender !== undefined) ? issue.sender : ''}`;
    url += `\n\n### *Path*:\n${(getters.path !== undefined) ? getters.path : ''}`;
    url += `\n\n### *Token*:\n${(getters.tokenID !== undefined) ? getters.tokenID : ''}`;
    url += `\n\n### *Agent*:\n${JSON.stringify(clientInfo.getBrowser())}`;

    fetch(encodeURI(url).replaceAll('#', '%23'), { method: 'POST' })
      .then((response) => {
        if (!response.ok)
          console.log(`Error while creating ticket : ${response.statusText}`);
      });
  },
};
