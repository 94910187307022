<template>
  <div class="row">
    <div v-if="!isAColSelected" class="col text-center text-muted">
      <p><i>{{ dictionary.leftBar.selectColToEdit }}</i></p>
    </div>
    <div v-else class="col">
      <div class="row" v-if="!colHasContent">
        <div class="col-12 mb-3">
          <button @click="setColumnContent('navbar-v2')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-bars me-3"></i>Barre de navigation</button>
        </div>
        <div class="col-12 mb-3">
          <button @click="setColumnContent('text')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-font me-3"></i>Bloc de texte</button>
        </div>
        <div class="col-12 mb-3">
          <button @click="setColumnContent('media')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-photo-video me-3"></i>Media / Iframe</button>
        </div>
        <div class="col-12 mb-3">
          <button @click="setColumnContent('code')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-code me-3"></i>Bloc de Code</button>
        </div>
        <div class="col-12 mb-3">
          <button @click="setColumnContent('form')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fab fa-wpforms me-3"></i>Formulaire</button>
        </div>
        <div class="col-12 mb-3">
          <button @click="setColumnContent('clock')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-clock me-3"></i>Decompte</button>
        </div>
        <div class="col-12 mb-3">
          <button @click="setColumnContent('separator')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-arrows-alt-v me-3"></i>Séparateur</button>
        </div>
        <div class="col-12 mb-3">
          <button @click="setColumnContent('carousel')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-horse me-3"></i>Carousel</button>
        </div>
        <div class="col-12 mb-3">
          <button @click="setColumnContent('legalInformations')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-balance-scale me-3"></i>{{ dictionary.columnContentContext.legalInfoButton }}</button>
        </div>
        <div class="col-12 mb-3">
          <button @click="setColumnContent('button')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="far fa-square me-3"></i>{{ dictionary.columnContentContext.buttonContentButton }}</button>
        </div>
        <div class="col-12 mb-3">
          <button @click="setColumnContent('mappedImage')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-vr-cardboard me-3"></i>{{ dictionary.columnContentContext.mappedImageButton }}</button>
        </div>
        <div class="col-12 mb-3">
          <button @click="setColumnContent('payment')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-dollar-sign me-3"></i>{{ dictionary.columnContentContext.paymentContentButton }}</button>
        </div>
        <div class="col-12 mb-3">
          <hr class="w-100">
        </div>
        <div class="col-12 mb-3">
          <button @click="importButtonClick" class="btn btn-success text-light w-100 text-start ps-4"><i class="fas fa-upload me-3"></i>Importer une colonne</button>
          <input accept="application/JSON" ref="importHiddenInput" type="file" @change="importCol" class="invisible">
        </div>
      </div>
      <div v-else>
        <TextConfig v-if="selectedColContentType === 'text'" v-model="columnContent"/>
        <MediaConfig v-else-if="selectedColContentType === 'media'" v-model="columnContent"/>
        <ClockConfig v-else-if="selectedColContentType === 'clock'" v-model="columnContent"/>
        <NavbarConfig v-else-if="selectedColContentType === 'navbar-v2'" v-model="columnContent"/>
        <FormConfig v-else-if="selectedColContentType === 'form'" v-model="columnContent"/>
        <CodeConfig v-else-if="selectedColContentType === 'code'" v-model="columnContent"/>
        <separatorConfig v-else-if="selectedColContentType === 'separator'" v-model="columnContent"/>
        <carouselConfig v-else-if="selectedColContentType === 'carousel'" v-model="columnContent"/>
        <LegalInformationsConfig v-else-if="selectedColContentType === 'legalInformations'" v-model="columnContent"/>
        <ButtonConfig v-else-if="selectedColContentType === 'button'" v-model="columnContent"/>
        <PaymentConfig v-else-if="selectedColContentType === 'payment'" v-model="columnContent"/>
        <p v-else class="text-center small text-muted">Contenus invalide: {{ selectedColContentType }}</p>
        <button class="btn btn-danger btn-raised w-100 mt-4 text-white" @click="$refs.confirmationDeleteContent.openContext()"><i class="fas fa-trash text-light me-3"></i>{{ dictionary.leftBar.deleteContent }}</button>
        <confirmationContext
            @confirm="removeColumnContent"
            ref="confirmationDeleteContent"
            :introMessage="`<h6>${dictionary.leftBar.deleteContextText}</h6>`"
            :confirmButtonContent="dictionary.confirm"
            :deniButtonContent="dictionary.no"/>
      </div>
    </div>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import ConfirmationContext from '../../../contexts/ConfirmationContext.vue';
import TextConfig from './ContentConfigs/TextConfig.vue';
import MediaConfig from './ContentConfigs/MediaConfig.vue';
import ClockConfig from './ContentConfigs/ClockConfig.vue';
import NavbarConfig from './ContentConfigs/NavbarConfig.vue';
import FormConfig from './ContentConfigs/FormConfig.vue';
import CodeConfig from './ContentConfigs/CodeConfig.vue';
import SeparatorConfig from './ContentConfigs/SeparatorConfig.vue';
import CarouselConfig from './ContentConfigs/CarouselConfig.vue';
import LegalInformationsConfig from './ContentConfigs/LegalInformationsConfig.vue';
import ButtonConfig from './ContentConfigs/ButtonConfig.vue';
import PaymentConfig from './ContentConfigs/PaymentConfig.vue';

export default {
  name: 'ContentEditor',
  components: {
    PaymentConfig,
    ButtonConfig,
    LegalInformationsConfig,
    ClockConfig,
    SeparatorConfig,
    MediaConfig,
    TextConfig,
    NavbarConfig,
    FormConfig,
    CodeConfig,
    ConfirmationContext,
    CarouselConfig,
  },
  computed: {
    ...mapGetters(['selectedColumn', 'dictionary', 'getDefaultContentForType']),
    isAColSelected()
    {
      return this.selectedColumn !== undefined;
    },
    colHasContent()
    {
      if (!this.isAColSelected)
        return false;
      return this.selectedColumn.content !== undefined &&
          this.selectedColumn.content.type !== undefined &&
          this.selectedColumn.content.type !== '';
    },
    selectedColContentType()
    {
      if (this.isAColSelected && this.colHasContent)
        return this.selectedColumn.content.type;
      return '';
    },
    columnContent: {
      get()
      {
        return this.selectedColumn.content;
      },
      set(content) {
        this.$store.commit('setColumnContent', { column: this.selectedColumn, content, dbSync: true });
      },
    },
  },
  methods: {
    importButtonClick()
    {
      this.$refs.importHiddenInput.click();
    },
    importCol(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object !== undefined)
          {
            if (object.content)
              this.$store.commit('setColumnContent', { column: this.selectedColumn, content: object.content, dbSync: true });
            if (object.values)
              this.$store.commit('setColumnValues', { col: this.selectedColumn, values: object.values, dbSync: true });
            if (object.layout)
              this.$store.commit('setColumnSize', { col: this.selectedColumn, layout: object.layout, dbSync: true });
            this.importColumnNotif();
          }
        } catch (ex)
        {
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
    setColumnContent(contentType)
    {
      this.$store.commit('setColumnContent', { column: this.selectedColumn, content: this.getDefaultContentForType(contentType), dbSync: true });
      this.setColumnContentNotif({ message: `Type <span class="text-primary">${contentType}</span> appliqué a la colonne` });
    },
    openContentContextClick()
    {
      this.$refs.addContentContextLeft.openContext();
    },
    removeColumnContent()
    {
      this.$store.commit('setColumnContent', { column: this.selectedColumn, content: {}, dbSync: true });
      this.deleteColumnContentNotif();
    },
  },
  notifications: {
    setColumnContentNotif: {
      type: VueNotifications.types.success,
      title: 'Colonne Modifié !',
      message: 'Contenu de la colonne appliqué.',
    },
    importColumnNotif: {
      type: VueNotifications.types.success,
      title: 'Colonne importé !',
      message: 'La colonne à été importé avec succès.',
    },
    deleteColumnContentNotif: {
      type: VueNotifications.types.success,
      title: 'Colonne Modifié !',
      message: 'Contenus de la colonne supprimé.',
    },
  },
};

</script>

<style scoped>

</style>
