<template>
  <perfect-scrollbar class="h-100 pe-3" :options="{ wheelPropagation: false, suppressScrollX: true }">
    <form @submit.stop.prevent>
      <inputColorPicker v-model="color" label="Couleur de la zone" class="mb-2"/>
      <ActionSelector v-model="action"/>
      <div class="mb-2 row">
        <div class="col-auto">
          <label class="small">Position<i class="fas fa-arrows-alt-h ms-2"></i></label>
        </div>
        <div class="col">
          <input type="number" class="form-control"
                 :value="this.zone.position.x || 0"
                 @change="$emit('change', { ...zone, position: { ...zone.position, x: parseInt($event.target.value)} })">
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-auto">
          <label class="small">Position <i class="fas fa-arrows-alt-v ms-2"></i></label>
        </div>
        <div class="col">
          <input type="number" class="form-control"
                 :value="this.zone.position.y || 0"
                 @change="$emit('change', { ...zone, position: { ...zone.position, y: parseInt($event.target.value)} })">
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-auto">
          <label class="small">Hauteur</label>
        </div>
        <div class="col">
          <input type="number" class="form-control"
                 :value="this.zone.position.h || 0"
                 @change="$emit('change', { ...zone, position: { ...zone.position, h: parseInt($event.target.value)} })">
        </div>
      </div>
      <div class="mb-2 row">
        <div class="col-auto">
          <label class="small">Largeur</label>
        </div>
        <div class="col">
          <input type="number" class="form-control"
                 :value="this.zone.position.w || 0"
                 @change="$emit('change', { ...zone, position: { ...zone.position, w: parseInt($event.target.value)} })">
        </div>
      </div>
      <button role="button" type="button" @click="$emit('removeSelected')" class="btn btn-danger w-100 mb-3 text-light"><i class="fas fa-trash me-2"></i>Supprimer la zone</button>
    </form>
  </perfect-scrollbar>
</template>

<script>

import { mapGetters } from 'vuex';
import InputColorPicker from '../../Utilities/InputColorPicker.vue';
import ActionSelector from '../../Utilities/ActionSelector.vue';

export default {
  name: 'ZoneProperties',
  components: { InputColorPicker, ActionSelector },
  model: {
    prop: 'zone',
    event: 'change',
  },
  props: {
    zone: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    action: {
      get()
      {
        return this.zone || {};
      },
      set(newActionObj)
      {
        this.$emit('change', { ...this.zone, ...newActionObj });
      },
    },
    color: {
      get()
      {
        return this.zone.color || {};
      },
      set(newColor)
      {
        this.$emit('change', { ...this.zone, color: newColor });
      },
    },
  },
};

</script>

<style scoped>

</style>
