<template>
  <div class="rowOverlay mx-0 position-relative">
    <div class="position-absolute row bg-primary rounded-end p-1 m-0 d-block top-0" style="right: -40px;">
      <div class="col-12 p-0">
        <i class="fas fa-grip-lines text-secondary rowDragHandle p-2 cursor-grab"></i>
      </div>
      <div class="col-12 p-0 ">
        <button v-tooltip.left="dictionary.rowOverlay.deleteRowTooltip" class="btn btn-sm btn-danger w-100 py-0 mt-1 text-center" @click="$refs.confirmationDeleteLine.openContext()">
          <i class="fas fa-trash text-light" ></i>
        </button>
      </div>
      <div class="col-12 p-0">
        <button v-tooltip.left="dictionary.rowOverlay.hideRowTooltip" class="btn btn-sm btn-secondary w-100 py-0 mt-1 text-center" @click="visibilityClick" :class="isVisible ? 'btn-success' : 'btn-secondary'">
          <i v-if="isVisible" class="fas fa-eye-slash small text-light"></i>
          <i v-else class="fas fa-eye small text-light"></i>
        </button>
      </div>
      <div class="col-12 p-0">
        <button v-tooltip.left="dictionary.rowOverlay.stickyRowTooltip" class="btn btn-sm w-100 py-0 mt-1 text-center" @click="stickyClick" :class="isSticky ? 'btn-success' : 'btn-secondary'">
          <i class="fas fa-map-pin small text-light"></i>
        </button>
      </div>
      <div v-if="hasFilter" class="col-12 p-0">
        <button style="cursor: default" v-tooltip.left="dictionary.rowOverlay.filteredRowTooltip" class="btn btn-sm w-100 py-0 mt-1 text-center btn-info">
          <i class="fas fa-filter small text-light"></i>
        </button>
      </div>
      <div v-if="hasSpacing" class="col-12 p-0">
        <button v-tooltip.left="dictionary.rowOverlay.displayPadMarTooltip" class="btn btn-sm w-100 py-0 mt-1 text-center" @click="onToggleSpacing" :class="toggleSpacing ? 'btn-success' : 'btn-secondary'">
          <i class="fas fa-object-group text-white"></i>
        </button>
      </div>
      <div v-if="isDesktopOnly" class="col-12 p-0">
        <button v-tooltip.left="'Visible uniquement sur ordinateur'" class="btn btn-sm w-100 py-0 mt-1 text-center btn-success">
          <i class="fas fa-desktop text-white"></i>
        </button>
      </div>
      <div v-if="isMobileOnly" class="col-12 p-0">
        <button v-tooltip.left="'Visible uniquement sur mobile'" class="btn btn-sm w-100 py-0 mt-1 text-center btn-success">
          <i class="fas fa-mobile text-white"></i>
        </button>
      </div>
      <div v-if="!isRowFirst(row)" class="col-12 p-0">
        <button @click="onRowUp" v-tooltip.left="'Monter la ligne'" class="btn btn-sm w-100 py-0 mt-1 text-center btn-light">
          <i class="fas fa-arrow-up text-secondary"></i>
        </button>
      </div>
      <div class="col-12 p-0">
        <button v-if="!isRowLast(row)" @click="onRowDown" v-tooltip.left="'Descendre la ligne'" class="btn btn-sm w-100 py-0 mt-1 text-center btn-light">
          <i class="fas fa-arrow-down text-secondary"></i>
        </button>
      </div>
    </div>
    <confirmation-context ref="confirmationDeleteLine" :introMessage="`<h6>${dictionary.rowOverlay.deleteRowConfirm}</h6>`" @confirm="onDelete"/>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import ConfirmationContext from '../contexts/ConfirmationContext.vue';

export default {
  name: 'RowOverlay',
  components: { ConfirmationContext },
  props: {
    row: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data()
  {
    return {
      toggleSpacing: false,
    };
  },
  computed: {
    ...mapGetters(['dictionary', 'isRowFirst', 'isRowLast']),
    isEmpty()
    {
      return !(this.row.columns.length > 0);
    },
    isVisible()
    {
      return this.row.values ? this.row.values.hidden || false : false;
    },
    isSticky()
    {
      return this.row.values ? this.row.values.sticky || false : false;
    },
    hasFilter()
    {
      return !!this.row.filter;
    },
    hasSpacing()
    {
      if (!this.row.values || !this.row.values.spacing)
        return false;
      if (this.row.values.spacing.margin)
        return this.row.values.spacing.margin.top ||
          this.row.values.spacing.margin.down ||
          this.row.values.spacing.margin.left ||
          this.row.values.spacing.margin.right;
      if (this.row.values.spacing.padding)
        return this.row.values.spacing.padding.top ||
          this.row.values.spacing.padding.down ||
          this.row.values.spacing.padding.left ||
          this.row.values.spacing.padding.right;
      return false;
    },
    isDesktopOnly()
    {
      if (!this.row.values)
        return false;
      const deviceFilter = this.row.values.deviceFilter;
      return (deviceFilter && (deviceFilter.displayDesktop || deviceFilter.displayDesktop === undefined) && !deviceFilter.displayMobile);
    },
    isMobileOnly()
    {
      if (!this.row.values)
        return false;
      const deviceFilter = this.row.values.deviceFilter;
      return (deviceFilter && (deviceFilter.displayMobile || deviceFilter.displayMobile === undefined) && !deviceFilter.displayDesktop);
    },
  },
  mounted()
  {
    this.$emit('toggleSpacing', this.toggleSpacing);
  },
  methods: {
    visibilityClick()
    {
      this.$store.commit('setRowValues', { row: this.row, values: { ...this.row.values, hidden: !this.isVisible }, dbSync: true });
    },
    stickyClick()
    {
      this.$store.commit('setRowValues', { row: this.row, values: { ...this.row.values, sticky: !this.isSticky }, dbSync: true });
    },
    onDelete()
    {
      this.$store.commit('removeRow', { row: this.row, dbSync: true });
      this.deleteRow();
    },
    onRowUp()
    {
      if (!this.isRowFirst(this.row))
        this.$store.commit('swapTwoRows', { rowIdx1: this.rowIndex, rowIdx2: this.rowIndex - 1 });
    },
    onRowDown()
    {
      if (!this.isRowLast(this.row))
        this.$store.commit('swapTwoRows', { rowIdx1: this.rowIndex, rowIdx2: this.rowIndex + 1 });
    },
    onToggleSpacing()
    {
      this.toggleSpacing = !this.toggleSpacing;
      this.$emit('toggleSpacing', this.toggleSpacing);
    },
  },
  notifications: {
    deleteRow: {
      type: VueNotifications.types.success,
      title: 'Ligne supprimé !',
      message: 'Ligne supprimé avec succès.',
    },
  },
};
</script>

<style scoped>

.rowOverlay {
  border: 2px dashed rgba(var(--bs-primary-rgb));
}

</style>
