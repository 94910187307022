<template>
  <div v-if="isEmpty && !deploymentMode">
    <div class="row">
      <div class="col-12 text-center mt-5 mb-5 pb-3 text-muted">
        <h6 class="fab fa-wpforms fa-2x mb-2"></h6><br>
        <h6>{{ dictionary.noForm }}</h6>
      </div>
    </div>
  </div>
  <FormItemList v-else :formItems="formItems" :deploymentMode="deploymentMode" :hiddenFields="hiddenFields" :alerts="alerts" :alreadyRegisteredConfig="alreadyRegisteredConfig"/>
</template>

<script>

import { mapGetters } from 'vuex';
import FormItemList from './FormItem/FormItemList.vue';

export default {
  name: 'FormContent',
  components: {
    FormItemList,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    isEmpty()
    {
      return !this.content.data || !this.content.data.formType;
    },
    hiddenFields()
    {
      if (this.content.data)
        return this.content.data.hiddenFields || [];
      return [];
    },
    alerts()
    {
      if (this.content.data)
        return this.content.data.alerts || {};
      return {};
    },
    formItems()
    {
      if (this.content.data)
        return this.content.data.fields || [];
      return [];
    },
    alreadyRegisteredConfig()
    {
      if (this.content.data)
        return this.content.data.alreadyRegisteredConfig || {};
      return {};
    },
  },
};

</script>

<style scoped>

</style>
