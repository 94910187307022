<template>
  <div v-if="loaded" class="h-100">
    <card class="h-50" :title="`<i class='fas fa-money-bill-wave me-3'></i>Paiements`">
      <multiselect
          :options="availablePaymentList"
          :multiple="true"
          :close-on-select="false"
          :custom-label="customLabelPayment"
          :value="paymentList"
          @select="addPayment($event)"
          @remove="removePayment($event)"/>
    </card>
    <card class="h-50" :title="`<i class='fas fa-plug me-3'></i>Integrations`">
      <multiselect
          :options="availableIntegrationList"
          :multiple="true"
          :close-on-select="false"
          :custom-label="customLabelIntegrations"
          :value="integrationsList"
          @select="addIntegrations($event)"
          @remove="removeIntegrations($event)"/>
    </card>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect';
import _ from 'lodash';
import Card from '../../Utilities/Card.vue';

export default {
  name: 'IntegrationsSettings',
  components: {
    Multiselect,
    Card,
  },
  model: {
    prop: 'Integrations',
    event: 'change',
  },
  props: {
    Integrations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payments: [],
      integrations: [],
      loaded: false,
    };
  },
  async mounted()
  {
    try {
      this.payments = await this.$store.dispatch('getInvoiceAnimationsList');
      this.integrations = await this.$store.dispatch('getIntegrationsList');
      this.loaded = true;
    } catch (err)
    {
      console.error(err);
      this.loaded = true;
    }
  },
  computed: {
    availablePaymentList()
    {
      return Object.keys(this.payments || {});
    },
    availableIntegrationList()
    {
      return Object.keys(this.integrations || {});
    },
    paymentList()
    {
      return this.Integrations?.payments || [];
    },
    integrationsList()
    {
      return this.Integrations?.integrations || [];
    },
  },
  methods: {
    customLabelIntegrations(id)
    {
      if (this.integrations && this.integrations[id])
        return this.integrations[id].name || id;
      return `[ANIMATION INCONNUE] ${id}`;
    },
    addIntegrations(toAdd)
    {
      const out = _.cloneDeep(this.integrationsList);
      out.push(toAdd);
      this.$emit('change', { ...this.Integrations, integrations: out });
    },
    removeIntegrations(toDelete)
    {
      const out = _.cloneDeep(this.integrationsList);
      out.splice(out.indexOf(toDelete), 1);
      this.$emit('change', { ...this.Integrations, integrations: out });
    },
    customLabelPayment(id)
    {
      if (this.payments && this.payments[id])
        return this.payments[id].name || id;
      return `[ANIMATION INCONNUE] ${id}`;
    },
    addPayment(toAdd)
    {
      const out = _.cloneDeep(this.paymentList);
      out.push(toAdd);
      this.$emit('change', { ...this.Integrations, payments: out });
    },
    removePayment(toDelete)
    {
      const out = _.cloneDeep(this.paymentList);
      out.splice(out.indexOf(toDelete), 1);
      this.$emit('change', { ...this.Integrations, payments: out });
    },
  },
};

</script>

<style scoped>
</style>
