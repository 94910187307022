<template>
  <div class="editorUIStyle">
    <label v-if="label" class="small">{{ label }}</label>
    <div class="input-group w-100">
      <select class="form-select" :value="family" @input="$emit('changeFamily', $event.target.value)">
        <option class="disabled" value="" selected>{{ dictionary.utilities.defaultFont }}</option>
        <optgroup :label="dictionary.utilities.baseFont">
          <option v-for="(ft, key) in baseFont" :key="key" :value="ft.value" >{{ ft.name }}</option>
        </optgroup>
        <optgroup :label="dictionary.utilities.exoticFont">
          <option v-for="(ft, key) in serverFont" :key="key" :value="ft.value" >{{ ft.name }}</option>
        </optgroup>
        <optgroup v-if="googleFonts" :label="dictionary.utilities.importedFonts">
          <option v-for="(ft, key) in googleFonts" :key="key" :value="`${ft.family}${ft.category ? `, ${ft.category}` : ''}`" >{{ ft.family }} ({{ ft.source || ''}})</option>
        </optgroup>
      </select>
      <select v-if="!disableWeight" class="form-select" :value="weight" @input="$emit('changeWeight', $event.target.value)">
        <option value="lighter">{{ dictionary.fontWeightSelector.thinText }}</option>
        <option value="" selected>{{ dictionary.fontWeightSelector.regularText }}</option>
        <option value="bold">{{ dictionary.fontWeightSelector.boldText }}</option>
        <option value="bolder">{{ dictionary.fontWeightSelector.bolderText }}</option>
      </select>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { baseFont, serverFont } from '../../configuration/defaultFont.json';

export default {
  name: 'FontSelector',
  model: {
    prop: 'family',
    event: 'changeFamily',
  },
  props: {
    family: {
      type: String,
      required: false,
    },
    weight: {
      type: String,
      required: false,
    },
    disableWeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['dictionary', 'googleFonts']),
    serverFont()
    {
      return serverFont;
    },
    baseFont()
    {
      return baseFont;
    },
  },
};

</script>

<style scoped>

</style>
