<template>
  <modal name="metaEditorModal" :width="'80%'" height="70%">
    <card class="h-100">
      <template v-slot:header-left><h6 class="text-white mb-0">{{ dictionary.propertiesModal.metasCollapseTitle }}</h6></template>
      <template v-slot:header-right><button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button></template>
      <div class="row h-100 pb-2">
        <card class="col-4 h-100 px-0 me-1">
          <template v-slot:header-left><p class="text-white mb-0">Liste des pages</p></template>
          <div class="d-flex flex-column h-100">
            <div class="row mx-2 pb-2 align-items-center">
              <div class="col">
                <div class="input-group">
                  <span class="input-group-text"><i class="fas fa-search text-secondary"></i></span>
                  <input v-model="pageSearchQuery" type="text" class="form-control">
                </div>
              </div>
            </div>
            <perfect-scrollbar class="flex-grow-1">
              <ul class="list-group">
                <li v-for="page in pages" :key="`pageButton-${page.id}`" :class="(page.id === selectedPage) ? 'active' : ''" class="list-group-item">
                  <div class="row">
                    <div class="col">
                      <p class="p-0 m-0">{{ page.name }}</p>
                    </div>
                    <div class="col-auto" v-if="page.id !== selectedPage">
                      <button @click="selectedPage = page.id" class="btn btn-success text-light"><i class="fas fa-pen"></i></button>
                    </div>
                  </div>
                </li>
              </ul>
            </perfect-scrollbar>
          </div>
        </card>
        <card class="col px-0 ms-1 h-100">
          <template v-slot:header-left><p class="text-white mb-0">Editeur</p></template>
          <template v-if="selectedPage" v-slot:header-right>
            <button v-if="getConfigForPage().mode === 'simple' || getConfigForPage().mode === ''" @click="setMetaEditorMode('advanced')" class="btn btn-primary text-light">Editeur avancé</button>
            <button v-if="getConfigForPage().mode === 'advanced'" @click="$refs.confirmationChangeMode.openContext()" class="btn btn-primary text-light">Editeur Simple</button>
            <confirmationContext
                @confirm="setMetaEditorMode('simple')"
                ref="confirmationChangeMode"
                introMessage="<h6>En changeant le mode vous pourriez perdre vos modifications</h6>"
                :confirmButtonContent="dictionary.confirm"
                :deniButtonContent="dictionary.cancel"/>
          </template>
          <template v-slot:default v-if="selectedPage === ''">
            <div class="row">
              <div class="col-12 mt-4 text-center">
                <p class="small text-muted">Sélectionnez une page à droite pour éditer ses metas</p>
              </div>
            </div>
          </template>
          <div v-else class="h-100">
            <perfect-scrollbar v-if="getConfigForPage().mode !== 'advanced'" class="h-100 pe-3" :options="{ wheelPropagation: false, suppressScrollX: true }" >
              <div class="mb-2">
                <label class="form-label" for="meta-title">Titre de la page</label>
                <input :value="getConfigForPage().title" @change="setPageTitle($event.target.value)" id="meta-title" class="form-control">
              </div>
              <div class="mb-2">
                <label class="form-label" for="meta-description">Description de la page</label>
                <textarea :value="getConfigForPage().description" @change="setPageDescription($event.target.value)" style="height: 180px" id="meta-description" class="form-control"/>
              </div>
              <div>
                <input-image-upload title="Image d'aperçu" :value="getConfigForPage().image" @change="setPageImage($event)"/>
              </div>
            </perfect-scrollbar>
            <div v-else class="h-100 d-flex flex-column">
              <div class="row flex-shrink-1 pb-2">
                <div class="ms-auto col-auto">
                </div>
                <div class="col-auto" v-if="getConfigForPage().mode === 'advanced'">
                  <button @click="setMetaForPage(getConfigForPage().content + '\n' + defaultMetas)" class="btn btn-success text-light"><i class="fas fa-plus me-2"></i>Ajouter les metas par defaut</button>
                </div>
              </div>
              <div class="flex-grow-1" style="overflow-y: hidden;">
                <codemirror @input="setMetaForPage($event)" :value="getConfigForPage().content" class="h-100" ref="codeMirror" style="font-size: 11px;" :options="{ ...cmOptions }"/>
              </div>
            </div>
          </div>
        </card>
      </div>
    </card>
  </modal>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import { codemirror } from 'vue-codemirror';
import Card from '../Utilities/Card.vue';
import confirmationContext from '../contexts/ConfirmationContext.vue';
import defaultMeta from '../../configuration/defaultMeta.json';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/darcula.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/addon/search/search';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/html-hint';
import 'codemirror/addon/hint/css-hint';
import 'codemirror/addon/search/matchesonscrollbar.css';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/matchtags';
import 'codemirror/addon/edit/trailingspace';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/css-lint';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/display/autorefresh';
import InputImageUpload from '../Utilities/InputImageUpload.vue';

export default {
  name: 'PagesMetaEditor',
  components: {
    InputImageUpload,
    Card,
    codemirror,
    confirmationContext,
  },
  data()
  {
    return {
      selectedPage: '',
      pageSearchQuery: '',
      cmOptions: {
        scrollbarStyle: 'simple',
        tabSize: 4,
        mode: 'text/html',
        theme: 'monokai',
        lineNumbers: true,
        line: true,
        addons: 'show-hint',
        autoCloseTags: true,
        styleActiveLine: true,
        autofocus: true,
        lineWrapping: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        enableSearchTools: true,
        enableCodeFolding: true,
        enableCodeFormatting: true,
        autoFormatOnStart: true,
        autoFormatOnModeChange: true,
        autoFormatOnUncomment: true,
        showSearchButton: false,
        showTrailingSpace: false,
        highlightMatches: true,
        showFormatButton: false,
        showCommentButton: false,
        showUncommentButton: false,
        showAutoCompleteButton: true,
        smartIndent: true,
        indentUnit: 2,
        foldGutter: true,
      },
    };
  },
  computed: {
    ...mapGetters(['pageList', 'dictionary', 'preferredUrl']),
    pages()
    {
      const out = {};
      const tmp = { ...this.pageList };
      delete tmp.default;
      Object.entries(tmp).forEach(([id, page]) => {
        page.id = id;
      });
      if (!this.pageSearchQuery)
        return _.orderBy(tmp, 'name');
      Object.entries(tmp).forEach(([key, value]) => {
        if (key.toLowerCase().includes(this.pageSearchQuery.toLowerCase()) || (value.name && value.name.toLowerCase().includes(this.pageSearchQuery.toLowerCase())))
          out[key] = value;
      });
      return _.orderBy(out, 'name');
    },
    defaultMetas()
    {
      return defaultMeta.content;
    },
  },
  methods: {
    getConfigForPage()
    {
      if (!this.selectedPage)
        return { mode: 'simple', content: '' };
      return { mode: 'simple', content: '', ...this.pageList[this.selectedPage].metas };
    },
    setMetaEditorMode(mode)
    {
      this.$store.commit('setPageMetas', { pageID: this.selectedPage, metas: { ...(this.pageList[this.selectedPage].metas || {}), mode }, dbSync: true });
      this.generateMetas();
    },
    setMetaForPage(content)
    {
      this.$store.commit('setPageMetas', { pageID: this.selectedPage, metas: { ...(this.pageList[this.selectedPage].metas || {}), content }, dbSync: true });
    },
    setPageTitle(title)
    {
      this.$store.commit('setPageMetas', { pageID: this.selectedPage, metas: { ...(this.pageList[this.selectedPage].metas || {}), title }, dbSync: true });
      this.generateMetas();
    },
    setPageDescription(description)
    {
      this.$store.commit('setPageMetas', { pageID: this.selectedPage, metas: { ...(this.pageList[this.selectedPage].metas || {}), description }, dbSync: true });
      this.generateMetas();
    },
    setPageImage(image)
    {
      this.$store.commit('setPageMetas', { pageID: this.selectedPage, metas: { ...(this.pageList[this.selectedPage].metas || {}), image }, dbSync: true });
      this.generateMetas();
    },
    generateMetas()
    {
      const title = this.getConfigForPage().title || '';
      const description = this.getConfigForPage().description || '';
      const image = this.getConfigForPage().image || '';
      const pageName = this.selectedPage ? this.pageList[this.selectedPage].name || '' : '';
      const out = `
<title>${title}</title>
<!-- General Meta Tags -->
<meta name="title" content="${title}">
<meta name="description" content="${description}">

<!-- Open Graph  -->
<meta property="og:title" content="${title}">
<meta property="og:description" content="${description}">
<meta property="og:url" content="${this.preferredUrl}/${pageName}">
${image ? `<meta property="og:image" content="${image}">` : ''}
<meta property="og:type" content="website">

<!-- Twitter -->
<meta property="twitter:title" content="${title}">
<meta property="twitter:description" content="${description}">
<meta property="twitter:url" content="${this.preferredUrl}/${pageName}">
${image ? `<meta property="twitter:image" content="${image}">` : ''}
<meta property="twitter:card" content="summary_large_image">`;
      this.setMetaForPage(out);
    },
    open()
    {
      this.$modal.show('metaEditorModal');
    },
    close()
    {
      this.$modal.hide('metaEditorModal');
    },
  },
};

</script>

<style scoped>

</style>
