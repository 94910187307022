<template>
  <div v-if="!deploymentMode && !content.date">
    <div class="row text-muted">
      <div class="col-12 text-center my-5">
        <i class="fas fa-clock fa-2x mb-4"></i>
        <h6 v-html="dictionary.clockContent.noDate"></h6>
      </div>
    </div>
  </div>
  <div v-else-if="!deploymentMode && hiddenClock">
    <div class="row py-5">
      <div class="col-12 text-center text-muted">
        <h6>{{dictionary.clockContent.endOfTimer}}</h6>
        <h5>{{dictionary.clockContent.displayDate}} : {{ content.date }}</h5>
      </div>
    </div>
  </div>
  <div v-else class="row clock" :data-end-action="content.endAction || 'stay'" :data-end-time="endTime">
    <div :class="wrappingClass"
         class="text-center"
         v-if="content.displayedValues && content.displayedValues.includes('Days')">
      <p :style="numbersStyle" class="clock-days">{{ getDays }}</p>
      <p :style="textStyle">{{ content.clockWording.days }}</p>
    </div>
    <div :class="wrappingClass"
         class="text-center"
         v-if="content.displayedValues && content.displayedValues.includes('Hours')">
      <p :style="numbersStyle" class="clock-hours">{{ getHours }}</p>
      <p :style="textStyle">{{ content.clockWording.hours }}</p>
    </div>
    <div :class="wrappingClass"
         class="text-center"
         v-if="content.displayedValues && content.displayedValues.includes('Minutes')">
      <p :style="numbersStyle" class="clock-minutes">{{ getMinutes }}</p>
      <p :style="textStyle">{{ content.clockWording.minutes }}</p>
    </div>
    <div :class="wrappingClass"
         class="text-center"
         v-if="content.displayedValues && content.displayedValues.includes('Seconds')">
      <p :style="numbersStyle" class="clock-seconds">{{ getSeconds }}</p>
      <p :style="textStyle">{{ content.clockWording.seconds }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ClockContent',
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    content: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data()
  {
    return {
      currentTime: 0,
      difference: 0,
      hiddenClock: false,
      interval: undefined,
    };
  },
  watch:
  {
    content() {
      this.initTimer();
    },
  },
  mounted()
  {
    this.initTimer();
  },
  computed: {
    ...mapGetters(['dictionary']),
    endTime()
    {
      const endDate = new Date(this.content.date);
      return endDate.getTime();
    },
    getDays()
    {
      return Math.floor(this.difference / (1000 * 60 * 60 * 24));
    },
    getHours()
    {
      return Math.floor((this.difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    },
    getMinutes()
    {
      return Math.floor((this.difference % (1000 * 60 * 60)) / (1000 * 60));
    },
    getSeconds()
    {
      return Math.floor((this.difference % (1000 * 60)) / 1000);
    },
    wrappingClass()
    {
      if (this.content.allowWrapping)
        return 'col';
      return `col-${Math.floor(12 / this.content.displayedValues.length)}`;
    },
    numbersStyle()
    {
      const out = {};
      if (this.content.numbersSize)
        out['font-size'] = this.content.numbersSize;
      out.color = this.content.numbersColor.hex8;
      return out;
    },
    textStyle()
    {
      const out = {};
      if (this.content.textSize)
        out['font-size'] = this.content.textSize;
      out.color = this.content.wordingColor.hex8;
      return out;
    },
  },
  methods: {
    initTimer() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.currentTime = Date.now();
      this.difference = this.endTime - this.currentTime;
      this.hiddenClock = false;
      const interval = setInterval(() =>
      {
        this.currentTime = Date.now();
        this.difference = this.endTime - this.currentTime;
        if (this.difference <= 0)
        {
          this.difference = 0;
          if (this.content.endAction === 'hide')
          {
            this.hiddenClock = true;
          }
          clearInterval(interval);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>

</style>
