<template>
  <div>
    <div class="mb-3">
      <label class="small required" for="reportTitle">{{ dictionary.text }}</label>
      <input v-model="content" id="reportTitle" type="text" class="form-control">
    </div>
    <card class="mb-3">
      <template v-slot:header-left>
        <p class="text-light mb-0">Éléments du menu</p>
      </template>
      <template v-slot:header-right>
        <div>
          <button class="btn btn-success text-light dropdown-toggle" type="button" id="addItemButton" data-bs-toggle="dropdown"  data-bs-auto-close="true" aria-expanded="false">
            <i class="fas fa-plus me-2"></i>Ajouter
          </button>
          <ul class="dropdown-menu" aria-labelledby="addItemButton">
            <li><a class="dropdown-item" @click="addItem('text')" href="#">Boutton</a></li>
            <li><a class="dropdown-item" @click="addItem('separator')" href="#">Séparateur</a></li>
          </ul>
        </div>
      </template>
      <template v-slot:default>
        <p v-if="!subItems || subItems.length <= 0" class="text-muted small text-center">Pas d'éléments pour le moment</p>
        <draggable
            v-else
            v-model="subItems"
            tag="div"
            handle=".dragHandle">
          <div v-for="(item, itemIdx) in subItems" :key="itemIdx" class="row mb-2 position-relative w-auto">
            <div class="col-auto dragHandle cursor-grab"><i class="fas fa-grip-lines"></i></div>
            <div v-if="item.type !== 'separator'" class="col">{{ item.content }}</div>
            <div v-else class="col text-muted"><i class="fas fa-arrows-alt-h me-2"></i>Séparateur</div>
            <div class="col-auto">
              <div class="btn-group">
                <button v-if="item.type !== 'separator'" @click="$refs[`configCtx-${itemIdx}`][0].open()" class="btn btn-sm btn-info"><i class="fas fa-pen text-light"></i></button>
                <NavbarSubItemContext :key="itemIdx" :ref="`configCtx-${itemIdx}`" :value="item" @change="editItem(itemIdx, $event)"/>
                <button @click="$refs[`confirmationDeleteItem-${itemIdx}`][0].openContext()" class="btn btn-sm btn-danger"><i class="fas fa-trash text-light"></i></button>
                <confirmationContext
                    @confirm="removeItem(itemIdx)"
                    :introMessage="`<h6>Supprimer l'élément ?</h6>`"
                    :confirmButtonContent="dictionary.confirm"
                    :deniButtonContent="dictionary.no"
                    :ref="`confirmationDeleteItem-${itemIdx}`"/>
              </div>
            </div>
          </div>
        </draggable>
      </template>
    </card>
    <input-color-picker class="mb-3" label="Couleur de fond du menu" v-model="backgroundColor"/>
    <border-editor class="mb-3" v-model="border" label="Bord du menu"/>
    <div class="row mb-3">
      <div class="col-auto">
        <label class='small'>{{ dictionary.styleEditor.borderRadius }}</label>
      </div>
      <div class="col justify-content-center">
        <VueSlider
            :min="0"
            :max="100"
            v-model="borderRadius"/>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Vue from 'vue';
import _ from 'lodash';
import draggable from 'vuedraggable';
import VueSlider from 'vue-slider-component';
import Card from '../Utilities/Card.vue';
import NavbarSubItemContext from '../contexts/NavbarSubItemContext.vue';
import InputColorPicker from '../Utilities/InputColorPicker.vue';
import BorderEditor from '../Utilities/BorderEditor.vue';
import confirmationContext from '../contexts/ConfirmationContext.vue';

export default {
  name: 'DropdownConfig',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    confirmationContext,
    BorderEditor,
    InputColorPicker,
    Card,
    NavbarSubItemContext,
    draggable,
    VueSlider,
  },
  computed: {
    ...mapGetters(['dictionary']),
    backgroundColor: {
      get()
      {
        return this.value.menuBackgroundColor || { hex8: '#ffffffff' };
      },
      set(newBackgroundColor)
      {
        this.$emit('change', { ...this.value, menuBackgroundColor: newBackgroundColor });
      },
    },
    border: {
      get()
      {
        return this.value.border || { thickness: 1, style: 'solid', color: { hex: '#ADADAD' } };
      },
      set(newBorder)
      {
        this.$emit('change', { ...this.value, border: newBorder });
      },
    },
    borderRadius: {
      get()
      {
        return this.value.borderRadius || 5;
      },
      set(newRadius)
      {
        this.$emit('change', { ...this.value, borderRadius: newRadius });
      },
    },
    content: {
      get()
      {
        return this.value.content || '';
      },
      set(newContent)
      {
        this.$emit('change', { ...this.value, content: newContent || '' });
      },
    },
    subItems: {
      get()
      {
        return this.value.items || [];
      },
      set(items)
      {
        this.$emit('change', { ...this.value, items });
      },
    },
  },
  methods: {
    addItem(type)
    {
      const items = _.cloneDeep(this.subItems);
      Vue.set(items, items.length, { content: 'Votre Texte', type });
      this.subItems = items;
    },
    removeItem(itemIdx)
    {
      if (this.subItems.length <= itemIdx)
        return;
      const items = _.cloneDeep(this.subItems);
      items.splice(itemIdx, 1);
      this.subItems = items;
    },
    editItem(itemIdx, content)
    {
      if (this.subItems.length <= itemIdx)
        return;
      const items = _.cloneDeep(this.subItems);
      items[itemIdx] = content;
      this.subItems = items;
    },
  },
};

</script>

<style scoped>

</style>
