import firebaseReference from '../FirebaseReference';

export default {
  /**
   * Get and return the website settings from base + unicity and urlInsc
   * @param getters
   * @returns An object containing the website settings
   */
  async getWebsiteSettings({ getters })
  {
    let out = {};
    const fbRef = await firebaseReference(getters.tokenID);
    await fbRef.ref('animations')
      .child(getters.path)
      .child('config')
      .once('value', (snapshot) => {
        if (snapshot.val().settings !== undefined)
          out = { ...snapshot.val().settings };
        if (snapshot.val().unicity !== undefined)
          out.unicity = snapshot.val().unicity;
        if (snapshot.val().urlInsc !== undefined)
          out.urlInsc = snapshot.val().urlInsc;
      });
    return out;
  },

  async getInvoiceAnimationsList({ getters })
  {
    const out = {};
    let tmp = {};
    const fbRef = await firebaseReference(getters.tokenID);
    await fbRef.ref('animations')
      .child(getters.eventID)
      .once('value', (snapshot) => {
        tmp = snapshot.val();
      });
    Object.entries(tmp || {}).forEach(([animID, anim]) => {
      if (anim.type === 'INVOICE')
        out[animID] = { name: anim.name || '' };
    });
    return out;
  },

  async getIntegrationsList({ getters })
  {
    let out = {};
    const fbRef = await firebaseReference(getters.tokenID);
    await fbRef.ref('integrations')
      .child(getters.eventID)
      .once('value', (snapshot) => {
        out = snapshot.val();
      });
    return out;
  },

  /**
   * Get and return all the documents from firebase
   * these docs can be added through the Event manager software
   * @warning These documents are different from builder's medias
   * @param getters
   * @returns An array containing objects representing documents
   */
  async getDocumentsFromBase({ getters })
  {
    let out = {};
    const fbRef = await firebaseReference(getters.tokenID);
    await fbRef.ref('docs')
      .child(getters.eventID)
      .once('value', (snapshot) => {
        if (snapshot.val())
          out = { ...snapshot.val() };
      });
    return out;
  },

  /**
   * @brief Return the agendas details for the given agenda's ids
   * @param getters
   * @param ids the id of the agendas you want details
   * @returns array of agendas with details (including names ...)
   */
  async getAgendasFromIds({ getters }, { ids })
  {
    const out = {};
    await firebaseReference(getters.tokenID).then(async (fbRef) => {
      await fbRef.ref('agendas')
        .child(getters.eventID)
        .once('value', (snapshot) => {
          if (snapshot.val())
            Object.entries(snapshot.val()).forEach(([id, val]) => {
              if (ids.includes(id))
                out[id] = val;
            });
        });
    });
    return out;
  },

  /**
   * Get and return all the messages from firebase
   * @warning For performance purposes, only ids, types and names will be stored
   * @param getters
   * @returns An array containing objects representing messages
   */
  async getMessagesFromBase({ getters })
  {
    const value = [];
    const fbRef = await firebaseReference(getters.tokenID);
    await fbRef.ref('messages')
      .child(getters.eventID)
      .once('value', (snapshot) => {
        const messages = snapshot.val();
        if (messages)
          Object.keys(messages).forEach((key) => {
            value.push({ id: key, type: messages[key].type, name: messages[key].name });
          });
      });
    return value;
  },
};
