<template>
  <div class="editorUIStyle">
    <label class="small">{{ label }}</label>
    <div class="input-group mb-3">
      <input min="0" placeholder="Inherit" type="number" class="form-control" aria-label="Font size" v-model="fontSize">
      <button class="btn btn-outline-secondary dropdown-toggle" :class="fontSize === '' ? 'disabled' : ''" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{ unit }}</button>
      <ul class="dropdown-menu dropdown-menu-end">
        <li v-for="(_unit, idx) in availableUnits" :key="idx"><a class="dropdown-item" @click="unit = _unit">{{ _unit }}</a></li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FontSizeInput',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    availableUnits: {
      type: Array,
      required: false,
      default: () => [
        'px',
        '%',
        'rem',
      ],
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    fontSize: {
      get()
      {
        if (!this.value)
          return '';
        const numberExpression = this.value.match(/[0-9]*/g)[0];
        return numberExpression || '';
      },
      set(newValue)
      {
        this.$emit('change', newValue ? `${newValue}${this.unit}` : '');
      },
    },
    unit: {
      get()
      {
        if (!this.value)
          return this.availableUnits[0];
        let unitRegex = '';
        this.availableUnits.forEach((unit) => {
          unitRegex += (unitRegex === '') ? unit : `|${unit}`;
        });
        const matches = this.value.match(new RegExp(unitRegex));
        if (matches && matches.length >= 1)
          return matches[0];
        return this.availableUnits[0];
      },
      set(newUnit)
      {
        this.$emit('change', this.fontSize ? `${this.fontSize}${newUnit}` : '');
      },
    },
  },
};

</script>

<style scoped>

</style>
