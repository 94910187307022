<template>
  <context-menu style="transform: unset;" @ctx-close="onClose" :id="`${this.contextId}-rename-context-menu`" :ref="`${this.contextId}-rename-ctxMenu`" >
    <form class="p-2" style="width: 300px;" @submit.stop.prevent>
      <div class="mb-3">
        <label for="renameInput" class="form-label required" v-html="title"></label>
        <input required type="text" class="form-control required" id="renameInput" v-model="name">
      </div>
      <div class="w-100 text-center">
        <button @click="$emit('change', currName); close();" class="btn btn-success btn-raised text-white">Valider</button>
      </div>
    </form>
  </context-menu>
</template>

<script>

import contextMenu from 'vue-context-menu';

export default {
  name: 'renameContext',
  components: {
    contextMenu,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: 'Éditer le nom',
    },
  },
  data()
  {
    return {
      ctxId: '',
      currName: undefined,
    };
  },
  computed: {
    name: {
      get()
      {
        if (!this.currName)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.currName = this.value;
        return this.currName;
      },
      set(newValue)
      {
        this.currName = newValue;
      },
    },
    contextId()
    {
      if (!this.ctxId || this.ctxId === '')
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.ctxId = this.$chance.string(this.$newIDParams);
      return this.ctxId;
    },
  },
  methods: {
    onClose()
    {
      this.$refs[`${this.contextId}-rename-ctxMenu`].ctxVisible = true;
    },
    open()
    {
      this.$refs[`${this.contextId}-rename-ctxMenu`].open();
      this.currName = this.value;
    },
    close()
    {
      this.$refs[`${this.contextId}-rename-ctxMenu`].ctxVisible = false;
    },
  },
};

</script>

<style scoped>

</style>
