<template>
  <modal name="FontAwesomePickerModal" :width="'80%'" :adaptive="true" height="80%">
    <Card class="h-100">
      <template v-slot:header-left><h6 class="text-white mb-0">{{ dictionary.leftBar.faExplorerLabel }}<sup>©</sup></h6></template>
      <template v-slot:header-right><button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button></template>
      <div class="h-100 d-flex flex-column">
        <div class="row d-flex align-items-center flex-shrink-1">
          <div class="col-auto">
            <a href="https://fontawesome.com/v5.15/icons?d=gallery&p=2" target="_blank" class="btn btn-outline-secondary">Aller sur fontawesome.com</a>
          </div>
          <div class="col-auto">
            <InputColorPicker label="Couleur des icons" v-model="iconColor"/>
          </div>
          <div class="col-auto mx-auto">
            <label class="form-check-label" for="hiddenCodeInput">Code d'intégration<span v-if="lastCopied" class="ms-2 badge rounded-pill bg-success animate__animated animate__heartBeat">copié!</span></label>
            <input readonly class="form-control" :value="iconCode" id="hiddenCodeInput">
          </div>
          <div class="col-auto">
            <div class="btn-group" role="group">
              <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                Filtres <span class="badge bg-secondary">{{ activeFilters }}</span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <li><a class="dropdown-item text-secondary" @click="filterAll" href="#">Tout afficher <i class="fas fa-arrow-right ms-3"></i></a></li>
                <li><a class="dropdown-item text-danger" @click="filterPro" href="#">Pro uniquement <i class="fab fa-font-awesome-flag ms-3"></i></a></li>
                <li><a class="dropdown-item text-success" @click="filterFree" href="#">Free uniquement <i class="fas fa-globe-europe ms-3"></i></a></li>
                <li><hr class="dropdown-divider"></li>
                <li>
                  <a class="dropdown-item d-flex align-items-center" href="#" @click="filters.fas = !filters.fas">
                    <span class="badge bg-success me-3"><i class="fas fa-globe-europe"></i> Free</span>
                    Solid <i class="fas fa-check ms-3" v-if="filters.fas"></i>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item d-flex align-items-center" href="#" @click="filters.far = !filters.far">
                    <span class="badge bg-success me-3"><i class="fas fa-globe-europe"></i> Free</span>
                    Regular<i class="fas fa-check ms-3" v-if="filters.far"></i>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item d-flex align-items-center" href="#" @click="filters.fal = !filters.fal">
                    <span class="badge bg-danger me-3"><i class="fab fa-font-awesome-flag"></i> Pro</span>
                    Light<i class="fas fa-check ms-3" v-if="filters.fal"></i>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item d-flex align-items-center" href="#" @click="filters.fad = !filters.fad">
                    <span class="badge bg-danger me-3"><i class="fab fa-font-awesome-flag"></i> Pro</span>
                    Duotone<i class="fas fa-check ms-3" v-if="filters.fad"></i>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item d-flex align-items-center" href="#" @click="filters.fab = !filters.fab">
                    <span class="badge bg-success me-3"><i class="fas fa-globe-europe"></i> Free</span>
                    Brands<i class="fas fa-check ms-3" v-if="filters.fab"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-auto ms-2">
            <div class="form-floating">
              <input v-model="searchQuery" type="text" class="form-control" id="searchInput" placeholder="Icon Name">
              <label for="searchInput">Rechercher un icon</label>
            </div>
          </div>
          <div class="col-12">
            <hr>
          </div>
        </div>
        <div class="flex-grow-1" style="overflow-y: hidden;">
          <perfect-scrollbar ref="fontawesomePickerScrollContainer" class="h-100" :options="{ suppressScrollX: true, wheelPropagation: false }">
            <div class="row justify-content-center" >
              <div v-for="(icon, iconIdx) in slicedIcons" :key="`faCol-${iconIdx}`" @click="iconClick(icon)" class="col-2 m-2 py-2 icon-col">
                <div class="row">
                  <div class="col-12 text-center mt-3 " :class="(lastCopied === icon) ? 'animate__animated animate__heartBeat' : ''">
                    <h1 :style="`color: ${iconColor.hex8}`" :class="icon"></h1>
                  </div>
                  <div class="col-12 text-center mt-3 mb-2">
                    <span class="badge bg-secondary me-2">{{ iconCategory(icon) }}</span>
                    <span v-if="isIconFree(icon)" class="badge bg-success"><i class="fas fa-globe-europe"></i> Free</span>
                    <span v-else class="badge bg-danger"><i class="fab fa-font-awesome-flag"></i> Pro</span>
                  </div>
                  <div class="col-12 text-center">
                    <small class="small text-muted">{{ iconName(icon) }}</small>
                  </div>
                </div>
              </div>
              <div class="col-12 text-center">
                <mugen-scroll scroll-container="fontawesomePickerScrollContainer" :handler="fetchData" :should-handle="!loading">
                  <p v-if="!scrolledEnd"></p>
                </mugen-scroll>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </Card>
  </modal>
</template>

<script>

import MugenScroll from 'vue-mugen-scroll';
import { mapGetters } from 'vuex';
import Card from '../Utilities/Card.vue';
import FontAwesomeIcons from '../../configuration/FontAwesomeList.json';
import InputColorPicker from '../Utilities/InputColorPicker.vue';

export default {
  name: 'FontAwesomePickerModal',
  components: {
    InputColorPicker,
    Card,
    MugenScroll,
  },
  data()
  {
    return {
      loading: false,
      scrolledEnd: false,
      displayed: 60,
      lastCopied: undefined,
      iconCode: '',
      filters: {
        fas: true,
        far: true,
        fal: true,
        fad: true,
        fab: true,
      },
      searchQuery: '',
      iconColor: {
        hex: '#000000ff',
        rgba: {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
    slicedIcons()
    {
      // eslint-disable-next-line arrow-body-style
      const filtered = FontAwesomeIcons.icons.filter((icon) => {
        const catCode = icon.split(' ')[0];
        let catOK;
        switch (catCode)
        {
          case 'fas': catOK = this.filters.fas; break;
          case 'far': catOK = this.filters.far; break;
          case 'fal': catOK = this.filters.fal; break;
          case 'fad': catOK = this.filters.fad; break;
          case 'fab': catOK = this.filters.fab; break;
          default: catOK = false;
        }
        if (catOK && this.searchQuery)
          return icon.includes(this.searchQuery);
        return catOK;
      });
      if (filtered.length > this.displayed)
        return filtered.slice(0, this.displayed);
      return filtered;
    },
    activeFilters()
    {
      let out = 0;
      // eslint-disable-next-line no-return-assign
      Object.values(this.filters).forEach((boolean) => out += (boolean) ? 1 : 0);
      return out;
    },
  },
  methods: {
    filterAll()
    {
      this.filters = {
        fas: true,
        far: true,
        fal: true,
        fad: true,
        fab: true,
      };
    },
    filterFree()
    {
      this.filters = {
        fas: true,
        far: true,
        fal: false,
        fad: false,
        fab: true,
      };
    },
    filterPro()
    {
      this.filters = {
        fas: false,
        far: false,
        fal: true,
        fad: true,
        fab: false,
      };
    },
    iconClick(iconClass)
    {
      if (this.iconColor.hex || this.iconColor.hex8)
        this.iconCode = `<i style="color: ${this.iconColor.hex8 || this.iconColor.hex}" class="${iconClass}"></i>`;
      else
        this.iconCode = `<i class="${iconClass}"></i>`;
      const copyText = document.getElementById('hiddenCodeInput');
      copyText.value = this.iconCode;
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand('copy');

      this.lastCopied = iconClass;
      setTimeout(() => { this.lastCopied = undefined; }, 1000);
    },
    iconCategory(icon)
    {
      let out = '';
      switch (icon.split(' ')[0])
      {
        case 'fas': out = 'Solid'; break;
        case 'far': out = 'Regular'; break;
        case 'fal': out = 'Light'; break;
        case 'fad': out = 'Duotone'; break;
        case 'fab': out = 'Brands'; break;
        default: out = '';
      }
      return out;
    },
    iconName(icon)
    {
      const words = icon.split(' ')[1].split('-');
      words.splice(0, 1);
      let out = '';
      // eslint-disable-next-line no-return-assign
      words.forEach((w) => out += `${w} `);
      return out;
    },
    isIconFree(icon)
    {
      switch (icon.split(' ')[0])
      {
        case 'fas': return true;
        case 'far': return true;
        case 'fal': return false;
        case 'fad': return false;
        case 'fab': return true;
        default: return false;
      }
    },
    fetchData()
    {
      this.scrolledEnd = false;
      this.loading = true;
      if (FontAwesomeIcons.icons && FontAwesomeIcons.icons.length > this.displayed)
        this.displayed += 24;
      else
        this.scrolledEnd = true;
      this.loading = false;
    },
    open()
    {
      this.$modal.show('FontAwesomePickerModal');
    },
    close()
    {
      this.$modal.hide('FontAwesomePickerModal');
    },
  },
};

</script>

<style>

.icon-col:hover {
  outline: dashed 1px var(--bs-primary);
  border-radius: 15px;
  cursor: pointer;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}

.bounce-leave-active {
  animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

</style>
