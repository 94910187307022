<template>
  <div class="row h-100">
    <div class="col-12" style="height: 23%;">
      <Card class="h-100" :title="dictionary.preview">
        <CarouselPreview :carouselContent="carouselContent" />
      </Card>
    </div>
    <div class="col-6" style="text-align:start !important; height: 74%;">
      <card class="h-100" v-if="selectedMedia !== -1" :title="dictionary.carouselModule.configurationMediaCardTitle" :title-size="6">
        <template v-slot:header-right>
          <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="selectedMedia = -1;"></button>
        </template>
        <MediaConfig v-model="selectedMediaObject"/>
      </card>
      <Card v-else class="h-100" :title="dictionary.carouselModule.configurationCardTitle" :title-size="6">
          <CarouselConfig :carouselContent="carouselContent" @change="$emit('change', $event)"/>
      </Card>
    </div>
    <div class="col-6" style="height: 74%;">
      <Tabs class="pt-1 h-100 text-start" ref="tabs" :tabs="getTabs" :animation="false">
        <template v-slot:slot-0>
            <MediaList v-model="mediaList" @addMedia="addMedia" @mediaSelected="selectedMedia = $event"/>
        </template>
        <template v-slot:slot-1>
          <WebsiteImagesList v-model="mediaList" />
        </template>
      </Tabs>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import VueNotifications from 'vue-notifications';
import Card from '../Utilities/Card.vue';
import Tabs from '../Utilities/Tabs.vue';
import CarouselConfig from './CarouselConfig.vue';
import CarouselPreview from './CarouselPreview.vue';
import MediaList from './MediaList.vue';
import WebsiteImagesList from './websiteImagesList.vue';
import MediaConfig from './MediaConfig.vue';

export default {
  name: 'CarouselBuilder',
  components: {
    MediaConfig,
    WebsiteImagesList,
    MediaList,
    CarouselPreview,
    CarouselConfig,
    Card,
    Tabs,
  },
  model: {
    prop: 'carouselContent',
    event: 'change',
  },
  props: {
    carouselContent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedMedia: -1,
    };
  },
  computed: {
    ...mapGetters(['dictionary', 'path', 'eventID', 'animID']),
    mediaList: {
      get()
      {
        if (this.carouselContent)
          return this.carouselContent.images || [];
        return [];
      },
      set(newImagesList)
      {
        this.$emit('change', { ...this.carouselContent, images: newImagesList });
      },
    },
    selectedMediaObject: {
      get()
      {
        if (this.selectedMedia > -1)
          return this.mediaList[this.selectedMedia] || {};
        return {};
      },
      set(newValue)
      {
        if (this.selectedMedia === undefined || this.selectedMedia < 0)
          return;
        const newImages = _.cloneDeep(this.mediaList);
        newImages[this.selectedMedia] = newValue;
        this.mediaList = newImages;
      },
    },
    getTabs() {
      return [
        `<h5>${this.dictionary.carouselModule.mediaTabList}</h5>`,
        `<h5>${this.dictionary.carouselModule.mediaTabAvailable}</h5>`,
      ];
    },
  },
  methods: {
    addMedia() {
      window.cptgUploader.init('identifiantUnique', `${this.eventID}/website/${this.animID}`, {
        apiKey: 'SjX6yhkY4RSlpE32x4xK',
        sources: ['local'],
        lang: this.language || 'fr',
        theme: 'light',
        useEditor: false,
        forceSquareCrop: false,
        maxFileSize: 20,
      },
      (result) => {
        this.$store.dispatch('addToMediaList', {
          deleteURL: result.deleteURL || '',
          fileID: result.fileID || '',
          link: result.originalURL ? `${result.originalURL}?p=resize-xl` : '',
          thumbnail: result.thumbURL || '',
          title: `media-${this.$chance.string(this.$newIDParams)}`,
        }).then((media) => {
          this.uploadSuccess();
          const newImages = _.cloneDeep(this.mediaList);
          newImages.push({ src: media.link });
          this.mediaList = newImages;
        });
      }).open();
    },
  },
  notifications: {
    uploadSuccess: {
      type: VueNotifications.types.success,
      title: 'Image uploadé !',
      message: 'Image uploadé avec succès.',
    },
  },
};
</script>

<style scoped>

</style>
