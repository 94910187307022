<template>
  <StyleEditor
      v-model="HeaderStyle"
      :properties="[
            'spacing',
            'border',
            'borderRadius',
          ]">
    <template v-slot:form-start>
      <div class="mb-3">
        <label>Largeur du header</label>
        <select v-model="headerFullWidth" class="form-control">
          <option :value="false">Normal</option>
          <option :value="true">Pleine largeur</option>
        </select>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="toggle-sticky" v-model="stickyHeader">
        <label class="form-check-label small" for="toggle-sticky">Header Collant</label>
      </div>
    </template>
  </StyleEditor>
</template>

<script>

import { mapGetters } from 'vuex';
import StyleEditor from '../../../Utilities/StyleEditor.vue';

export default {
  name: 'HeaderConfig',
  components: {
    StyleEditor,
  },
  computed: {
    ...mapGetters(['headerConfig']),
    stickyHeader: {
      get()
      {
        return this.headerConfig.stickyHeader || false;
      },
      set(newSticky)
      {
        this.$store.commit('setHeaderConfig', { config: { ...this.headerConfig, stickyHeader: newSticky }, dbSync: true });
      },
    },
    HeaderStyle: {
      get()
      {
        return this.headerConfig || {};
      },
      set(newConfig)
      {
        this.$store.commit('setHeaderConfig', { config: newConfig, dbSync: true });
      },
    },
    headerFullWidth: {
      get()
      {
        return this.headerConfig.fullWidth || false;
      },
      set(newFullWidth)
      {
        this.$store.commit('setHeaderConfig', { config: { ...this.headerConfig, fullWidth: newFullWidth }, dbSync: true });
      },
    },
  },
};

</script>

<style scoped>

</style>
