var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.itemType === 'separator')?_c('div',{staticClass:"text-center text-muted"},[_c('p',[_vm._v("Aucune configuration possible pour les séparateurs")])]):_c('Tabs',{staticClass:"h-100",attrs:{"tabs":['Configuration', 'Style']},scopedSlots:_vm._u([{key:"slot-0",fn:function(){return [_c('perfect-scrollbar',{staticClass:"h-100 pe-3",attrs:{"options":{ wheelPropagation: false, suppressScrollX: true }}},[(_vm.itemType === 'dropdown')?_c('DropdownConfig',{model:{value:(_vm.fullItem),callback:function ($$v) {_vm.fullItem=$$v},expression:"fullItem"}}):_c('form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('ActionSelector',{model:{value:(_vm.action),callback:function ($$v) {_vm.action=$$v},expression:"action"}}),(_vm.itemType !== 'image')?_c('div',[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"small required",attrs:{"for":"reportTitle"}},[_vm._v(_vm._s(_vm.dictionary.text))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemContent),expression:"itemContent"}],staticClass:"form-control",attrs:{"id":"reportTitle","type":"text"},domProps:{"value":(_vm.itemContent)},on:{"input":function($event){if($event.target.composing)return;_vm.itemContent=$event.target.value}}})])]):_c('InputImageUpload',{attrs:{"title":_vm.dictionary.navbarModule.imageLink},model:{value:(_vm.itemContent),callback:function ($$v) {_vm.itemContent=$$v},expression:"itemContent"}})],1)],1)]},proxy:true},{key:"slot-1",fn:function(){return [_c('perfect-scrollbar',{staticClass:"h-100 pe-3",attrs:{"options":{ wheelPropagation: false, suppressScrollX: true }}},[(_vm.itemType !== 'image')?_c('StyleEditor',{attrs:{"properties":[
            'spacing',
            'fontFamily',
            'fontWeight',
            'fontSize',
            'color',
            'hr',
            'backgroundColor',
            'border',
            'borderRadius',
          ]},model:{value:(_vm.style),callback:function ($$v) {_vm.style=$$v},expression:"style"}}):_c('StyleEditor',{staticClass:"pe-3",attrs:{"properties":[
                        'spacing',
                        'border',
                        'borderRadius',
                      ]},model:{value:(_vm.style),callback:function ($$v) {_vm.style=$$v},expression:"style"}}),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.dictionary.customClass))]),_c('Multiselect',{attrs:{"id":"customClasses","tag-placeholder":_vm.dictionary.addThisClass,"placeholder":_vm.dictionary.selectOrTypeAClass,"options":_vm.defaultCustomClasses,"multiple":true,"taggable":true},on:{"tag":_vm.onNewClass},model:{value:(_vm.customClasses),callback:function ($$v) {_vm.customClasses=$$v},expression:"customClasses"}})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }