<template>
  <Tabs class="pt-1 h-100 modal-editor" ref="tabs" :tabs="['Corps', 'Titre', 'Footer', 'Style']" :animation="false" >
    <template v-slot:slot-0>
      <codemirror
          v-model="body"
          :options="{ ...cmOptions }"
          class="codemirror-border"/>
    </template>
    <template v-slot:slot-1>
      <codemirror
          v-model="title"
          :options="{ ...cmOptions }"
          class="codemirror-border"/>
    </template>
    <template v-slot:slot-2>
      <codemirror
          v-model="footer"
          :options="{ ...cmOptions }"
          class="codemirror-border"/>
    </template>
    <template v-slot:slot-3>
      <ModalStyleEditor v-model="ModalStyle"/>
    </template>
  </Tabs>
</template>

<script>

import { codemirror } from 'vue-codemirror';
import { mapGetters } from 'vuex';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/mode/xml/xml';
import 'codemirror/theme/darcula.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/edit/closetag';
import Tabs from '../../Utilities/Tabs.vue';
import ModalStyleEditor from './ModalStyleEditor.vue';

export default {
  name: 'ModalEditor',
  components: {
    ModalStyleEditor,
    codemirror,
    Tabs,
  },
  model: {
    prop: 'modal',
    event: 'change',
  },
  props: {
    modal: {
      type: Object,
      required: true,
    },
  },
  data()
  {
    return {
      cmOptions: {
        tabSize: 4,
        theme: 'monokai',
        lineNumbers: true,
        line: true,
        autofocus: true,
        addons: 'show-hint',
        autoCloseTags: true,
        styleActiveLine: true,
        mode: 'text/html',
      },
    };
  },
  computed: {
    ...mapGetters(['googleFonts']),
    title: {
      get()
      {
        if (this.modal)
          return this.modal.titleData || '';
        return '';
      },
      set(newTitle)
      {
        this.$emit('change', { ...this.modal, titleData: newTitle });
      },
    },
    body: {
      get()
      {
        if (this.modal)
          return this.modal.bodyData || '';
        return '';
      },
      set(newBody)
      {
        this.$emit('change', { ...this.modal, bodyData: newBody });
      },
    },
    footer: {
      get()
      {
        if (this.modal)
          return this.modal.footerData || '';
        return '';
      },
      set(newFooter)
      {
        this.$emit('change', { ...this.modal, footerData: newFooter });
      },
    },
    ModalStyle: {
      get()
      {
        if (this.modal !== '')
          return this.modal.style || {};
        return '';
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.modal, style: newStyle });
      },
    },
  },
};

</script>

<style>

.codemirror-border {
  border: 1px solid var(--bs-secondary);
}

</style>
