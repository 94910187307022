<template>
  <modal name="ModalEditorModal" :width="'95%'" :height="'95%'">
    <Card class="h-100">
      <template v-slot:header-left><p class="text-white mb-0"><i class="fas fa-desktop me-3"></i>Éditeur de modales</p></template>
      <template v-slot:header-right><button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close"></button></template>
      <div class="row h-100">
        <div class="col-3 h-100">
          <card class="h-100" title="Liste des modales">
            <perfect-scrollbar class="h-100" :options="{ wheelPropagation: false, suppressScrollX: true }">
              <ModalListView :modalList="modalList" @selected="selectedModal = $event" :selectedModal="selectedModal" @importModal="importModal" @newModal="addModal" @delete="removeModal"/>
            </perfect-scrollbar>
          </card>
        </div>
        <div class="col-9 d-flex flex-column h-100" style="overflow-y: hidden;">
          <template v-if="selectedModal">
            <div class="row justify-content-center flex-shrink-1">
              <div class="col my-auto">
                <div class="form-floating">
                  <input v-model="modalName" type="text" class="form-control" id="modalNameInput" placeholder="Nom">
                  <label for="modalNameInput">Nom de la modale</label>
                </div>
              </div>
              <div class="col-auto d-flex align-items-center">
                <div class="input-group">
                  <div class="form-floating">
                    <input :value="selectedModal" type="text" class="form-control" id="modalNameInput" placeholder="name@example.com">
                    <label for="modalNameInput">ID</label>
                  </div>
                  <button @click="copyModalID(selectedModal)" v-tooltip="'Copier l\'ID de la modale'" class="btn btn-primary text-light"><i class="fas fa-copy fa-2x"></i></button>
                </div>
                <input readonly class="form-control visually-hidden" :value="selectedModal" id="hiddenIDInput">
              </div>
              <div class="col-auto d-flex align-items-center btn-group">
                <div class="btn-group-vertical">
                  <button type="button" class="btn btn-success text-light" data-bs-toggle="modal" :data-bs-target="`#modal-${selectedModal}`">
                    <i class="fas fa-eye me-2"></i>Voir la modale
                  </button>
                  <button @click.stop.prevent="exportModal" type="button" class="btn btn-info text-light">
                    <i class="fas fa-download me-2"></i>Exporter
                  </button>
                </div>
              </div>
            </div>
            <div class="flex-fill" style="overflow-y: hidden">
              <modalEditor v-model="selectedModalData"/>
            </div>
          </template>
          <div v-else class="row">
            <div class="col-12 text-center my-5">
              <h5 class="text-muted">Séléctionnez ou ajoutez une modale a gauche pour l'éditer</h5>
            </div>
          </div>
        </div>
      </div>
    </Card>
    <ModalContent v-if="selectedModal" :modal="selectedModalData" :modalID="selectedModal"/>
  </modal>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import VueNotifications from 'vue-notifications';
import Card from '../../Utilities/Card.vue';
import ModalListView from './ModalListView.vue';
import ModalDefaultContent from '../../../configuration/ModalDefaultContent.json';
import ModalEditor from './ModalEditor.vue';
import ModalContent from '../../NormalModeContent/ModalContent.vue';

export default {
  name: 'ModalEditorModal',
  components: {
    ModalContent,
    ModalEditor,
    ModalListView,
    Card,
  },
  data()
  {
    return {
      selectedModal: '',
    };
  },
  computed: {
    ...mapGetters(['modalList', 'dictionary']),
    modalName: {
      get()
      {
        if (this.selectedModal)
          return this.selectedModalData.name || '';
        return '';
      },
      set(newName)
      {
        this.selectedModalData = { ...this.selectedModalData, name: newName };
      },
    },
    selectedModalData:
      {
        get()
        {
          if (this.selectedModal)
            return this.modalList[this.selectedModal] || {};
          return {};
        },
        set(newModalData)
        {
          const modals = _.cloneDeep(this.modalList);
          modals[this.selectedModal] = newModalData;
          this.$store.commit('setModalsList', { modals, dbSync: true });
        },
      },
  },
  methods: {
    open()
    {
      this.newFontMode = false;
      this.selectedModal = '';
      this.$modal.show('ModalEditorModal');
    },
    close()
    {
      this.$modal.hide('ModalEditorModal');
    },
    removeModal(modalID) {
      if (this.selectedModal === modalID)
        this.selectedModal = '';
      const modals = {};
      Object.entries(this.modalList).forEach(([id, modal]) => {
        if (modalID !== id)
          modals[id] = modal;
      });
      this.$store.commit('setModalsList', { modals, dbSync: true });
    },
    importModal(modalData)
    {
      const modals = _.cloneDeep(this.modalList);
      const id = this.$chance.string(this.$newIDParams);
      modals[id] = {
        ...modalData,
        name: (modalData.name ? modalData.name + '_' : '') + id,
      };
      this.$store.commit('setModalsList', { modals, dbSync: true });
      this.selectedModal = id;
      this.importModalNotif();
    },
    addModal(modalName) {
      const modals = _.cloneDeep(this.modalList);
      const id = this.$chance.string(this.$newIDParams);
      modals[id] = { name: modalName, ...ModalDefaultContent };
      this.$store.commit('setModalsList', { modals, dbSync: true });
      this.selectedModal = id;
      this.newModalNotif();
    },
    copyModalID(ID)
    {
      const copyText = document.getElementById('hiddenIDInput');
      copyText.value = ID;
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy');
      this.copyIdNotif();
    },
    exportModal()
    {
      const jsonData = JSON.stringify({ ...this.selectedModalData, id: undefined } || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `modal_${this.selectedModal || 'unknown'}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
  },
  notifications: {
    newModalNotif: {
      type: VueNotifications.types.success,
      title: 'Modal crée !',
      message: 'La modal a été crée avec succès',
    },
    importModalNotif: {
      type: VueNotifications.types.success,
      title: 'Modal importé !',
      message: 'La modal a été importé avec succès',
    },
    copyIdNotif: {
      type: VueNotifications.types.success,
      title: 'Lien de la modal copié !',
      message: 'L\'ID de la modal a été copié dans votre presse papier !',
    },
  },
};

</script>

<style scoped>

</style>
