<template>
  <div class="editorUIStyle">
    <label class="small" for="backgroundPropertiesSelects">{{ label }}</label>
    <div class="input-group" id="backgroundPropertiesSelects">
      <select class="form-control" v-model="cover">
        <option value="">{{ dictionary.default }}</option>
        <option value="contain">{{ dictionary.styleEditor.content }}</option>
        <option value="cover">{{ dictionary.styleEditor.cover }}</option>
      </select>
      <select class="form-control" v-model="repetition">
        <option value="">{{ dictionary.styleEditor.repeat }}</option>
        <option value="no-repeat">{{ dictionary.styleEditor.noRepeat }}</option>
        <option value="repeat-x">{{ dictionary.styleEditor.horizontalRepeat }}</option>
        <option value="repeat-y">{{ dictionary.styleEditor.verticalRepeat }}</option>
      </select>
      <select class="form-control" v-model="scroll" >
        <option value="">{{ dictionary.styleEditor.scroll }}</option>
        <option value="fixed">{{ dictionary.styleEditor.fix }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BackgroundProperties',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      required: true,
      type: Object,
    },
    label: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    scroll: {
      get()
      {
        return this.value.scroll || '';
      },
      set(scroll)
      {
        this.$emit('change', { ...this.value, scroll });
      },
    },
    repetition: {
      get()
      {
        return this.value.repetition || '';
      },
      set(repetition)
      {
        this.$emit('change', { ...this.value, repetition });
      },
    },
    cover: {
      get()
      {
        return this.value.cover || '';
      },
      set(cover)
      {
        this.$emit('change', { ...this.value, cover });
      },
    },
  },
};
</script>

<style scoped>

</style>
