<template>
  <div v-if="backgroundImage" class="position-relative h-100">
    <img @click="$emit('selected', -1)" @load="onImgLoad" :src="backgroundImage" style="object-fit: contain; object-position: top left" class="h-100 w-100" alt="backgroundImage" ref="backgroundImg" >
    <div class="position-absolute" style="top:0; left: 0;">
      <VueDragResize
          v-for="(zone, zoneID) in zoneList"
          :key="`zone-${zoneID}`"
          :parentH="imageHeight"
          :parentW="imageWidth"
          :isActive="selectedZone === zoneID"
          :w="zone.position.w || 100"
          :h="zone.position.h || 100"
          :x="zone.position.x || 0"
          :y="zone.position.y || 0"
          :minh="10"
          :minw="10"
          :parentLimitation="true"
          :style="`background-color: ${getZoneColor(zone)}`"
          @resizestop="resize($event, zoneID)"
          @dragstop="resize($event, zoneID)"
          @activated="$emit('selected', zoneID)">
      </VueDragResize>
    </div>
  </div>
  <div v-else class="h-100 w-100 d-flex align-items-center">
    <h5 class="text-muted mx-auto">Ajoutez une image de fond<br>depuis le menu de gauche</h5>
  </div>
</template>

<script>

import _ from 'lodash';
import VueDragResize from 'vue-drag-resize';

export default {
  name: 'ZoneEditor',
  components: {
    VueDragResize,
  },
  model: {
    prop: 'zoneList',
    event: 'change',
  },
  props: {
    zoneList: {
      type: Array,
      required: true,
    },
    backgroundImage: {
      type: String,
      required: true,
    },
    foregroundImage: {
      type: String,
      required: true,
    },
    selectedZone: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data()
  {
    return {
      imageHeight: 0,
      imageWidth: 0,
      loading: true,
    };
  },
  methods: {
    getZoneColor(zone)
    {
      return `rgba(${zone.color.rgba.r}, ${zone.color.rgba.g}, ${zone.color.rgba.b}, 0.5)`;
    },
    getRenderedSize(contains, cWidth, cHeight, width, height, pos) {
      const oRatio = width / height;
      const cRatio = cWidth / cHeight;
      const out = {};
      if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
        out.width = cWidth;
        out.height = cWidth / oRatio;
      } else {
        out.width = cHeight * oRatio;
        out.height = cHeight;
      }
      out.left = (cWidth - out.width) * (pos / 100);
      out.right = out.width + out.left;
      return out;
    },
    onImgLoad(event)
    {
      const realSize = this.getRenderedSize(true, event.target.width, event.target.height, event.target.naturalWidth, event.target.naturalHeight);
      this.imageHeight = realSize.height;
      this.imageWidth = realSize.width;
    },
    resize(event, idx) {
      const zonesTmp = _.cloneDeep(this.zoneList);
      zonesTmp[idx] = {
        ...zonesTmp[idx],
        position: {
          percent: {
            x: ((100 * event.left) / this.imageWidth).toFixed(4),
            y: ((100 * event.top) / this.imageHeight).toFixed(4),
            h: ((100 * event.height) / this.imageHeight).toFixed(4),
            w: ((100 * event.width) / this.imageWidth).toFixed(4),
          },
          x: event.left,
          y: event.top,
          h: event.height,
          w: event.width,
        },
      };
      this.$emit('change', zonesTmp);
    },
  },
};

</script>

<style scoped>

</style>
