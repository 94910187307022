<template>
  <div class="h-100 d-flex flex-column">
    <div v-if="!loaded" class="text-center mt-5">
      <h5 class="text-muted">{{ dictionary.deploymentModal.historyTabLoading }}</h5>
    </div>
    <perfect-scrollbar v-if="loaded && websiteHistory.length >= 1" class="flex-grow-1 pe-3 pt-3" :options="{ wheelPropagation: false }">
      <table class="table">
        <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">{{ dictionary.author }}</th>
          <th scope="col">{{ dictionary.date }}</th>
          <th scope="col">{{ dictionary.message }}</th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(commit, key) in websiteHistory" :key="key">
          <td><i v-tooltip.top="getTooltipCommitID(commit.id)" class="ms-3 fas fa-tags text-secondary"></i></td>
          <td>{{commit.author || dictionary.deploymentModal.unknown}}</td>
          <td>{{ formatDate(commit.date || '') }}</td>
          <td v-if="commit.message !== ''">{{commit.message || ''}}</td>
          <td v-else class="text-muted small">{{ dictionary.deploymentModal.noMessage }}</td>
          <td class="text-muted small">{{ ~~(commit.commitSize / 1024) }} Ko</td>
          <td><span v-if="liveCommit.commitId === commit.id" class="badge rounded-pill bg-info">{{ dictionary.production }}</span></td>
          <td class="d-flex justify-content-end h-100">
            <button v-tooltip.top="`${dictionary.deploymentModal.rollbackTooltip}`" @click.prevent="rollbackCommit(commit)" class="btn btn-primary mx-1 text-white"><i class="fas fa-undo-alt"></i></button>
            <button v-tooltip.top="`${dictionary.deploymentModal.downloadTooltip}`" @click.prevent="downloadCommit(commit)" class="btn btn-success mx-1 text-white"><i class="fas fa-download"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </perfect-scrollbar>
    <div v-else-if="loaded" class="row">
      <div class="col-auto mx-auto mt-4">
        <p class="text-muted">{{ dictionary.deploymentModal.noDeploymentHistory }}</p>
      </div>
    </div>
    <a class="text-muted" data-bs-toggle="collapse" href="#collapseAdvOptions" role="button" aria-expanded="false" aria-controls="collapseAdvOptions">Paramètres avancées</a>
    <div class="collapse" id="collapseAdvOptions">
      <form class="flex-shrink-1 pb-3">
        <div class="mb-2">
          <label for="historyLimit" class="form-label">Limite de l'historique</label>
          <input @change="setHistoryLimit($event.target.value)" :value="historyLimit" type="number" class="form-control" id="historyLimit" aria-describedby="historyLimitHelp">
          <div id="historyLimitHelp" class="form-text small">Modifier cette valeur peux avoir de sérieuses conséquences sur les performances du builder et sur la publication du site </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';

export default {
  name: 'DeploymentHistory',
  data()
  {
    return {
      loaded: false,
      history: {},
    };
  },
  mounted()
  {
    this.$store.dispatch('getWebsiteHistory').then((val) => {
      this.loaded = true;
      this.history = val;
    });
  },
  computed: {
    ...mapGetters(['liveCommit', 'dictionary', 'historyLimit']),
    websiteHistory()
    {
      if (this.history)
        return Object.values(this.history).reverse();
      return [];
    },
  },
  methods: {
    setHistoryLimit(value)
    {
      this.$store.dispatch('setHistoryLimit', { value });
    },
    getTooltipCommitID(commitID)
    {
      return `Identifiant : ${commitID}`;
    },
    formatDate(timestamp)
    {
      return this.$formatDate(new Date(timestamp));
    },
    rollbackCommit(commit)
    {
      this.$store.dispatch('setLiveWebsite', { ...commit }).then(() => {
        if (commit.websiteContent)
        {
          if (commit.websiteContent.body)
            this.$store.commit('setBodyConfig', { config: commit.websiteContent.body, dbSync: false });
          if (commit.websiteContent.filters)
            this.$store.commit('setWebsiteFilters', { filters: commit.websiteContent.filters, dbSync: false });
          if (commit.websiteContent.modals)
            this.$store.commit('setModalsList', { modals: commit.websiteContent.modals, dbSync: false });
          if (commit.websiteContent.origin)
            this.$store.commit('setWebsiteContentOrigin', { origin: commit.websiteContent.origin, dbSync: false });
          if (commit.websiteContent.pages)
            this.$store.commit('setAllPages', { pages: commit.websiteContent.pages, dbSync: false });
        }
        if (commit.js || commit.clearJS)
        {
          this.$store.commit('setCustomJS', { value: commit.clearJS || commit.js, dbSync: false });
        }
        if (commit.css)
          this.$store.commit('setCustomCSS', { value: commit.css, dbSync: false });

        this.$store.dispatch('setWebsiteContent', { ...commit.websiteContent, customJS: commit.js, customCSS: commit.css }).then(() => {
          this.rollbackNotification();
        });
      });
    },
    downloadCommit(commit)
    {
      const out = { ...commit.websiteContent };
      const jsonData = JSON.stringify(out || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `deployment_${commit.id || ''}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
  },
  notifications: {
    rollbackNotification: {
      type: VueNotifications.types.success,
      title: 'Rollback effectué !',
      message: 'Le site en production a été rollback vers un precedent deployement.',
    },
  },
};

</script>

<style scoped>

</style>
