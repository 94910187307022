<template>
  <div class="row">
    <div class="col-12">
      <div v-if="formHasError" class="alert alert-warning d-flex align-items-center" role="alert">
        <div class="row w-100">
          <div class="col text-center">
            <h5><span class="fas fa-exclamation-triangle me-4"></span>{{ dictionary.formModule.formErrorsMessage }}</h5>
          </div>
          <div class="col-12 text-center fw-bold">
            <p v-html="formErrorMessage"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form" id="preview-form" style="margin-bottom: 150px;">
        <FormItemsList
            :selectedItem="selectedItem"
            :previewStyle="computedPreviewStyle"
            :depth-level="0"
            v-model="draggableItems"
            @itemClick="$emit('itemClick', $event)"
            @removeItem="$emit('removeItem', $event)"
            @editItem="$emit('editItem', $event)"/>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import FormItemsList from './FormItemListWithOverlay.vue';

export default {
  name: 'FormPreview',
  components: {
    FormItemsList,
  },
  props: {
    formContent: {
      type: Object,
      required: true,
    },
    selectedItem: {
      type: String,
      required: false,
      default: undefined,
    },
    previewStyle: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data()
  {
    return {
      formErrorMessage: '',
    };
  },
  computed: {
    ...mapGetters(['dictionary', 'eventData']),
    draggableItems: {
      get()
      {
        if (this.formContent.data)
          return this.formContent.data.fields || [];
        return [];
      },
      set(items)
      {
        this.$emit('change', { ...this.formContent, data: { ...this.formContent.data, fields: items } });
      },
    },
    computedPreviewStyle() {
      return this.previewStyle;
    },
    formHasError()
    {
      let hasButton = false;
      let foundError = false;
      this.draggableItems.forEach((item) => {
        if (item.type === 'validateButton')
          hasButton = true;
        if (item.type === 'group')
          this.getBlockItems(item).forEach((innerItem) => {
            if (innerItem.type === 'validateButton')
              hasButton = true;
            if (this.hasErrors(innerItem))
              foundError = true;
          });
        if (this.hasErrors(item))
          foundError = true;
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.formErrorMessage = '';
      if (foundError)
      {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.formErrorMessage = this.dictionary.formModule.formErrorInputMessage;
      }
      if (!hasButton)
      {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.formErrorMessage += `${foundError ? '<br>' : ''}${this.dictionary.formModule.formErrorValidMessage}`;
      }
      return foundError || !hasButton;
    },
  },
  methods: {
    hasErrors(item)
    {
      if (item.type === 'input' ||
        item.type === 'textarea' ||
        item.type === 'multipleChoices' ||
        item.type === 'file')
        return (!item.data || !this.eventData[item.data]);
      return false;
    },
    getBlockItems(block)
    {
      return block.innerItems || [];
    },
  },
};

</script>

<style scoped>

</style>
