<template>
  <modal :name="id" :width="'90%'" height="90%" @scroll.prevent>
    <Card class="h-100">
      <template v-slot:header-left><h6 class="text-white mb-0"  v-html="title"></h6></template>
      <template v-slot:header-right>
        <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button>
      </template>
      <NavbarBuilderV2 ref="builder" :computedStyle="computedStyle" :navbarContent="navbarContent" @change="$emit('change', $event)"/>
    </Card>
  </modal>
</template>

<script>

import { mapGetters } from 'vuex';
import Card from '../Utilities/Card.vue';
import NavbarBuilderV2 from '../NavbarBuilderV2/NavbarBuilderV2.vue';

export default {
  name: 'NavbarBuilderModal',
  components: {
    NavbarBuilderV2,
    Card,
  },
  model: {
    prop: 'navbarContent',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Editeur de barre de navigation',
    },
    navbarContent: {
      type: Object,
      required: true,
    },
    computedStyle: {
      type: Object,
      required: false,
    },
  },
  mounted()
  {
    if (!this.id) {
      this.id = `navbarEditorModal${this.$chance.string(this.$newIDParams)}`;
    }
  },
  data()
  {
    return {
      id: '',
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
  methods: {
    open()
    {
      this.$modal.show(this.id);
    },
    close()
    {
      this.$modal.hide(this.id);
      this.$refs.builder.setSelectedItem({
        itemIdx: -1,
        itemZone: '',
      });
    },
  },
};

</script>

<style>

</style>
