<template>
  <div>
    <div class="row">
      <div class="col-12 mb-3">
        <button @click="addItems('text')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-font me-3"></i>Text</button>
      </div>
      <div class="col-12 mb-3">
        <button @click="addItems('btnFill')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-square me-3"></i>Bouton plein</button>
      </div>
      <div class="col-12 mb-3">
        <button @click="addItems('btnOutline')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-border-style me-3"></i>Bouton vide</button>
      </div>
      <div class="col-12 mb-3">
        <button @click="addItems('image')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-images me-3"></i>Image</button>
      </div>
      <div class="col-12 mb-3">
        <button @click="addItems('dropdown')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="far fa-caret-square-down me-3"></i>Menu déroulant</button>
      </div>
      <div class="col-12 mb-3">
        <button @click="addItems('separator')" class="btn btn-outline-secondary btn-raised w-100 text-start ps-4"><i class="fas fa-arrows-alt-h me-3"></i>Séparateur</button>
      </div>
    </div>
    <hr class="w-100">
    <p v-if="navbarItems.length <= 0" class="text-center text-muted small">Pas d'éléments pour le moment<br>Ajoutez-en avec les boutons ci-dessus</p>
    <draggable v-else tag="form" v-model="navbarItems" :group="{ name: 'formOptions' }" handle=".optionDragHandle">
      <div class="row mb-3 px-0 mx-0 d-flex align-items-center border rounded" :class="selectedItemID === itemIdx ? 'border-success bg-success text-light' : 'border-primary'" v-for="(item, itemIdx) in navbarItems" :key="`opt-${itemIdx}`">
        <div class="col-auto">
          <i class="fas fa-grip-lines optionDragHandle cursor-grab"></i>
        </div>
        <div class="col-auto border border-secondary text-secondary rounded-2">
          <i v-if="item.type === 'text'" class="fas fa-font"></i>
          <i v-if="item.type === 'image'" class="fas fa-photo-video"></i>
          <i v-if="item.type === 'separator'" class="fas fa-arrows-alt-h"></i>
          <i v-if="item.type === 'dropdown'" class="fas fa-caret-square-down"></i>
        </div>
        <div class="col">
          <p v-if="item.type === 'separator'" class="py-2 m-0 fw-bold small">Séparateur : {{ itemIdx }}</p>
          <p v-else-if="!item.content" class="py-2 m-0 fw-bold">{{ item.type }} : {{ itemIdx }}</p>
          <p v-else-if="item.type === 'text'" class="py-2 m-0 fw-bold small"> {{ item.content }}</p>
          <p v-else-if="item.type === 'image'" class="py-2 m-0 fw-bold small">{{ item.type }} : {{ itemIdx }}</p>
          <p v-else-if="item.type === 'dropdown'" class="py-2 m-0 fw-bold small">{{ item.content }}</p>
        </div>
        <div class="col-auto pe-1">
          <button class="btn btn-info text-light" @click.stop.prevent="selectItem(itemIdx)"><i class="fas fa-pen"></i></button>
        </div>
        <div class="col-auto p-0 pe-1">
          <button class="btn btn-danger text-light" @click.stop.prevent="removeItem(itemIdx)"><i class="fas fa-trash"></i></button>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>

import _ from 'lodash';
import draggable from 'vuedraggable';
import defaultItemsValues from '../../configuration/navbarDefaultItems.json';

export default {
  name: 'NewNavbarItems',
  components: { draggable },
  props: {
    navbarContent: {
      type: Object,
      required: true,
    },
    selectedItemID: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  computed: {
    navbarItems: {
      get()
      {
        return this.navbarContent.items || [];
      },
      set(newItems)
      {
        if (!newItems)
          return;
        this.selectItem(-1);
        this.$emit('change', { ...this.navbarContent, items: newItems });
      },
    },
  },
  methods: {
    addItems(itemType)
    {
      if (!defaultItemsValues[itemType])
        return;
      const newItems = _.cloneDeep(this.navbarContent.items || []);
      newItems.push(defaultItemsValues[itemType]);
      this.$emit('change', { ...this.navbarContent, items: newItems });
    },
    selectItem(newSelectedItemId)
    {
      this.$emit('selectItem', newSelectedItemId);
    },
    removeItem(removedItemId)
    {
      if (!this.navbarItems[removedItemId])
        return;
      const newItems = _.cloneDeep(this.navbarItems || []);
      newItems.splice(removedItemId, 1);
      this.navbarItems = newItems;
    },
  },
};

</script>

<style scoped>

</style>
