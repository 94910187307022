<template>
  <form @submit.stop.prevent>
    <div class="form-check form-switch mb-3">
      <input v-model="hidden" class="form-check-input" type="checkbox" id="FormItemHideSwitch">
      <label class="form-check-label" for="FormItemHideSwitch">Masquer ce champ</label>
    </div>
    <div class="mb-2">
      <alignment-properties label="Alignement du texte" v-model="alignment"/>
    </div>
    <div class="mt-4">
      <div class="row d-flex align-items-center">
        <div class="col">
          <label class="form-label w-100">{{ dictionary.formModule.textInputLabel }}</label>
        </div>
        <div class="col-auto">
          <button @click.prevent="mode = (mode === 'text') ? 'code' : 'text'" class="btn btn-outline-primary">{{ dictionary.formModule.editCodeButton }}</button>
        </div>
      </div>
      <div style="height: 380px;" class="mt-3">
        <CkEditor5
            v-if="mode === 'text'"
            v-model="html"
            :importedFonts="googleFonts"
            @blur="onBlur"
            type="classic"/>
        <codemirror
            v-else
            v-model="html"
            :options="{ ...cmOptions }"
            @blur="onBlur"
            class="h-100"
            style="border: 1px solid var(--bs-secondary)"/>
      </div>
    </div>
  </form>
</template>

<script>

import { codemirror } from 'vue-codemirror';
import { mapGetters } from 'vuex';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/mode/xml/xml';
import 'codemirror/theme/darcula.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/edit/closetag';
import AlignmentProperties from '../../Utilities/AlignmentProperties.vue';
import CkEditor5 from '../../Utilities/CkEditor.vue';

export default {
  name: 'FormTextConfig',
  components: {
    AlignmentProperties,
    CkEditor5,
    codemirror,
  },
  props: {
    formItem: {
      type: Object,
      required: true,
    },
  },
  data()
  {
    return {
      temporaryHtml: undefined,
      mode: 'text',
      cmOptions: {
        tabSize: 4,
        mode: this.mode,
        theme: 'monokai',
        lineNumbers: true,
        line: true,
        autofocus: true,
        addons: 'show-hint',
        autoCloseTags: true,
        styleActiveLine: true,
      },
    };
  },
  mounted()
  {
    this.temporaryHtml = this.formItem.html;
  },
  unmounted()
  {
    this.save();
  },
  watch: {
    formItem()
    {
      this.temporaryHtml = this.formItem.html;
    },
  },
  computed: {
    ...mapGetters(['googleFonts', 'dictionary']),
    hidden: {
      get()
      {
        return this.formItem.hidden || false;
      },
      set(newHidden)
      {
        this.$emit('change', { ...this.formItem, hidden: newHidden });
      },
    },
    html: {
      get()
      {
        return this.temporaryHtml || `${this.formItem.html}`;
      },
      set(newHtml)
      {
        this.temporaryHtml = newHtml;
      },
    },
    alignment: {
      get()
      {
        return this.formItem.alignment || 'default';
      },
      set(newAlignment)
      {
        this.$emit('change', { ...this.formItem, alignment: newAlignment });
      },
    },
  },
  methods: {
    save()
    {
      this.$emit('change', { ...this.formItem, html: (this.temporaryHtml === undefined) ? `${this.formItem.html}` : this.temporaryHtml });
    },
    onBlur()
    {
      this.save();
    },
  },
};

</script>

<style scoped>

</style>
