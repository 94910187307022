<template>
  <div class="position-relative editorUIStyle">
    <button @click="toggleContext" type="button" class="btn btn-outline-success text-light fw-bold">
      <i class="far fa-file-alt me-2"></i><span v-if="pageList[currentPage]">{{ pageList[currentPage].name }}</span>
    </button>
    <div @click="toggleContext" v-if="contextOpened" class="position-fixed top-0 start-0 vh-100 vw-100" style="background-color: rgba(127, 127, 127, 0.2);"></div>
    <div v-if="contextOpened" class="position-absolute bg-white widget p-2 translate-middle-x start-50 d-flex flex-column" style="border-radius: 10px; height: 350px; width: 700px; bottom: 50px;">
      <div class="row mx-2 pb-2 d-flex align-items-center">
        <div class="col-auto">
          <div class="btn-group ms-auto" role="group" aria-label="Edit page btn">
            <button v-tooltip.top="'Nouvelle page'" @click="$refs.newPageCtx.openContext()" class="btn btn-success text-white"><i class="fas fa-plus"></i></button>
            <button v-tooltip.top="'Importer une page'" class="btn btn-secondary text-white" @click="$refs.importHiddenInput.click()"><i class="fas fa-download"></i></button>
          </div>
        </div>
        <div class="col">
          <div class="input-group">
            <span class="input-group-text"><i class="fas fa-search text-secondary"></i></span>
            <input v-model="pageSearchQuery" type="text" class="form-control">
          </div>
        </div>
      </div>
      <perfect-scrollbar class="w-100 position-relative pe-3 pb-2 flex-grow-1" :options="{ wheelPropagation: false, suppressScrollX: true }">
        <template v-for="page in pages">
          <div :key="`${page.id}-line`" class="row ms-2 py-2 position-relative" style="border-radius: 10px;" :class="currentPage === page.id ? 'bg-success text-light' : ''">
            <div class="col my-auto" ><p class="my-auto">{{ page.name }}<i v-tooltip.top="'Renommer la page'" style="cursor: pointer" class="fas fa-pen ms-2 fa-xs editBtn" @click="editPageName(page.id)"></i><i v-if="pageList.default && pageList.default === page.name" class="fas fa-home ms-2 fa-xs"></i><span v-if="page.disabled" class="badge rounded-pill bg-dark text-white ms-2 small">Désactivé en production</span></p></div>
            <div class="col-auto" v-if="currentPage !== page.id"><button v-tooltip.top="'Voir / éditer la page'" @click="setCurrentPage(page.id)" class="btn btn-outline-success m-0"><i class="fas fa-hammer" ></i></button></div>
          </div>
        </template>
      </perfect-scrollbar>
      <div class="row mx-2 pt-2">
        <div class="col-auto">
          <button @click="$refs.pageMetaEditorModal.open()" class="btn btn-secondary"><i class="fas fa-tags me-2"></i>Editer les metas</button>
        </div>
        <div class="col-auto">
          <button v-if="currentPageName !== pageList.default" @click="setDefaultPage(currentPage)" class="btn btn-outline-secondary"><i class="fas fa-home me-2"></i>Utiliser comme page d'accueil</button>
          <button v-else class="btn btn-secondary"><i class="fas fa-home me-2"></i>Page d'accueil</button>
        </div>
        <div class="col">
          <div class="input-group" role="group" aria-label="Edit page btn">
            <button @click="exportPage(currentPage)" v-tooltip.top="'Exporter la page'" class="btn btn-primary m-0"><i class="fas  fa-upload text-li2ght text-white" ></i></button>
            <button v-if="Object.keys(pages).length > 1" v-tooltip.top="'Supprimer la page'" @click="$refs[`confirmationDeletePage-#${currentPage}`][0].openContext()" class="btn btn-danger m-0"><i class="fas fa-trash text-li2ght text-white" ></i></button>
            <button @click="togglePageEnabled(currentPage, !pageList[currentPage].disabled)" :class="pageList[currentPage] && pageList[currentPage].disabled ? 'btn-dark text-white' : 'btn-outline-dark'" class="btn" v-tooltip="'Désactiver la page en production'"><i :class="pageList[currentPage] && pageList[currentPage].disabled ? 'fa-eye-slash' : 'fa-eye'" class="fas"></i></button>
            <select class="form-select" :value="pageHeaderFooterValue(currentPage)" @change="setPageHeaderFooter(currentPage, $event.target.value)">
              <option value="">Header et Footer</option>
              <option value="h">Header</option>
              <option value="f">Footer</option>
              <option value="none">Aucun des deux</option>
            </select>
            <select class="form-select" @change="setPageFullWidth(currentPage, $event.target.value)" :value="pageFullWidthValue(currentPage)">
              <option value="">{{ bodyConfig.fullWidth ? 'Pleine largeur' : 'Normal' }} (Réglage du site)</option>
              <option :value="false">Largeur normale</option>
              <option :value="true">Pleine largeur</option>
            </select>
          </div>
        </div>
      </div>
      <input accept="application/JSON" ref="importHiddenInput" type="file" @change="importPage" class="invisible">
    </div>
    <NewPageContext ref="newPageCtx" @addPage="newPage($event)"/>
    <template v-for="(page) in pages">
      <NewPageContext :key="`${page.id}-ctx`" :ref="`newPageCtx-${page.id}`" :page-name="page.name" @addPage="renamePage(page.id, $event)"/>
      <confirmation-context :key="`${page.id}-deleteContext`" :ref="`confirmationDeletePage-#${page.id}`" :introMessage="`<h6>Étes vous sur de vouloir supprimer cette page ?</h6>`" @confirm="removePage(page.id)"/>
    </template>
    <PagesMetaEditor ref="pageMetaEditorModal"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import _ from 'lodash';
import NewPageContext from '../contexts/NewPageContext.vue';
import ConfirmationContext from '../contexts/ConfirmationContext.vue';
import defaultPageContent from '../../configuration/defaultNewPage.json';
import PagesMetaEditor from '../Modals/PagesMetaEditor.vue';

export default {
  name: 'PagesWidget',
  components: {
    PagesMetaEditor,
    NewPageContext,
    ConfirmationContext,
  },
  filters: {
    order(obj)
    {
      return obj;
    },
  },
  data()
  {
    return {
      contextOpened: false,
      pageSearchQuery: '',
    };
  },
  computed: {
    ...mapGetters(['pageList', 'currentPage', 'defaultPage', 'preferredUrl', 'bodyConfig']),
    externalUrl()
    {
      return this.preferredUrl || '';
    },
    pages()
    {
      const out = {};
      const tmp = { ...this.pageList };
      delete tmp.default;
      Object.entries(tmp).forEach(([id, page]) => { page.id = id; });
      if (!this.pageSearchQuery)
        return _.orderBy(tmp, 'name');
      Object.entries(tmp).forEach(([key, value]) => {
        if (key.includes(this.pageSearchQuery) || value.name.includes(this.pageSearchQuery))
          out[key] = value;
      });
      return _.orderBy(out, 'name');
    },
    currentPageName()
    {
      if (this.pageList[this.currentPage])
        return this.pageList[this.currentPage].name || this.currentPage;
      return this.currentPage;
    },
  },
  methods: {
    renamePage(pageID, newName)
    {
      const dPage = (this.defaultPage === pageID);
      this.$store.commit('setPageName', { pageID, pageName: newName, dbSync: true });
      if (dPage)
        this.setDefaultPage(pageID);
    },
    editPageName(name)
    {
      this.$refs[`newPageCtx-${name}`][0].openContext();
    },
    newPage(name)
    {
      const ret = {};
      this.$store.commit('addPage', {
        content: defaultPageContent,
        pageName: name,
        newID: ret,
        dbSync: true,
      });
      this.setCurrentPage(ret.id);
    },
    removePage(pageID)
    {
      if (this.currentPage === pageID)
      {
        if (this.defaultPage !== pageID)
          this.$store.commit('setCurrentPage', { page: this.defaultPage, dbSync: true });
        else
          Object.keys(this.pageList).forEach((pID) => {
            if (pID !== pageID && pID !== 'default')
              this.$store.commit('setCurrentPage', { page: pID, dbSync: true });
          });
      }
      const dPage = (this.defaultPage === pageID);
      this.$store.commit('removePage', { pageID, dbSync: true });
      if (dPage)
        Object.keys(this.pageList).forEach((pID) => {
          if (pID !== pageID && pID !== 'default')
            this.setDefaultPage(pID);
        });
    },
    setCurrentPage(pageID)
    {
      this.$store.commit('setCurrentPage', { page: pageID, dbSync: true });
    },
    setDefaultPage(pageID)
    {
      this.$store.commit('setDefaultPage', { pageID, dbSync: true });
    },
    toggleContext()
    {
      this.pageSearchQuery = '';
      this.contextOpened = !this.contextOpened;
    },
    togglePageEnabled(pageID, disabled)
    {
      this.$store.commit('enablePage', { pageID, disabled, dbSync: true });
    },
    pageFullWidthValue(pageID)
    {
      return this.pageList[pageID].fullWidth;
    },
    setPageFullWidth(pageID, value)
    {
      this.$store.commit('setPageFullWidth', { pageID, value, dbSync: true });
    },
    setPageHeaderFooter(pageID, value)
    {
      if (value === 'none')
        this.$store.commit('setPageFooterAndHeader', { pageID, footer: false, header: false, dbSync: true });
      if (value === '')
        this.$store.commit('setPageFooterAndHeader', { pageID, footer: true, header: true, dbSync: true });
      if (value === 'h')
        this.$store.commit('setPageFooterAndHeader', { pageID, footer: false, header: true, dbSync: true });
      if (value === 'f')
        this.$store.commit('setPageFooterAndHeader', { pageID, footer: true, header: false, dbSync: true });
    },
    pageHeaderFooterValue(pageID)
    {
      if (!this.pageList[pageID])
        return '';
      const header = !!(this.pageList[pageID].header);
      const footer = !!(this.pageList[pageID].footer);
      if (header && footer)
        return '';
      if (!footer && header)
        return 'h';
      if (footer && !header)
        return 'f';
      return '';
    },
    exportPage(pageID)
    {
      const jsonData = JSON.stringify(this.pageList[pageID] || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `website_${this.$chance.string(this.$newIDParams)}_page_${this.pageList[pageID].name || 'unknown'}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    importPage(ev)
    {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object)
          {
            const ret = {};
            if (object.rows)
              this.$store.commit('addPage', {
                pageName: `import-${object.name || 'website'}`,
                content: { rows: object.rows },
                newID: ret,
                dbSync: true,
              });
            this.setCurrentPage(ret.id);
          }
        } catch (ex)
        {
          console.log(`Invalid website content file : ${ex.message}`);
        }
      };
      reader.readAsText(ev.target.files[0]);
    },
  },
};

</script>

<style scoped>

.widget {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}

.editBtn:hover {
  color: black!important;
}

</style>
