<template>
  <div class="h-100 d-flex flex-column">
    <div class="row">
      <div class="col-auto"><button @click="$refs.newAlertContext.openContext()" class="btn btn-success text-light"><i class="fas fa-plus me-2"></i>Nouvelle alerte</button></div>
      <div class="col-auto">
        <div class="dropdown">
          <button class="btn btn-info dropdown-toggle text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fas fa-download me-2"></i>Export / Import
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li class="dropdown-item cursor-pointer" @click="exportAlerts">Exporter les alertes<i class="ms-2 fas fa-upload"></i></li>
            <li class="dropdown-item cursor-pointer" @click="$refs.importHiddenInput.click()">Importer des alertes<i class="ms-2 fas fa-download"></i></li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="input-group">
          <input v-model="searchQuery" type="text" class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2">
          <button class="btn btn-outline-secondary" type="button" id="button-addon2"><i class="fas fa-search"></i></button>
        </div>
      </div>
    </div>
    <input accept="application/JSON" ref="importHiddenInput" type="file" @change="importAlerts" class="invisible">
    <perfect-scrollbar class="flex-grow-1">
      <div class="row my-3 pe-3">
        <div class="col-auto">
          <button class="text-light btn btn-info" type="button" data-bs-toggle="collapse" data-bs-target="#emailButtonCollapse" aria-expanded="false" aria-controls="emailButtonCollapse">Éditer le bouton de renvois d'email</button>
        </div>
        <div class="col">
          <hr>
        </div>
        <div class="col-12 collapse pt-3" id="emailButtonCollapse">
          <StyleEditor @change="$emit('alreadyRegisteredConfigChange', $event)" :value="alreadyRegisteredConfig" :properties="['spacing', 'hr', 'borderRadius', 'border', 'color', 'backgroundColor', 'alignment', 'fontFamily', 'fontWeight']"  :noButtons="true">
            <template v-slot:form-start>
              <div class="mb-3">
                <label for="FormItemLabelInput" class="form-label">Texte de l'alerte<CodeCompatibleBadge class="ms-2" position="top"/></label>
                <input :value="displayedAlerts.register_error_exists.content" @change="setAlertContent('register_error_exists', $event.target.value)" type="text" class="form-control" id="FormItemLabelInput">
              </div>
              <div class="mb-3">
                <label for="FormItemLabelInput" class="form-label">Texte du bouton de renvois d'email<CodeCompatibleBadge class="ms-2" position="top"/></label>
                <input @change="$emit('alreadyRegisteredConfigChange', { ...alreadyRegisteredConfig, label: $event.target.value})" :value="alreadyRegisteredConfig.label || ''" type="text" class="form-control" id="FormItemLabelInput">
              </div>
              <div class="mb-3">
                <label for="FormItemLabelInput" class="form-label">Texte du message de confirmation<CodeCompatibleBadge class="ms-2" position="top"/></label>
                <input @change="$emit('alreadyRegisteredConfigChange', { ...alreadyRegisteredConfig, confirmationMessage: $event.target.value})" :value="alreadyRegisteredConfig.confirmationMessage || ''" type="text" class="form-control" id="FormItemLabelInput">
              </div>
              <div class="row mb-3">
                <div class="col-12">
                  <h6>Message a envoyer<span v-if="(alreadyRegisteredConfig.messages || []).length > 0" class="badge rounded-pill bg-success ms-2">{{ (alreadyRegisteredConfig.messages || []).length }} messages</span></h6>
                </div>
                <div class="col-12">
                  <multiselect
                      :options="serverMessages"
                      :multiple="true"
                      :close-on-select="false"
                      :value="alreadyRegisteredConfig.messages"
                      :custom-label="customLabel"
                      @select="addMessages($event)"
                      @remove="removeMessages($event)"
                      label="name"
                      track-by="name"
                  />
                </div>
              </div>
            </template>
          </StyleEditor>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-auto">
          <p class="small text-muted"><span class="me-2 badge bg-success small"><i class="fas fa-code"></i></span>Toutes les alertes supportent le HTML</p>
        </div>
        <div class="col">
          <hr>
        </div>
      </div>
      <div v-for="(alert, alertCode) in displayedAlerts" :key="alertCode" class="pe-3">
        <div v-if="alertCode !== 'register_error_exists'" class="input-group mb-3 d-flex flex-row">
          <div class="form-floating flex-grow-1">
            <textarea class="form-control" id="floatingTextarea2" style="height: 80px" :value="alert.content" @change="setAlertContent(alertCode, $event.target.value)"></textarea>
            <label for="floatingTextarea2">{{ alertCode }}</label>
          </div>
          <button :class="TypeButtonClass(alert.type)" data-bs-auto-close="true" class="btn dropdown-toggle text-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i v-if="alert.type === 'success'" class="me-2 fas fa-check-circle fa-lg"></i>
            <i v-if="alert.type === 'info'" class="me-2 fas fa-info-circle fa-lg"></i>
            <i v-if="alert.type === 'warning'" class="me-2 fas fa-exclamation-triangle fa-lg"></i>
            <i v-if="alert.type === 'error'" class="me-2 fas fa-times  fa-lg"></i>
            <i v-if="alert.type === 'fatal'" class="me-2 fas fa-bomb fa-lg"></i>
          </button>
          <ul class="dropdown-menu">
            <li @click="setAlertType(alertCode, 'success')" class="px-2 dropdown-item cursor-pointer "><i class="me-2 fas fa-check-circle"></i>Succès</li>
            <li @click="setAlertType(alertCode, 'info')" class="px-2 dropdown-item cursor-pointer"><i class="me-2 fas fa-info-circle"></i>Information</li>
            <li @click="setAlertType(alertCode, 'warning')" class="px-2 dropdown-item cursor-pointer"><i class="me-2 fas fa-exclamation-triangle"></i>Avertissement</li>
            <li @click="setAlertType(alertCode, 'error')" class="px-2 dropdown-item cursor-pointer"><i class="me-2 fas fa-times"></i>Erreur</li>
            <li @click="setAlertType(alertCode, 'fatal')" class="px-2 dropdown-item cursor-pointer"><i class="me-2 fas fa-bomb"></i>Erreur fatale</li>
          </ul>
        </div>
      </div>
    </perfect-scrollbar>
    <NewAlertContext id="newalert" ref="newAlertContext" @addAlert="addAlert('success', $event)"/>
  </div>
</template>

<script>

import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import VueNotifications from 'vue-notifications';
import NewAlertContext from '../../contexts/NewAlertContext.vue';
import StyleEditor from '../../Utilities/StyleEditor.vue';
import CodeCompatibleBadge from '../../Utilities/CodeCompatibleBadge.vue';

export default {
  name: 'AlertesEditor',
  components: {
    NewAlertContext,
    StyleEditor,
    CodeCompatibleBadge,
    Multiselect,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    alreadyRegisteredConfig: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data()
  {
    return {
      searchQuery: '',
      loaded: false,
      serverMessages: [],
    };
  },
  mounted() {
    this.$store.dispatch('getMessagesFromBase').then((messages) => {
      this.serverMessages = messages;
      this.loaded = true;
    });
  },
  computed: {
    displayedAlerts()
    {
      if (!this.searchQuery || this.searchQuery === '')
        return this.alerts;
      const out = {};
      Object.entries(this.alerts).forEach(([key, value]) => {
        if (key.includes(this.searchQuery) ||
          (value.content && value.content.includes(this.searchQuery)) ||
          (value.type && value.type.includes(this.searchQuery)))
          out[key] = value;
      });
      return out;
    },
    alerts: {
      get()
      {
        return this.value || {};
      },
      set(newAlerts)
      {
        this.$emit('change', newAlerts);
      },
    },
  },
  methods: {
    customLabel({ name, type }) {
      return `[${type}] ${name}`;
    },
    addMessages(event) {
      const out = [...this.alreadyRegisteredConfig.messages || []];
      out.push(event);
      this.$emit('alreadyRegisteredConfigChange', { ...this.alreadyRegisteredConfig, messages: out });
    },
    removeMessages(message) {
      const out = [...this.alreadyRegisteredConfig.messages || []];
      const index = out.indexOf(message);
      if (index < 0)
        return;
      out.splice(index, 1);
      this.$emit('alreadyRegisteredConfigChange', { ...this.alreadyRegisteredConfig, messages: out });
    },
    importAlerts(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object)
          {
            this.alerts = object;
            this.importAlertsNotif();
          }
        } catch (ex)
        {
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
    exportAlerts()
    {
      const jsonData = JSON.stringify(this.alerts || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = 'alerts.json';
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    addAlert(type, code)
    {
      const alerts = _.cloneDeep(this.value || { });
      alerts[code] = { type, content: 'Le texte de votre alerte' };
      this.alerts = alerts;
    },
    setAlertType(alertCode, newType)
    {
      if (!this.alerts[alertCode])
        return;
      const alerts = _.cloneDeep(this.value);
      alerts[alertCode].type = newType;
      this.alerts = alerts;
    },
    setAlertContent(alertCode, newTitle)
    {
      if (!this.alerts[alertCode])
        return;
      const alerts = _.cloneDeep(this.value);
      alerts[alertCode].content = newTitle;
      this.alerts = alerts;
    },
    TypeButtonClass(type)
    {
      switch (type)
      {
        case 'success': return 'btn-success';
        case 'info': return 'btn-info';
        case 'warning': return 'btn-primary';
        case 'error': return 'btn-danger';
        case 'fatal': return 'btn-danger';
        default: return 'btn-primary';
      }
    },
  },
  notifications: {
    importAlertsNotif: {
      type: VueNotifications.types.success,
      title: 'Alertes importé !',
      message: 'Les alertes ont été importé avec succès.',
    },
  },
};

</script>

<style scoped>

</style>
