<template>
  <div class="d-flex flex-column">
    <ul class="nav nav-tabs nav-justified flex-shrink-1">
      <li v-for="(tab, tabIdx) in tabs" :id="tabIdx" :key="tabIdx" class="nav-item">
        <a :class="(activeTab === tabIdx) ? 'active' : ''" class="nav-link cursor-pointer" :aria-current="`tab-${tabIdx}`" style="color: black;" @click="setActiveTab(tabIdx)" v-html="tab"></a>
      </li>
    </ul>
    <div class="position-relative flex-fill tab-content" style="overflow-y: hidden">
      <template v-if="animation">
        <transition  name="slide-fade" v-for="(tab, tabIdx) in tabs" :key="tabIdx">
          <slot v-if="activeTab === tabIdx" :name="`slot-${tabIdx}`">
          </slot>
        </transition>
      </template>
      <template v-else v-for="(tab, tabIdx) in tabs">
        <slot v-if="activeTab === tabIdx" :name="`slot-${tabIdx}`">
        </slot>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    animation: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data()
  {
    return {
      activeTab: 0,
    };
  },
  mounted()
  {
    this.$emit('tabChanged', 0);
  },
  methods: {
    setActiveTab(tabIdx)
    {
      this.activeTab = tabIdx;
      this.$emit('tabChanged', tabIdx);
    },
  },
};

</script>

<style>

.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-enter {
  transform: translateX(-100%);
}

</style>
