<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h5 v-if="hiddenFields.length <= 0" class="text-muted small text-center">{{ dictionary.formModule.noHiddenInputMessage }}</h5>
        <draggable v-model="hiddenFields" :group="{ name: 'formHiddenFields' }" tag="ul" class="list-group" handle=".hiddenFieldsDragHandle">
          <li class="list-group-item" v-for="(item, itemIdx) in hiddenFields" :key="`hiddenFields-${itemIdx}`">
            <div class="row">
              <div class="col-auto d-flex align-items-center">
                <i class="fas fa-grip-lines text-dark hiddenFieldsDragHandle cursor-grab"></i>
              </div>
              <div class="col pe-0">
                <div class="input-group mb-1">
                  <span class="input-group-text"><i class="fas fa-tags"></i></span>
                  <EventDataSelect :value="getDataName(item)" @change="setFieldData(itemIdx, $event)"/>
                  <span class="input-group-text"><i class="fas fa-database"></i></span>
                  <input v-if="getDataValueType(getDataName(item) || '') === 'input'" @change="setFieldValue(itemIdx, $event.target.value)" :value="item.value" type="text" class="form-control" :placeholder="dictionary.empty">
                  <select v-else class="form-select" id="filterValue" @change="setFieldValue(itemIdx, $event.target.value)" :value="item.value || ''">
                    <option value="" selected>{{ dictionary.empty }}</option>
                    <option v-for="(ch, chIdx ) in  getChoicesForData(getDataName(item) || '')" :key="`value-opt-${chIdx}`" :value="ch">{{ ch }}</option>
                  </select>
                </div>
              </div>
              <div class="col-auto d-flex align-items-center">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <button @click.prevent="removeField(itemIdx)" class="btn btn-danger text-center">
                    <i class="fas fa-trash text-light"></i>
                  </button>
                  <button  class="btn btn-info text-center" :data-bs-target="`#filterCollapse-${itemIdx}`" data-bs-toggle="collapse" aria-expanded="false" :aria-controls="`filterCollapse-${itemIdx}`">
                    <span v-if="item.filters && item.filters.length > 0" class="badge rounded-pill bg-primary me-2">{{ item.filters.length }}</span><i class="fas fa-filter text-light"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row collapse" :id="`filterCollapse-${itemIdx}`">
              <formFilterEditor :isHiddenField="true" :form-item="item" @change="setFieldFilters(itemIdx, $event)"/>
            </div>
          </li>
        </draggable>
      </div>
    </div>
    <div class="row justify-content-center my-3">
      <div class="col-auto"><button @click="addField" class="btn btn-success btn-raised text-light"><i class="fas fa-plus-circle me-2"></i>{{ dictionary.formModule.addInputButton }}</button></div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import EventDataSelect from '../Utilities/EventDataSelect.vue';
import FormFilterEditor from './FormFilterEditor.vue';

export default {
  name: 'HiddenFieldsEditor',
  components: {
    FormFilterEditor,
    draggable,
    EventDataSelect,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['eventData', 'dictionary']),
    hiddenFields: {
      get()
      {
        return this.value || [];
      },
      set(newFields)
      {
        this.$emit('change', newFields);
      },
    },
  },
  methods: {
    addField()
    {
      let id = 'hiddenField-';
      id += this.$chance.string(this.$newIDParams);
      this.hiddenFields = [...this.hiddenFields, { data: '', value: '', id }];
    },
    removeField(fieldIdx)
    {
      const fields = [...this.hiddenFields];
      fields.splice(fieldIdx, 1);
      this.hiddenFields = fields;
    },
    setFieldData(fieldIdx, newData)
    {
      const fields = [...this.hiddenFields];
      if (this.eventData[newData] !== undefined &&
          (this.eventData[newData].type === 'Workshops-Agendas' ||
          this.eventData[newData].type === 'Checkboxes'))
        fields[fieldIdx] = { ...fields[fieldIdx], data: `${newData}[]`, value: '' };
      else
        fields[fieldIdx] = { ...fields[fieldIdx], data: newData, value: '' };
      this.hiddenFields = [...fields];
    },
    setFieldValue(fieldIdx, newValue)
    {
      const fields = [...this.hiddenFields];
      fields[fieldIdx] = { ...fields[fieldIdx], value: newValue };
      this.hiddenFields = [...fields];
    },
    setFieldFilters(fieldIdx, item)
    {
      const fields = [...this.hiddenFields];
      fields[fieldIdx] = { ...fields[fieldIdx], filters: item.filters };
      this.hiddenFields = [...fields];
    },
    getDataName(item)
    {
      return item.data.replace(/\[]$/, '');
    },
    getDataValueType(dataID)
    {
      if (!dataID ||
          !this.eventData[dataID] ||
          !this.eventData[dataID].type)
        return 'input';
      switch (this.eventData[dataID].type)
      {
        case 'Radioboxes': return 'select';
        case 'List': return 'select';
        case 'Checkboxes': return 'select';
        case 'Workshops-Agendas': return 'select';
        default: return 'input';
      }
    },
    getChoicesForData(dataID)
    {
      if (this.getDataValueType(dataID) !== 'select')
        return [];
      return this.eventData[dataID].choices || [];
    },
  },
};

</script>

<style scoped>

</style>
