<template>
  <perfect-scrollbar class="h-100 pe-3" :options="{ wheelPropagation: false, suppressScrollX: true }">
    <div class="row pt-3 h-100">
      <div class="col-6">
        <card class="h-100">
          <template v-slot:header-left><p class="text-white mb-0">Utilisateur en base</p></template>
          <template>
            <div class="mb-3">
              <label>Status de l'utilisateur</label>
              <select class="form-select" v-model="privateStatus">
                <option value="Confirmed">Confirmed</option>
                <option value="ToBeTreated">A traiter</option>
                <option value="Invited">Invited</option>
                <option value="Waiting">Waiting</option>
                <option value="Denied">Denied</option>
                <option value="Deleted">Deleted</option>
              </select>
            </div>
            <div class="mb-3">
              <label>Status de l'accompagnant</label>
              <select class="form-select" v-model="privateAccStatus">
                <option value="Confirmed">Confirmed</option>
                <option value="ToBeTreated">A traiter</option>
                <option value="Invited">Invited</option>
                <option value="Waiting">Waiting</option>
                <option value="Denied">Denied</option>
                <option value="Deleted">Deleted</option>
              </select>
            </div>
          </template>
        </card>
      </div>
      <div class="col-6">
        <card class="h-100">
          <template v-slot:header-left><p class="text-white mb-0">Nouvel utilisateur</p></template>
          <template>
            <div class="mb-3">
              <label>Status de l'utilisateur</label>
              <select class="form-select" v-model="publicStatus">
                <option value="Confirmed">Confirmed</option>
                <option value="ToBeTreated">A traiter</option>
                <option value="Invited">Invited</option>
                <option value="Waiting">Waiting</option>
                <option value="Denied">Denied</option>
                <option value="Deleted">Deleted</option>
              </select>
            </div>
            <div class="mb-3">
              <label>Status de l'accompagnant</label>
              <select class="form-select" v-model="publicAccStatus">
                <option value="Confirmed">Confirmed</option>
                <option value="ToBeTreated">A traiter</option>
                <option value="Invited">Invited</option>
                <option value="Waiting">Waiting</option>
                <option value="Denied">Denied</option>
                <option value="Deleted">Deleted</option>
              </select>
            </div>
          </template>
        </card>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>

import { mapGetters } from 'vuex';
import Card from '../../Utilities/Card.vue';

export default {
  name: 'StatusConfig',
  components: {
    Card,
  },
  model: {
    prop: 'Status',
    event: 'change',
  },
  props: {
    Status: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    privateStatus: {
      get()
      {
        return this.Status.privateStatus || '';
      },
      set(newStatus)
      {
        this.$emit('change', { ...this.Status, privateStatus: newStatus });
      },
    },
    privateAccStatus: {
      get()
      {
        return this.Status.privateAccStatus || '';
      },
      set(newAccStatus)
      {
        this.$emit('change', { ...this.Status, privateAccStatus: newAccStatus });
      },
    },
    publicStatus: {
      get()
      {
        return this.Status.publicStatus || '';
      },
      set(newStatus)
      {
        this.$emit('change', { ...this.Status, publicStatus: newStatus });
      },
    },
    publicAccStatus: {
      get()
      {
        return this.Status.publicAccStatus || '';
      },
      set(newAccStatus)
      {
        this.$emit('change', { ...this.Status, publicAccStatus: newAccStatus });
      },
    },
  },
};
</script>

<style scoped>

label {
  font-size: 18px;
}

i {
  font-size: 20px;
}

.status-icon.fa-check-circle {
  color: #66BB6A !important;
}

.status-icon.fa-times-circle {
  color: #ff0000 !important;
}

.status-icon.fa-trash {
  color: #546E7A !important
}

.status-icon.fa-clock {
  color: #0277bd !important;
}

.status-icon.fa-spinner {
  color: rgb(249, 168, 37) !important;
}

.list-group-item {
  cursor: pointer;
}

</style>
