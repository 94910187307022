<template>
  <div class="h-100 d-flex flex-column" v-if="loaded" style="overflow-y: hidden;">
      <perfect-scrollbar class="flex-grow-1" :options="{ wheelPropagation: false, suppressScrollX: true }">
        <div class="row mx-0 mb-2" v-for="(type, domain) in domains" :key="domain">
          <div class="col px-0">
            <div class="input-group">
              <span class="input-group-text">
                <i v-if="type === 'domain'" class="fas fa-tags"/>
                <i v-else-if="type === 'url'" class="fas fa-globe-europe"/>
              </span>
              <input disabled :value="domain" type="text" class="form-control" placeholder="">
            </div>
          </div>
          <div class="col-auto">
            <div class="btn-group" role="group" aria-label="Basic example">
              <a :href="type === 'domain' ? `https://register.captag.events/${domain}` : `https://${domain}`" target="_blank" v-tooltip="'Acceder au site'" class="btn btn-info btn-raised text-white"><i class="fas fa-external-link-alt"></i></a>
              <button v-if="preferredUrl === 'domain' || preferredUrl.includes(domain)" @click="setPreferredUrl(type, domain)" v-tooltip="'C\'est votre site par defaut !'" class="btn btn-success btn-raised text-white"><i class="fas fa-check-circle"></i></button>
              <button v-else @click="setPreferredUrl(type, domain)" v-tooltip="'Definir par defaut'" class="btn btn-outline-secondary btn-raised"><i class="far fa-circle"></i></button>
              <button @click="removeDomain(type, domain)" v-tooltip="'Supprimer le domaine'" class="btn btn-danger btn-raised text-light"><i class="fas fa-trash"></i></button>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      <div v-if="loaded" class="row justify-content-end flex-shrink-1 mx-1">
        <div class="col px-0">
          <div class="input-group mb-1">
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <span v-if="newDomainMode === 'domain'"><i  class="fas fa-tags me-2"></i> {{ dictionary.propertiesModal.subDomainButtonText }}</span>
              <span v-else-if="newDomainMode === 'url'"><i class="fas fa-globe-europe me-2"></i> {{ dictionary.propertiesModal.customDomainButtonText }}</span>
              <i v-else class="fas fa-question"></i>
            </button>
            <ul class="dropdown-menu">
              <li @click.prevent="newDomainMode = 'domain'"><a class="dropdown-item" href="#"><i class="fas fa-tags me-2"></i> {{ dictionary.propertiesModal.subDomainButtonText }}</a></li>
              <li @click.prevent="newDomainMode = 'url'"><a class="dropdown-item" href="#"><i class="fas fa-globe-europe me-2"></i> {{ dictionary.propertiesModal.customDomainButtonText }}</a></li>
            </ul>
            <input aria-describedby="domainInputHelp" v-model="newDomain" type="text" class="form-control" :class="domainUsedError ? 'is-invalid' : ''" :placeholder="newDomainMode === 'domain' ? dictionary.propertiesModal.domainInputPlaceholder : 'https:// ...'">
          </div>
        </div>
        <div class="col-auto">
          <button @click="addDomain(newDomain)" v-tooltip="dictionary.propertiesModal.addDomainTooltip" class="btn btn-success btn-raised text-white"><i class="fas fa-plus-circle me-2"></i>{{ dictionary.add }}</button>
        </div>
        <div v-if="domainUsedError" class="col-12 text-start">
          <div id="domainInputHelp" class="form-text text-danger small">Ce{{ newDomainMode === 'domain' ? ' domaine' : 'tte url' }} est déjà utilisée</div>
        </div>
      </div>
  </div>
  <div v-else class="col-12 text-center mt-5">
    <h5 class="text-muted">{{ dictionary.propertiesModal.domainsLoadingMessage }}</h5>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import VueNotifications from 'vue-notifications';
import Vue from 'vue';

export default {
  name: 'DomainEditor',
  data()
  {
    return {
      loaded: false,
      domains: {},
      newDomain: '',
      newDomainMode: 'domain',
      domainUsedError: false,
    };
  },
  mounted()
  {
    this.loadDomains();
  },
  computed: {
    ...mapGetters(['dictionary', 'path', 'stringToSlug', 'preferredUrl']),
  },
  methods: {
    setPreferredUrl(type, domain)
    {
      if (type === 'domain')
        this.$store.dispatch('setPreferredUrl', { url: `https://register.captag.events/${domain}` });
      else
        this.$store.dispatch('setPreferredUrl', { url: `https://${domain}` });
    },
    loadDomains()
    {
      this.loaded = false;
      this.$store.dispatch('getDomains').then((val) => {
        this.domains = val;
        this.loaded = true;
      });
    },
    removeDomain(type, domain)
    {
      const sluggedDomain = (type === 'url') ? this.stringToSlug(domain) : domain;
      this.$store.dispatch('removeDomain', { domain: sluggedDomain }).then(() => {
        this.loadDomains();
        this.removeDomainNotif();
      });
    },
    async addDomain(newDomain)
    {
      if (!this.newDomain)
        return;
      this.domainUsedError = false;
      const exist = await this.$store.dispatch('isDomainUsed', { domain: newDomain });
      if (exist)
      {
        this.domainUsedError = true;
        return;
      }
      if (this.newDomainMode === 'domain')
      {
        const sluged = this.stringToSlug(newDomain.toString());
        this.$store.dispatch('addDomain', { domain: newDomain }).then(() => {
          Vue.set(this.domains, sluged, this.path);
          this.newDomain = '';
        }).then(() => {
          this.loadDomains();
          this.setPreferredUrl('domain', sluged);
        });
        this.newDomainNotif();
      }
      else if (this.newDomainMode === 'url')
      {
        newDomain = newDomain.replace(/(^\w+:|^)\/\//, '');// Removing https:// at the beginning
        newDomain = newDomain.replace(/\/$/, '');// Removing / at the end
        newDomain = newDomain.trim();
        this.$store.dispatch('addDomain', { domain: newDomain }).then(() => {
          const domainSlug = this.stringToSlug(newDomain.toString());
          Vue.set(this.domains, domainSlug, this.path);
          this.$store.dispatch('addCustomUrl', { domain: domainSlug, url: newDomain });
          this.newDomain = '';
        }).then(() => {
          this.loadDomains();
          this.setPreferredUrl('url', newDomain);
        });
        this.newDomainNotif();
      }
    },
  },
  notifications: {
    newDomainNotif: {
      type: VueNotifications.types.success,
      title: 'Domaine ajouté !',
      message: 'Votre domain a été ajouté avec succès !',
    },
    removeDomainNotif: {
      type: VueNotifications.types.error,
      title: 'Domaine supprimé !',
      message: 'Le domain a été supprimé !',
    },
  },
};

</script>

<style scoped>

</style>
