import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire';
import mutation from './mutations/mutations';
import actions from './actions/actions';
import state from './state';
import getters from './getters';

Vue.use(Vuex);

/**
 * Program Store Object
 * @type {Store<unknown>}
 */
const vuex = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state,
  mutations: {
    ...mutation,
    ...vuexfireMutations,
  },
  actions,
  getters,
});

export default vuex;
