<template>
  <div @click.stop="onClick" :id="column.id" :class="computedClasses" class="px-0 mt-2">
    <div class="row position-relative p-0 m-0 h-100 w-100">
      <div class="position-absolute top-0 start-0 px-0">
        <p class="ps-2 pt-2 m-0 text-muted small">{{ column.id }}</p>
      </div>
      <div class="row position-absolute top-0 end-0 w-auto">
        <div class="col-auto"><i v-tooltip="'Alignement'" :class="alignmentIcon" class="fas text-success"></i></div>
        <div v-if="hasCustomClasses" class="col-auto" v-tooltip="'Classes custom'"><i class="fab fa-css3-alt text-primary"></i></div>
        <div v-if="hasFilter" class="col-auto" v-tooltip="'Filtrage'"><i class="fas fa-filter text-info"></i></div>
      </div>
      <div class="row h-100 position-absolute top-0 start-0">
        <div class="col-auto mx-auto my-auto"><i :class="typeIconClass" class="fa-2x"></i></div>
      </div>
      <ColumnOverlay
          v-if="isSelected"
          :row="Row"
          :column="column"
          style="left: 0;top: 0;"
          class="w-100 h-100 position-absolute"/>
      <template v-if="!isEmpty">
        <TextEditorModal
            v-if="contentType === 'text'"
            v-model="colHtmlContent"
            :title="`Editeur de texte <span class='ms-3 text-muted'>${column.id}</span>`"
            ref="textEditorModal"/>
        <NavbarBuilderModal
            v-if="contentType === 'navbar-v2'"
            :title="`${dictionary.navbarEditor} <span class='ms-3 text-muted'>${column.id}</span>`"
            ref="navbarEditorModal"
            v-model="colNavbarContent"/>
        <CustomCodeEditorModal
            v-if="contentType === 'code'"
            v-model="colHtmlContent"
            ref="codeEditorModal"
            id="codeEditorModal"
            :preview="true"
            :title="`Code de la colonne <span class='ms-3 text-muted'>${column.id}</span>`"
            mode="text/html"/>
        <FormBuilderModal
            v-if="contentType === 'form'"
            v-model="colFormContent"
            :title="`Editeur de formulaire <span class='ms-3 text-muted'>${column.id}</span>`"
            ref="formBuilderModal"/>
        <CarouselModal
            v-if="contentType === 'carousel'"
            :title="`${dictionary.carouselModule.editorModalTitle} <span class='ms-3 text-muted'>${column.id}</span>`"
            v-model="colCarouselContent"
            ref="carouselEditorModal" />
        <MappedImageModal
            v-if="contentType === 'mappedImage'"
            :title="`<i class='fas fa-vr-cardboard me-3'></i>Editeur d'image Dynamique <span class='ms-3 text-muted'>${column.id}</span>`"
            v-model="colMappedImageContent"
            ref="mappedImageModal" />
      </template>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ColumnOverlay from '../Overlays/ColumnOverlay.vue';
import CustomCodeEditorModal from '../Modals/CustomCodeEditorModal.vue';
import FormBuilderModal from '../Modals/FormBuilderModal.vue';
import MappedImageModal from '../Modals/MappedImageModal/MappedImageModal.vue';
import CarouselModal from '../Modals/CarouselModal.vue';
import NavbarBuilderModal from '../Modals/NavbarBuilderModal.vue';
import TextEditorModal from '../Modals/TextEditorModal.vue';

export default {
  name: 'AdvancedColumn',
  components: {
    TextEditorModal,
    NavbarBuilderModal,
    CarouselModal,
    MappedImageModal,
    FormBuilderModal,
    CustomCodeEditorModal,
    ColumnOverlay,
  },
  props: {
    column: {
      type: Object,
      required: true,
    },
    Row: {
      type: Object,
      required: true,
    },
    isDndPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clicks: 0,
    };
  },
  mounted() {
    this.$EventBus.$on('editColumn', this.onEdit);
  },
  beforeDestroy() {
    this.$EventBus.$off('editColumn', this.onEdit);
  },
  computed: {
    ...mapGetters(['selectedColID', 'dictionary', 'isColSelectedByUser']),
    isSelected() {
      return this.selectedColID === this.column.id;
    },
    computedClasses()
    {
      let out = '';
      out += `${(!this.column.layout) ? 'col-auto' : `col-md-${this.column.layout}`}`;
      if (this.column.value)
      {
        const align = this.column.values.alignment || undefined;
        if (align && align !== 'default')
          out += ` ${align}`;
      }
      out += ' position-relative preview-column';
      if (this.isSelected)
        out += ' selected';
      return out;
    },
    contentType()
    {
      return (this.isEmpty) ? '' : this.column.content.type;
    },
    alignmentIcon()
    {
      if (!this.column.values || !this.column.values.alignment)
        return 'd-none';
      switch (this.column.values.alignment)
      {
        case 'text-start': return 'fa-align-left';
        case 'text-center': return 'fa-align-center';
        case 'text-end': return 'fa-align-right';
        default: return 'd-none';
      }
    },
    hasFilter()
    {
      return this.column.filter !== undefined && this.column.filter !== '';
    },
    hasCustomClasses()
    {
      return (this.column.values) ? this.column.values.customClasses || false : false;
    },
    isEmpty()
    {
      if (this.column.content === undefined)
        return true;
      return this.column.content.type === undefined;
    },
    colHtmlContent: {
      get() {
        return (!this.isEmpty) ? this.column.content.html : '';
      },
      set(newValue) {
        this.$store.commit('setColumnContent', { column: this.column, content: { ...this.column.content, html: newValue }, dbSync: true });
      },
    },
    colFormContent: {
      get() {
        return (!this.isEmpty) ? this.column.content : {};
      },
      set(newContent) {
        this.$store.commit('setColumnContent', { column: this.column, content: newContent, dbSync: true });
      },
    },
    colNavbarContent: {
      get() {
        return (!this.isEmpty) ? this.column.content : {};
      },
      set(newContent) {
        this.$store.commit('setColumnContent', { column: this.column, content: newContent, dbSync: true });
      },
    },
    colCarouselContent: {
      get() {
        return (!this.isEmpty) ? this.column.content : {};
      },
      set(newContent) {
        this.$store.commit('setColumnContent', { column: this.column, content: newContent, dbSync: true });
      },
    },
    colMappedImageContent: {
      get() {
        return (!this.isEmpty) ? this.column.content : {};
      },
      set(newContent) {
        this.$store.commit('setColumnContent', { column: this.column, content: newContent, dbSync: true });
      },
    },
    typeIconClass()
    {
      switch (this.contentType)
      {
        case 'navbar-v2': return 'fas fa-bars';
        case 'text': return 'fas fa-font';
        case 'media': return 'fas fa-photo-video';
        case 'form': return 'fab fa-wpforms';
        case 'clock': return 'fas fa-clock';
        case 'separator': return 'fas fa-arrows-alt-v';
        case 'code': return 'fas fa-code';
        case 'carousel': return 'fas fa-horse';
        case 'legalInformations': return 'fas fa-balance-scale';
        case 'button': return 'far fa-square';
        case 'mappedImage': return 'fas fa-vr-cardboard';
        case 'payment': return 'fas fa-dollar-sign';
        default: return '';
      }
    },
  },
  methods: {
    onClick(event)
    {
      if (!this.isSelected)
        this.$store.commit('setSelectedColumnID', { selectedCol: this.column.id, dbSync: true });
      this.clicks = this.clicks + 1;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.clicks = 0;
        }, 500);
      } else {
        clearTimeout(this.timer);
        if (!this.deploymentMode && this.isSelected)
        {
          this.onEdit(this.column);
        }
        this.clicks = 0;
      }
    },
    onEdit(col)
    {
      const editedCol = col || this.column;
      if (!editedCol.content || !this.isSelected)
        return;
      switch (editedCol.content.type)
      {
        case 'text':
          this.$refs.textEditorModal.open();
          break;
        case 'code':
          this.$refs.codeEditorModal.open();
          break;
        case 'form':
          this.$refs.formBuilderModal.open();
          break;
        case 'navbar':
          this.$refs.navbarEditorModal.open();
          break;
        case 'navbar-v2':
          this.$refs.navbarEditorModal.open();
          break;
        case 'carousel':
          this.$refs.carouselEditorModal.open();
          break;
        case 'mappedImage':
          this.$refs.mappedImageModal.open();
          break;
        default: break;
      }
    },
  },
};

</script>

<style scoped>

.preview-column {
    border: 2px dashed var(--bs-gray);
    height: 100px;
}
.preview-column.selected {
    border: unset;
}

</style>
