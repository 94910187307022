<template>
  <StyleEditor v-model="style" :properties="['hr', 'fontWeight', 'fontFamily', 'fontSize', 'color', 'hr', 'backgroundColor', 'spacing', 'border', 'borderRadius']">
    <template v-slot:form-start>
      <div class="mb-3">
        <label>Text du bouton</label>
        <input class="form-control" :value="content.text" @change="$emit('change', { ...content, text: $event.target.value })">
      </div>
      <ActionSelector v-model="action" :availableActions="['url', 'row', 'page', 'filter', 'modal']"/>
    </template>
  </StyleEditor>
</template>

<script>

import { mapGetters } from 'vuex';
import StyleEditor from '../../../../Utilities/StyleEditor.vue';
import ActionSelector from '../../../../Utilities/ActionSelector.vue';

export default {
  name: 'ButtonConfig',
  components: { ActionSelector, StyleEditor },
  model: {
    prop: 'content',
    event: 'change',
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    action: {
      get()
      {
        return this.content || {};
      },
      set(newActionObj)
      {
        this.$emit('change', { ...this.content, ...newActionObj });
      },
    },
    style: {
      get()
      {
        return this.content;
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.content, ...newStyle });
      },
    },
  },
};

</script>

<style scoped>

</style>
