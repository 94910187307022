<template>
  <div class="row d-flex align-items-center">
    <div class="col-auto">
      <div class="btn-group" role="group" aria-label="Basic mixed styles example">
        <button @click="$refs.bugReportModal.open()" type="button" class="btn btn-outline-danger"><i class="fas fa-bug"></i></button>
      </div>
    </div>
    <BugReportModal ref="bugReportModal" :title="this.dictionary.bugReport" id="BugReportModal"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import BugReportModal from '../../Modals/BugReportModal.vue';

export default {
  name: 'HelpWidget',
  components: {
    BugReportModal,
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
};

</script>

<style scoped>

</style>
