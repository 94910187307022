import _ from 'lodash';
import Vue from 'vue';
import getters from '../getters';

const rowMutations = {
  /**
   * Remove a row from the website content
   * @param state
   * @param pageID: the id of the page containing the row to remove
   * @param row: the row to remove (the whole object)
   */
  removeRow(state, { row })
  {
    function removeRowFromList(list)
    {
      if (list.indexOf(row) < 0)
        return false;
      list.splice(list.indexOf(row), 1);
      return true;
    }
    if (state.websiteContent.pages[getters.currentPage(state, getters)].rows)
      if (removeRowFromList(state.websiteContent.pages[getters.currentPage(state, getters)].rows))
        return;
    if (state.websiteContent.header && state.websiteContent.header.rows)
      if (removeRowFromList(state.websiteContent.header.rows))
        return;
    if (state.websiteContent.footer && state.websiteContent.footer.rows)
      removeRowFromList(state.websiteContent.footer.rows);
  },
  /**
   * set columns for a specific row
   * @param state
   * @param pageID: the id of the page containing the row to edit
   * @param cols: the columns set to the row
   * @param row: thw row you want to edit
   */
  updateColumnsForRow(state, { pageID, cols, row })
  {
    Vue.set(row, 'columns', cols);
  },
  /**
   * update website content row list with thw given list
   * @param state
   * @param pageID: the id of the page containing the row to set
   * @param rowList: the rows to set
   */
  updateRowList(state, { pageID, rowList })
  {
    if (state.websiteContent.pages[pageID])
      state.websiteContent.pages[pageID].rows = rowList;
  },
  updateHeaderRows(state, { rowList })
  {
    if (!state.websiteContent.header)
      Vue.set(state.websiteContent, 'header', {});
    Vue.set(state.websiteContent.header, 'rows', rowList);
  },
  updateFooterRows(state, { rowList })
  {
    if (!state.websiteContent.footer)
      Vue.set(state.websiteContent, 'footer', {});
    Vue.set(state.websiteContent.footer, 'rows', rowList);
  },
  swapTwoRows(state, { rowIdx1, rowIdx2 })
  {
    const pageID = getters.currentPage(state, getters);
    if (state.websiteContent.pages[pageID] && state.websiteContent.pages[pageID].rows[rowIdx1] && state.websiteContent.pages[pageID].rows[rowIdx2])
    {
      const tmp1 = _.cloneDeep(state.websiteContent.pages[pageID].rows[rowIdx1]);
      const tmp2 = _.cloneDeep(state.websiteContent.pages[pageID].rows[rowIdx2]);
      Vue.set(state.websiteContent.pages[pageID].rows, rowIdx1, tmp2);
      Vue.set(state.websiteContent.pages[pageID].rows, rowIdx2, tmp1);
    }
  },
  /**
   * Set the ID of a specific row
   * @param state
   * @param row: thw row you want to edit the ID
   * @param id: the ID to set
   */
  setRowID(state, { row, id })
  {
    row.id = id;
  },
  /**
   * Set a specific row Values (aka. style)
   * @param state
   * @param values: the value object
   * @param row: thw row you want to edit the value
   */
  setRowValues(state, { row, values })
  {
    Vue.set(row, 'values', values);
  },
  /**
   * set row animations
   * @param state
   * @param animations: the new animations parameters
   * @param row:  the row to edit the animations
   */
  setRowAnimations(state, { animations, row })
  {
    row.animations = animations;
  },
  /**
   * Apply a filter to a row
   * @param state
   * @param filter: the ID of the filter you want to apply
   * @param row: the row you want to set a filter
   */
  setRowFilter(state, { filter, row })
  {
    row.filter = filter;
  },
};

export default rowMutations;
