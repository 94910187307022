<template>
  <div class="h-100 row">
    <div class="col-12 h-100 text-center" v-if="mode === 'start'">
      <div class="row h-25 justify-content-center d-flex align-items-center">
        <div class="col-12 text-center text-secondary">
          <h1><i class="text-primary fas fa-hammer me-3"></i>{{ dictionary.pageTitle }}</h1>
        </div>
      </div>
      <div class="row mb-4 justify-content-center">
        <div class="col-6 text-center text-secondary">
          <label for="domain" class="form-label"></label>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon3">https://register.captag.events/</span>
            <input v-model="domain" type="text" class="form-control" id="domain" aria-describedby="basic-addon3" :placeholder="animID">
          </div>
        </div>
      </div>
      <div class="row h-25 justify-content-center d-flex align-items-center">
        <div
            :class="(selected === 'blank') ? 'formTypeSelected' : ''"
            @click="formCardClick('blank')"
            class="col-3 h-100 me-3 py-5 formTypeCard formTypeCardFull d-flex align-items-center justify-content-center">
          <div class="row text-center">
            <div class="col-12">
              <h1 class="formPicto fas fa-industry"></h1>
            </div>
            <div class="col-12">
              <h6 class="fw-bold">{{ dictionary.websiteWizard.emptySiteLabel }}</h6>
            </div>
          </div>
        </div>
        <div
            :class="(selected === 'template') ? 'formTypeSelected' : ''"
            @click="formCardClick('template')"
            class="col-3 h-100 mx-3 py-5 formTypeCard formTypeCardFull d-flex align-items-center justify-content-center">
          <div class="row text-center">
            <div class="col-12">
              <h1 class="formPicto fas fa-box-open"></h1>
            </div>
            <div class="col-12">
              <h6 class="fw-bold">{{ dictionary.websiteWizard.templateLabel }}</h6>
            </div>
          </div>
        </div>
        <div
            :class="(selected === 'import') ? 'formTypeSelected' : ''"
            @click="formCardClick('import')"
            class="col-3 h-100 ms-3 py-5 formTypeCard formTypeCardOutline d-flex align-items-center justify-content-center">
          <div class="row text-center">
            <div class="col-12">
              <h1 class="formPicto fas fa-file-import"></h1>
            </div>
            <div class="col-12">
              <h6 class="fw-bold">{{ dictionary.websiteWizard.importSiteLabel }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row h-25 justify-content-center d-flex align-items-center">
        <div class="col-12 text-center" v-if="mode === 'start'">
          <button @click="validateClick" :disabled="selected === ''" :class="selected === '' ? 'btn-outline-dark' : ' text-light btn-success'" class="btn btn-lg">{{ dictionary.continue.toUpperCase() }}</button>
        </div>
        <div class="col-12">
          <input accept="application/JSON" ref="importHiddenInput" type="file" @change="readImportData" style="visibility: hidden">
        </div>
      </div>
    </div>
    <div v-if="mode === 'templates'" class="col-12 h-100">
      <TemplateBrowser @cancel="mode = 'start'; selected = ''" @import="importWebsite"/>
    </div>
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import TemplateBrowser from './TemplateBrowser.vue';
import BlankDefaultContent from './blank.json';

export default {
  name: 'NewWebsiteWizzard',
  components: { TemplateBrowser },
  data() {
    return {
      selected: '',
      mode: 'start',
      domain: '',
    };
  },
  computed: {
    ...mapGetters(['dictionary', 'animID']),
  },
  methods: {
    importWebsite(content)
    {
      if (content.pages)
        this.$store.commit('setAllPages', { pages: content.pages, dbSync: false });
      else if (content.rows)
      {
        // We are trying to import an old website as a page featured website
        console.log('Converting old website into a page featured website');
        const ret = {};
        this.$store.commit('addPage', {
          pageName: 'home',
          content: { rows: content.rows },
          newID: ret,
          dbSync: true,
        });
        if (ret.id)
          this.$store.commit('setDefaultPage', { pageID: ret.id, dbSync: true });
      }
      if (content.header)
        this.$store.commit('setHeaderConfig', { config: content.header, dbSync: false });
      if (content.footer)
        this.$store.commit('setFooterConfig', { config: content.footer, dbSync: false });
      if (content.body)
        this.$store.commit('setBodyConfig', { config: content.body, dbSync: false });
      if (content.customCSS)
        this.$store.commit('setCustomCSS', { value: content.customCSS, dbSync: false });
      if (content.customJS)
        this.$store.commit('setCustomJS', { value: content.customJS, dbSync: false });
      if (content.filters)
        this.$store.commit('setWebsiteFilters', { filters: content.filters, dbSync: false });
      if (content.modals)
        this.$store.commit('setModalsList', { modals: content.modals, dbSync: false });
      if (content.colorPalette)
        content.colorPalette.forEach((col) => this.$store.commit('addColorToPalette', { color: col, dbSync: false }));
      if (content.googleFonts)
      {
        Object.values(content.googleFonts).forEach((font) => {
          this.$store.commit('addGoogleFont', { fontObject: font, dbSync: false });
        });
      }
      this.$store.commit('setWebsiteContentOrigin', { origin: this.selected, dbSync: true });
      if (!this.domain)
        this.domain = this.animID;
      this.$store.dispatch('addDomain', { domain: this.domain }).then(() => {
        this.$store.dispatch('setPreferredUrl', { url: `https://register.captag.events/${this.domain}` });
        this.websiteImported();
      });
    },
    formCardClick(selected)
    {
      this.selected = selected;
    },
    validateClick()
    {
      this.$store.dispatch('getWebsiteSettings').then((settings) => {
        settings.accompagnants = true;
        settings.quotas = true;
        this.$store.dispatch('setWebsiteSettings', { settings });
      });
      if (this.selected === 'blank')
      {
        if (!this.domain)
          this.domain = this.animID;
        this.$store.commit('setAllPages', { pages: BlankDefaultContent.pages, dbSync: true });
        this.$store.commit('setWebsiteContentOrigin', { origin: this.selected, dbSync: true });
        this.$store.commit('setWebsiteFilters', { filters: BlankDefaultContent.filters, dbSync: true });
        this.$store.commit('setModalsList', { modals: BlankDefaultContent.modals, dbSync: true });
        this.$store.commit('setCustomCSS', { value: BlankDefaultContent.customCSS, dbSync: false });
        this.$store.dispatch('addDomain', { domain: this.domain }).then(() => {
          this.$store.dispatch('setPreferredUrl', { url: `https://register.captag.events/${this.domain}` });
          this.blankSiteCreated();
        });
      }
      else if (this.selected === 'template')
      {
        this.mode = 'templates';
      }
      else if (this.selected === 'import')
      {
        this.$refs.importHiddenInput.click();
      }
    },
    readImportData(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object)
            this.importWebsite(object);
        } catch (ex)
        {
          console.log(`Invalid website content file : ${ex.message}`);
          this.importError();
        }
      };
      reader.readAsText(file);
    },
  },
  notifications: {
    websiteImported: {
      type: VueNotifications.types.success,
      title: 'Site web importé !',
      message: 'Le template a été importé avec succès',
    },
    blankSiteCreated: {
      type: VueNotifications.types.success,
      title: 'Site web crée !',
      message: 'Amusez vous bien :)',
    },
    importError: {
      type: VueNotifications.types.error,
      title: 'Erreur lors de l\'import',
      message: 'Erreur de l\'import du site, vérifiez le site que vous essayez d\'importer.',
    },
  },
};

</script>

<style scoped>

.formTypeCard {
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

.formTypeCardFull {
  background-color: var(--bs-secondary);
  border: 2px solid var(--bs-secondary);
}

.formTypeCardOutline {
  border: 2px solid var(--bs-secondary);
  color: var(--bs-secondary)
}

.formTypeSelected {
  border: 2px solid var(--bs-primary);
  color: var(--bs-primary);
}

.formTypeCard:not([disabled]):hover {
  border: 2px solid var(--bs-primary);
  color: var(--bs-primary);
}

</style>
