<template>
  <div style="overflow-x: hidden;">
    <form @submit.stop.prevent="onSubmit">
      <div class="mb-3">
        <label class="small fw-bold" for="senderName">{{ dictionary.author }}</label>
        <input readonly v-model="commit.author" required id="senderName" type="text" class="form-control required readonly" :placeholder="workerData.name">
      </div>
      <div class="mb-3">
        <label class="small fw-bold " for="timestamp">{{ dictionary.deploymentModal.deployTabDate }}</label>
        <input :value="formatDate" disabled id="timestamp" type="text" class="form-control required">
      </div>
      <div class="mb-3">
        <label class="small fw-bold ">{{ dictionary.message }}</label>
        <textarea v-model="commit.message" name="message" type="text" class="form-control" placeholder="Pour garder une trace de vos modifications ..."></textarea>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <button v-if="!onDeployment" class="btn btn-raised text-light fw-bold btn-success" :disabled="!(commitUpdated && modalReady)" :class="commitUpdated && modalReady ? '' : 'disabled'"><i v-if="!(commitUpdated && modalReady)" class="fas fa-circle-notch fa-spin me-2"></i>{{ dictionary.deploy }}</button>
          <h3 class="text-muted mt-4 text-center" v-else><i class="fas fa-circle-notch fa-spin me-3"></i>Déploiement en cours</h3>
        </div>
      </div>
    </form>
    <template v-if="modalReady">
      <div class="invisible" v-for="(page, pageID) in websiteContent.pages" :key="`pageContent-${pageID}`">
        <WebsitePreview v-if="!page.disabled" :ref="`finalHTML-${pageID}`" :deploymentMode="true" :page="pageID"/>
      </div>
    </template>
  </div>
</template>

<script>

import crypto from 'crypto';
import VueNotifications from 'vue-notifications';
import { mapGetters, mapState } from 'vuex';
import { uuid } from 'vue-uuid';
import sizeof from 'object-sizeof';
import sanitizeHtml from 'sanitize-html';
import JavaScriptObfuscator from 'javascript-obfuscator';
import Vue from 'vue';
import WebsitePreview from '../../NormalModeContent/websitePreview.vue';

export default {
  name: 'NewDeployment',
  components: {
    WebsitePreview,
  },
  props: {
    modalReady: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data()
  {
    return {
      onDeployment: false,
      commitUpdated: false,
      commit: {
        id: '',
        author: '',
        message: '',
        content: '',
        date: 0,
        checksum: '',
        websiteContent: {},
      },
    };
  },
  mounted()
  {
    this.updateCommitData().then(() => {
      this.commitUpdated = true;
    });
  },
  computed: {
    ...mapGetters(['dictionary', 'workerData', 'customCSS', 'customJS', 'websiteFilters', 'isJSObfuscation', 'workerData']),
    ...mapState(['websiteContent']),
    formatDate()
    {
      return this.$formatDate(new Date());
    },
  },
  methods: {
    async updateCommitData()
    {
      this.commitUpdated = false;
      this.commit.date = Date.now();
      this.commit.author = this.workerData.name;
      this.commit.websiteContent = { ...this.websiteContent };
      this.commit.checksum = this.checksum(JSON.stringify(this.commit.websiteContent));
      const { quickRefs, filters, datas } = await this.generateFilterAndDataTable();
      this.commit.filters = { quickRefs, filters };
      this.commit.formDatas = datas;
    },
    checksum(data)
    {
      const options = {
        algorithm: 'sha1',
        encoding: 'hex',
      };
      const hash = crypto.createHash(options.algorithm);

      if (!hash.write)
        hash.update(data);// pre-streaming crypto API in node < v0.9
      else
        hash.write(data);
      return hash.digest(options.encoding);
    },
    async generateFilterAndDataTable()
    {
      const quickRefs = {};
      const filters = {};
      const datas = {};
      function parseFields(fields, isAcc = false, depth = 0)
      {
        fields.forEach((field) => {
          if (field.hidden)
            return;
          if (field.id && field.filters)
          {
            filters[field.id] = field.filters;
            field.filters.forEach((condition) => {
              if (condition.data)
              {
                const dataName = isAcc ? `${condition.data}_acc_` : condition.data;
                quickRefs[dataName] = (!quickRefs[dataName]) ? [] : quickRefs[dataName];
                if (!quickRefs[dataName].includes(field.id))
                  quickRefs[dataName].push(field.id);
              }
            });
          }
          if (field.data)
          {
            if (field.format === 'password') {
              datas[field.data.replace('[]', '')] = 'protected';
            } else if (field.filters)
              datas[field.data.replace('[]', '')] = 'under-conditions';
            else
            {
              datas[field.data.replace('[]', '')] = field.readonly ? 'readonly' : 'editable';
              if (field.required)
                datas[field.data.replace('[]', '')] = datas[field.data.replace('[]', '')] + '-required';
            }
          }
          if (field.filters)
          {
            field.filters.forEach((condition) => {
              if (condition.data)
              {
                if (condition.data && !datas[condition.data])
                {
                  datas[condition.data] = 'readonly';
                }
              }
            });
          }
          if (field.innerItems)
            parseFields(field.innerItems, field.accMode || isAcc, depth + 1);
        });
      }
      Object.values(this.websiteFilters).forEach((filter) => {
        if (!filter.conditions)
          return;
        filter.conditions.forEach((cond) => {
          if (cond.data && !datas[cond.data.replace('[]', '')])
          {
            datas[cond.data.replace('[]', '')] = 'readonly';
          }
        });
      });
      Object.entries(this.websiteContent.pages).forEach(([pageID, page]) => {
        if (pageID === 'default' || !page.rows)
          return;
        page.rows.forEach((row) => {
          if (!row.columns)
            return;
          row.columns.forEach((col) => {
            if (col.content && col.content.type === 'form' && col.content.data)
            {
              parseFields(col.content.data.fields || []);
              parseFields(col.content.data.hiddenFields || []);
              if (col.content.data.hiddenFields)
                col.content.data.hiddenFields.forEach((hField) => {
                  if (hField.data)
                    datas[hField.data.replace('[]', '')] = 'editable';
                });
            }
          });
        });
      });
      return { filters, quickRefs, datas };
    },
    getPagesHTML()
    {
      const out = {};
      Object.entries(this.websiteContent.pages).forEach(([pageId, page]) => {
        if (pageId === 'default' || page.disabled)
          return;
        const dirtyHtml = this.$refs[`finalHTML-${pageId}`][0].$el.outerHTML;
        let cleanHtml = sanitizeHtml(dirtyHtml, { allowedTags: false, allowedAttributes: false });
        cleanHtml = cleanHtml.replace(/data-v-[0-9a-zA-Z]*/g, '');
        cleanHtml = cleanHtml.replace('data-filter=""', '');
        out[page.name] = {
          html: cleanHtml,
          default: false,
          name: page.name,
          metas: page.metas ? page.metas.content || '' : '',
        };
      });
      if (this.websiteContent.pages.default && out[this.websiteContent.pages.default])
      {
        out[this.websiteContent.pages.default].default = true;
        out.default = this.websiteContent.pages.default;
      }
      return out;
    },
    async deploy()
    {
      this.commit.id = uuid.v4();
      const pages = this.getPagesHTML();
      let obfuscatedJS = this.customJS;
      if (this.isJSObfuscation)
        obfuscatedJS = JavaScriptObfuscator.obfuscate(this.customJS, {
          compact: false,
          controlFlowFlattening: true,
          controlFlowFlatteningThreshold: 1,
          numbersToExpressions: true,
          simplify: true,
          stringArrayShuffle: true,
          splitStrings: true,
          stringArrayThreshold: 1,
        }).toString();
      const bodyCSS = await this.$store.dispatch('getBodyCSS');
      const fontCSS = await this.$store.dispatch('getImportedFontCSS');
      const payload = {
        ...this.commit,
        pages,
        css: this.customCSS.replace('\n', ''),
        js: obfuscatedJS,
        clearJS: this.customJS,
        mainCss: `${fontCSS}${bodyCSS}`,
        websiteFilters: this.websiteFilters,
      };
      payload.commitSize = sizeof(payload);
      console.log(`Commit Size : ${~~(sizeof(payload) / 1024)} KB`);
      await this.$store.dispatch('deployWebsiteContent', payload);
      this.deploymentSuccessNotif({ message: `Le deploiement en production a bien été éffectué !<br><br><span class="text-muted">${this.commit.id}</span>` });
      this.commit = {
        id: '',
        author: '',
        message: '',
        content: '',
        date: 0,
        checksum: '',
        websiteContent: {},
      };
    },
    async onSubmit()
    {
      Vue.set(this, 'onDeployment', true);
      await this.deploy();
      Vue.set(this, 'onDeployment', false);
      this.$emit('close');
    },
  },
  notifications: {
    deploymentSuccessNotif: {
      type: VueNotifications.types.success,
      title: 'Déploiement terminé !',
      message: 'Le déploiement en production a bien été effectué !',
    },
  },
};

</script>

<style scoped>

</style>
