<template>
  <div
      v-if="itemType !== 'text' && itemType !== 'code' && itemType !== 'separator' && itemType !== 'validateButton'"
      :id="id" @click="$emit('itemClick', id)"
      :style="computedStyle"
      :class="computedClasses"
      class="formField formItem">
    <template v-if="layout === 'top'">
      <label v-if="itemType !== 'validateButton' && itemType !== 'separator' && label !== ''" :for="`${id}-input`" class="form-label mb-0" v-html="label"></label>
      <div v-if="formItem.smallLabel && formItem.descriptionLayout === 'top'" :id="`help-${id}`" class="form-text mt-0" v-html="smallLabel"></div>
      <FormInput v-if="itemType === 'input'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :isAccField="isAccField"/>
      <FormTextArea v-else-if="itemType === 'textarea'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :isAccField="isAccField"/>
      <FormMultiplesChoices v-else-if="itemType === 'multipleChoices'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :isAccField="isAccField"/>
      <FormFileUpload v-else-if="itemType === 'file'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :isAccField="isAccField"/>
      <div v-if="formItem.smallLabel && formItem.descriptionLayout === 'bottom'" :id="`help-${id}`" class="form-text" v-html="smallLabel"></div>
    </template>
    <div v-else-if="layout === 'left' || layout === 'left-aligned'" class="row">
      <div class="col-auto" :class="layout === 'left' ? 'col-auto' : 'col-md-3'">
        <label v-if="itemType !== 'validateButton' && itemType !== 'separator'" :for="`${id}-input`" class="form-label" v-html="label"></label>
      </div>
      <div class="col">
        <div v-if="formItem.smallLabel && formItem.descriptionLayout === 'top'" :id="`help-${id}`" class="form-text mt-0" v-html="smallLabel"></div>
        <FormInput v-if="itemType === 'input'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :isAccField="isAccField"/>
        <FormTextArea v-else-if="itemType === 'textarea'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :isAccField="isAccField"/>
        <FormMultiplesChoices v-else-if="itemType === 'multipleChoices'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :isAccField="isAccField"/>
        <FormFileUpload v-else-if="itemType === 'file'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :isAccField="isAccField"/>
        <div v-if="formItem.smallLabel && formItem.descriptionLayout === 'bottom'" :id="`help-${id}`" class="form-text" v-html="smallLabel"></div>
      </div>
    </div>
    <div v-else-if="layout === 'floating'" class="form-floating">
      <div v-if="formItem.smallLabel && formItem.descriptionLayout === 'top'" :id="`help-${id}`" class="form-text" v-html="smallLabel"></div>
      <FormInput v-if="itemType === 'input'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :isAccField="isAccField"/>
      <FormTextArea v-else-if="itemType === 'textarea'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :isAccField="isAccField"/>
      <FormMultiplesChoices v-else-if="itemType === 'multipleChoices'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :isAccField="isAccField"/>
      <label v-if="itemType !== 'validateButton' && itemType !== 'separator' && label !== ''" :for="`${id}-input`" class="form-label mb-0" v-html="label"></label>
      <div v-if="formItem.smallLabel && formItem.descriptionLayout === 'bottom'" :id="`help-${id}`" class="form-text" v-html="smallLabel"></div>
    </div>
    <!-- confirmation -->
    <FormConfirmationField v-if="isConfirmationField && itemType === 'input'" :confirmedField="formItem" :confirmedFieldID="id"/>
  </div>
  <div v-else @click="$emit('itemClick', id)" :style="computedStyle" :id="id" class="formItem formField flex-fill" :class="computedClasses">
    <hr v-if="itemType === 'separator'" :style="separatorStyle" :class="separatorClasses">
    <div v-else-if="itemType === 'text'" v-html="formItem.html || ''" :style="computedStyle" :class="textAlignment"></div>
    <div v-else-if="itemType === 'code'" v-html="formItem.html || ''" :style="computedStyle"></div>
    <FormValidateButton v-if="itemType === 'validateButton'" :formItem="formItem" :id="id" :deploymentMode="deploymentMode" :style="computedStyle"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import FormInput from './FormInput.vue';
import FormTextArea from './FormTextArea.vue';
import FormMultiplesChoices from './FormMultiplesChoices.vue';
import FormFileUpload from './FormFileUpload.vue';
import FormValidateButton from './FormValidateButton.vue';
import FormConfirmationField from './FormConfirmationField.vue';

export default {
  name: 'FormItem',
  components: {
    FormConfirmationField,
    FormValidateButton,
    FormFileUpload,
    FormMultiplesChoices,
    FormTextArea,
    FormInput,
  },
  props: {
    formItem: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAccField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['styleFromObject']),
    layout()
    {
      return this.formItem.layout || 'top';
    },
    id()
    {
      if (this.formItem.id)
        return this.formItem.id;
      return `formItem-${this.$chance.string(this.$newIDParams)}`;
    },
    itemType() {
      return this.formItem.type || '';
    },
    label()
    {
      if (this.required)
        return `${this.formItem.label || ''}${(this.formItem.label !== '') ? '<span class="text-danger ms-1">*</span>' : ''}`;
      return this.formItem.label || '';
    },
    required()
    {
      return this.formItem.required || false;
    },
    smallLabel()
    {
      return this.formItem.smallLabel || '';
    },
    textAlignment()
    {
      if (this.formItem.alignment && this.formItem.alignment !== 'default')
        return this.formItem.alignment;
      return '';
    },
    isConfirmationField()
    {
      return this.formItem.confirmationField || false;
    },
    computedStyle()
    {
      const getterStyle = this.styleFromObject(this.formItem.style || {});
      getterStyle.width = undefined;
      if (this.itemType === 'separator')
        getterStyle.border = 'unset';
      return getterStyle || {};
    },
    computedClasses()
    {
      let out = '';
      if (this.isAccField)
        out += 'accBaseItem ';
      if ((this.formItem.style || {}).alignment)
        out += this.formItem.style.alignment;
      else
        out += 'text-start';
      const classes = this.formItem.customClasses || [];
      classes.forEach((cls) => { out += ` ${cls}`; });
      return out;
    },
    separatorClasses()
    {
      switch (this.formItem.style.alignment)
      {
        case 'default': return 'mx-auto';
        case 'text-center': return 'mx-auto';
        case 'text-end': return 'ms-auto';
        case 'text-start': return 'me-auto';
        default: return 'mx-auto';
      }
    },
    separatorStyle()
    {
      const out = this.styleFromObject(this.formItem.style || {});
      out.border = 'unset';
      out.opacity = 1;
      const border = this.formItem.style.border;
      if (border)
      {
        out.height = border.thickness ? `${border.thickness}px` : '';
        out.backgroundColor = border.color.hex8 || '';
        out.color = border.color.hex8 || '';
      }
      return out;
    },
  },
};

</script>

<style scoped>

</style>
