<template>
  <div :style="carouselHeight" v-if="carouselContent.images && carouselContent.images.length > 0">
    <swiper class="h-100" ref="mySwiper" :options="swiperOptions"
            :auto-update="true"
            :auto-destroy="true"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true" >
      <swiper-slide class="text-center" v-for="(image, index) in carouselContent.images" :key="index">
        <img :src="image.src" style="height: 90%;"  alt="carousel preview image"/>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination" :style="{ color: carouselContent.navArrowColor.hex }"></div>
      <div class="swiper-button-prev" slot="button-prev" :style="{ display: carouselContent.arrowLeft ? 'flex' : 'none', color: carouselContent.navArrowColor.hex }"></div>
      <div class="swiper-button-next" slot="button-next" :style="{ display: carouselContent.arrowRight ? 'flex' : 'none', color: carouselContent.navArrowColor.hex }"></div>
    </swiper>
  </div>
  <div v-else>
    <div class="row py-4">
      <div class="col-12 text-center text-muted">
        <h6 class="fas fa-horse fa-2x"></h6><br>
        <h6>{{ dictionary.carouselModule.noContentMessage }}</h6>
        <p v-html="dictionary.useEditButton"></p>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  name: 'CarouselPreview',
  model: {
    prop: 'carouselContent',
    event: 'change',
  },
  props: {
    carouselContent: {
      type: Object,
      required: true,
    },
    customHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  updated()
  {
    this.$refs.mySwiper.$swiper.destroy();
    this.$nextTick(() => {
      this.$refs.mySwiper.initSwiper();
      this.$refs.mySwiper.$swiper.update();
    });
  },
  computed: {
    ...mapGetters(['dictionary']),
    swiperOptions() {
      return {
        pagination: this.carouselContent.pagination ? {
          el: '.swiper-pagination',
        } : false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: this.carouselContent.mobileSlidesPerView || 1,
        breakpoints: {
          768: {
            slidesPerView: this.carouselContent.slidesPerView,
          },
        },
        loop: this.carouselContent.looping,
        autoplay: this.carouselContent.autoplay ? {
          delay: this.carouselContent.autoplaySpeed,
          reverseDirection: this.carouselContent.autoplayDirection,
        } : false,
        speed: this.carouselContent.slideSpeed * 1000,
        direction: 'horizontal',
        freemode: {
          enabled: this.carouselContent.freeMode,
        },
      };
    },
    carouselHeight() {
      return { height: (this.customHeight ? `${this.carouselContent.carouselHeight}px` : '100%') };
    },
  },
};
</script>

<style>

.swiper-container {
  height: 100%;
  width: 100%;
}

.swiper-container > .swiper-wrapper {
  transition-timing-function: linear !important;
}

</style>
