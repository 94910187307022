<template>
  <input
      v-if="formItem.format !== 'password'"
      :type="formItem.format || 'text'"
      :class="formItem.format === 'telephone' ? 'phones' : ''"
      :id="`${id}-input`"
      :aria-describedby="`help-${id}`"
      :required="!!required"
      :readonly="!!readonly"
      :min="(formItem.min) ? formItem.min : ''"
      :max="(formItem.max) ? formItem.max : ''"
      :placeholder="placeholder"
      :name="dataName"
      :data-phone-id="formItem.format === 'telephone' ? id : ''"
      :confirmed-by="(formItem.confirmationField) ? `confirmation-${id}` : false"
      :data-country="formItem.countryCode || ''"
      data-lang="gl"
      value=""
      class="form-control">
  <div v-else class="input-group">
    <input
        :id="id"
        :aria-describedby="`help-${id}`"
        :required="!!required"
        :readonly="!!readonly"
        :min="(formItem.min) ? formItem.min : ''"
        :max="(formItem.max) ? formItem.max : ''"
        :placeholder="placeholder"
        :name="dataName"
        :confirmed-by="(formItem.confirmationField) ? `confirmation-${id}` : false"
        value=""
        type="password"
        data-lang="gl"
        class="form-control">
    <button class="btn btn-outline-secondary btn-password" type="button">
      <i class="fa fa-eye-slash" aria-hidden="true"></i>
    </button>
  </div>
</template>

<script>

export default {
  name: 'FormInput',
  props: {
    formItem: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    isAccField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    placeholder()
    {
      if (this.formItem.layout === 'floating')
        return this.formItem.label || ' ';
      return this.formItem.placeholder || '';
    },
    required()
    {
      return this.formItem.required || false;
    },
    readonly()
    {
      return this.formItem.readonly || false;
    },
    dataName()
    {
      if (this.isAccField)
        return `${this.formItem.data || ''}_acc_1`;
      return this.formItem.data || '';
    },
  },
};

</script>

<style scoped>

</style>
