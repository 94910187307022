<template>
  <div class="editorUIStyle">
    <label v-if="label">{{ label }}</label>
    <select v-model="data" class="form-select editorUIStyle" :class="isError ? 'is-invalid' : 'is-valid'">
      <option disabled value="" selected>{{ dictionary.utilities.chooseDataLabel }}</option>
      <option v-for="(opt, idx) in optionList" :key="`data-${idx}`" :value="opt.variable" v-html="opt.title"></option>
    </select>
    <div v-if="isError" class="invalid-feedback">
      La donnée '{{ data }}' n'existe pas/plus
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'EventDataSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    includeStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    allowedTypes: {
      type: Array,
      required: false,
      default: () => [
        'String',
        'Radioboxes',
        'Checkboxes',
        'StringMultiline',
        'Date',
        'DateTime',
        'List',
        'Tab',
        'Photo',
        'Coloc',
        'Hotel',
        'Workshops-Agendas',
        'Link',
        'Accompagnants',
        'rgpd',
        'Password',
        'access_code',
      ],
    },
    hideError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['eventData', 'dictionary']),
    data: {
      get()
      {
        return this.value || '';
      },
      set(newValue)
      {
        this.$emit('change', newValue || '');
      },
    },
    isError()
    {
      if (this.hideError)
        return false;
      if (this.data === 'rgpd' ||
        this.data === '__status__' ||
        this.data === 'access_code')
        return false;
      return (!this.data || !this.eventData[this.data]);
    },
    optionList()
    {
      const out = [];
      if (this.includeStatus)
      {
        out.push({ variable: '__status__', title: 'Status', type: 'status' });
      }
      if (this.allowedTypes.includes('rgpd'))
      {
        out.push({ variable: 'rgpd', title: 'consentement RGPD', type: 'rgpd' });
      }
      if (this.allowedTypes.includes('access_code'))
      {
        out.push({ variable: 'access_code', title: 'Code d\'accès', type: 'access_code' });
      }
      // Add the datas from base, excluding forbidden types and reserved data keywords
      Object.values(this.eventData).forEach((opt) => {
        if (this.allowedTypes.includes(opt.type) &&
            opt.variable !== 'rgpd' &&
            opt.variable !== 'access_code' &&
            opt.variable !== '__status__')
          out.push(opt);
      });
      out.sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase())
          return -1;
        if (a.title.toLowerCase() > b.title.toLowerCase())
          return 1;
        return 0;
      });
      return out;
    },
  },
};

</script>

<style scoped>

</style>
