<template>
  <div v-html="html"></div>
</template>

<script>

export default {
  name: 'HtmlContent',
  props: {
    content: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    html()
    {
      return this.content.html || '';
    },
  },
};
</script>

<style scoped>

</style>
