<template>
  <modal name="websiteFiltersModal" :width="'80%'" :adaptive="true" height="80%">
    <Card class="h-100">
      <template v-slot:header-left><h6 class="text-white mb-0"><i class='fas fa-filter me-3'></i>{{ dictionary.websiteFiltersModal.modalTitle }}</h6></template>
      <template v-slot:header-right><button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button></template>
      <template v-slot:default class="h-100">
        <div class="row h-100 pb-3">
          <div class="col-4 h-100">
            <card class="h-100" title="Liste des filtres">
              <div class="d-flex flex-column h-100">
                <div class="flex-"></div>
                <perfect-scrollbar class="flex-fill pe-3">
                  <template v-if="Object.keys(filteredWebsiteFilters).length <= 0">
                    <div class="col-12 mt-5 text-center">
                      <h5 class="small text-muted text-center">Pas de filtres pour le moment</h5>
                    </div>
                    <div class="col-12 mt-3 text-center">
                      <button @click="$refs.newFilterCtx.open()" class="btn btn-success text-light"><i class="fas fa-plus me-1"></i>Ajouter un filtre</button>
                    </div>
                  </template>
                  <div v-for="(filter, filterId) in filteredWebsiteFilters" :key="filterId" class="row mb-3 px-0 py-1 mx-0 d-flex align-items-center border rounded" :class="selectedFilter === filterId ? 'border-success bg-success text-light' : 'border-primary'" >
                    <div class="col">
                      <i v-tooltip.top="'Renommer le filtre'" class="fas fa-pen me-2 fa-xs editBtn cursor-pointer" @click="$refs[`renameCtx-${filterId}`][0].open()"></i><span>{{ filter.name }}</span>
                    </div>
                    <div v-if="getFilterUsage(filterId).total > 0" class="col-auto">
                      <span class="badge rounded-pill bg-primary ms-2">{{ getFilterUsage(filterId).total || 0 }} Utilisations</span>
                    </div>
                    <div v-if="selectedFilter !== filterId" class="col-auto pe-1">
                      <button class="btn btn-info text-light" @click.stop.prevent="selectedFilter = filterId"><i class="fas fa-pen"></i></button>
                    </div>
                    <div class="col-auto p-0 pe-1">
                      <button class="btn btn-danger text-light" @click.stop.prevent="$refs[`confirmationRemove-${filterId}`][0].openContext()"><i class="fas fa-trash"></i></button>
                      <confirmationContext
                          @confirm="removeFilter(filterId)"
                          :ref="`confirmationRemove-${filterId}`"
                          :introMessage="`<h6>Etes vous sûr de vouloir supprimer ce filtre ?</h6><h6 class='text-danger mt-2'>${getFilterUsage(filterId).total} utilisation(s)</h6>`"
                          :confirmButtonContent="dictionary.yes.toUpperCase()"
                          :deniButtonContent="dictionary.no.toUpperCase()"/>
                    </div>
                  </div>
                </perfect-scrollbar>
                <div class="row flex-shrink-1 my-3">
                  <div class="col pe-0" v-if="Object.keys(filteredWebsiteFilters).length > 0">
                    <button @click="$refs.newFilterCtx.open()" class="btn btn-success text-light"><i class="fas fa-plus me-1"></i>Ajouter un filtre</button>
                  </div>
                  <div class="col-auto pe-1">
                    <button @click="exportFilters" class="btn btn-primary text-light"><i class="fas fa-upload me-2"></i>Exporter</button>
                  </div>
                  <div class="col-auto ps-0">
                    <button @click="importButtonClick" class="btn btn-info text-light"><i class="fas fa-download me-2"></i>Importer</button>
                  </div>
                </div>
              </div>
            </card>
            <renameContext :ref="`newFilterCtx`" @change="newFilter($event)" title="Nouveau filtre"/>
            <renameContext v-for="(filter, filterId) in filteredWebsiteFilters" :key="filterId" :ref="`renameCtx-${filterId}`" :value="filter.name" @change="renameFilter(filterId, $event)"/>
            <input accept="application/JSON" ref="importHiddenInput" type="file" @change="importFilters" class="invisible">
          </div>
          <div class="col h-100">
            <h6 v-if="selectedFilter === ''" class="mt-5 text-center text-muted">Sélectionnez un filtre avec <i class="fas fa-pen text-info"></i> pour l'éditer</h6>
            <website-filter-editor v-else v-model="currentFilter"/>
          </div>
        </div>
      </template>
    </Card>
  </modal>
</template>

<script>

import { mapGetters } from 'vuex';
import _ from 'lodash';
import VueNotifications from 'vue-notifications';
import Card from '../../Utilities/Card.vue';
import RenameContext from '../../contexts/RenameContext.vue';
import WebsiteFilterEditor from './websiteFilterEditor.vue';
import ConfirmationContext from '../../contexts/ConfirmationContext.vue';

export default {
  name: 'websiteFiltersModal',
  components: {
    ConfirmationContext,
    WebsiteFilterEditor,
    RenameContext,
    Card,
  },
  data()
  {
    return {
      searchQuery: '',
      selectedFilter: '',
    };
  },
  computed: {
    ...mapGetters(['websiteFilters', 'dictionary', 'getFilterUsage']),
    filteredWebsiteFilters()
    {
      if (this.searchQuery)
      {
        const out = {};
        Object.entries(this.websiteFilters).forEach(([filterIdx, filter]) => {
          if (filter.name.includes(this.searchQuery))
            out[filterIdx] = filter;
        });
        return out;
      }
      return this.websiteFilters;
    },
    currentFilter: {
      get()
      {
        return this.websiteFilters[this.selectedFilter] || {};
      },
      set(newValue)
      {
        const filters = _.cloneDeep(this.websiteFilters);
        filters[this.selectedFilter] = newValue;
        this.$store.commit('setWebsiteFilters', { filters, dbSync: true });
      },
    },
  },
  methods: {
    open()
    {
      this.$modal.show('websiteFiltersModal');
      this.selectedFilter = '';
    },
    close()
    {
      this.$modal.hide('websiteFiltersModal');
    },
    renameFilter(filterId, newName)
    {
      const filters = _.cloneDeep(this.websiteFilters);
      filters[filterId].name = newName;
      this.$store.commit('setWebsiteFilters', { filters, dbSync: true });
    },
    newFilter(filterName)
    {
      const id = this.$chance.string(this.$newIDParams);
      const out = { ...this.websiteFilters };
      out[id] = { name: (filterName && filterName !== '') ? filterName : `Filter-${id}` };
      this.$store.commit('setWebsiteFilters', { filters: out, dbSync: true });
      this.selectedFilter = id;
    },
    removeFilter(filterId)
    {
      if (this.selectedFilter === filterId)
        this.selectedFilter = '';
      const filters = {};
      Object.entries(this.websiteFilters).forEach(([key, value]) => {
        if (key !== filterId)
          filters[key] = value;
      });
      this.$store.commit('setWebsiteFilters', { filters, dbSync: true });
    },
    exportFilters()
    {
      const jsonData = JSON.stringify(this.websiteFilters || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `filters_${this.$chance.string(this.$newIDParams)}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    importButtonClick()
    {
      this.$refs.importHiddenInput.click();
    },
    importFilters(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object)
            this.$store.commit('setWebsiteFilters', { filters: object, dbSync: true });
        } catch (ex)
        {
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
      this.importFiltersSuccess();
    },
  },
  notifications: {
    importFiltersSuccess: {
      type: VueNotifications.types.success,
      title: 'Filtres importés',
      message: 'Filtre(s) importé avec succès.',
    },
  },
};

</script>

<style scoped>

.editBtn:hover {
    color: var(--bs-info)!important;
}

</style>
