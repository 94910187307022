export default {
  /**
   * Return the Google font list form the Google Api
   * @returns an array containing the fonts, empty upon failure
   */
  async getGoogleFontList()
  {
    let out = [];

    try
    {
      await fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyARhQYV7SROMQ2fdaCaPpILJtliMajpEKU').then(async (response) => {
        if (response.ok)
          await response.json().then((content) => {
            out = content.items;
          });
      });
    } catch (err)
    {
      console.error(err);
      out = [];
    }
    return out;
  },

  /**
   * Return the CSS for custom fonts
   * @return string containing the imports
   */
  getImportedFontCSS({ getters })
  {
    let fontsCSS = '';
    Object.values(getters.googleFonts).forEach((element) => {
      if (element.source === 'GoogleFont' && element.GoogleFontURL !== undefined && element.GoogleFontURL !== '')
        fontsCSS += `@import url(${element.GoogleFontURL});`;
      if (element.source === 'Custom' && element.fontFace !== undefined && element.fontFace !== '')
        fontsCSS += element.fontFace;
    });
    return fontsCSS;
  },

  /**
   * Return the CSS for website body
   * @return string The actual CSS to apply
   */
  getBodyCSS({ getters })
  {
    let bodyCSS = '';
    if (getters.bodyConfig.isMobileImage)
    {
      if (getters.bodyConfig.backgroundImage !== undefined && getters.bodyConfig.backgroundImage !== '')
      {
        bodyCSS +=
          '@media (min-width: 768px) {' +
          '    html {' +
          `        background-image: url(${getters.bodyConfig.backgroundImage});` +
          '    }' +
          '}';
      }
      if (getters.bodyConfig.mobileBackgroundImage !== undefined && getters.bodyConfig.mobileBackgroundImage !== '')
      {
        bodyCSS +=
          '@media (max-width: 768px) {' +
          '    html {' +
          `        background-image: url(${getters.bodyConfig.mobileBackgroundImage});` +
          '    }' +
          '}';
      }
      if (getters.bodyConfig.backgroundImageProperties !== undefined)
      {
        bodyCSS += 'html {';
        const backgroundProperties = getters.bodyConfig.backgroundImageProperties;
        if (backgroundProperties.cover !== undefined && backgroundProperties.cover !== '')
          bodyCSS += `    background-size: ${backgroundProperties.cover};`;
        if (backgroundProperties.repetition !== undefined && backgroundProperties.repetition !== '')
          bodyCSS += `    background-repeat: ${backgroundProperties.repetition};`;
        if (backgroundProperties.scroll !== undefined && backgroundProperties.scroll !== '')
          bodyCSS += `    background-attachment: ${backgroundProperties.scroll};`;
        bodyCSS += '}';
      }
    }
    else if (!getters.bodyConfig.isMobileImage && getters.bodyConfig.backgroundImage !== undefined && getters.bodyConfig.backgroundImage !== '')
    {
      bodyCSS += 'html {';
      bodyCSS += `    background-image: url(${getters.bodyConfig.backgroundImage});`;
      if (getters.bodyConfig.backgroundImageProperties !== undefined)
      {
        const backgroundProperties = getters.bodyConfig.backgroundImageProperties;
        if (backgroundProperties.cover !== undefined && backgroundProperties.cover !== '')
          bodyCSS += `    background-size: ${backgroundProperties.cover};`;
        if (backgroundProperties.repetition !== undefined && backgroundProperties.repetition !== '')
          bodyCSS += `    background-repeat: ${backgroundProperties.repetition};`;
        if (backgroundProperties.scroll !== undefined && backgroundProperties.scroll !== '')
          bodyCSS += `    background-attachment: ${backgroundProperties.scroll};`;
      }
      bodyCSS += '}';
    }
    bodyCSS += 'html, body {';
    if (getters.bodyConfig.backgroundColor)
    {
      if (getters.bodyConfig.backgroundColor.hex8)
        bodyCSS += `background-color: ${getters.bodyConfig.backgroundColor.hex8};`;
      else if (getters.bodyConfig.backgroundColor.hex)
        bodyCSS += `background-color: ${getters.bodyConfig.backgroundColor.hex};`;
      else if (getters.bodyConfig.backgroundColor.rgba)
        bodyCSS += `background-color: rgba(${getters.bodyConfig.backgroundColor.rgba.r},
        ${getters.bodyConfig.backgroundColor.rgba.g},
        ${getters.bodyConfig.backgroundColor.rgba.b},
        ${getters.bodyConfig.backgroundColor.rgba.a});`;
    }
    if (getters.bodyConfig.fontFamily)
      bodyCSS += `font-family: ${getters.bodyConfig.fontFamily};`;
    if (getters.bodyConfig.color)
    {
      if (getters.bodyConfig.color.hex8)
        bodyCSS += `color: ${getters.bodyConfig.color.hex8};`;
      else if (getters.bodyConfig.color.hex)
        bodyCSS += `color: ${getters.bodyConfig.color.hex};`;
      else if (getters.bodyConfig.color.rgba)
        bodyCSS += `color: rgba(${getters.bodyConfig.color.rgba.r},
        ${getters.bodyConfig.color.rgba.g},
        ${getters.bodyConfig.color.rgba.b},
        ${getters.bodyConfig.color.rgba.a});`;
    }
    bodyCSS += '}';
    if (getters.bodyConfig.linkColor)
      bodyCSS += `a { color: ${getters.bodyConfig.linkColor.hex || '#0000EE'}; }`;
    return bodyCSS;
  },
};
