<template>
  <perfect-scrollbar class="h-100 pe-3" :options="{ wheelPropagation: false, suppressScrollX: true }">
    <form @submit.stop.prevent>
      <div class="row mb-3">
        <div class="col">
          <div class="form-check form-switch">
            <input v-model="cookie" class="form-check-input" type="checkbox" id="toggleCookiesCheck">
            <label class="form-check-label" for="toggleCookiesCheck">{{ dictionary.propertiesModal.activateCookieText }}</label>
          </div>
        </div>
        <div class="col">
          <div class="form-check form-switch">
            <input v-model="enableGAnalytics" class="form-check-input" type="checkbox" id="toggleGAnalyticsCheck">
            <label class="form-check-label" for="toggleGAnalyticsCheck">{{ dictionary.propertiesModal.activateAnalyticsText }}</label>
          </div>
        </div>
      </div>
      <div class="mb-3" v-show="enableGAnalytics === true">
        <label for="GAnalyticsInput" class="form-label">{{ dictionary.propertiesModal.analyticsTagsLabel }}</label>
        <input :value="GAnalyticsID" @change="GAnalyticsID = $event.target.value" type="text" class="form-control" id="GAnalyticsInput" placeholder="">
      </div>
    </form>
  </perfect-scrollbar>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'RgpdConfig',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    cookie: {
      get()
      {
        return this.value.cookie || false;
      },
      set(newCookiesValue)
      {
        this.$emit('change', { ...this.value, cookie: newCookiesValue });
      },
    },
    policy: {
      get()
      {
        return this.value.policy || '';
      },
      set(newPolicyValue)
      {
        this.$emit('change', { ...this.value, policy: newPolicyValue });
      },
    },
    enableGAnalytics: {
      get()
      {
        return this.value.googleTag.active || false;
      },
      set(newEnableGAnalyticsValue)
      {
        this.$emit('change', {
          ...this.value,
          googleTag: {
            active: newEnableGAnalyticsValue,
            id: this.value.googleTag.id,
          },
        });
      },
    },
    GAnalyticsID: {
      get()
      {
        return this.value.googleTag.id || '';
      },
      set(newGAnalyticsIDValue)
      {
        this.$emit('change', {
          ...this.value,
          googleTag: {
            active: this.value.googleTag.active,
            id: newGAnalyticsIDValue,
          },
        });
      },
    },
  },
};

</script>

<style scoped>

</style>
