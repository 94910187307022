<template>
  <div v-if="!isEmpty" class="w-100 h-100 position-relative" id="interactiveHome">
    <img class="w-100" :src="source" alt="">
    <a v-for="(zone, zoneID) in this.content.zoneList"
       :key="`zone-${zoneID}`"
       class="position-absolute "
       :data-pos-x="zone.position.percent.x"
       :data-pos-y="zone.position.percent.y"
       :data-hover-img="content.foregroundImage || undefined"
       :onclick="targetOnClick(zone)"
       :target="newTab(zone) ? '_blank' : undefined"
       :href="targetUrl(zone)"
       :style="`cursor: pointer; top: ${zone.position.percent.y}%; left: ${zone.position.percent.x}%; height: ${zone.position.percent.h}%; width: ${zone.position.percent.w}%; background-color: ${backgroundColor(zone)}`"></a>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12 text-center my-5 text-muted">
        <i class="fas fa-vr-cardboard fa-2x mb-2"></i>
        <h6>{{ dictionary.noMappingConfiguration }}</h6>
        <p v-html="dictionary.useEditButton"></p>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'MappedImageContent',
  props: {
    content: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['dictionary', 'pageList', 'pageList']),
    externalUrl() {
      return this.preferredUrl || '';
    },
    isEmpty() {
      return !this.content.backgroundImage;
    },
    source() {
      return this.content.backgroundImage || '';
    },
  },
  methods: {
    backgroundColor(zone)
    {
      if (this.deploymentMode || !this.isSelected)
        return '';
      return `rgba(${zone.color.rgba.r}, ${zone.color.rgba.g}, ${zone.color.rgba.b}, 0.5)`;
    },
    targetUrl(zone) {
      let pageName = '';
      if (zone.targetType === 'page' && this.pageList[zone.targetPage])
        pageName = this.pageList[zone.targetPage].name || '';
      switch (zone.targetType)
      {
        case 'page': return `${this.externalUrl}/${pageName}`;
        case 'url': return zone.targetUrl;
        case 'row': return zone.targetRow;
        case 'filter': return undefined;
        case 'modal': return undefined;
        case 'javascript': return undefined;
        default: return undefined;
      }
    },
    targetOnClick(zone) {
      switch (zone.targetType)
      {
        case 'url': return '';
        case 'row': return '';
        case 'filter': return `forceRowsFilter('${zone.targetFilter || ''}')`;
        case 'modal': return `$('#modal-${zone.targetModal}').modal('show')`;
        case 'javascript': return zone.targetJavascript;
        default: return '#';
      }
    },
    newTab(zone) {
      if (zone.targetType === 'url')
        return zone.newTab || false;
      return false;
    },
    isTargetUrl(zone) {
      return zone.isTargetUrl || false;
    },
  },
};

</script>

<style scoped>

</style>
