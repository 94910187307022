<template>
  <div v-if="itemType === 'separator'" class="text-center text-muted">
    <p>Aucune configuration possible pour les séparateurs</p>
  </div>
  <Tabs class="h-100" v-else :tabs="['Configuration', 'Style']">
    <template v-slot:slot-0>
      <perfect-scrollbar class="h-100 pe-3" :options="{ wheelPropagation: false, suppressScrollX: true }">
        <DropdownConfig v-if="itemType === 'dropdown'" v-model="fullItem"/>
        <form v-else @submit.stop.prevent>
          <ActionSelector v-model="action"/>
          <div v-if="itemType !== 'image'">
            <div class="mb-3">
              <label class="small required" for="reportTitle">{{ dictionary.text }}</label>
              <input v-model="itemContent" id="reportTitle" type="text" class="form-control">
            </div>
          </div>
          <InputImageUpload v-else :title="dictionary.navbarModule.imageLink" v-model="itemContent"/>
        </form>
      </perfect-scrollbar>
    </template>
    <template v-slot:slot-1>
      <perfect-scrollbar class="h-100 pe-3" :options="{ wheelPropagation: false, suppressScrollX: true }">
        <StyleEditor v-if="itemType !== 'image'"
          v-model="style"
          :properties="[
              'spacing',
              'fontFamily',
              'fontWeight',
              'fontSize',
              'color',
              'hr',
              'backgroundColor',
              'border',
              'borderRadius',
            ]"/>
          <StyleEditor v-else
                       v-model="style"
                       :properties="[
                          'spacing',
                          'border',
                          'borderRadius',
                        ]"
                        class="pe-3" />
        <div class="mb-2">
          <label class="small">{{ dictionary.customClass }}</label>
          <Multiselect
              id="customClasses"
              v-model="customClasses"
              :tag-placeholder="dictionary.addThisClass"
              :placeholder="dictionary.selectOrTypeAClass"
              :options="defaultCustomClasses"
              :multiple="true"
              :taggable="true"
              @tag="onNewClass"/>
        </div>
      </perfect-scrollbar>
    </template>
  </Tabs>
</template>

<script>

import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import InputImageUpload from '../Utilities/InputImageUpload.vue';
import defaultCustomClasses from '../../configuration/defaultCustomClasses.json';
import StyleEditor from '../Utilities/StyleEditor.vue';
import ActionSelector from '../Utilities/ActionSelector.vue';
import DropdownConfig from './DropdownConfig.vue';
import Tabs from '../Utilities/Tabs.vue';

export default {
  name: 'NavbarItemConfigV2',
  components: {
    Tabs,
    DropdownConfig,
    StyleEditor,
    Multiselect,
    InputImageUpload,
    ActionSelector,
  },
  model: {
    event: 'change',
    prop: 'navbarItem',
  },
  props: {
    navbarItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['rowIDList', 'googleFonts', 'dictionary', 'websiteFilters', 'modalList']),
    itemType() {
      return this.navbarItem.type || 'text';
    },
    defaultCustomClasses()
    {
      return defaultCustomClasses;
    },
    hrefList() {
      const out = [];
      this.rowIDList.forEach((rowID) => {
        out.push(`#${rowID}`);
      });
      return out;
    },
    itemTargetBlank: {
      get() {
        return this.navbarItem.targetBlank || false;
      },
      set(newTarget) {
        this.$emit('change', { ...this.navbarItem, targetBlank: newTarget });
      },
    },
    itemHref: {
      get() {
        return this.navbarItem.href || '';
      },
      set(newHref) {
        this.$emit('change', { ...this.navbarItem, href: newHref });
      },
    },
    itemFilterTarget: {
      get() {
        return this.navbarItem.filterTarget || '';
      },
      set(newFilter) {
        this.$emit('change', { ...this.navbarItem, filterTarget: newFilter });
      },
    },
    itemModalTarget: {
      get() {
        return this.navbarItem.modalTarget || '';
      },
      set(newModal) {
        this.$emit('change', { ...this.navbarItem, modalTarget: newModal });
      },
    },
    action: {
      get()
      {
        return this.navbarItem || {};
      },
      set(newActionObj)
      {
        this.$emit('change', { ...this.navbarItem, ...newActionObj });
      },
    },
    itemContent: {
      get() {
        return this.navbarItem.content || '';
      },
      set(newContent) {
        this.$emit('change', { ...this.navbarItem, content: newContent });
      },
    },
    fullItem: {
      get()
      {
        return this.navbarItem || {};
      },
      set(newItem)
      {
        this.$emit('change', { ...newItem });
      },
    },
    customClasses: {
      get()
      {
        if (!this.navbarItem.style || !this.navbarItem.style.customClasses)
          return [];
        return this.navbarItem.style.customClasses || [];
      },
      set(newClasses)
      {
        if (this.navbarItem.style)
          this.$emit('change', {
            ...this.navbarItem,
            style: {
              ...this.navbarItem.style,
              customClasses: newClasses,
            },
          });
        else
          this.$emit('change', {
            ...this.navbarItem,
            style: {
              customClasses: newClasses,
            },
          });
      },
    },
    style: {
      get()
      {
        return this.navbarItem.style || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.navbarItem, style: { ...(this.navbarItem.style || {}), ...newStyle } });
      },
    },
  },
  methods: {
    onNewClass(newTag)
    {
      this.customClasses = [...this.customClasses, newTag];
    },
  },
};

</script>

<style scoped>

</style>
