<template>
  <div class="row justify-content-center py-5">
    <div class="col-12 text-center my-5">
      <h3 class="text-secondary" >{{ dictionary.formModule.selectFormTypeLabel }}</h3>
    </div>
    <div @click="formCardClick('classic')" :class="(selected === 'classic') ? 'formTypeSelected' : ''" class="col-3 px-3 py-5 me-3 formTypeCard formTypeCardFull d-flex align-items-center justify-content-center">
      <div class="row text-center">
        <div class="col-12 mb-2">
          <h1 class="formPicto fab fa-wpforms"></h1>
        </div>
        <div class="col-12">
          <h6 class="fw-bold">{{ dictionary.formModule.classicFormTitle }}</h6>
          <p>{{ dictionary.formModule.classicFormMessage }}</p>
        </div>
      </div>
    </div>
    <div disabled :class="(selected === 'steps') ? 'formTypeSelected' : ''" class="text-muted col-3 px-3 py-5 mx-3 formTypeCard formTypeCardFull d-flex align-items-center justify-content-center">
      <div class="row text-center">
        <div class="col-12 mb-2">
          <h1 class="formPicto fas fa-shoe-prints"></h1>
        </div>
        <div class="col-12">
          <h6 class="fw-bold">{{ dictionary.formModule.stepFormTitle }}</h6>
          <p>{{ dictionary.formModule.stepFormMessage }}</p>
          <p class="text-muted">{{ dictionary.formModule.unavailableNow }}</p>
        </div>
      </div>
    </div>
    <div @click="formCardClick('import')" :class="(selected === 'import') ? 'formTypeSelected' : ''" class="col-3 px-3 py-5 ms-3 formTypeCard formTypeCardOutline d-flex align-items-center justify-content-center">
      <div class="row text-center">
        <div class="col-12 mb-2">
          <h1 class="formPicto fas fa-file-import"></h1>
        </div>
        <div class="col-12">
          <h6 class="fw-bold">{{ dictionary.formModule.importForm }}</h6>
        </div>
      </div>
    </div>
    <div class="col-12 text-center mt-5">
      <button @click="submitClick" class="btn btn-lg text-light" :disabled="selected === ''" :class="selected ? 'btn-success' : 'btn-outline-dark'">{{ dictionary.continue }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FormTypeSelector',
  data() {
    return {
      selected: '',
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
  methods: {
    formCardClick(selected)
    {
      this.selected = selected;
    },
    submitClick()
    {
      this.$emit('selected', this.selected);
    },
  },
};

</script>

<style scoped>

.formTypeCard {
  border-radius: 15px;
  color: white;
  cursor: pointer;
}

.formTypeCardFull {
  background-color: var(--bs-secondary);
  border: 2px solid var(--bs-secondary);
}

.formTypeCardOutline {
  border: 2px solid var(--bs-secondary);
  color: var(--bs-secondary)
}

.formTypeSelected {
  border: 2px solid var(--bs-primary);
  color: var(--bs-primary);
}

.formTypeCard:not([disabled]):hover {
  border: 2px solid var(--bs-primary);
  color: var(--bs-primary);
}

</style>
