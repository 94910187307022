<template>
  <StyleEditor v-model="style" :properties="['spacing', 'hr', 'borderRadius', 'border', 'color', 'backgroundColor', 'alignment', 'fontFamily', 'fontWeight']" :noButtons="true">
    <template v-slot:form-start>
      <div class="mb-3">
        <label for="FormItemLabelInput" class="form-label">{{ dictionary.formModule.inputLabelLabel }}<CodeCompatibleBadge class="ms-2" position="top"/></label>
        <input :value="formItem.label || ''" @change="$emit('change', { ...formItem, label: $event.target.value })"  type="text" class="form-control" id="FormItemLabelInput">
      </div>
    </template>
    <template v-slot:form-end>
      <div class="form-check form-switch">
        <input v-model="hidden" class="form-check-input" type="checkbox" id="FormItemHideSwitch">
        <label class="form-check-label" for="FormItemHideSwitch">Masquer ce bouton</label>
      </div>
    </template>
  </StyleEditor>
</template>

<script>

import { mapGetters } from 'vuex';
import CodeCompatibleBadge from '../../Utilities/CodeCompatibleBadge.vue';
import StyleEditor from '../../Utilities/StyleEditor.vue';

export default {
  name: 'FormValidateButtonConfig',
  components: { StyleEditor, CodeCompatibleBadge },
  props: {
    formItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    hidden: {
      get()
      {
        return this.formItem.hidden || false;
      },
      set(newHidden)
      {
        this.$emit('change', { ...this.formItem, hidden: newHidden });
      },
    },
    style: {
      get()
      {
        return this.formItem || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.formItem, ...newStyle });
      },
    },
  },
};

</script>

<style scoped>

</style>
