import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// Firebase database information
const firebaseConfig = {
  apiKey: 'AIzaSyBfONQeE8VLk1zWzJ5PDzAZEA1a7gl9Y2k',
  authDomain: 'captag-events.firebaseapp.com',
  databaseURL: 'https://captag-events-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'captag-events',
  storageBucket: 'captag-events.appspot.com',
  messagingSenderId: '418843808210',
  appId: '1:418843808210:web:52ea9222621c9d1c76f80b',
  measurementId: 'G-JFHBLW7WVF',
};

let fb = undefined;

export default async function (tokenID, dispatch = null) {
  if (fb === undefined)
  {
    const firebaseApp = firebase.initializeApp(firebaseConfig);
    try {
      const credentials = await firebaseApp.auth().signInWithCustomToken(tokenID);
      fb = firebaseApp.database();
      const claims = (await credentials.user.getIdTokenResult(true)).claims;
      if (dispatch) {
        dispatch('loadTokenParams', claims);
      }
      console.log('Logged in !');
    } catch (error) {
      console.log('Can\'t log in!');
      fb = null;
    }
  }
  return fb;
}
