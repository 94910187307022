<template>
  <form @submit.stop.prevent class="editorUIStyle">
    <div class="mb-2">
      <label class="form-label">{{ dictionary.formModule.inputTypeLabel }}</label>
      <select v-model="format" class="form-select" aria-label="itemFormat">
        <option value="text">{{ dictionary.formModule.textInputType }}</option>
        <option value="telephone">{{ dictionary.formModule.telInputType }}</option>
        <option value="date">{{ dictionary.formModule.dateInputType }}</option>
        <option value="time">{{ dictionary.formModule.timeInputType }}</option>
        <option value="datetime-local" v-html="dictionary.formModule.datetimeInputType"></option>
        <option value="email">{{ dictionary.formModule.mailInputType }}</option>
        <option value="number">{{ dictionary.formModule.numberInputType }}</option>
        <option value="password">{{ dictionary.formModule.passwordInputType }}</option>
      </select>
    </div>
    <div class="mb-2">
      <label>{{ dictionary.formModule.dataLabel }}</label>
      <EventDataSelect v-model="data" :allowed-types="allowedTypes"/>
    </div>
    <div>
      <label for="FormItemLabelInput" class="form-label">{{ dictionary.formModule.inputLabelLabel }}<CodeCompatibleBadge class="ms-2" position="top"/></label>
      <div class="input-group">
        <input :value="formItem.label || ''" @change="$emit('change', { ...formItem, label: $event.target.value })" type="text" class="form-control" id="FormItemLabelInput">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{ layoutDisplayButton }}</button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" @click="layout = 'top'">{{ dictionary.formModule.labelTopPositionLabel }}</a></li>
          <li><a class="dropdown-item" @click="layout = 'left'">{{ dictionary.formModule.labelLeftPositionLabel }}</a></li>
          <li><a class="dropdown-item" @click="layout = 'left-aligned'">{{ dictionary.formModule.labelLeftPositionLabel }} aligné</a></li>
          <li><a class="dropdown-item" @click="layout = 'floating'">Floating</a></li>
        </ul>
      </div>
    </div>
    <div v-if="format !== 'date'">
      <label for="FormItemPlaceholderInput" class="form-label">{{ dictionary.formModule.placeholderLabel }}</label>
      <input :value="formItem.placeholder || ''" @change="$emit('change', { ...formItem, placeholder: $event.target.value })" type="text" class="form-control" id="FormItemPlaceholderInput">
    </div>
    <div class="mb-2">
      <label for="FormItemSmallLabelInput" class="form-label">{{ dictionary.formModule.descriptionLabel }}</label>
      <div class="input-group">
        <input :value="formItem.smallLabel || ''" @change="$emit('change', { ...formItem, smallLabel: $event.target.value })" type="text" class="form-control" id="FormItemSmallLabelInput">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="true">{{ descriptionLayout === 'bottom' ? dictionary.formModule.descBottomPositionLabel : dictionary.formModule.descTopPositionLabel }}</button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" @click="descriptionLayout = 'top'">{{ dictionary.formModule.descTopPositionLabel }}</a></li>
          <li><a class="dropdown-item" @click="descriptionLayout = 'bottom'">{{ dictionary.formModule.descBottomPositionLabel }}</a></li>
        </ul>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="required" class="form-check-input" type="checkbox" id="FormItemRequiredSwitch">
          <label class="form-check-label" for="FormItemRequiredSwitch">{{ dictionary.formModule.requiredLabel }}</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="confirmationField" class="form-check-input" type="checkbox" id="FormItemConfirmationSwitch">
          <label class="form-check-label" for="FormItemConfirmationSwitch">{{ dictionary.formModule.confirmLabel }}</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="readonly" class="form-check-input" type="checkbox" id="FormItemReadonlySwitch">
          <label class="form-check-label" for="FormItemReadonlySwitch">{{ dictionary.formModule.readonlyLabel }}</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="hidden" class="form-check-input" type="checkbox" id="FormItemHideSwitch">
          <label class="form-check-label" for="FormItemHideSwitch">Masquer ce champs</label>
        </div>
      </div>
    </div>
    <div v-if="confirmationField">
      <div class="mb-3">
        <label for="FormItemConfirmationLabelInput" class="form-label">{{ dictionary.formModule.confirmInputLabel }}<CodeCompatibleBadge class="ms-2" position="top"/></label>
        <input :value="formItem.confirmationLabel || ''" @change="$emit('change', { ...formItem, confirmationLabel: $event.target.value })" type="text" class="form-control" id="FormItemConfirmationLabelInput">
      </div>
    </div>
    <div class="row" v-if="format === 'number' || format === 'date'">
      <div class="col-6">
        <div>
          <label for="FormItemMaximumInput" class="form-label">{{ dictionary.formModule.minimum }}</label>
          <input v-model="minimum" :type="format" class="form-control" id="FormItemMaximumInput" :placeholder="dictionary.formModule.noLimitPlaceholder">
        </div>
      </div>
      <div class="col-6">
        <div>
          <label for="FormItemMinimumInput" class="form-label">{{ dictionary.formModule.maximum }}</label>
          <input v-model="maximum" :type="format" class="form-control" id="FormItemMinimumInput" :placeholder="dictionary.formModule.noLimitPlaceholder">
        </div>
      </div>
    </div>
    <div v-if="format === 'telephone'">
      <label class="form-label">{{ dictionary.formModule.defaultCountryCodeLabel }}</label>
      <select v-model="countryCode" class="form-select" aria-label="itemFormat">
        <option v-for="(ct, ctIdx) in countriesList" :key="`country-${ctIdx}`" :value="ct[1]">{{ ct[0] }} +{{ ct[2] }}</option>
      </select>
    </div>
  </form>
</template>

<script>

import { mapGetters } from 'vuex';
import CodeCompatibleBadge from '../../Utilities/CodeCompatibleBadge.vue';
import countriesList from '../../../configuration/countriesList.json';
import EventDataSelect from '../../Utilities/EventDataSelect.vue';

export default {
  name: 'FormInputConfig',
  components: {
    CodeCompatibleBadge,
    EventDataSelect,
  },
  props: {
    formItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    countriesList()
    {
      return countriesList;
    },
    format: {
      get()
      {
        return this.formItem.format || '';
      },
      set(newFormat)
      {
        this.$emit('change', { ...this.formItem, format: newFormat });
      },
    },
    readonly: {
      get()
      {
        return this.formItem.readonly || '';
      },
      set(newReadonly)
      {
        this.$emit('change', { ...this.formItem, readonly: newReadonly });
      },
    },
    hidden: {
      get()
      {
        return this.formItem.hidden || false;
      },
      set(newHidden)
      {
        this.$emit('change', { ...this.formItem, hidden: newHidden });
      },
    },
    required: {
      get()
      {
        return this.formItem.required || '';
      },
      set(newRequired)
      {
        this.$emit('change', { ...this.formItem, required: newRequired });
      },
    },
    data: {
      get()
      {
        return this.formItem.data || '';
      },
      set(newData)
      {
        this.$emit('change', { ...this.formItem, data: newData });
      },
    },
    minimum: {
      get()
      {
        return this.formItem.min || '';
      },
      set(newMinimum)
      {
        this.$emit('change', { ...this.formItem, min: newMinimum });
      },
    },
    maximum: {
      get()
      {
        return this.formItem.max || '';
      },
      set(newMaximum)
      {
        this.$emit('change', { ...this.formItem, max: newMaximum });
      },
    },
    countryCode: {
      get()
      {
        return this.formItem.countryCode || '';
      },
      set(newCountryCode)
      {
        this.$emit('change', { ...this.formItem, countryCode: newCountryCode });
      },
    },
    descriptionLayout: {
      get()
      {
        return this.formItem.descriptionLayout || 'bottom';
      },
      set(newDescriptionLayout)
      {
        this.$emit('change', { ...this.formItem, descriptionLayout: newDescriptionLayout });
      },
    },
    layout: {
      get()
      {
        return this.formItem.layout || '';
      },
      set(newLayout)
      {
        this.$emit('change', { ...this.formItem, layout: newLayout });
      },
    },
    layoutDisplayButton()
    {
      if (typeof this.layout === 'boolean' && this.layout === true)
        return this.dictionary.formModule.labelTopPositionLabel;
      if (typeof this.layout === 'boolean' && this.layout === false)
        return this.dictionary.formModule.labelLeftPositionLabel;
      switch (this.layout)
      {
        case 'top': return this.dictionary.formModule.labelTopPositionLabel;
        case 'left': return this.dictionary.formModule.labelLeftPositionLabel;
        case 'floating': return 'Floating';
        default: return this.dictionary.formModule.labelTopPositionLabel;
      }
    },
    confirmationField: {
      get()
      {
        return this.formItem.confirmationField || false;
      },
      set(newConfirmationField)
      {
        this.$emit('change', { ...this.formItem, confirmationField: newConfirmationField });
      },
    },
    allowedTypes() {
      if (this.format === 'text' ||
          this.format === 'number' ||
          this.format === 'telephone' ||
          this.format === 'email' ||
          this.format === 'time' ||
          this.format === 'password')
      {
        if (this.format === 'password')
          return ['access_code', 'Password'];
        return ['String', 'StringMultiline', 'Link'];
      }
      if (this.format === 'date' || this.format === 'datetime-local')
      {
        return ['Date', 'DateTime'];
      }
      return [];
    },
  },
};

</script>

<style>

</style>
