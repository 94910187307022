<template>
  <div class="h-100">
    <form-type-selector v-if="!formType" @selected="formTypeSelected"/>
    <div v-else class="row h-100">
      <div class="col-5 h-100">
        <Tabs class="pt-1 h-100 text-start" ref="tabs" :tabs="getTabs">
          <template v-slot:slot-0>
            <div class="h-100 d-flex flex-column">
              <div class="flex-fill pt-2" style="overflow-y: hidden">
                <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }" >
                  <DndFormItems/>
                </perfect-scrollbar>
              </div>
              <div class="my-2 flex-shrink-1 text-end">
                <div class="ms-auto btn-group">
                  <button id="toolboxDropdown" type="button" class="btn btn-success dropdown-toggle text-white" data-bs-toggle="dropdown" aria-expanded="false">
                    Options avancées <i class="fas fa-cogs mx-2"></i>
                  </button>
                  <ul class="dropdown-menu cursor-pointer" aria-labelledby="toolboxDropdown">
                    <li><a class="dropdown-item" @click="formExport">{{ dictionary.formModule.exportForm }} <i class="fas fa-upload ms-3"></i></a></li>
                    <li><a class="dropdown-item" @click="importButtonClick">{{ dictionary.formModule.importForm }} <i class="fas fa-download ms-3"></i></a></li>
                    <li><a class="dropdown-item" @click="importStyleButtonClick">{{ dictionary.formModule.importFormStyle }} <i class="fas fa-mask ms-3"></i></a></li>
                    <li><a class="dropdown-item" @click="removeAllStyleButtonClick">{{ dictionary.formModule.removeFormStyle }} <i class="fas fa-trash ms-3"></i></a></li>
                    <li><a class="dropdown-item" @click="regeneratesAllIds">Reg-génerer les ids du formulaire <i class="fas fa-sync-alt ms-3"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:slot-1>
            <Card class="h-100">
              <template v-slot:header-left>
                <p class="text-white mb-0">
                  <i class="fa fa-pen text-white me-2"></i>{{ dictionary.formModule.editInput }}<span v-if="selectedItemID" class="text-muted small ms-2">{{ selectedItemID }}</span>
                </p>
              </template>
              <template v-if="selectedItemID" v-slot:header-right>
                <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="selectedItemID = ''; $refs.tabs.setActiveTab(0);"></button>
              </template>
              <FormItemConfig v-if="selectedItemID" v-model="selectedItem" />
              <div v-else class="row">
                <div class="col-12 text-center">
                  <p class="small text-muted">{{ dictionary.formModule.selectInputToEditLabel }}</p>
                </div>
              </div>
            </Card>
          </template>
          <template v-slot:slot-2>
            <Card class="h-100">
              <template v-slot:header-left><p class="text-white mb-0"><i class="fas fa-filter text-white me-2"></i>{{ dictionary.formModule.filterTabTitle }}</p></template>
              <template v-if="selectedItemID" v-slot:header-right>
                <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="selectedItemID = ''; $refs.tabs.setActiveTab(0);"></button>
              </template>
              <perfect-scrollbar class="h-100" v-if="selectedItemID" :options="{ suppressScrollX: true, wheelPropagation: false }" >
                <FormFilterEditor class="pe-3" v-model="selectedItem"/>
              </perfect-scrollbar>
              <div v-else class="row">
                <div class="col-12 text-center">
                  <p class="small text-muted">{{ dictionary.formModule.selectInputToEditFilterLabel }}</p>
                </div>
              </div>
            </Card>
          </template>
          <template v-slot:slot-3>
            <Card class="h-100">
              <template v-slot:header-left><p class="text-white mb-0"><i class="fa fa-eye-slash text-white me-2"></i>{{ dictionary.formModule.hiddenInputsLabel }}</p></template>
              <perfect-scrollbar class="h-100"  :options="{ suppressScrollX: true, wheelPropagation: false }">
                <HiddenFieldsEditor v-model="hiddenFields"/>
              </perfect-scrollbar>
            </Card>
          </template>
          <template v-slot:slot-4>
            <Card class="h-100">
              <template v-slot:header-left><p class="text-white mb-0"><i class="fa fa-exclamation-triangle text-white me-2"></i>Éditeur d'alertes</p></template>
              <alertesEditor v-model="alerts" :alreadyRegisteredConfig="alreadyRegisteredConfig" @alreadyRegisteredConfigChange="alreadyRegisteredConfig = $event"/>
            </Card>
          </template>
        </Tabs>
      </div>
      <div class="col-7 h-100">
        <card class="h-100" :style="`background-color: ${previewBackgroundColor.hex || '#ffffff' }`">
          <template v-slot:header-left>
            <p class="mb-0 text-light"><i class="fas fa-eye mx-3"></i>{{ dictionary.preview }}</p>
          </template>
          <template v-slot:header-right>
            <inputColorPicker v-model="previewBackgroundColor" style="width: 200px;"/>
          </template>
          <perfect-scrollbar class="h-100 px-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
            <FormPreview
                @removeItem="removeItem($event)"
                @editItem="editItem($event)"
                @itemClick="selectedItemID = $event; $refs.tabs.setActiveTab(1);"
                @change="$emit('change', $event)"
                :selectedItem="selectedItemID"
                :formContent="formContent"
                :previewStyle="computedPreviewStyle"
                ref="formPreview"/>
          </perfect-scrollbar>
        </card>
      </div>
    </div>
    <input accept="application/JSON" ref="importHiddenInput" type="file" @change="formImport" class="invisible">
    <input accept="application/JSON" ref="importStyleHiddenInput" type="file" @change="formStyleImport" class="invisible">
  </div>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import Tabs from '../Utilities/Tabs.vue';
import Card from '../Utilities/Card.vue';
import DndFormItems from './DndFormItems.vue';
import FormItemConfig from './FormItemConfig.vue';
import FormPreview from './FormPreview.vue';
import FormTypeSelector from './FormTypeSelector.vue';
import HiddenFieldsEditor from './HiddenFieldsEditor.vue';
import FormFilterEditor from './FormFilterEditor.vue';
import InputColorPicker from '../Utilities/InputColorPicker.vue';
import AlertesEditor from './configs/AlertesEditor.vue';

export default {
  name: 'FormBuilder',
  components: {
    AlertesEditor,
    FormFilterEditor,
    HiddenFieldsEditor,
    FormTypeSelector,
    FormPreview,
    FormItemConfig,
    DndFormItems,
    Tabs,
    Card,
    InputColorPicker,
  },
  model: {
    prop: 'formContent',
    event: 'change',
  },
  props: {
    formContent: {
      type: Object,
      required: true,
    },
    previewStyle: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data()
  {
    return {
      selectedItemID: '',
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
    computedPreviewStyle()
    {
      return {
        ...this.previewStyle,
        marginTop: undefined,
        marginRight: undefined,
        marginBottom: undefined,
        marginLeft: undefined,
        paddingTop: undefined,
        paddingRight: undefined,
        paddingBottom: undefined,
        paddingLeft: undefined,
        border: undefined,
        outline: undefined,
      };
    },
    formType()
    {
      if (this.formContent.data)
        return this.formContent.data.formType || '';
      return '';
    },
    previewBackgroundColor: {
      get()
      {
        if (this.formContent.data)
          return this.formContent.data.previewBackgroundColor || { hex: '#ffffff' };
        return { hex: '#ffffff' };
      },
      set(newColor)
      {
        if (newColor)
          this.$emit('change', { ...this.formContent, data: { ...this.formContent.data || {}, previewBackgroundColor: newColor } });
      },
    },
    alerts: {
      get()
      {
        if (this.formContent.data)
          return this.formContent.data.alerts || {};
        return {};
      },
      set(newAlerts)
      {
        if (newAlerts)
          this.$emit('change', { ...this.formContent, data: { ...this.formContent.data || {}, alerts: newAlerts } });
      },
    },
    alreadyRegisteredConfig: {
      get()
      {
        if (this.formContent.data)
          return this.formContent.data.alreadyRegisteredConfig || {};
        return {};
      },
      set(newAlreadyRegisteredConfig)
      {
        if (newAlreadyRegisteredConfig)
          this.$emit('change', { ...this.formContent, data: { ...this.formContent.data || {}, alreadyRegisteredConfig: newAlreadyRegisteredConfig } });
      },
    },
    hiddenFields: {
      get()
      {
        if (this.formContent.data !== undefined)
          return this.formContent.data.hiddenFields || [];
        return [];
      },
      set(newHiddenFields)
      {
        this.$emit('change', { ...this.formContent, data: { ...this.formContent.data, hiddenFields: newHiddenFields } });
      },
    },
    selectedItem: {
      get()
      {
        return this.getItemFromID(this.selectedItemID);
      },
      set(newValue)
      {
        let found = false;
        const selectedItemID = this.selectedItemID;
        function findItem(innerFields, depth)
        {
          innerFields.forEach((item) => {
            if (item.id === selectedItemID)
            {
              found = !!(innerFields[innerFields.indexOf(item)] = newValue);
              return;
            }
            if (item.innerItems !== undefined)
              findItem(item.innerItems, depth + 1);
          });
        }
        const fields = _.cloneDeep(this.formContent.data.fields);
        findItem(fields, 0);
        if (found)
          this.$emit('change', { ...this.formContent, data: { ...this.formContent.data, fields } });
        this.selectedItemID = newValue.id;
      },
    },
    getTabs() {
      return [
        '<i class="fa fa-plus-circle text-secondary"></i>',
        '<i class="fa fa-pen text-success"></i>',
        '<i class="fas fa-filter text-info"></i>',
        '<i class="fa fa-eye-slash text-primary"></i>',
        '<i class="fas fa-exclamation-triangle text-danger"></i>',
      ];
    },
  },
  methods: {
    formExport()
    {
      const jsonData = JSON.stringify(this.formContent.data || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `form_${this.$store.getters.eventID}_${this.$store.getters.animID}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    importButtonClick() {
      this.$refs.importHiddenInput.click();
    },
    importStyleButtonClick() {
      this.$refs.importStyleHiddenInput.click();
    },
    removeAllStyleButtonClick()
    {
      function applyStyleRec(fields, style, depth = 0)
      {
        fields.forEach((item) => {
          item.style = style;
          if (item.innerItems)
            applyStyleRec(item.innerItems, style, depth + 1);
        });
      }
      const fields = _.cloneDeep(this.formContent.data.fields);
      applyStyleRec(fields, {});
      this.$emit('change', { ...this.formContent, data: { ...this.formContent.data, fields } });
    },
    regeneratesAllIds()
    {
      if (this.formContent.data && this.formContent.data.fields)
      {
        let fields = [];
        let hiddenFields = [];
        if (this.formContent.data.fields)
        {
          fields = _.cloneDeep(this.formContent.data.fields);
          this.generateNewIds(fields);
        }
        if (this.formContent.data.hiddenFields)
        {
          hiddenFields = _.cloneDeep(this.formContent.data.hiddenFields);
          hiddenFields.forEach((field) => {
            const rID = this.$chance.string(this.$newIDParams);
            field.id = `hiddenField-${rID}`;
          });
        }
        this.$emit('change', { ...this.formContent, data: { ...this.formContent.data, fields, hiddenFields } });
      }
    },
    generateNewIds(fields, depth = 0)
    {
      fields.forEach((field) => {
        if (field.id)
        {
          const rID = this.$chance.string(this.$newIDParams);
          field.id = `${field.type === 'group' ? 'formGroup' : 'formItem'}-${rID}`;
        }
        if (field.innerItems)
          this.generateNewIds(field.innerItems, depth + 1);
      });
    },
    formImport(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object)
          {
            this.generateNewIds(object.fields || {});
            this.$emit('change', { ...this.formContent, data: object });
          }
        } catch (ex)
        {
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
    formStyleImport(ev)
    {
      function applyStyleRec(fields, style, depth = 0)
      {
        fields.forEach((item) => {
          item.style = style;
          if (item.innerItems)
            applyStyleRec(item.innerItems, style, depth + 1);
        });
      }
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const style = JSON.parse(e.target.result);
          if (!style || !this.formContent.data || !this.formContent.data.fields)
            return;
          const fields = _.cloneDeep(this.formContent.data.fields);
          applyStyleRec(fields, style);
          this.$emit('change', { ...this.formContent, data: { ...this.formContent.data, fields } });
        } catch (ex)
        {
          console.log(`Invalid style file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
    formTypeSelected(newType)
    {
      if (newType === 'classic')
        this.$emit('change', { ...this.formContent, data: { ...this.formContent.data, formType: newType } });
      else if (newType === 'import')
      {
        this.$emit('change', { ...this.formContent, data: { ...this.formContent.data, formType: newType } });
        this.importButtonClick();
      }
    },
    removeItem(itemID)
    {
      function parseAndDelete(fields, depth)
      {
        fields.forEach((item, idx) => {
          if (item.id === itemID)
          {
            fields.splice(idx, 1);
            return;
          }
          if (item.innerItems)
            parseAndDelete(item.innerItems, depth + 1);
        });
      }
      if (this.selectedItemID === itemID)
      {
        this.selectedItemID = '';
        this.$refs.tabs.setActiveTab(0);
      }
      const fields = _.cloneDeep(this.formContent.data.fields);
      parseAndDelete(fields, 0);
      this.$emit('change', { ...this.formContent, data: { ...this.formContent.data, fields } });
    },
    editItem(itemID)
    {
      this.selectedItemID = itemID;
      this.$refs.tabs.setActiveTab(1); // Content tab
    },
    getItemFromID(itemID)
    {
      let out = undefined;
      function findItem(fields, depth)
      {
        fields.forEach((item) => {
          if (item.id === itemID)
          {
            out = item;
            return;
          }
          if (item.innerItems)
            findItem(item.innerItems, depth + 1);
        });
      }
      findItem(this.formContent.data.fields, 0);
      return out;
    },
  },
};

</script>

<style scoped>

</style>
