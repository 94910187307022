<template>
  <draggable tag="form" v-model="filterList" :group="{ name: 'formFilters' }" handle=".optionDragHandle" class="editorUIStyle">
    <template v-slot:header>
      <div class="row my-2" v-if="filterList.length <= 0">
        <div class="col-auto mx-auto">
          <p class="small text-muted">{{ dictionary.formModule.noConditionMessage }}</p>
        </div>
      </div>
      <div v-else class="row my-2">
        <div class="col-12">
          <p class="small"><i class="fas fa-exclamation-circle me-3 text-info"></i>{{ dictionary.formModule.conditionOrderMessage }}</p>
        </div>
      </div>
    </template>
    <div class="row mt-2" v-for="(filter, filterIdx) in filterList" :key="`filter-${filterIdx}`">
      <div class="col-auto d-flex align-items-center mx-0 ps-1 pe-2">
        <i class="fas fa-grip-lines text-dark optionDragHandle cursor-grab"></i>
      </div>
      <div class="col px-0">
        <div class="input-group px-0 mx-0">
          <EventDataSelect :value="filter.data || ''" @change="setDataForCondition(filterIdx, $event)" :allowed-types="allowedDatas" :includeStatus="true" :hideError="true"/>
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i v-if="filter.operator === 'eq'" class="fas fa-equals"></i>
            <i v-else-if="filter.operator === 'not-eq'" class="fas fa-not-equal"></i>
            <i v-else class="fas fa-question"></i>
          </button>
          <ul class="dropdown-menu">
            <li @click.prevent="setOperatorForCondition(filterIdx, 'eq')">
              <a class="dropdown-item" href="#"><i class="fas fa-equals"></i> {{ dictionary.formModule.equalLabel }}</a>
            </li>
            <li @click.prevent="setOperatorForCondition(filterIdx, 'not-eq')">
              <a class="dropdown-item" href="#"><i class="fas fa-not-equal"></i> {{ dictionary.formModule.differentLabel }}</a>
            </li>
          </ul>
          <input
              v-if="getDataValueType(filter.data || '') === 'input'"
              type="text"
              class="form-control"
              :placeholder="dictionary.empty"
              @change="setValueForCondition(filterIdx, $event.target.value)"
              :value="filter.value || ''">
          <select
              v-else class="form-select"
              id="filterValue"
              @change="setValueForCondition(filterIdx, $event.target.value)"
              :value="filter.value || ''">
            <option value="" selected>{{ dictionary.empty }}</option>
            <option
                v-for="(ch, chIdx ) in  getChoicesForData(filter.data || '')"
                :key="`value-opt-${chIdx}`"
                :value="ch">
              {{ ch }}
            </option>
          </select>
          <span class="input-group-text">pour</span>
          <select
              :value="filter.for || 'all'"
              @change="setPopulationForCondition(filterIdx, $event.target.value)"
              class="form-select"
              id="filterFor">
            <option disabled value="">{{ dictionary.formModule.forLabel }}</option>
            <option value="all">{{ dictionary.formModule.allFilterLabel }}</option>
            <option value="registered">{{ dictionary.formModule.registeredFilterLabel }}</option>
            <option value="unknown">{{ dictionary.formModule.unknownFilterLabel }}</option>
          </select>
        </div>
      </div>
      <div class="col-auto d-flex align-items-center">
        <button
            v-tooltip="dictionary.formModule.deleteConditionTooltip"
            class="btn btn-sm btn-danger py-0 px-1 text-center"
            @click.prevent="removeCondition(filterIdx)">
          <i class="fas fa-trash text-light"></i>
        </button>
      </div>
      <div class="col-11 offset-1">
        <div class="row">
          <div class="col-11">
            <div class="input-group px-0 mx-0">
              <span class="input-group-text"><i class="fas fa-rotate-90 fa-level-up-alt me-4"></i>{{ dictionary.formModule.actionLabel.toLowerCase() }}</span>
              <select class="form-select" id="filterAction" @change="setActionForCondition(filterIdx, $event.target.value)" :value="filter.action">
                <option disabled selected value="">{{ dictionary.formModule.actionLabel }}...</option>
                <template v-if="isHiddenField">
                  <option value="apply">Appliquer</option>
                  <option value="ignore">Ignorer</option>
                </template>
                <template v-else>
                  <option value="hide">{{ dictionary.formModule.hideActionLabel }}</option>
                  <option value="show">{{ dictionary.formModule.showActionLabel }}</option>
                  <option v-if="!IsBasicFormItem" value="required">{{ dictionary.formModule.requiredActionLabel }}</option>
                  <option v-if="!IsBasicFormItem" value="readonly">{{ dictionary.formModule.readonlyActionLabel }}</option>
                </template>
              </select>
              <span class="input-group-text">
                <input
                    class="form-check-input mt-0 me-3"
                    type="checkbox" :value="(filter.doElse ? filter.doElse : false).toString()"
                    :checked="filter.doElse ? filter.doElse : false"
                    @input="setDoElseForCondition(filterIdx, $event.target.value !== 'true')">
                <span class="filter.doElse ? '' : 'text-muted'">
                  {{ dictionary.formModule.elseLabel.toLowerCase() }}
                </span></span>
              <select
                  :class="filter.doElse ? '' : 'disabled'"
                  :disabled="!filter.doElse"
                  class="form-select" id="filterElse"
                  @change="setElseForCondition(filterIdx, $event.target.value)"
                  :value="filter.else || ''">
                <option disabled selected value="">{{ dictionary.formModule.elseLabel }}...</option>
                <template v-if="isHiddenField">
                  <option value="apply">Appliquer</option>
                  <option value="ignore">Ignorer</option>
                </template>
                <template v-else>
                  <option value="hide">{{ dictionary.formModule.hideActionLabel }}</option>
                  <option value="show">{{ dictionary.formModule.showActionLabel }}</option>
                  <option v-if="!IsBasicFormItem" value="required">{{ dictionary.formModule.requiredActionLabel }}</option>
                  <option v-if="!IsBasicFormItem" value="readonly">{{ dictionary.formModule.readonlyActionLabel }}</option>
                </template>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <div class="row my-2">
        <div class="col-auto ms-auto">
          <button
              class="btn btn-success btn-raised text-light"
              @click.prevent="addCondition">
            <i class="fas fa-plus-circle me-2"></i>{{ dictionary.formModule.addConditionButton }}
          </button>
        </div>
        <div v-if="filterList && filterList.length > 0" class="col-auto">
          <button
              class="btn btn-info btn-raised text-light"
              @click.prevent="exportConditions">
            <i class="fas fa-download me-2"></i>{{ dictionary.formModule.exportConditionButton }}
          </button>
        </div>
        <div class="col-auto me-auto">
          <confirmationContext
              @confirm="importButtonClick"
              ref="confirmationDeleteField"
              :introMessage="`<h6>Cette action va écraser les filtres déjà en place</h6>`"
              :confirmButtonContent="dictionary.confirm"
              :deniButtonContent="dictionary.no"/>
          <button
              class="btn btn-primary btn-raised text-light"
              @click.prevent="$refs.confirmationDeleteField.openContext();">
            <i class="fas fa-upload me-2"></i>{{ dictionary.formModule.importConditionButton }}
          </button>
        </div>
        <div class="col-12">
          <input accept="application/JSON" ref="importHiddenInput" type="file" @change="importConditions" style="visibility: hidden">
        </div>
      </div>
    </template>
  </draggable>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import EventDataSelect from '../Utilities/EventDataSelect.vue';
import confirmationContext from '../contexts/ConfirmationContext.vue';

export default {
  name: 'FormFilterEditor',
  props: {
    formItem: {
      type: Object,
      required: true,
    },
    isHiddenField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    confirmationContext,
    EventDataSelect,
    draggable,
  },
  model: {
    prop: 'formItem',
    event: 'change',
  },
  methods: {
    setPopulationForCondition(conditionIdx, value)
    {
      const filters = _.cloneDeep(this.filterList);
      filters[conditionIdx].for = value;
      this.filterList = filters;
    },
    setDoElseForCondition(conditionIdx, value)
    {
      const filters = _.cloneDeep(this.filterList);
      filters[conditionIdx].doElse = value;
      this.filterList = filters;
    },
    setElseForCondition(conditionIdx, value)
    {
      const filters = _.cloneDeep(this.filterList);
      filters[conditionIdx].else = value;
      this.filterList = filters;
    },
    setActionForCondition(conditionIdx, value)
    {
      const filters = _.cloneDeep(this.filterList);
      filters[conditionIdx].action = value;
      this.filterList = filters;
    },
    setValueForCondition(conditionIdx, value)
    {
      const filters = _.cloneDeep(this.filterList);
      filters[conditionIdx].value = value;
      this.filterList = filters;
    },
    setOperatorForCondition(conditionIdx, operator)
    {
      const filters = _.cloneDeep(this.filterList);
      filters[conditionIdx].operator = operator;
      this.filterList = filters;
    },
    setDataForCondition(conditionIdx, data)
    {
      const filters = _.cloneDeep(this.filterList);
      filters[conditionIdx].data = data;
      this.filterList = filters;
    },
    removeCondition(conditionIdx)
    {
      const filters = _.cloneDeep(this.filterList);
      filters.splice(conditionIdx, 1);
      this.filterList = filters;
    },
    addCondition()
    {
      const filters = _.cloneDeep(this.filterList);
      filters.push({
        data: '',
        value: '',
        operator: 'eq',
        for: 'all',
        action: 'show',
        doElse: '',
        else: '',
      });
      this.filterList = filters;
    },
    importButtonClick() {
      this.$refs.importHiddenInput.click();
    },
    importConditions(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object)
            this.filterList = object;
        } catch (ex)
        {
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
    exportConditions()
    {
      const jsonData = JSON.stringify(this.filterList || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `filters_${this.$chance.string(this.$newIDParams)}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    getDataValueType(dataID)
    {
      if (dataID === '__status__')
        return 'select';
      if (!dataID || !this.eventData[dataID] || !this.eventData[dataID].type)
        return 'input';
      switch (this.eventData[dataID].type)
      {
        case 'Radioboxes': return 'select';
        case 'Checkboxes': return 'select';
        case 'List': return 'select';
        case 'Workshops-Agendas': return 'select';
        default: return 'input';
      }
    },
    getChoicesForData(dataID)
    {
      if (dataID === '__status__')
        return [
          'Confirmed',
          'ToBeTreated',
          'Denied',
          'Waiting',
          'Deleted',
          'Invited',
        ];
      if (this.getDataValueType(dataID) !== 'select')
        return [];
      return this.eventData[dataID].choices || [];
    },
  },
  computed: {
    ...mapGetters(['eventData', 'dictionary']),
    allowedDatas() {
      return [
        'String',
        'Radioboxes',
        'Checkboxes',
        'StringMultiline',
        'Date',
        'DateTime',
        'Tab',
        'Photo',
        'Coloc',
        'Hotel',
        'Link',
        'List',
        'access_code',
      ];
    },
    filterList:
    {
      get()
      {
        return this.formItem.filters || [];
      },
      set(newFilters)
      {
        this.$emit('change', { ...this.formItem, filters: newFilters });
      },
    },
    IsBasicFormItem()
    {
      return (this.formItem.type === 'group' || this.formItem.type === 'separator' || this.formItem.type === 'text');
    },
  },
};

</script>

<style scoped>

</style>
