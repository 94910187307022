<template>
  <form @submit.stop.prevent>
    <input-image-upload v-model="source" title="Lien de l'image" class="mb-3"/>
    <ActionSelector v-model="action" :availableActions="['url', 'row', 'page', 'filter', 'modal', 'javascript']"/>
  </form>
</template>

<script>

import { mapGetters } from 'vuex';
import InputImageUpload from '../Utilities/InputImageUpload.vue';
import ActionSelector from '../Utilities/ActionSelector.vue';

export default {
  name: 'MediaConfig',
  components: { InputImageUpload, ActionSelector },
  model: {
    prop: 'media',
    event: 'change',
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    action: {
      get()
      {
        return this.media || {};
      },
      set(newActionObj)
      {
        this.$emit('change', { ...this.media, ...newActionObj });
      },
    },
    source: {
      get()
      {
        return this.media.src || '';
      },
      set(newSrc)
      {
        this.$emit('change', { ...this.media, src: newSrc });
      },
    },
  },
};

</script>

<style scoped>

</style>
