import crypto from 'crypto';
import { uuid } from 'vue-uuid';
import firebaseReference from '../FirebaseReference';

export default {
  /**
   * Get and return all the website medias added through the builder's getGoogleFontList()
   * @warning Theses medias are different from event's document
   * @param getters
   * @returns An array containing objects representing medias
   */
  async getWebsiteMedias({ getters })
  {
    let out = {};
    const fbRef = await firebaseReference(getters.tokenID);
    await fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('medias')
      .once('value', (snapshot) => {
        if (snapshot.val())
          out = { ...snapshot.val() };
      });
    return out;
  },

  /**
   * Remove a specific media from the list in base
   * @param getters
   * @param id: the id of the media you want to remove
   * @returns true on success
   */
  async removeFromMediaList({ getters }, { id })
  {
    console.log('remove media from list', id);
    const fbRef = await firebaseReference(getters.tokenID);
    return fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('medias')
      .child(id)
      .remove()
      .then(() => true);
  },

  /**
   * Add a document to the list in base
   * @param getters
   * @param payload
   * @returns true on success
   */
  async addToMediaList({ getters }, payload)
  {
    const media = {
      ...payload,
      date: Date.now(),
      id: uuid.v4(),
      uploadedBy: getters.workerData.name,
    };
    console.log('Add Media to list', payload.link);
    const fbRef = await firebaseReference(getters.tokenID);
    return fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('medias')
      .child(media.id)
      .set(media)
      .then(() => media);
  },

  /**
   * Remove a specific file from cloudinary, identified by he's cloudinary public_id
   * @param getters
   * @param fileName: the public_id of the file you want to remove
   * @returns true on success, false otherwise
   */
  async removeFileFromCloudinary({ getters }, { fileName })
  {
    const timestamp = Date.now().toString();
    let out = true;

    const hash = crypto.createHash('sha1');
    hash.write(`public_id=${fileName}&timestamp=${timestamp}qvxKQPUdSXQ_gxU4BxglNBsX-YE`);
    const signature = hash.digest('hex');

    const formData = new FormData();
    formData.append('api_key', '823397872269719');
    formData.append('timestamp', timestamp);
    formData.append('public_id', fileName);
    formData.append('signature', signature);

    await fetch('https://api.cloudinary.com/v1_1/captag/image/destroy', {
      method: 'POST',
      body: formData,
    }).then((response) => {
      out = response.ok;
      if (!response.ok)
        console.log(`Error while deleting file : ${response.statusText}`);
    });
    return out;
  },

  async removeFile({ getters }, { deleteURL })
  {
    let out = false;
    await fetch(deleteURL, {
      method: 'GET',
    }).then((response) => {
      out = response.ok;
      if (!response.ok)
        console.log(`Error while deleting file : ${response.statusText}`);
    });
    return out;
  },

  /**
   * Upload a font file to the captag cdn and return the access url
   * @param getters
   * @param fileData: the font file actual datas
   * @param fileName: the file name that will be used as public_id
   * @returns the file access URL
   */
  async uploadFont({ getters }, { fileData, fileName })
  {
    let out = undefined;
    const formData = new FormData();
    formData.append('', fileData, fileName);
    formData.append('apiKey', 'SjX6yhkY4RSlpE32x4xK');
    formData.append('folder', `${getters.eventID}/website/fonts`);

    await fetch('https://europe-west1-captag-events.cloudfunctions.net/uploads/uploadFile', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok)
          return response.json();
        console.log(`Error while uploading font : ${response.message}`);
        return {};
      })
      .then((data) => {
        if (data && data.url)
          out = data.url;
      })
      .catch((err) => console.error(err));
    return out;
  },
  async uploadMedia({ getters }, { fileData, fileName })
  {
    const formData = new FormData();
    formData.append('file', fileData, fileName);
    formData.append('apiKey', 'SjX6yhkY4RSlpE32x4xK');
    formData.append('folder', `${getters.eventID}/website/${getters.animID}`);

    // eslint-disable-next-line no-return-await
    return await fetch('https://europe-west1-captag-events.cloudfunctions.net/uploads/uploadFile', {
      method: 'POST',
      body: formData,
    }).then((response) => {
      if (response.ok)
        return response.json();
      console.log(`Error while uploading media : ${response.message}`);
      return {};
    }).catch((err) => console.error(err));
  },
};
