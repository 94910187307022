<template>
  <div class="h-100 d-flex justify-content-center align-items-center align-middle position-relative">
    <div class="row w-100 text-center">
      <div class="col-12 mb-5">
        <h1 class="title">Version incompatible</h1>
      </div>
      <div class="col-12 mb-5">
        <h5 class="subtitle">
          Ce site web utilise une version supérieur de l'éditeur de site, incompatible avec la version demandé
          <br>
          Merci de contacter Captag pour plus d'informations
        </h5>
      </div>
    </div>
    <a :href="pageUrl" target="_blank" class="btn btn-outline-light position-absolute bottom-0 end-0 mb-2 me-2"><i class="fas fa-expand"></i></a>
  </div>
</template>

<script>

export default {
  name: 'IncompatibleVersion.vue',
  computed: {
    pageUrl()
    {
      return window.location.href;
    },
  },
};

</script>

<style scoped>

</style>
