<template>
  <div class="row h-100" @click="selectedItemID = -1">
    <div class="col-3 h-100">
      <Tabs ref="tabs" class="w-100 h-100" :tabs="getTabs">
        <template v-slot:slot-0>
          <card class="h-100">
            <perfect-scrollbar class="pe-3 h-100" :options="{ suppressScrollX: true, wheelPropagation: false }">
              <NavbarMainConfig :navbarContent="navbarContent" @change="$emit('change', $event)"/>
            </perfect-scrollbar>
          </card>
        </template>
        <template v-slot:slot-1>
          <card class="h-100">
            <perfect-scrollbar class="pe-3 h-100" :options="{ suppressScrollX: true, wheelPropagation: false }">
              <NewNavbarItems
                  :selectedItemID="selectedItemID"
                  :navbarContent="navbarContent"
                  @change="$emit('change', $event)"
                  @selectItem="selectedItemID = $event"/>
            </perfect-scrollbar>
          </card>
        </template>
        <template v-slot:slot-2>
          <card class="h-100">
            <perfect-scrollbar class="h-100" :options="{ wheelPropagation: false, suppressScrollX: true }" @click.stop>
              <navbarItemConfigV2 v-if="isSelectedItem" v-model="selectedItem"/>
              <p v-else class="small text-muted text-center mt-4">Sélectionnez un élément pour l'éditer</p>
            </perfect-scrollbar>
          </card>
        </template>
      </Tabs>
    </div>
    <div class="col-9 px-4 my-auto">
      <div class="row bg-white w-100 navbar-preview">
        <div class="col-12">
          <navbar2Content :selectedItemID="selectedItemID"
                          :deploymentMode="false"
                          :content="navbarContent"
                          :editorMode="true"
                          @selectItem="selectedItemID = $event"
                          @change="$emit('change', $event)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import Navbar2Content from '../NormalModeContent/Navbar2Content.vue';
import NewNavbarItems from './NewNavbarItems.vue';
import Tabs from '../Utilities/Tabs.vue';
import Card from '../Utilities/Card.vue';
import NavbarMainConfig from './NavbarMainConfig.vue';
import NavbarItemConfigV2 from './NavbarItemConfigV2.vue';

export default {
  name: 'NavbarBuilderV2',
  components: {
    NavbarItemConfigV2,
    NavbarMainConfig,
    Navbar2Content,
    Tabs,
    Card,
    NewNavbarItems,
  },
  props: {
    navbarContent: {
      type: Object,
      required: true,
    },
    computedStyle: {
      type: Object,
      required: false,
    },
  },
  data()
  {
    return {
      selectedItemID: -1,
    };
  },
  watch: {
    selectedItemID(newSelectedItem) {
      if (newSelectedItem >= 0 && this.navbarContent.items && this.navbarContent.items[newSelectedItem])
        this.$refs.tabs.setActiveTab(2);
      else
        this.$refs.tabs.setActiveTab(1);
    },
  },
  computed: {
    isSelectedItem()
    {
      return (this.selectedItemID >= 0 && this.navbarContent.items && this.navbarContent.items[this.selectedItemID]);
    },
    selectedItem: {
      get()
      {
        if (this.isSelectedItem)
          return this.navbarContent.items[this.selectedItemID];
        return {};
      },
      set(newValue)
      {
        if (!this.isSelectedItem)
          return;
        const newItems = _.cloneDeep(this.navbarContent.items || []);
        newItems[this.selectedItemID] = newValue;
        this.$emit('change', { ...this.navbarContent, items: newItems });
      },
    },
    getTabs() {
      return [
        '<p class="text-secondary pb-0 mb-0"><span class="fa fa-pager"></span><br><span class="small"></span></p>',
        '<p class="text-secondary pb-0 mb-0"><span class="fa fa-plus-circle"></span><br><span class="small"></span></p>',
        '<p class="text-secondary pb-0 mb-0"><span class="fa fa-cogs"></span><br><span class="small"></span></p>',
      ];
    },
  },
};

</script>

<style scoped>

.navbar-preview {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}

</style>
