<template>
  <div class="confirmationField">
    <template v-if="!layout">
      <label :for="`confirmation-${confirmedFieldID}`" class="form-label mb-0 confirmationField" v-html="label"></label>
      <div v-if="confirmedField.format === 'password'" class="input-group">
        <input class="form-control" :id="`confirmation-${confirmedFieldID}`" :type="confirmedField.format || 'text'">
        <button class="btn btn-outline-secondary btn-password" type="button">
          <i class="fa fa-eye-slash" aria-hidden="true"></i>
        </button>
      </div>
      <input v-else class="form-control" :id="`confirmation-${confirmedFieldID}`" :type="confirmedField.format || 'text'" :required="!!required" :readonly="!!readonly">
    </template>
    <div v-else class="formField formItem row">
      <div class="col-4">
        <label :for="`confirmation-${confirmedFieldID}`" class="form-label mb-0 confirmationField" v-html="label"></label>
      </div>
      <div class="col-8">
        <div v-if="confirmedField.format === 'password'" class="input-group">
          <input class="form-control" :id="`confirmation-${confirmedFieldID}`" :type="confirmedField.format || 'text'">
          <button class="btn btn-outline-secondary btn-password" type="button">
            <i class="fa fa-eye-slash" aria-hidden="true"></i>
          </button>
        </div>
        <input v-else class="form-control" :id="`confirmation-${confirmedFieldID}`" :type="confirmedField.format || 'text'"  :required="!!required" :readonly="!!readonly">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FormConfirmationField',
  props: {
    confirmedField: {
      type: Object,
      required: true,
    },
    confirmedFieldID: {
      type: String,
      required: true,
    },
  },
  computed: {
    label()
    {
      if (this.required)
        return `${this.confirmedField.confirmationLabel || ''}${(this.confirmedField.confirmationLabel !== '') ? '<span v-if="required" class="text-danger ms-1">*</span>' : ''}`;
      return this.confirmedField.confirmationLabel || '';
    },
    layout()
    {
      return this.confirmedField.layout || false;
    },
    required()
    {
      return this.confirmedField.required || false;
    },
    readonly()
    {
      return this.confirmedField.readonly || false;
    },
  },
};

</script>

<style scoped>

</style>
