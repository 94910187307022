<template>
  <div class="row justify-content-center" data-fileUpload="True">
    <div class="col-auto mb-2 file-preview">
      <img style="width: 200px;" :class="`imagePreview${dataName || ''}`">
    </div>
    <div class="col-12">
      <div class="w-100" :class="btnAlignment">
        <button
            :style="btnStyle"
            :onclick="deploymentMode ? onClickString : ''"
            class="btn btn-primary btn-outline"
            type="button"
            v-html="placeholder"></button>
        <input
            :required="!!required"
            :name="dataName"
            :id="`${id}-input`"
            type="hidden"
            data-type="upload"
            value=""
            ignore-field="false"/>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'FormFileUpload',
  props: {
    formItem: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    isAccField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['eventID', 'styleFromObject']),
    btnStyle() {
      const getterStyle = this.styleFromObject(this.formItem.btnStyle || {});
      return getterStyle || {};
    },
    btnAlignment() {
      return this.formItem.btnStyle.alignment;
    },
    placeholder()
    {
      if (this.formItem.placeholder && this.formItem.placeholder !== 'default')
        return this.formItem.placeholder || '';
      return 'text-center';
    },
    required()
    {
      return this.formItem.required || false;
    },
    dataName()
    {
      if (this.isAccField)
        return `${this.formItem.data || ''}_acc_1`;
      return this.formItem.data || '';
    },
    allowedExtensionsString()
    {
      let out = '[';
      if (!this.formItem.extensions)
        return 'undefined';
      this.formItem.extensions.forEach((ext) => { out += `'${ext}',`; });
      out += ']';
      return out;
    },
    onClickString()
    {
      return `uploadFile('${this.eventID}', ` +
          `'${this.dataName || ''}',` +
          `'${this.formItem.directoryPath ? `/${this.formItem.directoryPath}` : '/'}',` +
          ` ${this.formItem.forceCropping || false},` +
          `${this.allowedExtensionsString},` +
          `${this.formItem.limitWidth || 'undefined'},` +
          `${this.formItem.limitHeight || 'undefined'},` +
          `'${this.formItem.widgetLanguage || 'fr'}', ` +
          `${this.formItem.limitSize || 'undefined'})`;
    },
  },
};

</script>

<style scoped>

</style>
