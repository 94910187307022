import getters from '../getters';

const columnMutations = {
  /**
   * Remove a column from a row
   * @param state
   * @param pageID: the id of the page containing the rows
   * @param row: the parent row of the column to delete
   * @param col: the col to delete the entire object
   */
  removeColumn(state, { row, col })
  {
    function removeColumnFromList(list)
    {
      if (list.indexOf(row) < 0)
        return false;
      const columns = list[list.indexOf(row)].columns;
      if (columns && columns.indexOf(col) >= 0)
      {
        columns.splice(columns.indexOf(col), 1);
        return true;
      }
      return false;
    }
    if (state.websiteContent.pages[getters.currentPage(state, getters)].rows)
      if (removeColumnFromList(state.websiteContent.pages[getters.currentPage(state, getters)].rows))
        return;
    if (state.websiteContent.header && state.websiteContent.header.rows)
      if (removeColumnFromList(state.websiteContent.header.rows))
        return;
    if (state.websiteContent.footer && state.websiteContent.footer.rows)
      removeColumnFromList(state.websiteContent.footer.rows);
  },
  /**
   * Set column width
   * @param state
   * @param layout: the new with of the column [1-12] (bootstrap size)
   * @param col: the column you want to edit the width
   */
  setColumnSize(state, { layout, col })
  {
    layout = layout > 12 ? 12 : layout;
    layout = layout < 1 ? 1 : layout;
    col.layout = layout;
  },
  /**
   * Set column ID
   * @param state
   * @param id: the new ID
   * @param col: the column you want to modify
   */
  setColumnID(state, { id, col })
  {
    col.id = id;
  },
  /**
   * set column values (style object)
   * @param state
   * @param values: the new values
   * @param col: the column you want to edit the values
   */
  setColumnValues(state, { col, values })
  {
    col.values = values;
  },
  /**
   * set column animations
   * @param state
   * @param animations: the new animations parameters
   * @param col: the column you want to edit the animations
   */
  setColumnAnimations(state, { col, animations })
  {
    col.animations = animations;
  },
  /**
   * Apply a filter to a column
   * @param state
   * @param filter: the ID of the filter you want to apply
   * @param col: the column you want to set a filter
   */
  setColumnFilter(state, { filter, col })
  {
    col.filter = filter;
  },
  /**
   * set Column content
   * @param state
   * @param content: the new content of the column
   * @param column: the column you want to edit the content
   */
  setColumnContent(state, { content, column })
  {
    column.content = content;
  },
};

export default columnMutations;
