<template>
  <div class="editorUIStyle">
    <label>Filtre PC / Mobile</label>
    <select v-model="deviceFilter" class="form-control">
      <option value="both">Tous les devices</option>
      <option value="desktop-only">PC uniquement</option>
      <option value="mobile-only">Mobile uniquement</option>
    </select>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'DeviceFilterSelector',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    deviceFilter: {
      get()
      {
        if (this.value)
        {
          if (this.value.displayDesktop && this.value.displayMobile)
            return 'both';
          if (!this.value.displayDesktop && this.value.displayMobile)
            return 'mobile-only';
          if (this.value.displayDesktop && !this.value.displayMobile)
            return 'desktop-only';
        }
        return 'both';
      },
      set(newValue)
      {
        if (newValue === 'both')
          this.$emit('change', { ...this.value || {}, displayDesktop: true, displayMobile: true });
        if (newValue === 'mobile-only')
          this.$emit('change', { ...this.value || {}, displayDesktop: false, displayMobile: true });
        if (newValue === 'desktop-only')
          this.$emit('change', { ...this.value || {}, displayDesktop: true, displayMobile: false });
      },
    },
  },
};

</script>

<style scoped>

</style>
