<template>
  <div class="container-fluid h-100 d-flex flex-column">
    <div class="row flex-shrink-1 my-4 mx-0 px-0">
      <div class="col-2">
        <button @click="$emit('cancel')" class="btn btn-primary btn-danger text-white ml-3">{{ dictionary.back.toUpperCase() }}</button>
      </div>
      <div class="col text-center text-secondary">
        <h2><i class="formPicto fas fa-box-open me-4 "></i><b>{{ dictionary.websiteWizard.templateExplorerTitle.toUpperCase() }}</b></h2>
        <p><i>{{ dictionary.websiteWizard.templateExplorerDescription }}</i></p>
      </div>
      <div class="col-2"/>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <button class="btn btn-primary btn-lg m-2" @click="typeTabs = 'simple'" :class="typeTabs === 'simple' ? 'btn-active' : 'btn-custom'">
          Template simple
        </button>
        <button class="btn btn-primary btn-lg m-2" @click="typeTabs = 'advanced'" :class="typeTabs === 'advanced' ? 'btn-active' : 'btn-custom'">
          Template avancé
        </button>
      </div>
    </div>
    <perfect-scrollbar class="flex-fill" :options="{ suppressScrollX: true, wheelPropagation: false }">
      <template v-if="typeTabs === 'simple'">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-12 p-3"  v-for="(template, templateIDX) in getSimpleWebsiteTemplates" :key="`template-${templateIDX}`">
            <div class="container-custom position-relative">
              <img class="background-custom" alt="website-preview" :src="template.picture"/>
              <div class="box-content w-100 d-flex justify-content-center align-items-center">
                <div class="row w-100">
                  <div class="col-12">
                    <h4 class="text-secondary mt-2"><b>{{ template.name }}</b></h4>
                  </div>
                  <div class="col-12">
                    <p class="text-secondary"><b>{{ template.description }}</b></p>
                  </div>
                  <div class="col-6">
                    <a :href="template.url" target="_blank" class="btn btn-info btn-raised w-100 text-white"><i class="fas fa-external-link-alt me-3"></i>{{ dictionary.websiteWizard.seeWebsiteLabel }}</a>
                  </div>
                  <div class="col-6">
                    <button @click="importClick(template)" class="btn btn-primary btn-raised text-white w-100"><i class="fas fa-cloud-download-alt me-3"></i>{{ dictionary.websiteWizard.importThisSiteLabel }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="typeTabs === 'advanced'">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-12 p-3"  v-for="(template, templateIDX) in getAdvancedWebsiteTemplates" :key="`template-${templateIDX}`">
            <div class="container-custom position-relative">
              <img class="background-custom" alt="website-preview" :src="template.picture"/>
              <div class="box-content w-100 d-flex justify-content-center align-items-center">
                <div class="row w-100">
                  <div class="col-12">
                    <h4 class="text-secondary mt-2"><b>{{ template.name }}</b></h4>
                  </div>
                  <div class="col-12">
                    <p class="text-secondary"><b>{{ template.description }}</b></p>
                  </div>
                  <div class="col-6">
                    <a :href="template.url" target="_blank" class="btn btn-info btn-raised w-100 text-white"><i class="fas fa-external-link-alt me-3"></i>{{ dictionary.websiteWizard.seeWebsiteLabel }}</a>
                  </div>
                  <div class="col-6">
                    <button @click="importClick(template)" class="btn btn-primary btn-raised text-white w-100"><i class="fas fa-cloud-download-alt me-3"></i>{{ dictionary.websiteWizard.importThisSiteLabel }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </perfect-scrollbar>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import WebsiteTemplates from '../../configuration/WebsiteTemplates.json';

export default {
  name: 'TemplateBrowser',
  data()
  {
    return {
      selectedTemplate: '',
      typeTabs: 'simple',
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
    getWebsiteTemplates()
    {
      return WebsiteTemplates;
    },
    getSimpleWebsiteTemplates()
    {
      const simpleTemplate = [];
      WebsiteTemplates.forEach((template) => {
        if (template.mode === 'simple' || !template.mode) {
          simpleTemplate.push(template);
        }
      });
      return simpleTemplate;
    },
    getAdvancedWebsiteTemplates()
    {
      const advancedTemplate = [];
      WebsiteTemplates.forEach((template) => {
        if (template.mode === 'advanced' || !template.mode) {
          advancedTemplate.push(template);
        }
      });
      return advancedTemplate;
    },
  },
  methods: {
    importClick(template)
    {
      fetch(template.content).then((response) => {
        if (response.ok)
          response.json().then((content) => {
            this.$emit('import', content || {});
          });
      });
    },
  },
};

</script>

<style scoped>
.btn-active{
  border: 1px solid #FF9800;
  background-color: white;
  color: #FF9800;
  font-weight: bold
}
.btn-active:hover{
  border: 1px solid #FF9800;
  background-color: white;
  color: #FF9800;
  font-weight: bold
}
.btn-custom {
  color: white;
  font-weight: bold;
}
.btn-custom:hover {
  color: white;
  font-weight: bold;
}
.background-custom{
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
.box-content{
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.container-custom:hover .background-custom {
  opacity: 0.3;
}
.container-custom:hover .box-content{
  opacity: 1;
}
.selectedTemplate {
  border: 2px dashed var(--bs-primary);
  border-radius: 6px;
}

</style>
