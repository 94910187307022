<template>
  <perfect-scrollbar class="h-100 px-3" :options="{ wheelPropagation: false, suppressScrollX: true }">
    <form @submit.stop.prevent>
      <div class="row my-2">
        <div class="col">
          <inputImageUpload :title="dictionary.propertiesModal.faviconLabel" :value="favicon" @change="favicon = $event" />
          <label class="small text-muted mb-3">{{ dictionary.propertiesModal.faviconSmall }}</label>
        </div>
        <div class="col">
          <label for="titleInput" class="form-label">{{ dictionary.propertiesModal.pageTitleLabel }} <span class="small text-muted">{{ dictionary.propertiesModal.pageTitleSmall }}</span></label>
          <input :value="title" @change="title = $event.target.value" type="text" class="form-control" id="titleInput" placeholder="">
        </div>
      </div>
      <div class="mb-2">
        <label for="typeBtnGroup" class="form-label">{{ dictionary.propertiesModal.websiteAccessLabel }}</label>
        <div id="typeBtnGroup" class="btn-group w-100" role="group" aria-label="website uniqueness button group">
          <input :checked="type === 'private'" @change="type = 'private'" type="radio" class="btn-check" name="typeBtn" id="typePrivateBtn" autocomplete="off" >
          <label class="btn btn-outline-secondary" for="typePrivateBtn"><i class="fas fa-key me-3"></i>{{ dictionary.propertiesModal.privateAccessText }}</label>
          <input @change="type = 'public'" :checked="type === 'public'" type="radio" class="btn-check" name="typeBtn" id="typePublicBtn" autocomplete="off" >
          <label class="btn btn-outline-secondary" for="typePublicBtn"><i class="fas fa-globe me-3"></i>{{ dictionary.propertiesModal.publicAccessText }}</label>
        </div>
      </div>
      <div class="mb-2">
        <label for="featuresBtnGroup" class="form-label">{{ dictionary.propertiesModal.featureEnablingLabel }}</label>
        <div id="featuresBtnGroup" class="btn-group w-100" role="group" aria-label="Basic checkbox toggle button group">
          <input v-model="quotas" type="checkbox" class="btn-check" id="quotaBtnCheck" autocomplete="off">
          <label class="btn btn-outline-success" for="quotaBtnCheck"><i class="fas fa-hand-paper me-3"></i> {{ dictionary.propertiesModal.quotasText }}</label>
          <input v-model="accompagnants" type="checkbox" class="btn-check" id="accBtnCheck" autocomplete="off">
          <label class="btn btn-outline-success" for="accBtnCheck"><i class="fas fa-user-friends me-3"></i> {{ dictionary.propertiesModal.accompagnantsText }}</label>
          <input v-model="colocation" type="checkbox" class="btn-check" id="colocBtnCheck" autocomplete="off">
          <label class="btn btn-outline-success" for="colocBtnCheck"><i class="fas fa-user-friends me-3"></i> Colocation</label>
          <input v-model="whiteList" type="checkbox" class="btn-check" id="whitelistBtnCheck" autocomplete="off">
          <label class="btn btn-outline-success" for="whitelistBtnCheck"><i class="fas fa-stream me-3"></i>Liste restreinte</label>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="mb-2">
            <label for="redirectionInput" class="form-label">{{ dictionary.propertiesModal.websiteRedirectLabel }}</label>
            <input :value="redirection" @change="redirection = $event.target.value" type="text" class="form-control" id="redirectionInput" placeholder="">
          </div>
        </div>
        <div class="col-6">
          <div class="mb-2">
            <label for="accessCodeInput" class="form-label">{{ dictionary.propertiesModal.websiteAccessCodeLabel }}</label>
            <div class="input-group">
              <input :value="accessCode" :type="!showAccessCode ? 'password' : 'text'" @change="accessCode = $event.target.value" class="form-control" id="accessCodeInput" placeholder="">
              <button class="btn btn-outline-secondary btn-password" type="button" @click="showAccessCode = !showAccessCode">
                <i class="fa" :class="showAccessCode ? 'fa-eye' : 'fa-eye-slash'" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="small">{{ dictionary.propertiesModal.mergedDatasLabel }}</label>
          <Multiselect
              id="mergeDatas"
              v-model="mergeDatas"
              :tag-placeholder="dictionary.propertiesModal.addThisData"
              :placeholder="dictionary.propertiesModal.typeOrSelectData"
              :options="dataList"
              :multiple="true"
              :taggable="true"
              @tag="onNewMergedData"
              label="title"
              track-by="variable"/>
        </div>
        <div class="col">
          <label class="small">{{ dictionary.propertiesModal.allowedDomainEmailsLabel }}</label>
          <Multiselect
              id="allowedDomainEmail"
              v-model="allowedDomainsEmails"
              :tag-placeholder="dictionary.propertiesModal.addThisDomain"
              :placeholder="dictionary.propertiesModal.typeOrSelectDomain"
              :multiple="true"
              :taggable="true"
              :options="[]"
              @tag="onNewAllowedDomainEmail"/>
        </div>
        <div class="col-12">
          <label class="small">Adresses IP authorisées<span class="small text-muted ms-2">Accepte les ip, plages (avec -) et les masques</span></label>
          <Multiselect
              id="allowedDomainEmail"
              v-model="allowedIPAddresses"
              :multiple="true"
              :taggable="true"
              :options="[]"
              @tag="onNewAllowedIpAddresses"/>
        </div>
      </div>
      <div class="mb-2">
        <div id="animationBtnGroup" class="btn-group w-100" role="group" aria-label="Basic checkbox toggle button group">
          <input v-model="filterAnimation" type="checkbox" class="btn-check" id="animationCheck" autocomplete="off">
          <label class="btn btn-outline-success" for="animationCheck"><i class="fas fa-sliders-h me-3"></i> Animation des filtres</label>
        </div>
      </div>
      <div class="my-3 w-100" v-if="filterAnimation">
        <label>Vitesse de l'animation</label>
        <VueSlider :min="100" :max="1000" v-model="filterAnimationSpeed"/>
      </div>
      <div>
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingErrorPage">
              <button class="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseErrorPage" aria-expanded="true" aria-controls="collapseOne">
                Page d'erreur site privé
              </button>
            </h2>
            <div id="collapseErrorPage" class="accordion-collapse collapse show" aria-labelledby="headingErrorPage" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <input-image-upload title="Image de fond" v-model="errorBg"/>
                <input-image-upload title="icon" v-model="errorLogo"/>
                <div>
                  <label for="redirectionInput" class="form-label">Message d'erreur</label>
                  <input :value="errorMessage" @change="errorMessage = $event.target.value" type="text" class="form-control" id="errorMessageInput" placeholder="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </perfect-scrollbar>
</template>

<script>

import { mapGetters } from 'vuex';
import VueSlider from 'vue-slider-component';
import Multiselect from 'vue-multiselect';
import InputImageUpload from '../../Utilities/InputImageUpload.vue';

export default {
  name: 'MainConfig',
  components: {
    InputImageUpload,
    Multiselect,
    VueSlider,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data()
  {
    return {
      showAccessCode: false,
    };
  },
  computed: {
    ...mapGetters(['dictionary', 'eventData']),
    dataList()
    {
      const out = [];
      Object.values(this.eventData).forEach((data) => {
        out.push(data);
      });
      return out;
    },
    title: {
      get()
      {
        return this.value.name || '';
      },
      set(newTitle)
      {
        this.$emit('change', { ...this.value, name: newTitle });
      },
    },
    redirection: {
      get()
      {
        return this.value.redirection || '';
      },
      set(newRedirection)
      {
        this.$emit('change', { ...this.value, redirection: newRedirection });
      },
    },
    favicon: {
      get()
      {
        return this.value.favicon || '';
      },
      set(newFavicon)
      {
        this.$emit('change', { ...this.value, favicon: newFavicon });
      },
    },
    unicity: {
      get()
      {
        return this.value.unicity || 'Email';
      },
      set(newUnicity)
      {
        this.$emit('change', { ...this.value, unicity: newUnicity });
      },
    },
    type: {
      get()
      {
        return this.value.type || 'public';
      },
      set(newType)
      {
        this.$emit('change', { ...this.value, type: newType });
      },
    },
    defaultLanguage: {
      get()
      {
        return this.value.language || 'FR';
      },
      set(newLanguage)
      {
        this.$emit('change', { ...this.value, language: newLanguage });
      },
    },
    quotas: {
      get()
      {
        return this.value.quotas || false;
      },
      set(newQuotas)
      {
        this.$emit('change', { ...this.value, quotas: newQuotas });
      },
    },
    accompagnants: {
      get()
      {
        return this.value.accompagnants || false;
      },
      set(newAccompagnants)
      {
        this.$emit('change', { ...this.value, accompagnants: newAccompagnants });
      },
    },
    colocation: {
      get()
      {
        return this.value.colocation || false;
      },
      set(newColocation)
      {
        this.$emit('change', { ...this.value, colocation: newColocation });
      },
    },
    whiteList: {
      get()
      {
        return this.value.whiteList || false;
      },
      set(newWhiteList)
      {
        this.$emit('change', { ...this.value, whiteList: newWhiteList });
      },
    },
    editing: {
      get()
      {
        return this.value.editing || false;
      },
      set(newLanguage)
      {
        this.$emit('change', { ...this.value, editing: newLanguage });
      },
    },
    accessCode: {
      get()
      {
        return this.value.access_code || '';
      },
      set(newAccessCode)
      {
        this.$emit('change', { ...this.value, access_code: newAccessCode });
      },
    },
    mergeDatas: {
      get()
      {
        if (!this.value.mergeDatas)
          return [];
        const out = [];
        this.value.mergeDatas.forEach((mergedData) => {
          if (this.eventData[mergedData])
            out.push(this.eventData[mergedData]);
          else
            out.push({ variable: mergedData, title: mergedData });
        });
        return out;
      },
      set(newMergeDatas)
      {
        const out = [];
        newMergeDatas.forEach((val) => { out.push(val.variable); });
        this.$emit('change', { ...this.value, mergeDatas: out });
      },
    },
    allowedDomainsEmails: {
      get()
      {
        return this.value.allowedDomainsEmails || [];
      },
      set(newAllowedDomainsEmails)
      {
        this.$emit('change', { ...this.value, allowedDomainsEmails: newAllowedDomainsEmails });
      },
    },
    allowedIPAddresses: {
      get()
      {
        return this.value.allowedIPAddresses || [];
      },
      set(newAllowedIPAddresses)
      {
        this.$emit('change', { ...this.value, allowedIPAddresses: newAllowedIPAddresses });
      },
    },
    filterAnimation: {
      get()
      {
        return this.value.filterAnimation || false;
      },
      set(newFilterAnimation)
      {
        this.$emit('change', { ...this.value, filterAnimation: newFilterAnimation });
      },
    },
    filterAnimationSpeed: {
      get()
      {
        return this.value.filterAnimationSpeed || 400;
      },
      set(newfilterAnimationSpeed)
      {
        this.$emit('change', { ...this.value, filterAnimationSpeed: newfilterAnimationSpeed });
      },
    },
    errorBg: {
      get()
      {
        return this.value.errorBg || '';
      },
      set(newErrorBg)
      {
        this.$emit('change', { ...this.value, errorBg: newErrorBg });
      },
    },
    errorLogo: {
      get()
      {
        return this.value.errorLogo || '';
      },
      set(newErrorLogo)
      {
        this.$emit('change', { ...this.value, errorLogo: newErrorLogo });
      },
    },
    errorMessage: {
      get()
      {
        return this.value.errorMessage || '';
      },
      set(newErrorMessage)
      {
        this.$emit('change', { ...this.value, errorMessage: newErrorMessage });
      },
    },
  },
  methods: {
    onNewMergedData(data)
    {
      this.mergeDatas = [...this.mergeDatas, { variable: data, title: data }];
    },
    onNewAllowedDomainEmail(domain)
    {
      this.allowedDomainsEmails = [...this.allowedDomainsEmails, domain];
    },
    onNewAllowedIpAddresses(adress)
    {
      this.allowedIPAddresses = [...this.allowedIPAddresses, adress];
    },
  },
};

</script>

<style scoped>

.btn-check:checked + .btn-outline-success {
  color: white;
}

</style>
