<template>
  <perfect-scrollbar v-if="loaded" class="h-100 pe-3">
    <!-- Main user -->
    <card :title="`<i class='fas fa-user me-3'></i>${this.dictionary.propertiesModal.userMessagesTitle}`">
      <div class="row">
        <div class="col-12">
          <h6><i class="status-icon fa fa-check-circle me-2"></i>{{ this.dictionary.propertiesModal.confirmedState }}<span v-if="confirmedMessages.length > 0" class="badge rounded-pill bg-success ms-2">{{ confirmedMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="confirmedMessages"
              :custom-label="customLabel"
              @select="addMessages('confirmedMessages', $event)"
              @remove="removeMessages('confirmedMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h6><i class="status-icon fa fa-question me-2"></i>{{ this.dictionary.propertiesModal.ToBeTreatedState }}<span v-if="toBeTreatedMessages.length > 0" class="badge rounded-pill bg-success ms-2">{{ toBeTreatedMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="toBeTreatedMessages"
              :custom-label="customLabel"
              @select="addMessages('toBeTreatedMessages', $event)"
              @remove="removeMessages('toBeTreatedMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h6><i class="status-icon fa fa-spinner me-2"></i>{{ this.dictionary.propertiesModal.invitedState }}<span v-if="invitedMessages.length > 0" class="badge rounded-pill bg-success ms-2">{{ invitedMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="invitedMessages"
              :custom-label="customLabel"
              @select="addMessages('invitedMessages', $event)"
              @remove="removeMessages('invitedMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h6><i class="status-icon fa fa-clock me-2"></i>{{ this.dictionary.propertiesModal.waitingState }}<span v-if="waitingMessages.length > 0" class="badge rounded-pill bg-success ms-2">{{ waitingMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="waitingMessages"
              :custom-label="customLabel"
              @select="addMessages('waitingMessages', $event)"
              @remove="removeMessages('waitingMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h6><i class="status-icon fa fa-times-circle me-2"></i>{{ this.dictionary.propertiesModal.deniedState }}<span v-if="deniedMessages.length > 0" class="badge rounded-pill bg-success ms-2">{{ deniedMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="deniedMessages"
              :custom-label="customLabel"
              @select="addMessages('deniedMessages', $event)"
              @remove="removeMessages('deniedMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h6><i class="status-icon fa fa-trash me-2"></i>{{ this.dictionary.propertiesModal.deletedState }}<span v-if="deletedMessages.length > 0" class="badge rounded-pill bg-success ms-2">{{ deletedMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="deletedMessages"
              :custom-label="customLabel"
              @select="addMessages('deletedMessages', $event)"
              @remove="removeMessages('deletedMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
    </card>
    <!-- ACC -->
    <card class="mt-3" :title="`<i class='fas fa-user-plus me-3'></i>${this.dictionary.propertiesModal.accMessagesTitle}`">
      <div class="row">
        <div class="col-12">
          <h6><i class="status-icon fa fa-check-circle me-2"></i>{{ this.dictionary.propertiesModal.confirmedState }}<span v-if="confirmedAccMessages.length > 0" class="badge rounded-pill bg-success">{{ confirmedAccMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="confirmedAccMessages"
              :custom-label="customLabel"
              @select="addMessages('confirmedAccMessages', $event)"
              @remove="removeMessages('confirmedAccMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h6><i class="status-icon fa fa-check-circle me-2"></i>{{ this.dictionary.propertiesModal.ToBeTreatedState }}<span v-if="toBeTreatedAccMessages.length > 0" class="badge rounded-pill bg-success">{{ toBeTreatedAccMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="toBeTreatedAccMessages"
              :custom-label="customLabel"
              @select="addMessages('toBeTreatedAccMessages', $event)"
              @remove="removeMessages('toBeTreatedAccMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h6><i class="status-icon fa fa-spinner me-2"></i>{{ this.dictionary.propertiesModal.invitedState }}<span v-if="invitedAccMessages.length > 0" class="badge rounded-pill bg-success ms-2">{{ invitedAccMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="invitedAccMessages"
              :custom-label="customLabel"
              @select="addMessages('invitedAccMessages', $event)"
              @remove="removeMessages('invitedAccMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h6><i class="status-icon fa fa-clock me-2"></i>{{ this.dictionary.propertiesModal.waitingState }}<span v-if="waitingAccMessages.length > 0" class="badge rounded-pill bg-success ms-2">{{ waitingAccMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="waitingAccMessages"
              :custom-label="customLabel"
              @select="addMessages('waitingAccMessages', $event)"
              @remove="removeMessages('waitingAccMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h6><i class="status-icon fa fa-times-circle me-2"></i>{{ this.dictionary.propertiesModal.deniedState }}<span v-if="deniedAccMessages.length > 0" class="badge rounded-pill bg-success ms-2">{{ deniedAccMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="deniedAccMessages"
              :custom-label="customLabel"
              @select="addMessages('deniedAccMessages', $event)"
              @remove="removeMessages('deniedAccMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
      <div class="row pb-3 mt-2">
        <div class="col-12">
          <h6><i class="status-icon fa fa-trash me-2"></i>{{ this.dictionary.propertiesModal.deletedState }}<span v-if="deletedAccMessages.length > 0" class="badge rounded-pill bg-success ms-2">{{ deletedAccMessages.length }} messages</span></h6>
        </div>
        <div class="col-12">
          <multiselect
              :options="serverMessages"
              :multiple="true"
              :close-on-select="false"
              :value="deletedAccMessages"
              :custom-label="customLabel"
              @select="addMessages('deletedAccMessages', $event)"
              @remove="removeMessages('deletedAccMessages', $event)"
              label="name"
              track-by="name"/>
        </div>
      </div>
    </card>
  </perfect-scrollbar>
  <div v-else class="row">
    <div class="col-auto mx-auto mt-4">
      <h3 class="text-muted">{{ this.dictionary.propertiesModal.messagesLoadingMessage }}</h3>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import Card from '../../Utilities/Card.vue';

export default {
  name: 'MessagesConfig',
  components: {
    Multiselect,
    Card,
  },
  model: {
    prop: 'Messages',
    event: 'change',
  },
  props: {
    Messages: {
      type: Object,
      required: true,
    },
  },
  data()
  {
    return {
      serverMessages: [],
      confirmedMessages: [],
      confirmedAccMessages: [],
      toBeTreatedMessages: [],
      toBeTreatedAccMessages: [],
      deniedMessages: [],
      deniedAccMessages: [],
      deletedMessages: [],
      deletedAccMessages: [],
      invitedMessages: [],
      invitedAccMessages: [],
      waitingMessages: [],
      waitingAccMessages: [],
      loaded: false,
    };
  },
  mounted()
  {
    this.$store.dispatch('getMessagesFromBase').then((messages) => {
      this.serverMessages = messages;
      this.serverMessages.forEach((message) => {
        if (this.Messages.confirmedMessages.includes(message.id))
          this.confirmedMessages.push(message);
        if (this.Messages.confirmedAccMessages.includes(message.id))
          this.confirmedAccMessages.push(message);
        if (this.Messages.toBeTreatedMessages.includes(message.id))
          this.toBeTreatedMessages.push(message);
        if (this.Messages.toBeTreatedAccMessages.includes(message.id))
          this.toBeTreatedAccMessages.push(message);
        if (this.Messages.deniedMessages.includes(message.id))
          this.deniedMessages.push(message);
        if (this.Messages.deniedAccMessages.includes(message.id))
          this.deniedAccMessages.push(message);
        if (this.Messages.deletedMessages.includes(message.id))
          this.deletedMessages.push(message);
        if (this.Messages.deletedAccMessages.includes(message.id))
          this.deletedAccMessages.push(message);
        if (this.Messages.invitedMessages.includes(message.id))
          this.invitedMessages.push(message);
        if (this.Messages.invitedAccMessages.includes(message.id))
          this.invitedAccMessages.push(message);
        if (this.Messages.waitingMessages.includes(message.id))
          this.waitingMessages.push(message);
        if (this.Messages.waitingAccMessages.includes(message.id))
          this.waitingAccMessages.push(message);
      });
      this.loaded = true;
    });
  },
  computed: {
    ...mapGetters(['dictionary']),
  },
  methods: {
    customLabel({ name, type }) {
      return `[${type}] ${name}`;
    },
    addMessages(messages, event) {
      const out = this.Messages[messages];
      out.push(event.id);
      this[messages].push(event);
      this.$emit('change', { ...this.Messages, messages: out });
    },
    removeMessages(messages, event) {
      const out = this.Messages[messages];
      const index = out.indexOf(event.id);
      out.splice(index, 1);
      this[messages] = [...this[messages].filter((element) => element !== event)];
      this.$emit('change', { ...this.Messages, messages: out });
    },
  },
};
</script>

<style scoped>

.status-icon.fa-check-circle {
  color: #66BB6A !important;
}

.status-icon.fa-times-circle {
  color: #ff0000 !important;
}

.status-icon.fa-trash {
  color: #546E7A !important
}

.status-icon.fa-clock {
  color: #0277bd !important;
}

.status-icon.fa-spinner {
  color: rgb(249, 168, 37) !important;
}

</style>
