<template>
  <modal @closed="onClose" :name="modalID" :width="'90%'" :height="'90%'" :styles="{ overflow: 'initial' }" ref="modal">
    <card class="h-100">
      <template v-slot:header-left><p class="text-white mb-0" v-html="title"></p></template>
      <template v-slot:header-right>
        <div class="row w-auto h-100">
          <div class="col-auto" style="width: 200px;"><input-color-picker v-model="backgroundColor"/></div>
          <div class="col-auto"><button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button></div>
        </div>
      </template>
      <div class="h-100 w-100 pt-5" :style="backgroundStyle">
        <ck-editor5 v-model="content"/>
      </div>
    </card>
  </modal>
</template>

<script>

import { mapGetters } from 'vuex';
import Card from '../Utilities/Card.vue';
import CkEditor5 from '../Utilities/CkEditor.vue';
import InputColorPicker from '../Utilities/InputColorPicker.vue';

export default {
  name: 'TextEditorModal',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Éditeur de texte',
    },
    value: {
      type: String,
      required: true,
    },
  },
  components: {
    InputColorPicker,
    CkEditor5,
    Card,
  },
  data()
  {
    return {
      id: '',
      backgroundColor: { hex8: '#ffffff' },
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
    backgroundStyle: {
      get()
      {
        return { backgroundColor: this.backgroundColor.hex8 || '#ffffff' };
      },
    },
    content: {
      get()
      {
        return this.value || '';
      },
      set(newVal)
      {
        this.$emit('change', newVal);
      },
    },
    modalID()
    {
      if (!this.id)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.id = `codeEditorModal${this.$chance.string(this.$newIDParams)}`;
      return this.id;
    },
  },
  methods: {
    onClose()
    {
      this.opened = false;
    },
    open()
    {
      this.$modal.show(this.id);
      this.opened = true;
    },
    close()
    {
      this.opened = false;
      this.$modal.hide(this.id);
      document.removeEventListener('keydown', this.onKeyDown);
    },
  },
};

</script>

<style>

.cke_textarea_inline, .cke_show_borders {
    border: unset!important;
    outline: unset!important;
}

</style>
