<template>
  <div>
    <div class="w-100">
      <div id="header" class="header container" >
        <draggable v-model="draggableHeaderRowList" group="rows" handle=".rowDragHandle">
          <AdvancedRow v-for="(row, index) in draggableHeaderRowList" :key="index" :row="row" :row-index="index"/>
          <template v-if="draggableHeaderRowList.length <= 0" v-slot:header>
            <div class="row">
              <div class="col-12">
                <div class="text-center d-flex align-items-center" style="height: 150px;">
                  <p class="text-muted mx-auto my-3"><i class="far fa-2x fa-caret-square-up"></i><br>Le header est vide<br>déplacez une ligne ici</p>
                </div>
              </div>
            </div>
          </template>
        </draggable>
        <div class="row text-muted my-5">
          <div class="col"><hr></div><div class="col-auto"><i class="fas fa-arrow-up me-4"></i>Header<i class="fas fa-arrow-up ms-4"></i></div><div class="col"><hr></div>
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="container" :id="pageName">
        <draggable v-model="draggableRowList" group="rows" handle=".rowDragHandle">
          <AdvancedRow v-for="(row, index) in draggableRowList" :key="index" :row="row" :row-index="index"/>
        </draggable>
      </div>
    </div>
    <div class="w-100">
      <div id="footer" class="footer container">
        <div class="row text-muted my-5">
          <div class="col"><hr></div><div class="col-auto"><i class="fas fa-arrow-down me-4"></i>Footer<i class="fas fa-arrow-down ms-4"></i></div><div class="col"><hr></div>
        </div>
        <draggable v-model="draggableFooterRowList" group="rows" handle=".rowDragHandle">
          <template v-if="draggableFooterRowList.length <= 0" v-slot:header>
            <div class="row">
              <div class="col-12">
                <div class="text-center d-flex align-items-center" style="height: 100px;">
                  <p class="text-muted mx-auto my-3"><i class="far fa-2x fa-caret-square-down"></i><br>Le Footer est vide<br>déplacez une ligne ici</p>
                </div>
              </div>
            </div>
          </template>
          <AdvancedRow v-for="(row, index) in draggableFooterRowList" :key="index" :row="row" :row-index="index"/>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import AdvancedRow from './AdvancedRow.vue';

export default {
  name: 'AdvancedWebsitePreview',
  components: { draggable, AdvancedRow },
  props: {
    page: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters([
      'rowList',
      'pageRowList',
      'headerRowList',
      'footerRowList',
      'currentPage',
    ]),
    draggableRowList: {
      get() {
        return (this.page) ? this.pageRowList(this.page) : this.rowList;
      },
      set(rowList)
      {
        this.$store.commit('updateRowList', { pageID: this.currentPage, rowList, dbSync: true });
      },
    },
    draggableHeaderRowList: {
      get() {
        return this.headerRowList || [];
      },
      set(headerRowList)
      {
        this.$store.commit('updateHeaderRows', { rowList: headerRowList, dbSync: true });
      },
    },
    draggableFooterRowList: {
      get() {
        return this.footerRowList || [];
      },
      set(footerRowList)
      {
        this.$store.commit('updateFooterRows', { rowList: footerRowList, dbSync: true });
      },
    },
    pageName()
    {
      if (this.page && this.pageList[this.page])
        return this.pageList[this.page].name || this.page;
      return '';
    },
  },
};

</script>

<style scoped>

</style>
