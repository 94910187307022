<template>
  <context-menu style="transform: unset;" @ctx-close="onClose" :id="`${this.contextId}-rename-context-menu`" :ref="`${this.contextId}-navbarSubItem-ctxMenu`" >
    <style-editor class="m-3" v-model="style" style="width: 400px;" :properties="['fontFamily', 'fontWeight', 'fontSize', 'color', 'spacing']">
      <template v-slot:form-start>
        <div class="mb-3">
          <label for="renameInput" class="form-label required">Texte de l'élément <code-compatible-badge/></label>
          <input required type="text" class="form-control required" id="renameInput" v-model="content">
        </div>
        <action-selector class="mb-3" v-model="action"/>
      </template>
    </style-editor>
  </context-menu>
</template>

<script>

import contextMenu from 'vue-context-menu';
import ActionSelector from '../Utilities/ActionSelector.vue';
import InputColorPicker from '../Utilities/InputColorPicker.vue';
import FontSelector from '../Utilities/FontSelector.vue';
import CodeCompatibleBadge from '../Utilities/CodeCompatibleBadge.vue';
import StyleEditor from '../Utilities/StyleEditor.vue';

export default {
  name: 'NavbarSubItemContext',
  components: {
    StyleEditor,
    CodeCompatibleBadge,
    FontSelector,
    InputColorPicker,
    ActionSelector,
    contextMenu,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data()
  {
    return {
      ctxId: '',
    };
  },
  computed: {
    style: {
      get()
      {
        return this.value.style || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.value, style: newStyle });
      },
    },
    action: {
      get()
      {
        return this.value.action || {};
      },
      set(newAction)
      {
        this.$emit('change', { ...this.value, action: newAction });
      },
    },
    content: {
      get()
      {
        return this.value.content || '';
      },
      set(newContent)
      {
        this.$emit('change', { ...this.value, content: newContent });
      },
    },
    contextId()
    {
      if (!this.ctxId)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.ctxId = this.$chance.string(this.$newIDParams);
      return this.ctxId;
    },
  },
  methods: {
    onClose()
    {
      this.$refs[`${this.contextId}-navbarSubItem-ctxMenu`].ctxVisible = true;
    },
    open()
    {
      this.$refs[`${this.contextId}-navbarSubItem-ctxMenu`].open();
      this.currName = this.value;
    },
    close()
    {
      this.$refs[`${this.contextId}-navbarSubItem-ctxMenu`].ctxVisible = false;
    },
  },
};

</script>

<style scoped>

</style>
