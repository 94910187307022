<template>
  <div class="btn-group editorUIStyle">
    <button id="toolboxDropdown" type="button" class="btn btn-success dropdown-toggle fw-bold text-white" data-bs-toggle="dropdown" aria-expanded="false">
      {{ dictionary.leftBar.toolboxTitle }} <i class="fas fa-toolbox mx-2"></i>
    </button>
    <ul class="dropdown-menu" aria-labelledby="toolboxDropdown">
      <li class="cursor-pointer"><a class="dropdown-item" @click="uploadMedia"><i class="fas fa-photo-video me-3"></i>{{ dictionary.leftBar.uploadMediaLabel }}</a></li>
      <li class="cursor-pointer"><a class="dropdown-item" @click="$refs.mediaLibraryModal.open()"><i class="fas fa-book-open me-3"></i>{{ dictionary.leftBar.mediaLibraryLabel }}</a></li>
      <li class="cursor-pointer"><a class="dropdown-item" @click="$refs.fontawesomePickerModal.open()"><i class="fab fa-font-awesome-flag me-3"></i>{{ dictionary.leftBar.faExplorerLabel }}<sup>©</sup></a></li>
      <li><a class="dropdown-item" href="#" @click="exportSite"><i class="fas fa-download me-3"></i>{{ dictionary.leftBar.exportSiteLabel }}</a></li>
    </ul>
    <FontAwesomePickerModal ref="fontawesomePickerModal"/>
    <ImageLibraryModal ref="mediaLibraryModal" :title="`<i class='fas fa-book-open me-3'></i>${dictionary.leftBar.mediaLibraryLabel}`"/>
    <input readonly class="form-control visually-hidden" :value="mediaLink" id="hiddenMediaLinkInput">
  </div>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters, mapState } from 'vuex';
import FontAwesomePickerModal from '../Modals/FontAwesomePickerModal.vue';
import ImageLibraryModal from '../Modals/ImageLibraryModal.vue';

export default {
  name: 'ToolboxWidget',
  components: { ImageLibraryModal, FontAwesomePickerModal },
  data()
  {
    return {
      mediaLink: '',
    };
  },
  computed: {
    ...mapState(['websiteContent']),
    ...mapGetters(['dictionary', 'eventID', 'animID', 'language']),
  },
  methods: {
    uploadMedia()
    {
      window.cptgUploader.init('identifiantUnique', `${this.eventID}/website/${this.animID}`, {
        apiKey: 'SjX6yhkY4RSlpE32x4xK',
        sources: ['local'],
        lang: this.language || 'fr',
        theme: 'light',
        useEditor: false,
        forceSquareCrop: false,
        maxFileSize: 20,
      },
      (result) => {
        this.$store.dispatch('addToMediaList', {
          deleteURL: result.deleteURL || '',
          fileID: result.fileID || '',
          link: result.originalURL ? `${result.originalURL}?p=resize-xl` : '',
          thumbnail: result.thumbURL || '',
          title: `media-${this.$chance.string(this.$newIDParams)}`,
        }).then((media) => {
          this.uploadSuccess();
          this.$emit('change', media.link);
        });
      }).open();
    },
    exportSite()
    {
      const jsonData = JSON.stringify(this.websiteContent || {}, null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `website_${this.$chance.string(this.$newIDParams)}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
  },
  notifications: {
    copyLinkNotif: {
      type: VueNotifications.types.success,
      title: 'Lien du fichier copié !',
      message: 'Le lien du fichié a été copié dans votre presse papier !',
    },
    uploadSuccess: {
      type: VueNotifications.types.success,
      title: 'Image uploadé !',
      message: 'Image uploadé avec succès.',
    },
    uploadError: {
      type: VueNotifications.types.error,
      title: 'Erreur',
      message: 'Une erreur est survenue lors de l\'envois de l\'image.',
    },
  },
};

</script>

<style scoped>

</style>
