<template>
  <Tabs class="h-100" :tabs="['Configuration', 'Style']">
    <template v-slot:slot-0>
      <perfect-scrollbar class="h-100 px-3 editorUIStyle" :options="{ suppressScrollX: true, wheelPropagation: false }">
        <FormInputConfig v-if="itemType === 'input'" :formItem="formItem" @change="$emit('change', $event)"/>
        <FormTextConfig v-else-if="itemType === 'text'" :formItem="formItem" @change="$emit('change', $event)"/>
        <FormCodeConfig v-else-if="itemType === 'code'" :formItem="formItem" @change="$emit('change', $event)"/>
        <FormSeparatorConfig v-else-if="itemType === 'separator'" :formItem="formItem" @change="$emit('change', $event)"/>
        <FormTextareaConfig v-else-if="itemType === 'textarea'" :formItem="formItem" @change="$emit('change', $event)"/>
        <FormMultipleChoicesConfig v-else-if="itemType === 'multipleChoices'" :formItem="formItem" @change="$emit('change', $event)"/>
        <FormFileConfig v-else-if="itemType === 'file'" :formItem="formItem" @change="$emit('change', $event)"/>
        <FormValidateButtonConfig v-else-if="itemType === 'validateButton'" :formItem="formItem" @change="$emit('change', $event)"/>
        <FormGroupConfig v-else-if="itemType === 'group'" :formItem="formItem" @change="$emit('change', $event);"/>
        <div v-else class="text-center">
          <span class="text-muted small">{{ dictionary.formModule.unknownType }} : {{ itemType }}</span>
        </div>
      </perfect-scrollbar>
    </template>
    <template v-slot:slot-1>
      <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
        <StyleEditor
            v-model="style"
            :properties="[
                      'spacing',
                      'fontFamily',
                      'fontWeight',
                      'fontSize',
                      'color',
                      'hr',
                      'alignment',
                      'backgroundColor',
                      'border',
                      'borderRadius',
                    ]">
          <template v-slot:form-end>
            <div class="mb-3">
              <label class="small">{{ dictionary.customClass }}</label>
              <Multiselect
                  id="customClasses"
                  v-model="customClass"
                  :tag-placeholder="dictionary.addThisClass"
                  :placeholder="dictionary.selectOrTypeAClass"
                  :options="defaultCustomClasses"
                  :multiple="true"
                  :taggable="true"
                  @tag="onNewClass"/>
            </div>
          </template>
        </StyleEditor>
      </perfect-scrollbar>
    </template>
  </Tabs>
</template>

<script>

import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import FormInputConfig from './configs/FormInputConfig.vue';
import FormTextConfig from './configs/FormTextConfig.vue';
import FormTextareaConfig from './configs/FormTextareaConfig.vue';
import FormMultipleChoicesConfig from './configs/FormMultipleChoicesConfig.vue';
import FormFileConfig from './configs/FormFileConfig.vue';
import StyleEditor from '../Utilities/StyleEditor.vue';
import FormValidateButtonConfig from './configs/FormValidateButtonConfig.vue';
import FormSeparatorConfig from './configs/FormSeparatorConfig.vue';
import defaultCustomClasses from '../../configuration/defaultCustomClasses.json';
import FormGroupConfig from './configs/FormGroupConfig.vue';
import FormCodeConfig from './configs/FormCodeConfig.vue';
import Tabs from '../Utilities/Tabs.vue';

export default {
  name: 'FormItemConfig',
  components: {
    Tabs,
    FormGroupConfig,
    FormSeparatorConfig,
    FormValidateButtonConfig,
    StyleEditor,
    FormFileConfig,
    FormMultipleChoicesConfig,
    FormTextareaConfig,
    FormTextConfig,
    FormInputConfig,
    Multiselect,
    FormCodeConfig,
  },
  model: {
    prop: 'formItem',
    event: 'change',
  },
  props: {
    formItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    defaultCustomClasses()
    {
      return defaultCustomClasses;
    },
    itemType() {
      return this.formItem.type;
    },
    style: {
      get()
      {
        return this.formItem.style || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.formItem, style: newStyle });
      },
    },
    customClass: {
      set(newCustomClasses)
      {
        this.$emit('change', { ...this.formItem, customClasses: [...newCustomClasses] });
      },
      get()
      {
        return this.formItem.customClasses || [];
      },
    },
  },
  methods: {
    onNewClass(newTag)
    {
      this.customClass = [...this.customClass, newTag];
    },
  },
};

</script>

<style scoped>

</style>
