<template>
  <div class="NewDnDButtons row pt-2 pb-2 px-2 pt-2 d-flex align-items-center bg-secondary">
    <div class="col-auto" v-tooltip="'Pour ajouter des lignes et colonnes sur le site,\n cliquez et deplacez les 2 icons a droite dans la vue'"><i class="text-light fas fa-question-circle"></i></div>
    <div class="col-auto">
      <draggable
          :disabled="isDragDisabled"
          @start="onDragCol = true"
          @end="onDragCol = false"
          class="dragArea"
          :list="rows"
          :group="{ name: 'rows', pull: (isDragDisabled) ? false : 'clone', put: false }"
          :clone="onCloneRow">
        <div v-if="!onDragCol" class="btn btn-outline-primary"
             :style="isDragDisabled ? 'opacity: 0.65' : ''"
             :class="(isDragDisabled) ? 'cursor-disabled' : 'cursor-grab'">
          <i class="fa fa-grip-lines"></i>
        </div>
        <div v-else class="ghost row p-0">
          <div class="col-12 row p-0">
            <Row class="col-12" :isDndPreview="true" :row="{ columns: [], id: 'previewColRow', values: {} }"/>
          </div>
        </div>
      </draggable>
    </div>
    <div class="col-auto">
      <draggable
          :disabled="isDragDisabled"
          @start="onDragRow = true"
          @end="onDragRow = false"
          class="dragArea"
          :list="columns"
          :group="{ name: 'cols', pull: (isDragDisabled) ? false : 'clone', put: false }"
          :clone="onCloneCol">
        <div v-if="!onDragRow" class="btn btn-outline-primary"
             :style="isDragDisabled ? 'opacity: 0.65' : ''"
             :class="(isDragDisabled) ? 'cursor-disabled' : 'cursor-grab'">
          <i class="fa fa-columns"></i>
        </div>
        <div v-else class="ghost col-md-12">
          <div class="row">
            <Column :isDndPreview="true" :column="{ id: 'previewCol', layout: 12 }" :row="{ columns: [], id: 'previewColRow', values: {} }"/>
          </div>
        </div>
      </draggable>
    </div>
    <div class="col-auto">
      <PagesWidget/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import Column from '../NormalModeContent/Column.vue';
import Row from '../NormalModeContent/Row.vue';
import PagesWidget from '../Utilities/PagesWidget.vue';

export default {
  name: 'BottomBar',
  components: {
    PagesWidget,
    draggable,
    Column,
    Row,
  },
  data()
  {
    return {
      onDragCol: false,
      onDragRow: false,
      rows: [
        {
          columns: [],
          id: 'newDnDColumnButtonBaseRow',
          values: {},
        },
      ],
      columns: [
        {
          id: 'newDnDColumnButtonBaseCol',
          layout: 12,
          content: {},
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['activeWebsiteFilter', 'dictionary']),
    isDragDisabled()
    {
      return this.activeWebsiteFilter !== undefined && this.activeWebsiteFilter !== '';
    },
  },
  methods: {
    onCloneCol(obj)
    {
      obj.id = this.$chance.string(this.$newIDParams);
      return { ...obj };
    },
    onCloneRow(obj)
    {
      obj.id = this.$chance.string(this.$newIDParams);
      return { ...obj };
    },
  },
};

</script>

<style scoped>

.NewDnDButtons .ghost {
  display: none;
}

.NewDnDButtons {
  z-index: 1000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

</style>
