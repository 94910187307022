import { firebaseAction } from 'vuexfire';
import firebaseReference from '../FirebaseReference';

export default {
  /**
   * Set the preferred url to the base
   * @param url: the url you want to set as preferred
   */
  setPreferredUrl: firebaseAction(async function callback({ getters, dispatch }, { url })
  {
    if (url === undefined)
      return {};
    const fbRef = await firebaseReference(getters.tokenID);
    fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('preferredUrl')
      .child('url')
      .set(url);
    return dispatch('setUrlInsc', { urlInsc: url });
  }),

  /**
   * Add a domain in the association table
   * @param getters
   * @param domain: the new domain
   */
  async addDomain({ getters }, { domain })
  {
    if (!domain)
      return;
    const sluggedDomain = getters.stringToSlug(domain);
    const fbRef = await firebaseReference(getters.tokenID);
    fbRef.ref('cloudfunctions')
      .child('landing')
      .child(sluggedDomain)
      .set(getters.path);
  },

  /**
   * Add a custom Url to the list in firebase
   * @param getters
   * @param domain: the slugged url, registered in the association table
   * @param url: the original domain, used to display in the builder config and access
   */
  async addCustomUrl({ getters }, { domain, url })
  {
    const fbRef = await firebaseReference(getters.tokenID);
    return fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('domainAndUrl')
      .child(domain)
      .set(url);
  },

  async removeDomain({ getters }, { domain })
  {
    if (!domain)
      return;
    console.log(`removing domain : ${domain}`);
    const fbRef = await firebaseReference(getters.tokenID);
    fbRef.ref('cloudfunctions')
      .child('landing')
      .child(domain)
      .set({});
  },

  async isDomainUsed({ getters }, { domain })
  {
    const fbRef = await firebaseReference(getters.tokenID);

    let found = false;
    const sluggedDomain = getters.stringToSlug(domain);
    await fbRef.ref('cloudfunctions')
      .child('landing')
      .child(sluggedDomain)
      .once('value', (snapshot) => {
        found = !!snapshot.val();
      });
    return found;
  },

  /**
   * Get and return all the landings associated with the website path
   * Lot of documentation because it's logic can be tricky
   * @param getters
   * @returns an array containing the domains
   */
  async getDomains({ getters })
  {
    const fbRef = await firebaseReference(getters.tokenID);

    // first we get all the landings
    let allLandings = {};
    await fbRef.ref('cloudfunctions')
      .child('landing')
      .once('value', (snapshot) => {
        if (snapshot.val())
          allLandings = { ...snapshot.val() };
      });

    // Then we gather the customs url registered in the builder's data
    let customUrls = {};
    await fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('domainAndUrl')
      .once('value', (snapshot) => { customUrls = snapshot.val() || {}; });

    // we keep only the one related to our path
    const pathRelatedLanding = {};
    Object.entries(allLandings).forEach(([landing, path]) => {
      if (path === getters.path)
        pathRelatedLanding[landing] = path;
    });

    // then we need to output a simpler array with 'url' or 'domain'
    const out = {};
    Object.keys(pathRelatedLanding).forEach((key) => {
      if (customUrls[key] !== undefined)
        out[customUrls[key]] = 'url';
      else
        out[key] = 'domain';
    });
    return out;
  },

  /**
   * Set the default url used by the Captag's EMS
   * @param getters
   * @param urlInsc the new "default" url
   */
  setUrlInsc({ getters }, { urlInsc })
  {
    firebaseReference(getters.tokenID).then((fbRef) => {
      fbRef.ref('animations')
        .child(getters.path)
        .child('config')
        .child('urlInsc')
        .set(urlInsc);
    });
  },
};
