<template>
  <form @submit.stop.prevent>
    <div>
      <label class="form-label mb-2">{{ dictionary.formModule.inputTypeLabel }}</label>
      <select v-model="format" class="form-select" aria-label="itemFormat">
        <option value="select">{{ dictionary.formModule.selectInputType }}</option>
        <option value="radio">{{ dictionary.formModule.radioInputType }}</option>
        <option value="checkbox">{{ dictionary.formModule.checkboxInputType }}</option>
      </select>
    </div>
    <div class="mb-2">
      <label>{{ dictionary.formModule.dataLabel }}</label>
      <EventDataSelect v-model="data" :allowed-types="['Radioboxes', 'Checkboxes', 'List', 'Workshops-Agendas', 'rgpd', 'Coloc']" />
    </div>
    <div>
      <label for="FormItemLabelInput" class="form-label">{{ dictionary.formModule.inputLabelLabel }}<CodeCompatibleBadge class="ms-2" position="top"/></label>
      <div class="input-group">
        <input :value="formItem.label || ''" @change="$emit('change', { ...formItem, label: $event.target.value })" type="text" class="form-control" id="FormItemLabelInput">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{ layoutDisplayButton }}</button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" @click="layout = 'top'">{{ dictionary.formModule.labelTopPositionLabel }}</a></li>
          <li><a class="dropdown-item" @click="layout = 'left'">{{ dictionary.formModule.labelLeftPositionLabel }}</a></li>
          <li><a class="dropdown-item" @click="layout = 'left-aligned'">{{ dictionary.formModule.labelLeftPositionLabel }} aligné</a></li>
          <li v-if="format === 'select'"><a class="dropdown-item" @click="layout = 'floating'">Floating</a></li>
        </ul>
      </div>
    </div>
    <div v-if="format === 'select'">
      <label for="FormItemPlaceholderInput" class="form-label">{{ dictionary.formModule.placeholderLabel }}</label>
      <input :value="formItem.placeholder || ''" @change="$emit('change', { ...formItem, placeholder: $event.target.value })" type="text" class="form-control" id="FormItemPlaceholderInput">
    </div>
    <div class="mb-2">
      <label for="FormItemSmallLabelInput" class="form-label">{{ dictionary.formModule.descriptionLabel }}</label>
      <div class="input-group">
        <input :value="formItem.smallLabel || ''" @change="$emit('change', { ...formItem, smallLabel: $event.target.value })" type="text" class="form-control" id="FormItemSmallLabelInput">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="true">{{ descriptionLayout === 'bottom' ? dictionary.formModule.descBottomPositionLabel : dictionary.formModule.descTopPositionLabel }}</button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" @click="descriptionLayout = 'top'">{{ dictionary.formModule.descTopPositionLabel }}</a></li>
          <li><a class="dropdown-item" @click="descriptionLayout = 'bottom'">{{ dictionary.formModule.descBottomPositionLabel }}</a></li>
        </ul>
      </div>
    </div>
    <div class="row mb-3 mt-2" v-if="format === 'checkbox'">
      <div class="col-6">
        <div>
          <label for="FormItemMaximumInput" class="form-label">{{ dictionary.formModule.minimum }}</label>
          <input v-model="minimum" type="number" class="form-control" id="FormItemMaximumInput" :placeholder="dictionary.formModule.noLimitPlaceholder">
        </div>
      </div>
      <div class="col-6">
        <div>
          <label for="FormItemMinimumInput" class="form-label">{{ dictionary.formModule.maximum }}</label>
          <input v-model="maximum" type="number" class="form-control" id="FormItemMinimumInput" :placeholder="dictionary.formModule.noLimitPlaceholder">
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <div class="form-check form-switch">
          <input v-model="required" class="form-check-input" type="checkbox" id="FormItemRequiredSwitch">
          <label class="form-check-label" for="FormItemRequiredSwitch">{{ dictionary.formModule.requiredLabel }}</label>
        </div>
      </div>
      <div class="col">
        <div class="form-check form-switch">
          <input v-model="readonly" class="form-check-input" type="checkbox" id="FormItemReadonlySwitch">
          <label class="form-check-label" for="FormItemReadonlySwitch">{{ dictionary.formModule.readonlyLabel }}</label>
        </div>
      </div>
      <div class="col" v-if="hasQuotasOnData">
        <div class="form-check form-switch">
          <input v-model="quotas" class="form-check-input" type="checkbox" id="FormItemQuotaSwitch">
          <label class="form-check-label" for="FormItemQuotaSwitch">{{ dictionary.formModule.displayQuotasLabel }}</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="hidden" class="form-check-input" type="checkbox" id="FormItemHideSwitch">
          <label class="form-check-label" for="FormItemHideSwitch">Masquer ce champs</label>
        </div>
      </div>
    </div>
    <div class="row mx-1 mb-2 p-1" style="border: solid 1px var(--bs-primary); border-radius: 5px" v-if="hasQuotasOnData && quotas">
      <div class="col-12 mt-3 mb-1">
        <label class="mb-2">{{ dictionary.formModule.quotasTextLabel }}<CodeCompatibleBadge class="ms-2" position="top"/></label>
        <input :value="quotasData.content" @change="quotasData = { ...quotasData, content: $event.target.value }" class="form-control">
      </div>
      <div class="col-12 mb-3">
        <label class="mb-2">Texte choix indisponibles<CodeCompatibleBadge class="ms-2" position="top"/></label>
        <input :value="quotasData.contentFull" @change="quotasData = { ...quotasData, contentFull: $event.target.value }" class="form-control">
      </div>
      <div class="col-12 mb-3">
        <InputColorPicker
          :label="dictionary.formModule.textColor"
          v-model="quotasData.color" />
      </div>
    </div>
    <card :showFooter="true">
      <template v-slot:header-left>
        <h6 class="m-0 text-light">Choix<CodeCompatibleBadge class="ms-2" position="top"/>
          <span v-if="isMissingOptions" class="ms-2 text-danger small">Des options sont manquantes</span>
        </h6>
      </template>
      <template v-slot:header-right>
        <select class="form-control" v-model="optionsOrganisation">
          <option value="">Chaque option sur une ligne</option>
          <option value="oneLine">Une seule ligne</option>
        </select>
      </template>
      <perfect-scrollbar class="pe-3" style="max-height: 200px;" :options="{ suppressScrollX: true }">
        <h5 v-if="options.length <= 0" class="text-muted small text-center">{{ dictionary.formModule.addChoiceLabel }}</h5>
        <draggable tag="form" v-model="options" :group="{ name: 'formOptions' }" handle=".optionDragHandle">
          <div class="row" v-for="(opt, optIdx) in options" :key="`opt-${optIdx}`">
            <div class="col-auto d-flex align-items-center">
              <i class="fas fa-grip-lines text-dark optionDragHandle cursor-grab"></i>
            </div>
            <div class="col pe-0">
              <div class="input-group mb-1">
                <span class="input-group-text"><i class="fas fa-tags"></i></span>
                <input @change="setOptionLabel(optIdx, $event.target.value)" :value="opt.label" type="text" class="form-control" placeholder="">
                <span class="input-group-text"><i class="fas fa-database"></i></span>
                <input @change="setOptionValue(optIdx, $event.target.value)" :value="opt.value" type="text" class="form-control" placeholder="">
              </div>
            </div>
            <div class="col-auto d-flex align-items-center">
              <button v-tooltip="dictionary.formModule.deleteOptionTooltip" class="btn btn-sm btn-danger py-0 text-center" @click.prevent="removeOption(optIdx)">
                <i class="fas fa-trash text-light"></i>
              </button>
              <button v-tooltip="dictionary.formModule.defaultOptionTooltip" class="d-none btn btn-sm btn-success py-0 text-center ms-2" @click.prevent="defaultOption(optIdx)">
                <i v-if="opt.default" class="fas fa-check-circle"></i>
                <i v-else class="fas fa-circle"></i>
              </button>
            </div>
          </div>
        </draggable>
      </perfect-scrollbar>
      <template v-slot:footer>
        <div class="row text-center">
          <div class="col">
            <button @click.prevent="generateOptions" class="h-100 btn btn-sm btn-raised btn-primary text-light"><i class="fas fa-cogs me-2"></i>{{ dictionary.formModule.generateOptionButton }}</button>
          </div>
          <div class="col">
            <button @click.prevent="orderOptions" class="h-100 btn btn-sm btn-raised btn-info text-light"><i class="fas fa-chevron-right me-2"></i>{{ dictionary.formModule.sortOptionsButton }}</button>
          </div>
          <div class="col">
            <button @click.prevent="addEmptyOption" class="h-100 btn btn-sm btn-raised btn-success text-light"><i class="fas fa-plus-circle me-2"></i>{{ dictionary.formModule.addOptionButton }}</button>
          </div>
          <div class="col">
            <button @click.prevent="removeAllOptions" class="h-100 btn btn-sm btn-raised btn-danger text-light"><i class="fas fa-trash me-2"></i>Supprimer les options</button>
          </div>
        </div>
      </template>
    </card>
  </form>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import CodeCompatibleBadge from '../../Utilities/CodeCompatibleBadge.vue';
import Card from '../../Utilities/Card.vue';
import EventDataSelect from '../../Utilities/EventDataSelect.vue';
import InputColorPicker from '../../Utilities/InputColorPicker.vue';

export default {
  name: 'FormMultipleChoicesConfig',
  components: {
    InputColorPicker,
    CodeCompatibleBadge,
    Card,
    draggable,
    EventDataSelect,
  },
  props: {
    formItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['eventData', 'dictionary']),
    hasQuotasOnData() {
      return this.data !== '' && this.eventData[this.data] &&
          this.eventData[this.data].quotas &&
          this.eventData[this.data].quotas.some((element) => element > 0);
    },
    isMissingOptions()
    {
      if (!this.data ||
        !this.eventData ||
        !this.eventData[this.data] ||
        !this.eventData[this.data].choices ||
        this.eventData[this.data].type === 'Workshops-Agendas')
        return false;
      const optionsList = [];
      let foundError = false;
      this.options.forEach((opt) => optionsList.push(opt.value));
      foundError = !(this.eventData[this.data].choices.every((ch) => optionsList.includes(ch)));
      return foundError;
    },
    hidden: {
      get()
      {
        return this.formItem.hidden || false;
      },
      set(newHidden)
      {
        this.$emit('change', { ...this.formItem, hidden: newHidden });
      },
    },
    options: {
      get()
      {
        return this.formItem.options || [];
      },
      set(optionsList)
      {
        return this.$emit('change', { ...this.formItem, options: optionsList });
      },
    },
    format: {
      get()
      {
        return this.formItem.format || '';
      },
      set(newFormat)
      {
        this.$emit('change', { ...this.formItem, format: newFormat });
      },
    },
    readonly: {
      get()
      {
        return this.formItem.readonly || false;
      },
      set(newReadonly)
      {
        this.$emit('change', { ...this.formItem, readonly: newReadonly });
      },
    },
    minimum: {
      get()
      {
        return this.formItem.min || '';
      },
      set(newMinimum)
      {
        this.$emit('change', { ...this.formItem, min: newMinimum });
      },
    },
    maximum: {
      get()
      {
        return this.formItem.max || '';
      },
      set(newMaximum)
      {
        this.$emit('change', { ...this.formItem, max: newMaximum });
      },
    },
    optionsOrganisation: {
      get()
      {
        return this.formItem.optionsOrganisation || '';
      },
      set(newOrganisation)
      {
        this.$emit('change', { ...this.formItem, optionsOrganisation: newOrganisation });
      },
    },
    required: {
      get()
      {
        return this.formItem.required || false;
      },
      set(newRequired)
      {
        this.$emit('change', { ...this.formItem, required: newRequired });
      },
    },
    quotas: {
      get()
      {
        return this.formItem.quotas || false;
      },
      set(newQuota)
      {
        this.$emit('change', { ...this.formItem, quotas: newQuota });
      },
    },
    quotasData: {
      get()
      {
        return this.formItem.quotasData || {};
      },
      set(newQuotasData)
      {
        this.$emit('change', { ...this.formItem, quotasData: newQuotasData });
      },
    },
    data: {
      get()
      {
        return this.formItem.data || '';
      },
      set(newData)
      {
        this.$emit('change', { ...this.formItem, data: newData });
      },
    },
    descriptionLayout: {
      get()
      {
        return this.formItem.descriptionLayout || 'bottom';
      },
      set(newDescriptionLayout)
      {
        this.$emit('change', { ...this.formItem, descriptionLayout: newDescriptionLayout });
      },
    },
    layout: {
      get()
      {
        return this.formItem.layout || '';
      },
      set(newLayout)
      {
        this.$emit('change', { ...this.formItem, layout: newLayout });
      },
    },
    layoutDisplayButton()
    {
      if (typeof this.layout === 'boolean' && this.layout === true)
        return this.dictionary.formModule.labelTopPositionLabel;
      if (typeof this.layout === 'boolean' && this.layout === false)
        return this.dictionary.formModule.labelLeftPositionLabel;
      switch (this.layout)
      {
        case 'top': return this.dictionary.formModule.labelTopPositionLabel;
        case 'left': return this.dictionary.formModule.labelLeftPositionLabel;
        case 'floating': return 'Floating';
        default: return this.dictionary.formModule.labelTopPositionLabel;
      }
    },
  },
  methods: {
    async generateOptions()
    {
      if (!this.data || !this.eventData || !this.eventData[this.data] || !this.eventData[this.data].choices)
      {
        this.cannotGenerateOptionsNotifs();
        return;
      }
      const optionsList = [];
      if (this.eventData[this.data].type === 'Workshops-Agendas')
      {
        await this.$store.dispatch('getAgendasFromIds', { ids: this.eventData[this.data].choices }).then((agendas) => {
          Object.values(agendas).forEach((ag) => {
            optionsList.push({ label: ag.name, value: ag.id });
          });
        });
      }
      else
      {
        this.eventData[this.data].choices.forEach((ch) => {
          optionsList.push({ label: ch, value: ch });
        });
      }
      this.options = [...optionsList];
      this.generateOptionsNotifs();
    },
    orderOptions()
    {
      this.options.sort((a, b) => {
        if (a.label < b.label)
          return -1;
        if (a.label > b.label)
          return 1;
        return 0;
      });
    },
    removeOption(optIdx)
    {
      const options = [...this.options];
      options.splice(optIdx, 1);
      this.options = options;
    },
    removeAllOptions()
    {
      this.options = [];
      this.removeOptionsNotifs();
    },
    addEmptyOption()
    {
      this.options = [...this.options, { label: '', value: '' }];
    },
    setOptionLabel(optionIdx, newLabel)
    {
      const opt = [...this.options];
      opt[optionIdx] = { label: newLabel, value: opt[optionIdx].value };
      this.options = [...opt];
    },
    setOptionValue(optionIdx, newValue)
    {
      const opt = [...this.options];
      opt[optionIdx] = { label: opt[optionIdx].label, value: newValue };
      this.options = [...opt];
    },
  },
  notifications: {
    cannotGenerateOptionsNotifs: {
      type: VueNotifications.types.error,
      title: 'Géneration des options impossible',
      message: 'La donnée associé ne comporte pas de choix ou n\'est pas d\'un type adapté !',
    },
    generateOptionsNotifs: {
      type: VueNotifications.types.success,
      title: 'Options généré !',
      message: 'La liste des options a été généré depuis la base de donnée',
    },
    removeOptionsNotifs: {
      type: VueNotifications.types.warn,
      title: 'Options supprimées !',
      message: 'La liste des options à été supprimée',
    },
  },
};

</script>

<style scoped>

</style>
