<template>
  <modal :name="id" :width="'90%'" height="90%">
    <card class="h-100">
      <template v-slot:header-left><h6 class="text-white mb-0" v-html="title"></h6></template>
      <template v-slot:header-right>
        <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button>
      </template>
      <FormBuilder :previewStyle="computedStyle" v-model="formData"/>
    </card>
  </modal>
</template>

<script>

import { mapGetters } from 'vuex';
import FormBuilder from '../FormBuilder/FormBuilder.vue';
import Card from '../Utilities/Card.vue';

export default {
  name: 'FormBuilderModal',
  components: {
    Card,
    FormBuilder,
  },
  model: {
    prop: 'formContent',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Editeur de formulaire',
    },
    formContent: {
      type: Object,
      required: true,
    },
    computedStyle: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  mounted()
  {
    if (!this.id) {
      this.id = `FormBuilderModal${this.$chance.string(this.$newIDParams)}`;
    }
  },
  data()
  {
    return {
      id: '',
    };
  },
  computed: {
    ...mapGetters(['dictionary']),
    formData: {
      get()
      {
        return this.formContent || {};
      },
      set(data)
      {
        this.$emit('change', data);
      },
    },
  },
  methods: {
    open()
    {
      this.$modal.show(this.id);
    },
    close()
    {
      this.$modal.hide(this.id);
    },
  },
};

</script>

<style scoped>

</style>
