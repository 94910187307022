<template>
  <button class="btn btn-primary btn-raised text-light w-100" @click.prevent="onEditClick">{{ dictionary.leftBar.textConfigText }}</button>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'TextConfig.vue',
  model: {
    prop: 'content',
    event: 'change',
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['dictionary', 'selectedColumn']),
  },
  methods: {
    onEditClick()
    {
      this.$EventBus.$emit('editColumn', this.selectedColumn);
    },
  },
};

</script>

<style scoped>

</style>
