<template>
  <div class="editorUIStyle">
    <label v-if="label" for="filterSelect">{{ label }}</label>
    <select aria-describedby="badFilterMessage" id="filterSelect" v-model="selectedFilter" class="form-select">
      <option value="">
        <template v-if="Object.keys(websiteFilters).length <= 0">{{ dictionary.filterSelector.noCreatedFilterLabel }}</template>
        <template v-else>{{ dictionary.filterSelector.noFilterLabel }}</template>
      </option>
      <option v-for="(filter, idx) in websiteFilters" :key="`filter-opt-${idx}`" :value="idx">{{ filter.name }}</option>
    </select>
    <div v-if="selectedFilter !== '' && !filterExist" id="badFilterMessage" class="form-text text-danger">{{ dictionary.filterSelector.deleteFilterMessage }} ({{ selectedFilter }})</div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'FilterSelector',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['websiteFilters', 'dictionary']),
    filterExist()
    {
      return (Object.keys(this.websiteFilters).length > 0 && this.websiteFilters[this.selectedFilter]);
    },
    selectedFilter: {
      get()
      {
        return this.value || '';
      },
      set(newFilterId)
      {
        this.$emit('change', newFilterId);
      },
    },
  },
};

</script>

<style scoped>

</style>
