<template>
  <form @submit.stop.prevent>
    <idEditor title="Identifiant" v-model="id" class="mb-3"/>
    <div class="mb-3">
      <label class="form-label">Agencement des éléments</label>
      <select v-model="itemsDisposition" class="form-select">
        <option value="">En lignes</option>
        <option value="column">En colonnes</option>
      </select>
    </div>
    <card v-if="itemsDisposition === 'column'" title="Parametres d'agencement des colonnes" class="mb-3">
      <div class="mb-3">
        <label class="form-label"><i class="fas fa-text-width me-2"></i>Largeur des éléments</label>
        <select v-model="itemsWidth" class="form-select">
          <option value="">Égale</option>
          <option value="grow">Automatique</option>
          <option value="shrink">Minimum</option>
        </select>
      </div>
      <alignmentProperties :noDefault="true" class="mb-3" label="<i class='fas fa-arrows-alt-h me-2'></i>Alignements des éléments" v-model="columnAlignment"/>
      <div>
        <label class="form-label"><i class="fas fa-mobile-alt me-2"></i>Comportement mobile</label>
        <select v-model="mobileItemsDisposition" class="form-select">
          <option value="">Rester en colonnes</option>
          <option value="row">En ligne</option>
        </select>
      </div>
    </card>
    <div class="row mb-3">
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="hidden" class="form-check-input" type="checkbox" id="FormItemHideSwitch">
          <label class="form-check-label" for="FormItemHideSwitch">Masquer ce groupe</label>
        </div>
      </div>
      <div class="col">
        <div class="form-check form-switch">
          <input v-model="accMode" class="form-check-input" type="checkbox" id="FormItemReadonlySwitch">
          <label class="form-check-label" for="FormItemReadonlySwitch">{{ dictionary.formModule.accGroupLabel }}</label>
        </div>
      </div>
    </div>
    <template v-if="accMode">
      <div class="mb-3">
        <label  for="addButtonLabelInput" class="form-label">{{ dictionary.formModule.addButtonTextLabel }}<CodeCompatibleBadge class="ms-2"/></label>
        <input :disabled="!accMode" :value="formItem.addButtonLabel || ''" @change="$emit('change', { ...formItem, addButtonLabel: $event.target.value })" type="text" class="form-control" id="addButtonLabelInput">
      </div>
      <div class="mb-3">
        <label class="form-label">Style du bouton de suppression</label>
        <select v-model="removeAccButtonType" class="form-select">
          <option value="bottom">En pied de formulaire (supprime la dernière entrée)</option>
          <option value="topRight">Croix en haut a droite de chaque entrée</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label">Couleur de la croix de suppression</label>
        <select v-model="removeAccButtonColor" class="form-select">
          <option value="black">Noir</option>
          <option value="white">Blanc</option>
        </select>
      </div>
      <div class="mb-3" v-if="removeAccButtonType === 'bottom'">
        <label for="deleteButtonLabelInput" class="form-label">{{ dictionary.formModule.delButtonTextLabel }}<CodeCompatibleBadge class="ms-2"/></label>
        <input :disabled="!accMode" :value="formItem.deleteButtonLabel || ''" @change="$emit('change', { ...formItem, deleteButtonLabel: $event.target.value })" type="text" class="form-control" id="deleteButtonLabelInput">
      </div>
      <div class="mb-3">
        <label for="maxAccInput" class="form-label">{{ dictionary.formModule.maxAccLabel }}</label>
        <input :disabled="!accMode" v-model="maxAcc" type="number" class="form-control" id="maxAccInput">
      </div>
      <card v-if="maxAcc !== 1" :title="dictionary.formModule.buttonStyleLabel">
        <StyleEditor
            v-model="buttonStyle"
            :properties="['spacing', 'hr', 'borderRadius', 'border', 'color', 'backgroundColor', 'alignment', 'fontFamily', 'fontWeight']"
            :noButtons="true"/>
      </card>
    </template>
  </form>
</template>

<script>

import { mapGetters } from 'vuex';
import IdEditor from '../../Utilities/IdEditor.vue';
import CodeCompatibleBadge from '../../Utilities/CodeCompatibleBadge.vue';
import StyleEditor from '../../Utilities/StyleEditor.vue';
import Card from '../../Utilities/Card.vue';
import AlignmentProperties from '../../Utilities/AlignmentProperties.vue';

export default {
  name: 'FormGroupConfig',
  components: {
    AlignmentProperties,
    Card,
    CodeCompatibleBadge,
    IdEditor,
    StyleEditor,
  },
  props: {
    formItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    hidden: {
      get()
      {
        return this.formItem.hidden || false;
      },
      set(newHidden)
      {
        this.$emit('change', { ...this.formItem, hidden: newHidden });
      },
    },
    buttonStyle: {
      get()
      {
        return this.formItem.buttonStyle || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.formItem, buttonStyle: newStyle });
      },
    },
    id: {
      get()
      {
        return this.formItem.id;
      },
      set(newID)
      {
        this.$emit('change', { ...this.formItem, id: newID });
      },
    },
    accMode: {
      get()
      {
        return this.formItem.accMode || false;
      },
      set(newValue)
      {
        this.$emit('change', { ...this.formItem, accMode: newValue });
      },
    },
    maxAcc: {
      get()
      {
        return this.formItem.maxAcc || 0;
      },
      set(newMax)
      {
        this.$emit('change', { ...this.formItem, maxAcc: Number(newMax) });
      },
    },
    removeAccButtonType: {
      get()
      {
        return this.formItem.removeBtnType || 'bottom';
      },
      set(newType)
      {
        this.$emit('change', { ...this.formItem, removeBtnType: newType });
      },
    },
    removeAccButtonColor: {
      get()
      {
        return this.formItem.removeBtnColor || 'black';
      },
      set(newColor)
      {
        this.$emit('change', { ...this.formItem, removeBtnColor: newColor });
      },
    },
    itemsDisposition: {
      get()
      {
        return this.formItem.itemsDisposition || '';
      },
      set(newDisposition)
      {
        this.$emit('change', { ...this.formItem, itemsDisposition: newDisposition });
      },
    },
    mobileItemsDisposition: {
      get()
      {
        return this.formItem.mobileItemsDisposition || '';
      },
      set(newDisposition)
      {
        this.$emit('change', { ...this.formItem, mobileItemsDisposition: newDisposition });
      },
    },
    itemsWidth: {
      get()
      {
        return this.formItem.itemsWidth || '';
      },
      set(newWidth)
      {
        this.$emit('change', { ...this.formItem, itemsWidth: newWidth });
      },
    },
    columnAlignment: {
      get()
      {
        return this.formItem.columnAlignment || 'text-start';
      },
      set(newAlignment)
      {
        this.$emit('change', { ...this.formItem, columnAlignment: newAlignment });
      },
    },
  },
};

</script>

<style scoped>

</style>
