var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[(!_vm.isAColSelected)?_c('div',{staticClass:"col text-center text-muted"},[_c('p',[_c('i',[_vm._v(_vm._s(_vm.dictionary.leftBar.selectColToEdit))])])]):_c('StyleEditor',{staticClass:"col-12",attrs:{"properties":[
          'spacing',
          'alignment',
          'fontFamily',
          'fontWeight',
          'hr',
          'backgroundColor',
          'backgroundImage',
          'backgroundImageConfig',
          'hr',
          'borderRadius',
          'border',
      ]},scopedSlots:_vm._u([{key:"form-start",fn:function(){return [_c('FilterSelector',{staticClass:"mb-3",attrs:{"label":"Filtrer la colonne"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.dictionary.leftBar.columnWidth)),_c('span',{staticClass:"badge bg-success ms-3"},[_vm._v(_vm._s(_vm.columnLayout))])]),_c('vue-slider',{staticClass:"mt-2",attrs:{"min":1,"max":12},model:{value:(_vm.columnLayout),callback:function ($$v) {_vm.columnLayout=$$v},expression:"columnLayout"}})],1)]},proxy:true},{key:"form-end",fn:function(){return [_c('animationEditor',{staticClass:"mt-3",model:{value:(_vm.animation),callback:function ($$v) {_vm.animation=$$v},expression:"animation"}}),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"small"},[_vm._v(_vm._s(_vm.dictionary.customClass))]),_c('Multiselect',{attrs:{"id":"customClasses","tag-placeholder":_vm.dictionary.addThisClass,"placeholder":_vm.dictionary.selectOrTypeAClass,"options":_vm.defaultCustomClasses,"multiple":true,"taggable":true},on:{"tag":_vm.onNewClass},model:{value:(_vm.customClass),callback:function ($$v) {_vm.customClass=$$v},expression:"customClass"}})],1),_c('div',{staticClass:"mb-4"},[_c('IdEditor',{attrs:{"readonly":true,"title":_vm.dictionary.leftBar.id},model:{value:(_vm.colID),callback:function ($$v) {_vm.colID=$$v},expression:"colID"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col m-0 pe-1"},[_c('button',{staticClass:"w-100 btn btn-success text-light",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.exportCol.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-download text-light me-2"}),_vm._v("Exporter la colonne")])])])]},proxy:true}]),model:{value:(_vm.style),callback:function ($$v) {_vm.style=$$v},expression:"style"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }