import { render, staticRenderFns } from "./BugReportModal.vue?vue&type=template&id=a40747a2"
import script from "./BugReportModal.vue?vue&type=script&lang=js"
export * from "./BugReportModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports