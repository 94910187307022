<template>
  <modal name="propertiesModal" :width="'80%'" :height="'80%'">
    <card class="h-100">
      <template v-slot:header-left><p class="text-white mb-0"><i class="fas fa-cogs me-3"></i>{{ dictionary.propertiesModal.modalTitle }}</p></template>
      <template v-slot:header-right><button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button></template>
      <div v-if="loaded" class="row h-100">
        <div class="col-5 h-100 d-flex flex-column">
          <card class="flex-grow-1 mb-2" style="overflow-y: hidden;">
            <template v-slot:header-left><p class="text-white mb-0"><i class="fas fa-home me-3"></i>{{ dictionary.propertiesModal.domainsTitle }}</p></template>
            <template v-slot:header-right><i @click="$refs.domainEditor.loadDomains()" class="fas fa-sync-alt text-light reload-icon"></i></template>
            <DomainEditor ref="domainEditor"/>
          </card>
          <card class="flex-shrink-1">
            <template v-slot:header-left><p class="text-white mb-0"><i class="fas fa-balance-scale me-3"></i>{{ dictionary.propertiesModal.rgpdTitle }}</p></template>
            <RgpdConfig v-model="RGPDSettings"/>
          </card>
        </div>
        <div class="col-7 h-100">
          <Tabs class="pt-1 h-100" ref="tabs" :tabs="getTabs" :animation="false">
            <template v-slot:slot-0>
              <MainConfig v-model="mainSettings"/>
            </template>
            <template v-slot:slot-1>
              <StatusConfig v-model="Status"/>
            </template>
            <template v-slot:slot-2>
              <MessagesConfig v-model="Messages"/>
            </template>
            <template v-slot:slot-3>
              <IntegrationsSettings v-model="Settings"/>
            </template>
          </Tabs>
        </div>
      </div>
      <div v-else class="row h-100 d-flex align-items-center">
        <div class="col-auto mx-auto">
          <p class="text-muted">{{ dictionary.propertiesModal.configLoadingMessage }}</p>
        </div>
      </div>
    </card>
  </modal>
</template>

<script>

import { mapGetters } from 'vuex';
import card from '../../Utilities/Card.vue';
import Tabs from '../../Utilities/Tabs.vue';
import DomainEditor from './DomainEditor.vue';
import MainConfig from './MainConfig.vue';
import RgpdConfig from './RgpdConfig.vue';
import MessagesConfig from './MessagesConfig.vue';
import StatusConfig from './StatusConfig.vue';
import IntegrationsSettings from './IntegrationsSettings.vue';

export default {
  name: 'PropertiesModal',
  components: {
    IntegrationsSettings,
    StatusConfig,
    MessagesConfig,
    RgpdConfig,
    MainConfig,
    DomainEditor,
    card,
    Tabs,
  },
  data()
  {
    return {
      loaded: false,
      settings: {},
    };
  },
  mounted()
  {
    this.$store.dispatch('getWebsiteSettings').then((settings) => {
      this.settings = settings;
      this.loaded = true;
    });
  },
  computed: {
    ...mapGetters(['dictionary']),
    mainSettings: {
      get()
      {
        return this.settings || {};
      },
      set(newMainSettings)
      {
        const rgpdSettings = { ...this.RGPDSettings };
        this.settings = { ...newMainSettings };
        if (rgpdSettings.cookie !== undefined)
          this.settings.cookie = rgpdSettings.cookie;
        if (rgpdSettings.policy !== undefined)
          this.settings.policy = rgpdSettings.policy;
        if (rgpdSettings.googleTag !== undefined)
          this.settings.googleTag = rgpdSettings.googleTag;
        this.$store.dispatch('setWebsiteSettings', { settings: this.settings }).then(() => this.loadSettingsFromBase());
      },
    },
    RGPDSettings: {
      get()
      {
        return {
          cookie: this.settings.cookie || false,
          policy: this.settings.policy || '',
          googleTag: this.settings.googleTag || {},
        };
      },
      set(newRGPDSettings)
      {
        if (newRGPDSettings.cookie !== undefined)
          this.settings.cookie = newRGPDSettings.cookie;
        if (newRGPDSettings.policy !== undefined)
          this.settings.policy = newRGPDSettings.policy;
        if (newRGPDSettings.googleTag !== undefined)
          this.settings.googleTag = newRGPDSettings.googleTag;
        this.$store.dispatch('setWebsiteSettings', { settings: this.settings }).then(() => this.loadSettingsFromBase());
      },
    },
    Alerts: {
      get()
      {
        return this.settings.alerts || [];
      },
      set(newAlerts)
      {
        this.settings.alerts = newAlerts;
        this.$store.dispatch('setWebsiteSettings', { settings: this.settings }).then(() => this.loadSettingsFromBase());
      },
    },
    Settings: {
      get()
      {
        return { payments: this.settings?.payments || [], integrations: this.settings?.integrations || [] };
      },
      set(newIntegrations)
      {
        this.$store.dispatch('setWebsiteSettings', { settings: { ...this.settings, payments: newIntegrations.payments || [], integrations: newIntegrations.integrations || [] } }).then(() => this.loadSettingsFromBase());
      },
    },
    Messages: {
      get()
      {
        return {
          confirmedMessages: this.settings.confirmedMessages || [],
          confirmedAccMessages: this.settings.confirmedMessages_acc || [],
          toBeTreatedMessages: this.settings.toBeTreatedMessages || [],
          toBeTreatedAccMessages: this.settings.toBeTreatedMessages_acc || [],
          deniedMessages: this.settings.deniedMessages || [],
          deniedAccMessages: this.settings.deniedMessages_acc || [],
          deletedMessages: this.settings.deletedMessages || [],
          deletedAccMessages: this.settings.deletedMessages_acc || [],
          invitedMessages: this.settings.invitedMessages || [],
          invitedAccMessages: this.settings.invitedMessages_acc || [],
          waitingMessages: this.settings.waitingMessages || [],
          waitingAccMessages: this.settings.waitingMessages_acc || [],
        };
      },
      set(newMessages)
      {
        if (newMessages.confirmedMessages)
          this.settings.confirmedMessages = newMessages.confirmedMessages;
        if (newMessages.confirmedAccMessages)
          this.settings.confirmedMessages_acc = newMessages.confirmedAccMessages;

        if (newMessages.toBeTreatedMessages)
          this.settings.toBeTreatedMessages = newMessages.toBeTreatedMessages;
        if (newMessages.toBeTreatedAccMessages)
          this.settings.toBeTreatedMessages_acc = newMessages.toBeTreatedAccMessages;

        if (newMessages.deniedMessages)
          this.settings.deniedMessages = newMessages.deniedMessages;
        if (newMessages.deniedAccMessages)
          this.settings.deniedMessages_acc = newMessages.deniedAccMessages;

        if (newMessages.deletedMessages)
          this.settings.deletedMessages = newMessages.deletedMessages;
        if (newMessages.deletedAccMessages)
          this.settings.deletedMessages_acc = newMessages.deletedAccMessages;

        if (newMessages.invitedMessages)
          this.settings.invitedMessages = newMessages.invitedMessages;
        if (newMessages.invitedAccMessages)
          this.settings.invitedMessages_acc = newMessages.invitedAccMessages;

        if (newMessages.waitingMessages)
          this.settings.waitingMessages = newMessages.waitingMessages;
        if (newMessages.waitingAccMessages)
          this.settings.waitingMessages_acc = newMessages.waitingAccMessages;

        this.$store.dispatch('setWebsiteSettings', { settings: this.settings }).then(() => this.loadSettingsFromBase());
      },
    },
    Status: {
      get()
      {
        return {
          privateStatus: this.settings.private_state || '',
          privateAccStatus: this.settings.private_state_acc || '',
          publicStatus: this.settings.public_state || '',
          publicAccStatus: this.settings.public_state_acc || '',
        };
      },
      set(newStatus)
      {
        if (newStatus.publicStatus)
          this.settings.public_state = newStatus.publicStatus;
        if (newStatus.privateStatus)
          this.settings.private_state = newStatus.privateStatus;
        if (newStatus.privateAccStatus)
          this.settings.private_state_acc = newStatus.privateAccStatus;
        if (newStatus.publicAccStatus)
          this.settings.public_state_acc = newStatus.publicAccStatus;
        this.$store.dispatch('setWebsiteSettings', { settings: this.settings }).then(() => this.loadSettingsFromBase());
      },
    },
    getTabs()
    {
      return [
        `<p class="text-secondary"><i class="fa fa-cogs fa-xs me-2"></i>${this.dictionary.propertiesModal.mainTabTitle}</p>`,
        `<p class="text-secondary"><i class="fa fa-check fa-xs me-2"></i>${this.dictionary.propertiesModal.statusTabTitle}</p>`,
        `<p class="text-secondary"><i class="fa fa-envelope fa-xs me-2"></i>${this.dictionary.propertiesModal.messagesTabTitle}</p>`,
        '<p class="text-secondary"><i class="fas fa-plug fa-xs me-2"></i>Integrations</p>',
      ];
    },
  },
  methods: {
    loadSettingsFromBase()
    {
      this.$store.dispatch('getWebsiteSettings').then((settings) => {
        this.settings = settings;
      });
    },
    open()
    {
      this.$modal.show('propertiesModal');
    },
    close()
    {
      this.$modal.hide('propertiesModal');
    },
  },
};

</script>

<style scoped>

.reload-icon {
  cursor: pointer;
  color: darkgray!important;
}

.reload-icon:hover {
  color: white!important;
}
</style>
