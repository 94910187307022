<template>
  <div>
    <div class="row mb-3">
      <div class="col-auto pe-1">
        <button @click="$refs.customJsModal.open()" v-tooltip="dictionary.leftBar.editJS" type="button" class="h-100 btn btn-danger btn-raised py-0 text-white" style="font-size: 20px;"><span class="fab fa-js"></span></button>
      </div>
      <div class="col-auto px-1">
        <button @click="$refs.customCSSModal.open()" v-tooltip="dictionary.leftBar.editCSS" type="button" class="h-100 btn btn-primary btn-raised py-0 text-white" style="font-size: 20px;"><span class="fab fa-css3-alt"></span></button>
      </div>
      <div class="col">
        <button @click="$refs.addFontModal.open()" class="btn btn-info btn-raised h-100 w-100 text-white">{{ dictionary.leftBar.addOrRemoveFont }}<span class="badge bg-secondary ms-2">{{ googleFontLength }}</span></button>
      </div>
    </div>
    <div class="mb-3">
      <button class="btn btn-primary btn-raised w-100 text-white" @click="$refs.propertiesModal.open()"><i class="fas fa-cogs me-3"></i>{{ dictionary.leftBar.websiteSettings }}</button>
    </div>
    <div class="mb-3">
      <button class="btn btn-secondary btn-raised w-100 text-white" @click="$refs.modalEditorModal.open()"><i class="fas fa-desktop me-3"></i>Editeur de Modales</button>
    </div>
    <div class="mb-3">
      <ToolboxWidget class="w-100"/>
    </div>
    <div class="text-center">
      <label class="small text-muted"><a class="small text-muted" target="_blank" :href="pathLink">{{ path }}</a><br>V. {{ versionNumber }}</label>
    </div>
    <AddFontModal ref="addFontModal" v-model="fontList"/>
    <CustomCodeEditorModal ref="customCSSModal" :title="dictionary.leftBar.cssEditor" mode="text/css" v-model="customStyle" id="customCssModal" />
    <CustomCodeEditorModal ref="customJsModal" :title="dictionary.leftBar.jsEditor"  mode="text/javascript" v-model="customScript" id="customJsModal"/>
    <propertiesModal ref="propertiesModal"/>
    <ModalEditorModal ref="modalEditorModal"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import AddFontModal from '../../../Modals/FontManager/FontManagerModal.vue';
import CustomCodeEditorModal from '../../../Modals/CustomCodeEditorModal.vue';
import ToolboxWidget from '../../../Utilities/ToolboxWidget.vue';
import PropertiesModal from '../../../Modals/PropertiesModal/PropertiesModal.vue';
import ModalEditorModal from '../../../Modals/ModalEditorModal/ModalEditorModal.vue';

export default {
  name: 'AdvancedConfiguration',
  components: {
    ModalEditorModal,
    PropertiesModal,
    AddFontModal,
    CustomCodeEditorModal,
    ToolboxWidget,
  },
  computed: {
    ...mapGetters([
      'googleFonts',
      'dictionary',
      'customCSS',
      'customJS',
      'googleFonts',
      'versionNumber',
      'eventID',
      'animID',
    ]),
    googleFontLength()
    {
      if (this.googleFonts === undefined)
        return 0;
      return Object.keys(this.googleFonts).length;
    },
    pathLink()
    {
      return `https://console.firebase.google.com/u/0/project/captag-events/database/captag-events-default-rtdb/data/Storage/WebsiteBuilder/${this.eventID}/${this.animID}`;
    },
    path()
    {
      return `${this.eventID} - ${this.animID}`;
    },
    fontList: {
      get()
      {
        return this.googleFonts || {};
      },
      set(fontData)
      {
        this.$store.commit('addGoogleFont', { fontObject: fontData, dbSync: true });
      },
    },
    customStyle: {
      get()
      {
        return this.customCSS;
      },
      set(value) {
        this.$store.commit('setCustomCSS', { value, dbSync: true });
      },
    },
    customScript: {
      get()
      {
        return this.customJS;
      },
      set(value) {
        this.$store.commit('setCustomJS', { value, dbSync: true });
      },
    },
  },
};
</script>

<style scoped>

</style>
