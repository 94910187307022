<template>
  <modal @closed="onClose" :name="id" width="95%" height="95%" :styles="{ overflow: 'initial' }" ref="modal">
    <card class="h-100" :showFooter="true">
      <template v-slot:header-left><h6 class="text-white mb-0" v-html="title"></h6></template>
      <template v-slot:header-right>
        <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close"></button>
      </template>
      <div class="row h-100">
        <div :class="(preview && displayPreview) ? 'col-6' : 'col-12'" class="h-100 text-start">
          <div class="row">
            <div class="col-auto pe-0">
              <button class="btn btn-secondary" :class="(mode === 'text/html') ? '' : 'disabled'" @click="beautify">{{ dictionary.customCodeEditorModal.beautifyButton }} <i class="fas fa-magic"></i></button>
            </div>
            <div class="col-auto ps-1 me-auto">
              <confirmationContext
                  @confirm="sanitize"
                  ref="confirmationSanitize"
                  :introMessage="`<h6>${dictionary.customCodeEditorModal.cleanCodeMessage}</h6>`"
                  :confirmButtonContent="dictionary.yes.toUpperCase()"
                  :deniButtonContent="dictionary.no.toUpperCase()"/>
              <button @click="$refs.confirmationSanitize.openContext()" :class="(mode === 'text/html') ? '' : 'disabled'" class="btn btn-danger text-light">{{ dictionary.customCodeEditorModal.sanitizeButton }} <i class="fas fa-broom"></i></button>
            </div>
            <div class="col-auto" v-if="(mode === 'text/javascript')">
              <div class="dropdown">
                <button class="btn btn-info dropdown-toggle text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fas fa-directions me-2"></i>{{ dictionary.customCodeEditorModal.customListenerLabel }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a @click="pastListenerSnippet('DOMContentLoaded')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.DOMReadyListener }}<span class="text-muted small ms-2">DOMContentLoaded</span></a></li>
                  <li><hr class="dropdown-divider"></li>
                  <li><a @click="pastListenerSnippet('cookie_save')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.cookieSaveListener }}<span class="text-muted small ms-2">cookie_save</span></a></li>
                  <li><a @click="pastListenerSnippet('document_ready')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.documentReadyListener }}<span class="text-muted small ms-2">document_ready</span></a></li>
                  <li><a @click="pastListenerSnippet('quotas_success')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.loadingQuotasListener }}<span class="text-muted small ms-2">quotas_success</span></a></li>
                  <li><a @click="pastListenerSnippet('qa_ready')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.qaReadyListener }}<span class="text-muted small ms-2">qa_ready</span></a></li>
                  <li><a @click="pastListenerSnippet('localize_success')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.appliedFiltersListener }}<span class="text-muted small ms-2">localize_success</span></a></li>
                  <li><a @click="pastListenerSnippet('change_lang')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.changeLangListener }}<span class="text-muted small ms-2">change_lang</span></a></li>
                  <li><a @click="pastListenerSnippet('filtres_set')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.setFiltersListener }}<span class="text-muted small ms-2">filtres_set</span></a></li>
                  <li><a @click="pastListenerSnippet('param_set')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.setParamsListener }}<span class="text-muted small">param_set</span></a></li>
                  <li><a @click="pastListenerSnippet('datas_set')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.formFillListener }}<span class="text-muted small ms-2">datas_set</span></a></li>
                  <li><a @click="pastListenerSnippet('validate_success')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.validateSuccessListener }}<span class="text-muted small">validate_success</span></a></li>
                  <li><a @click="pastListenerSnippet('highlight_error')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.displayErrorListener }}<span class="text-muted small ms-2">highlight_error</span></a></li>
                  <li><a @click="pastListenerSnippet('valid_form')" class="dropdown-item" href="#">{{ dictionary.customCodeEditorModal.validateFormListener }}<span class="text-muted small ms-2">valid_form</span></a></li>
                </ul>
              </div>
            </div>
            <div class="col-auto" v-if="(mode === 'text/css')">
              <div class="dropdown">
                <button class="btn btn-info dropdown-toggle text-light" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="fas fa-directions me-2"></i>Bout de code CSS
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li v-for="(snippet, snippetID) in cssSnippetList" :key="snippetID">
                    <a @click="pastCssSnippet(snippet.content)" class="dropdown-item" href="#" v-html="snippet.title"></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-auto">
              <select v-model="editorTheme" class="form-select" aria-label="Default select example">
                <option selected>{{ dictionary.customCodeEditorModal.chooseThemeLabel }}</option>
                <option value="monokai">Monokai</option>
                <option value="darcula">Darcula</option>
              </select>
            </div>
            <div class="col-auto" v-if="preview">
              <button v-if="!displayPreview" class="btn btn-primary btn-raised" @click="displayPreview = !displayPreview">
                <i class="fas fa-eye text-secondary me-2"></i><i class="fa fa-chevron-right pull-right text-black"></i>
              </button>
              <button v-else class="btn btn-primary btn-raised" @click="displayPreview = !displayPreview">
                <i class="fas fa-eye-slash text-secondary me-2"></i> <i class="fa fa-chevron-left pull-left text-black"></i>
              </button>
            </div>
          </div>
          <div class="col-12 mt-2 h-100">
            <codemirror ref="codeMirror" class="h-100" style="font-size: 11px;" v-model="localData" @ready="onCmReady" :options="{ ...cmOptions, theme: editorTheme }"/>
          </div>
        </div>
        <div v-if="preview && displayPreview" class="col-6 h-100 pb-4">
          <card class="h-100">
            <template v-slot:header-left>
              <h6 class="text-white m-0">{{ dictionary.preview }}</h6>
            </template>
            <template v-slot:header-right>
              <div class="row">
                <div class="col-auto">
                  <label class="text-light">{{ dictionary.customCodeEditorModal.backgroundColorLabel }}<span v-tooltip="dictionary.customCodeEditorModal.previewOnlyTooltip" class="badge rounded-pill bg-info text-white ms-2 small"><i class="fas fa-eye small"></i></span></label>
                </div>
                <div class="col" style="width: 150px;">
                  <inputColorPicker v-model="backgroundColor"/>
                </div>
              </div>
            </template>
            <perfect-scrollbar class="h-100" :options="{ wheelPropagation: false, suppressScrollX: true }">
              <div :style="previewStyle" v-html="this.localData"></div>
            </perfect-scrollbar>
          </card>
        </div>
      </div>
      <template v-slot:footer>
        <div class="w-100 text-end">
          <button @click="save(); close()" class="btn btn-success text-white w-auto my-1 ms-auto"><i class="fas fa-save me-2"></i>{{ dictionary.save.toUpperCase() }}</button>
        </div>
      </template>
    </card>
  </modal>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import sanitizeHtml from 'sanitize-html';
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/darcula.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/addon/search/search';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/html-hint';
import 'codemirror/addon/hint/css-hint';
import 'codemirror/addon/search/matchesonscrollbar.css';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/matchtags';
import 'codemirror/addon/edit/trailingspace';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/css-lint';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/display/autorefresh';

import Card from '../Utilities/Card.vue';
import confirmationContext from '../contexts/ConfirmationContext.vue';
import InputColorPicker from '../Utilities/InputColorPicker.vue';
import CssSnippet from '../../configuration/CssSnippet.json';

const beautifyHtml = require('js-beautify').html;

export default {
  name: 'CustomCodeEditorModal',
  components: {
    Card,
    confirmationContext,
    codemirror,
    InputColorPicker,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
    previewBackgroundColor: {
      type: String,
      required: false,
      default: () => ('#ffffffff'),
    },
    mode: {
      type: [String, Object],
      required: false,
      default: 'text/html',
    },
    title: {
      type: String,
      required: false,
      default: 'Custom Code editor Modal',
    },
  },
  data()
  {
    return {
      backgroundColor: {
        hex8: '#ffffffff',
      },
      id: '',
      displayPreview: true,
      editorTheme: 'monokai',
      localData: '',
      opened: false,
      cmOptions: {
        scrollbarStyle: 'simple',
        tabSize: 4,
        mode: this.mode,
        theme: 'monokai',
        lineNumbers: true,
        line: true,
        addons: 'show-hint',
        autoCloseTags: true,
        styleActiveLine: true,
        autofocus: true,
        lineWrapping: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        enableSearchTools: true,
        enableCodeFolding: true,
        enableCodeFormatting: true,
        autoFormatOnStart: true,
        autoFormatOnModeChange: true,
        autoFormatOnUncomment: true,
        showSearchButton: false,
        showTrailingSpace: false,
        highlightMatches: true,
        showFormatButton: false,
        showCommentButton: false,
        showUncommentButton: false,
        showAutoCompleteButton: true,
        smartIndent: true,
        indentUnit: 2,
        foldGutter: true,
      },
    };
  },
  beforeDestroy()
  {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  created()
  {
    this.backgroundColor.hex8 = this.previewBackgroundColor;
  },
  mounted()
  {
    if (this.id === undefined || this.id === '')
    {
      this.id = `codeEditorModal${this.$chance.string({
        length: 5,
        symbols: false,
        casing: 'lower',
        numeric: true,
        alpha: true,
      })}`;
    }
  },
  computed: {
    ...mapGetters(['dictionary']),
    tabs()
    {
      return [
        `<h5 class="m-0 text-secondary">${this.dictionary.customCodeEditorModal.title}<i class="ms-3 fas fa-code"></i></h5>`,
      ];
    },
    previewStyle()
    {
      if (!this.backgroundColor)
        return { backgroundColor: '#ffffffff' };
      if (this.backgroundColor.hex8)
        return { backgroundColor: this.backgroundColor.hex8 };
      if (this.backgroundColor.rgba)
        return { backgroundColor: `rgba(${this.backgroundColor.rgba.r},${this.backgroundColor.rgba.g},${this.backgroundColor.rgba.b},${this.backgroundColor.rgba.a},)` };
      return { backgroundColor: '#ffffffff' };
    },
    cssSnippetList()
    {
      return CssSnippet;
    },
  },
  methods: {
    onCmReady(cm) {
      cm.on('keypress', () => {
        cm.showHint({ completeSingle: false });
      });
    },
    pastListenerSnippet(eventName)
    {
      this.localData = `${this.localData}\nwindow.addEventListener('${eventName}', function()
  {\n  \n});\n`;
    },
    pastCssSnippet(content)
    {
      this.localData = `${this.localData}\n${content}`;
    },
    onClose()
    {
      this.opened = false;
    },
    setLocalData()
    {
      this.localData = `${this.value}`; // copy
    },
    open()
    {
      if (!this.opened)
      {
        document.addEventListener('keydown', this.onKeyDown);
        this.setLocalData();
      }
      this.$modal.show(this.id);
      this.opened = true;
    },
    close()
    {
      this.opened = false;
      this.$modal.hide(this.id);
      document.removeEventListener('keydown', this.onKeyDown);
    },
    beautify()
    {
      this.localData = beautifyHtml(this.localData, { indent_size: 2 });
    },
    sanitize()
    {
      this.localData = sanitizeHtml(this.localData, { allowedTags: false, allowedAttributes: false });
    },
    save()
    {
      this.$emit('input', this.localData);
      this.saveSuccess();
    },
    onKeyDown(ev)
    {
      if ((ev.keyCode === 83 && (ev.ctrlKey || ev.metaKey))) {
        ev.preventDefault();
        this.save();
      }
    },
  },
  notifications: {
    saveSuccess: {
      type: VueNotifications.types.success,
      title: 'Code sauvegardé !',
      message: 'Votre code a été sauvegardé',
    },
  },
};

</script>

<style>

.vue-codemirror {
  height: 100%;
}

</style>
