<template>
  <modal name="bugReportModal" width="50%" height="70%">
    <card class="h-100" :show-footer="true">
      <template v-slot:header-left><p class="text-white mb-0">{{ title }}</p></template>
      <template v-slot:header-right><button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button></template>
      <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
        <form @submit.prevent="sendReport">
          <div class="mb-2">
            <label class="required" for="senderName" >{{ dictionary.author }}</label>
            <input v-model="report.sender" :placeholder="workerData.name" id="senderName" type="text" class="form-control required">
          </div>
          <div class="mb-2">
            <label class="required" for="reportTitle" >{{ dictionary.title }}<span class="text-danger ms-1">*</span></label>
            <input required v-model="report.title" id="reportTitle" type="text" class="form-control">
          </div>
          <div class="mb-2">
            <label>{{ dictionary.message }}<span class="text-danger ms-1">*</span></label>
            <textarea required v-model="report.message" type="text" class="form-control " style="height: 100px"></textarea>
          </div>
        </form>
      </perfect-scrollbar>
      <template v-slot:footer>
        <div class="w-100 text-center">
          <button @click="sendReport" class="btn btn-success btn-raised text-white w-50"><b>{{ dictionary.send }}</b></button>
        </div>
      </template>
    </card>
  </modal>
</template>

<script>

import VueNotifications from 'vue-notifications';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import Card from '../Utilities/Card.vue';

export default {
  name: 'BugReportModal',
  props: {
    title: String,
    id: String,
  },
  components: {
    Multiselect,
    Card,
  },
  data()
  {
    return {
      report: {
        sender: '',
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters(['dictionary', 'workerData']),
  },
  methods: {
    open()
    {
      this.$modal.show('bugReportModal');
    },
    close()
    {
      this.$modal.hide('bugReportModal');
    },
    sendReport()
    {
      if (!this.report.sender)
        this.report.sender = this.workerData.name;

      if (this.report.message === '' || this.report.title === '')
        return;
      this.$store.dispatch('createGitlabIssue', { ...this.report }).then(() => {
        this.reportSendNotif();
        this.report = {
          sender: '',
          message: '',
          title: '',
        };
        this.close();
      });
    },
  },
  notifications: {
    reportSendNotif: {
      type: VueNotifications.types.success,
      title: 'Rapport envoyé !',
      message: 'Votre rapport a été envoyé et sera (peut-être) etudié.',
    },
  },
};

</script>

<style>

</style>
