<template>
  <div class="row h-100">
    <div class="col-3 h-100 d-flex flex-column">
      <Card class="flex-shrink-1">
        <template v-slot:header-left><p class="text-light m-0 small">Parametres de l'image</p></template>
        <form @submit.stop.prevent>
          <InputImageUpload v-model="backgroundImage" class="mb-2" title="Image de base"/>
          <InputImageUpload v-model="foregroundImage" class="mb-2" title="Image au survol des zones"/>
          <button @click="addZone" type="button" role="button" class="btn btn-outline-success w-100 my-2"><i class="fas fa-plus me-2"></i>Ajouter une zone<span class="badge rounded-pill bg-primary text-white ms-2 small">{{ zoneList.length }}</span></button>
        </form>
      </Card>
      <Card class="flex-grow-1 mt-2" style="overflow-y: hidden;">
        <template v-slot:header-left><p class="text-light m-0 small">Editeur de zone</p></template>
        <template v-slot:header-right v-if="selectedZone >= 0"><button @click="selectedZone = -1" type="button" class="btn-close btn-close-white btn-sm" aria-label="Close"></button></template>
        <ZoneProperties @removeSelected="removeZone(selectedZone)" v-if="selectedZone >= 0" v-model="selectedZoneObject"/>
        <p v-else class="small text-center text-muted">Séléctionnez une zone pour l'éditer</p>
      </Card>
    </div>
    <Card class="col-9 h-100 px-0 mx-0">
      <template v-slot:header-left><p class="text-light m-0">Editeur de zones</p></template>
      <ZoneEditor :selectedZone="selectedZone" @selected="selectedZone = $event" v-model="zoneList" :backgroundImage="backgroundImage" :foregroundImage="foregroundImage"/>
    </Card>
  </div>
</template>

<script>

import _ from 'lodash';
import Card from '../../Utilities/Card.vue';
import InputImageUpload from '../../Utilities/InputImageUpload.vue';
import ZoneEditor from './ZoneEditor.vue';
import ZoneProperties from './ZoneProperties.vue';

export default {
  name: 'MappedImageEditor',
  components: {
    ZoneProperties,
    ZoneEditor,
    InputImageUpload,
    Card,
  },
  model: {
    prop: 'mappedImageContent',
    event: 'change',
  },
  props: {
    mappedImageContent: {
      type: Object,
      required: true,
    },
  },
  data()
  {
    return {
      selectedZone: -1,
    };
  },
  computed: {
    backgroundImage: {
      get()
      {
        return this.mappedImageContent.backgroundImage || '';
      },
      set(newImage)
      {
        this.$emit('change', { ...this.mappedImageContent, backgroundImage: newImage });
      },
    },
    foregroundImage: {
      get()
      {
        return this.mappedImageContent.foregroundImage || '';
      },
      set(newImage)
      {
        this.$emit('change', { ...this.mappedImageContent, foregroundImage: newImage });
      },
    },
    zoneList: {
      get()
      {
        return this.mappedImageContent.zoneList || [];
      },
      set(newZones)
      {
        this.$emit('change', { ...this.mappedImageContent, zoneList: newZones });
      },
    },
    selectedZoneObject: {
      get()
      {
        if (this.selectedZone >= 0)
          return this.zoneList[this.selectedZone] || {};
        return {};
      },
      set(newZoneValue)
      {
        const zonesTmp = _.cloneDeep(this.zoneList || []);
        zonesTmp[this.selectedZone] = newZoneValue;
        this.$emit('change', { ...this.mappedImageContent, zoneList: zonesTmp });
      },
    },
  },
  methods: {
    removeZone(zoneIdx)
    {
      const zonesTmp = _.cloneDeep(this.zoneList || []);
      zonesTmp.splice(zoneIdx, 1);
      this.selectedZone = -1;
      this.$emit('change', { ...this.mappedImageContent, zoneList: zonesTmp });
    },
    addZone()
    {
      const zonesTmp = _.cloneDeep(this.zoneList || []);
      zonesTmp.push({
        position: {
          y: 0,
          x: 0,
          h: 200,
          w: 200,
        },
        color: {
          rgba: {
            r: this.$chance.integer({ min: 0, max: 255 }),
            g: this.$chance.integer({ min: 0, max: 255 }),
            b: this.$chance.integer({ min: 0, max: 255 }),
            a: 0.5,
          },
        },
      });
      this.$emit('change', { ...this.mappedImageContent, zoneList: zonesTmp });
    },
  },
};

</script>

<style scoped>

</style>
