<template>
  <div class="modal fade" :id="`modal-${modalID}`" aria-hidden="true" :class="automaticOpen ? 'automaticOpenModal' : ''">
    <div class="modal-dialog modal-dialog-centered" :class="modalSize">
      <div class="modal-content" :style="computedStyle" style="background-color: rgba(0, 0, 0, 0)">
        <div v-if="title !== '' || closeButton" class="modal-header" :style="titleStyle">
          <div class="w-100" v-html="title"></div>
          <button v-if="closeButton" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div v-if="body !== ''" class="modal-body" v-html="body" :style="bodyStyle">
        </div>
        <div v-if="footer !== ''" class="modal-footer text-left justify-content-start" v-html="footer" :style="footerStyle">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ModalContent',
  props: {
    modalID: {
      type: String,
      required: true,
    },
    modal: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['styleFromObject']),
    title()
    {
      return this.modal.titleData || '';
    },
    body()
    {
      return this.modal.bodyData || '';
    },
    footer()
    {
      return this.modal.footerData || '';
    },
    closeButton()
    {
      if (this.modal.style)
        return this.modal.style.closeButton || false;
      return false;
    },
    automaticOpen()
    {
      if (this.modal.style)
        return this.modal.style.automaticOpen || false;
      return false;
    },
    modalSize()
    {
      if (!this.modal.style)
        return '';
      switch (this.modal.style.modalSize)
      {
        case 'small': return 'modal-sm';
        case 'large': return 'modal-lg';
        case 'xlarge': return 'modal-xl';
        default: return '';
      }
    },
    bodyStyle() {
      const out = {};
      if (!this.modal.style)
        return {};
      const backgroundColor = this.modal.style.bodyBackgroundColor;
      if (backgroundColor)
      {
        if (backgroundColor.hex8)
          out.backgroundColor = backgroundColor.hex8;
        else if (backgroundColor.hex)
          out.backgroundColor = backgroundColor.hex;
        else if (backgroundColor.rgba)
          out.backgroundColor = `rgba(
            ${backgroundColor.rgba.r},
            ${backgroundColor.rgba.g},
            ${backgroundColor.rgba.b},
            ${backgroundColor.rgba.a})`;
      }
      if (this.modal.style.borderRadius)
      {
        if (!this.title)
        {
          out.borderTopLeftRadius = `${this.modal.style.borderRadius}px`;
          out.borderTopRightRadius = `${this.modal.style.borderRadius}px`;
        }
        if (!this.footer)
        {
          out.borderBottomLeftRadius = `${this.modal.style.borderRadius}px`;
          out.borderBottomRightRadius = `${this.modal.style.borderRadius}px`;
        }
      }
      return out;
    },
    titleStyle() {
      const out = {};
      if (!this.modal.style)
        return {};
      const backgroundColor = this.modal.style.titleBackgroundColor;
      if (backgroundColor)
      {
        if (backgroundColor.hex8)
          out.backgroundColor = backgroundColor.hex8;
        else if (backgroundColor.hex)
          out.backgroundColor = backgroundColor.hex;
        else if (backgroundColor.rgba)
          out.backgroundColor = `rgba(
            ${backgroundColor.rgba.r},
            ${backgroundColor.rgba.g},
            ${backgroundColor.rgba.b},
            ${backgroundColor.rgba.a})`;
      }
      if (this.modal.style.borderRadius)
      {
        out.borderTopLeftRadius = `${this.modal.style.borderRadius}px`;
        out.borderTopRightRadius = `${this.modal.style.borderRadius}px`;
      }
      return out;
    },
    footerStyle() {
      const out = {};
      const backgroundColor = this.modal.style.footerBackgroundColor;
      if (backgroundColor)
      {
        if (backgroundColor.hex8)
          out.backgroundColor = backgroundColor.hex8;
        else if (backgroundColor.hex)
          out.backgroundColor = backgroundColor.hex;
        else if (backgroundColor.rgba)
          out.backgroundColor = `rgba(
            ${backgroundColor.rgba.r},
            ${backgroundColor.rgba.g},
            ${backgroundColor.rgba.b},
            ${backgroundColor.rgba.a})`;
      }
      if (this.modal.style.borderRadius)
      {
        out.borderBottomLeftRadius = `${this.modal.style.borderRadius}px`;
        out.borderBottomRightRadius = `${this.modal.style.borderRadius}px`;
      }
      return out;
    },
    computedStyle() {
      const out = this.styleFromObject(this.modal.style || {});
      return out || {};
    },
  },
};

</script>

<style>

.modal-header p {
  padding-bottom: 0;
  margin-bottom: 0;
}

</style>
