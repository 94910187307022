<template>
  <div aria-label="Basic checkbox toggle button group editorUIStyle">
    <div class="btn-group" role="group">
      <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle fw-bold text-light" data-bs-toggle="dropdown" aria-expanded="false">
        Filtres<span class="badge bg-secondary ms-2 px-2">{{ Object.keys(filtersList).length }}</span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
        <li class="mb-2"><a
            href="#"
            @click="$refs.websiteFilterModal.open()"
            class="dropdown-item active text-light bg-success text-secondary">{{ dictionary.filterWidget.editFilterButton }} <i class="fas fa-cogs ms-3"></i></a></li>
        <li v-if="Object.keys(filtersList).length > 0 && !isFilterActive('')"><a
            class="dropdown-item"
            href="#"
            @click="setActiveFilter('')"
            :class="isFilterActive('') ? 'active text-light' : 'text-secondary'">{{ dictionary.filterWidget.noFilterLabel }} <i v-if="isFilterActive('')" class="fas fa-check ms-3"></i></a></li>
        <li><hr class="dropdown-divider"></li>
        <li v-for="(filter, filterId) in filtersList" :key="filterId">
          <a
              :class="isFilterActive(filterId) ? 'active text-light' : 'text-secondary'"
              @click="setActiveFilter(filterId)"
              href="#"
              class="dropdown-item">{{ filter.name }}<i v-if="isFilterActive(filterId)" class="fas fa-check ms-3"></i></a>
        </li>
        <li v-if="Object.keys(filtersList).length <= 0" class="dropdown-item text-center disabled"><p class="mb-0 pb-0 text-muted small">{{ dictionary.filterWidget.noFilterNowLabel }}</p></li>
      </ul>
    </div>
    <websiteFiltersModal ref="websiteFilterModal"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import WebsiteFiltersModal from '../Modals/websiteFiltersModal/websiteFiltersModal.vue';

export default {
  name: 'FilterWidget',
  components: { WebsiteFiltersModal },
  computed: {
    ...mapGetters(['websiteFilters', 'activeWebsiteFilter', 'dictionary']),
    filtersList()
    {
      return this.websiteFilters;
    },
  },
  updated()
  {
    if (this.activeWebsiteFilter)
    {
      const found = Object.entries(this.websiteFilters).every(([key, _]) => key !== this.activeWebsiteFilter);
      if (found)
        this.setActiveFilter('');
    }
  },
  methods: {
    setActiveFilter(filterId)
    {
      this.$store.commit('setActiveWebsiteFilter', { filterId, dbSync: false });
    },
    isFilterActive(filterId)
    {
      return this.activeWebsiteFilter === filterId;
    },
  },
};

</script>

<style scoped>

</style>
