<template>
  <draggable
      v-if="!isRowHiddenByFilters"
      :id="row.id"
      :group="{ name: 'cols', pull: pullState }"
      :clone="onClone"
      :class="computedClasses"
      class="preview-adv-row position-relative pt-5 mb-3"
      v-model="draggableColList"
      handle=".dragHandle"
      @start="onDndStart"
      @click.native="onClick">
    <template v-slot:header>
      <RowOverlay
          v-if="isRowSelected(row) || isEmpty"
          :row-index="rowIndex"
          :row="row"
          @toggleSpacing="displaySpacing = $event"
          class="h-100 w-100 position-absolute rowOverlay top-0 start-0"/>
    </template>
    <AdvancedColumn v-for="(Col, index) in draggableColList" :key="index" :column="Col" :Row="row"/>
    <template v-slot:footer>
      <p class="position-absolute top-0 start-0 pt-1 text-muted mt-1">#{{ row.id }}</p>
      <div class="row position-absolute top-0 end-0 d-flex align-items-center w-auto mt-1">
        <div class="col-auto"><i :class="alignmentIcon" class="fas text-success"></i></div>
        <div class="col-auto" v-if="isRowHidden"><i class="fas fa-eye-slash"></i></div>
        <div class="col-auto" v-if="isRowSticky"><i class="fas fa-map-pin text-danger"></i></div>
        <div class="col-auto" v-if="hasFilter"><i class="fas fa-filter text-info"></i></div>
      </div>
    </template>
  </draggable>
</template>

<script>

import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import _ from 'lodash';
import AdvancedColumn from './AdvancedColumn.vue';
import RowOverlay from '../Overlays/RowOverlay.vue';

export default {
  name: 'AdvancedRow',
  components: { RowOverlay, AdvancedColumn, draggable },
  data() {
    return {
      cloneOnPull: false,
    };
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(['isRowSelected', 'activeWebsiteFilter']),
    draggableColList: {
      get() {
        return this.row.columns || [];
      },
      set(cols)
      {
        this.$store.commit('updateColumnsForRow', { row: this.row, cols, dbSync: true });
      },
    },
    isRowHiddenByFilters()
    {
      if (!this.activeWebsiteFilter || this.activeWebsiteFilter === '')
        return false;
      return (this.row.filter && this.row.filter !== '' && this.row.filter !== this.activeWebsiteFilter);
    },
    pullState()
    {
      return this.cloneOnPull ? 'clone' : true;
    },
    isEmpty()
    {
      return (!this.row.columns || this.row.columns.length <= 0);
    },
    computedClasses()
    {
      let out = '';
      out += 'row';
      if (this.row.values && this.row.values.alignment && this.row.values.alignment !== 'default')
      {
        switch (this.row.values.alignment)
        {
          case 'text-end': out += ' justify-content-end'; break;
          case 'text-center': out += ' justify-content-center'; break;
          case 'text-start': out += ' justify-content-start'; break;
          default: out += ' justify-content-start';
        }
      }
      out += ' d-none d-md-flex';
      if (this.isRowSelected(this.row))
        out += ' selected';
      return out;
    },
    isRowHidden()
    {
      if (this.row.values)
        return this.row.values.hidden || false;
      return false;
    },
    isRowSticky()
    {
      if (this.row.values)
        return this.row.values.sticky || false;
      return false;
    },
    hasFilter()
    {
      return this.row.filter !== undefined && this.row.filter !== '';
    },
    alignmentIcon()
    {
      if (!this.row.values || !this.row.values.alignment)
        return 'd-none';
      switch (this.row.values.alignment)
      {
        case 'text-start': return 'fa-align-left';
        case 'text-center': return 'fa-align-center';
        case 'text-end': return 'fa-align-right';
        default: return 'd-none';
      }
    },
  },
  methods: {
    onDndStart({ originalEvent })
    {
      this.cloneOnPull = originalEvent.ctrlKey;
    },
    // Cette fonction n'est pas tres propre, mais je n'ai pas trouvé de manière élégante de le faire pour le moment.
    onClone(object)
    {
      function parseFieldsIds(fields, chance, depth)
      {
        fields.forEach((field) => {
          let spacing = '';
          for (let i = 0; i < depth; i = i + 1)
            spacing = spacing + '- ';
          const rID = chance.string({
            length: 5,
            casing: 'upper',
            alpha: true,
            numeric: true,
          });
          if (field.innerItems)
          {
            field.id = `formGroup-${rID}`;
            parseFieldsIds(field.innerItems, chance, depth + 1);
          }
          else
            field.id = `formItem-${rID}`;
        });
      }
      const copy = _.cloneDeep(object);
      copy.id = this.$chance.string({
        length: 5,
        casing: 'upper',
        alpha: true,
        numeric: false,
      });
      if (copy.content && copy.content.type === 'form' && copy.content.data && copy.content.data.fields)
      {
        parseFieldsIds(copy.content.data.fields, this.$chance, 0);
      }
      return copy;
    },
    onClick()
    {
      if (!this.isEmpty)
      {
        let found = false;
        this.row.columns.forEach((col) => {
          if (col.id === this.selectedColID)
            found = true;
        });
        if (!found)
          this.$store.commit('setSelectedColumnID', { selectedCol: this.row.columns[0].id, dbSync: true });
      }
    },
  },
};

</script>

<style scoped>

.preview-adv-row {
    border: 2px dashed var(--bs-primary);
    padding: 30px 10px;
}

.preview-adv-row.selected {
    border: 2px solid var(--bs-primary);
}

.preview-adv-row.selected .rowOverlay {
    border: unset;
}

</style>
