import Vue from 'vue';
import { uuid } from 'vue-uuid';
import columnMutations from './columnMutations';
import rowMutations from './rowMutations';
import pageMutations from './pageMutations';

const Chance = require('chance');

/**
 * General information for mutations
 * use this way inside your components :
 * this.$store.commit('mutationName', { paramInPayload: Value, dbSync: [true;false] });
 * You must pass payload as an object containing your datas AND dbSync !
 * dbSync is used to tell the firebase worker to send the whole website content to firebase
 * if you do not define this key the data will not be synchronised and an error will be fired in the console
 */
const mutations = {
  ...rowMutations,
  ...columnMutations,
  ...pageMutations,
  /**
   * Set the firebase token ID
   * @param state Vuex state (do not pass for users)
   * @param tokenID: the token to set
   */
  setTokenId(state, { tokenID })
  {
    tokenID.trim();
    state.tokenID = tokenID;
  },
  /**
   * Set the website path on firebase
   * @param state Vuex state (do not pass for users)
   * @param path: the website path
   */
  setPath(state, { path })
  {
    state.path = path;
  },
  setEditorMode(state, { newMode })
  {
    state.currentEditorMode = newMode || 'normal';
  },
  /**
   * Set the current lang for the current builder session
   * @param state Vuex state (do not pass for users)
   * @param lang: the builder language [fr;en]
   */
  setLang(state, { lang })
  {
    state.lang = lang;
  },
  /**
   * Add a Custom font to the website list.
   * An uuid will be created for this font
   * TODO rename from 'GoogleFont' to 'CustomFont' everywhere
   * @param state Vuex state (do not pass for users)
   * @param fontObject: The new font Data
   */
  addGoogleFont(state, { fontObject })
  {
    if (!state.websiteContent.googleFonts)
      state.websiteContent.googleFonts = {};
    Vue.set(state.websiteContent.googleFonts, uuid.v4(), fontObject);
  },
  /**
   * Remove a Custom font from the website list
   * identified by he's ID
   * @param state state Vuex state (do not pass for users)
   * @param fontID: The ID of the removed font
   */
  deleteGoogleFont(state, { fontID })
  {
    Vue.delete(state.websiteContent.googleFonts, fontID);
  },
  /**
   * Set the current selected column identified by he's ID
   * @param state state Vuex state (do not pass for users)
   * @param selectedCol: The newly selected column's id
   */
  setSelectedColumnID(state, { selectedCol })
  {
    state.workerData.selectedColumnID = selectedCol;
  },
  /**
   * Toggle the page loading screen
   * @param state state Vuex state (do not pass for users)
   * @param value: Boolean [true;false]
   */
  toggleLoading(state, { value })
  {
    state.loading = value;
  },
  /**
   * Toggle the error page
   * @param state state Vuex state (do not pass for users)
   * @param error: true to show the page
   * @param content: the error message
   */
  toggleLoadingError(state, { error, content })
  {
    state.loadingError = error;
    state.lastError = content;
  },
  /**
   * Toggle the user defined custom CSS
   * @param state state Vuex state (do not pass for users)
   * @param value: the CSS to set
   */
  setCustomCSS(state, { value })
  {
    state.websiteContent.customCSS = value;
  },
  /**
   * Toggle the user defined custom JS
   * @param state state Vuex state (do not pass for users)
   * @param value: the JS to set
   */
  setCustomJS(state, { value })
  {
    state.websiteContent.customJS = value;
  },
  /**
   * Set the website body configuration
   * @param state state Vuex state (do not pass for users)
   * @param config: an object containing the body configuration
   */
  setBodyConfig(state, { config })
  {
    state.websiteContent.body = config;
  },
  /**
   * Set the website header configuration
   * @param state state Vuex state (do not pass for users)
   * @param config: an object containing the header configuration
   */
  setHeaderConfig(state, { config })
  {
    state.websiteContent.header = config;
  },
  /**
   * Set the website header configuration
   * @param state state Vuex state (do not pass for users)
   * @param config: an object containing the header configuration
   */
  setFooterConfig(state, { config })
  {
    state.websiteContent.footer = config;
  },
  /**
   * Set the website filters
   * @param state state Vuex state (do not pass for users)
   * @param filters: an object containing all the filters
   */
  setWebsiteFilters(state, { filters })
  {
    state.websiteContent.filters = filters;
  },
  /**
   * Update the modal list
   * @param state state Vuex state (do not pass for users)
   * @param modals: an object containing all the modal datas
   */
  setModalsList(state, { modals })
  {
    state.websiteContent.modals = modals;
  },
  /**
   * Set the current website filter, the one applied to the preview
   * @param state state Vuex state (do not pass for users)
   * @param filterId: the new active website filter
   */
  setActiveWebsiteFilter(state, { filterId })
  {
    state.activeWebsiteFilter = filterId;
  },
  /**
   * Initialize the worker data
   * datas are loaded from a cookie called WorkerData or the actual datas from state
   * @param state
   */
  initWorkerData(state)
  {
    if (!state.workerData.id)
      state.workerData.id = uuid.v4();
    // eslint-disable-next-line no-underscore-dangle
    const previousData = this._vm.$cookies.get('WorkerData');
    if (previousData)
      console.log(`Retrieving previous identity from Cookies : ${previousData.name}`);
    if (!state.workerData.name)
    {
      if (previousData)
      {
        state.workerData.name = previousData.name || 'Tricheur';
        state.workerData.initial = previousData.initial || 'TR';
      }
      else
      {
        const chance = new Chance();
        const first = chance.first();
        const animal = chance.animal();
        state.workerData.name = `${first} the ${animal}`;
        state.workerData.initial = `${first[0].toUpperCase()}${animal[0].toUpperCase()}`;
      }
    }
    if (!state.workerData.color)
    {
      if (previousData && previousData.color)
        state.workerData.color = previousData.color || { primary: '#000000', secondary: '#000000' };
      else
      {
        const chance = new Chance();
        state.workerData.color = {};
        state.workerData.color.primary = chance.color({ format: 'hex', min_alpha: 1 });
        state.workerData.color.secondary = chance.color({ format: 'hex', min_alpha: 1 });
      }
    }
    if (!state.workerData.currentPage)
    {
      if (!state.websiteContent.pages || !state.websiteContent.pages.default || !state.websiteContent.pages[state.websiteContent.pages.default] === undefined)
        state.workerData.currentPage = '';
      else
      {
        Object.entries(state.websiteContent.pages).forEach(([pageID, page]) => {
          if (state.websiteContent.pages.default === page.name)
            state.workerData.currentPage = pageID;
        });
      }
    }
    state.workerData.lastUpdate = Math.floor(Date.now());
    // eslint-disable-next-line no-underscore-dangle
    this._vm.$cookies.set('WorkerData', state.workerData);
  },
  /**
   * Set the worker name, this name defines the worker identity
   * for deployments and interactions with other workers
   * @param state Vuex state (do not pass for users)
   * @param name: the actual name, the initials are deduced from the name with a split on spaces
   */
  setWorkerName(state, { name })
  {
    state.workerData.name = name;
    const wordArray = name.split(' ');
    state.workerData.initial = '';
    wordArray.forEach((word) => {
      state.workerData.initial += `${word[0]}`;
    });
    // eslint-disable-next-line no-underscore-dangle
    this._vm.$cookies.set('WorkerData', state.workerData);
  },
  /**
   * Set the version number.
   * This value is displayed on the bottom of the advanced configuration
   * @param state Vuex state (do not pass for users)
   * @param version: the version number, a string
   */
  setVersionNumber(state, { version })
  {
    state.versionNumber = version;
  },
  /**
   * Display the firebase sync spinner when set to true
   * @param state Vuex state (do not pass for users)
   * @param value: [true; false]
   */
  toggleFirebaseSpinner(state, { value })
  {
    state.firebaseSpinner = value;
  },
  /**
   * Set the website content origin, this is used by the new website wizard
   * @param state Vuex state (do not pass for users)
   * @param origin: a string, usually [blank; template; import]
   */
  setWebsiteContentOrigin(state, { origin })
  {
    state.websiteContent.origin = origin;
  },
  /**
   * By default, when the builder is browsed with a mobile device, an error page is shown
   * but this can be bypassed by the boolean forceMobileAccess
   * this mutation is used to toggle it
   * @param state Vuex state (do not pass for users)
   * @param forceMobileAccess: Boolean [true; false]
   */
  setForceMobileAccess(state, { forceMobileAccess })
  {
    state.forceMobileAccess = forceMobileAccess;
  },

  /**
   *
   * @param state
   * @param color: The color code you want to add to the palette
   */
  addColorToPalette(state, { color })
  {
    console.log(`add color to palette : ${color}`);
    if (!state.websiteContent.colorPalette)
      state.websiteContent.colorPalette = [];
    if (!state.websiteContent.colorPalette.includes(color))
      state.websiteContent.colorPalette.push(color);
  },

  /**
   *
   * @param state
   * @param color: The color code you want to remove from the palette
   */
  removeColorFromPalette(state, { color })
  {
    if (!state.websiteContent.colorPalette)
      state.websiteContent.colorPalette = [];
    const idx = state.websiteContent.colorPalette.indexOf(color);
    if (idx > -1)
      state.websiteContent.colorPalette.splice(idx, 1);
  },

  setJSObfuscation(state, { value })
  {
    state.websiteContent.JSObfuscation = value;
  },
};

export default mutations;
