<template>
  <div class="row h-100">
    <perfect-scrollbar class="col-12 pt-4" style="height: 90%" :options="{ suppressScrollX: true, wheelPropagation: false }">
      <draggable
          handle=".dragHandle"
          :group="{ name: 'carrouselImages' }"
          v-model="draggableImages" class="px-3 row">
        <div class="col-4 mb-3" v-for="(image, index) in draggableImages" :key="`image-${index}`">
          <card style="max-height: 300px">
            <template v-slot:header-left><i class="fas fa-arrows-alt text-light dragHandle cursor-grab"></i></template>
            <template v-slot:header-center><p class="text-white mb-0 text-center fw-bold">Images {{ index + 1 }}</p></template>
            <template v-slot:header-right>
              <button class="btn btn-info text-light me-2" @click="$emit('mediaSelected', index)"><i class="fas fa-pen"></i></button>
              <button class="btn btn-danger text-white" @click="$refs.confirmationDeleteImage[index].openContext()" ><i class="fas fa-trash"></i></button>
              <confirmationContext
                  @confirm="deleteImage(index)"
                  ref="confirmationDeleteImage"
                  :introMessage="`<h6>${dictionary.carouselModule.deleteImageContextMessage}</h6>`"
                  :confirmButtonContent="dictionary.confirm"
                  :deniButtonContent="dictionary.cancel"/>
            </template>
            <div class="row mb-2">
              <div class="col-12 text-center mb-3">
                <img class="img-fluid" style="max-height: 100px" :src="image.src" alt="media">
              </div>
            </div>
          </card>
        </div>
        <template v-slot:footer v-if="draggableImages.length <= 0">
          <div class="row my-5">
            <div class="col-12 text-center">
              <h6 class="text-muted">Pas de medias pour le moment</h6>
            </div>
            <div class="col-12 text-center mt-3">
              <button class="btn btn-success text-white" @click="$emit('addMedia')"><i class="fas fa-plus-circle me-3"></i>{{ dictionary.carouselModule.addMediaButton }}</button>
            </div>
          </div>
        </template>
      </draggable>
    </perfect-scrollbar>
    <div v-if="draggableImages.length > 0" class="col-12 text-center d-flex align-items-center justify-content-center" style="height: 10%">
      <button class="btn btn-success text-white" @click="$emit('addMedia')"><i class="fas fa-plus-circle me-3"></i>{{ dictionary.carouselModule.addMediaButton }}</button>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import Card from '../Utilities/Card.vue';
import confirmationContext from '../contexts/ConfirmationContext.vue';

export default {
  name: 'MediaList',
  components: {
    draggable,
    Card,
    confirmationContext,
  },
  model: {
    prop: 'images',
    event: 'change',
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    draggableImages: {
      get()
      {
        return this.images || [];
      },
      set(newImagesList)
      {
        this.$emit('change', newImagesList);
      },
    },
  },
  methods: {
    deleteImage(index)
    {
      const newImage = [...this.draggableImages];
      newImage.splice(index, 1);
      this.draggableImages = newImage;
    },
  },
};
</script>

<style scoped>

</style>
