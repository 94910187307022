<template>
  <div class="row py-2 d-flex align-items-center topbar bg-secondary position-relative">
    <div class="col-auto">
      <button class="btn btn-primary btn-raised text-light" @click="folded = !folded">
        <i v-if="folded" class="fa fa-chevron-left pull-right"></i>
        <i v-else class="fa fa-chevron-right pull-right"></i>
      </button>
    </div>
    <div v-if="!folded" class="col-auto px-1">
      <HelpWidget/>
    </div>
    <div v-if="!folded" class="col-auto">
      <FilterWidget/>
    </div>
    <div class="col-auto">
      <CoworkerIcons/>
    </div>
    <div class="col-auto">
      <button @click="$refs.deploymentModal.open()" class="btn btn-danger btn-raised text-light" type="button"><span v-if="!folded" class="me-3">{{ dictionary.deploy }}</span><i class=" fas fa-save"></i></button>
    </div>
    <div class="col-auto" role="group" :class="Object.keys(pageList).length > 2 ? 'btn-group' : ''">
      <a :href="currentPageUrl" :disabled="externalUrl === ''" v-tooltip.bottom="externalUrl === '' ? 'Veuillez définir votre domain préférée depuis les propriétés du site' : 'Accéder au site'" target="_blank" class="btn btn-light" :class="externalUrl === '' ? 'disabled' : ''" type="button"><i class="fas fa-external-link-alt fa-lg"></i></a>
      <button v-if="Object.keys(pageList).length > 2" id="btnGroupDropDeploy" type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-file-alt fas me-2"></i>
      </button>
      <ul class="dropdown-menu" aria-labelledby="btnGroupDropDeploy">
        <li class="mb-2" v-for="(page, pageID) in pageList" :key="pageID">
          <a v-if="pageID !== 'default'"
              :href="`${externalUrl}/${page.name}`"
              target="_blank"
              class="dropdown-item text-secondary"><i class="fas fa-external-link-alt me-2"></i>{{ page.name }}<i v-if="pageList.default && pageList.default === page.name" class="fas fa-home ms-2 text-success"></i></a>
        </li>
      </ul>
    </div>
    <div v-if="isFilterActive" class="position-absolute" style="bottom: -32px; left: 0;">
      <div class="row w-100">
        <div class="col-auto mx-auto bg-danger" style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
          <p v-if="folded" class="m-0 my-1 text-light">{{ dictionary.topBar.foldedFilterLabel }} <i class="fas fa-eye"></i></p>
          <p v-else class="m-0 my-1 text-light"><i class="fas fa-eye me-2"></i>{{ dictionary.topBar.activeFilterLabel }} : {{ activeFilter.name }}</p>
        </div>
      </div>
    </div>
    <DeploymentModal ref="deploymentModal"/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import CoworkerIcons from './CoworkerIcons.vue';
import HelpWidget from './HelpWidget.vue';
import DeploymentModal from '../../Modals/Deployment/DeploymentModal.vue';
import FilterWidget from '../../Utilities/FilterWidget.vue';

export default {
  name: 'Topbar',
  components: {
    FilterWidget,
    HelpWidget,
    CoworkerIcons,
    DeploymentModal,
  },
  data()
  {
    return {
      folded: false,
    };
  },
  computed: {
    ...mapGetters(['dictionary', 'activeWebsiteFilter', 'websiteFilters', 'preferredUrl', 'pageList', 'currentPage']),
    externalUrl()
    {
      if (this.preferredUrl !== undefined && typeof this.preferredUrl === 'string')
        return this.preferredUrl;
      return '';
    },
    currentPageUrl()
    {
      if (this.pageList[this.currentPage] && Object.keys(this.pageList).length > 2)
        return `${this.externalUrl}/${this.pageList[this.currentPage].name}`;
      return this.externalUrl;
    },
    pageUrl()
    {
      return window.location.href;
    },
    isFilterActive()
    {
      return this.activeWebsiteFilter !== '';
    },
    activeFilter()
    {
      if (Object.keys(this.websiteFilters).length > 0 &&
          this.activeWebsiteFilter)
        return this.websiteFilters[this.activeWebsiteFilter];
      return {
        name: '',
        conditions: [],
      };
    },
  },
};

</script>

<style scoped>

.topbar {
  z-index: 1000;
  border-bottom-left-radius: 10px;
}

</style>
