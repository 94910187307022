<template>
  <div class="row">
    <div class="col-12" :class="alignment">
      <i id="loader" class="fa fa-spinner fa-pulse fa-2x fa-fw loader d-none"></i>
      <button type="button" style="border: 0;" :style="btnStyle" class="valid_form" :id="`${id}-validate-button`" v-html="formItem.label"></button>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'FormValidateButton',
  props: {
    formItem: {
      type: Object,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['styleFromObject']),
    btnStyle()
    {
      const out = this.styleFromObject(this.formItem || {});
      return out || {};
    },
    alignment()
    {
      if (this.formItem.alignment && this.formItem.alignment !== 'default')
        return this.formItem.alignment;
      return 'text-center';
    },
  },
};
</script>

<style scoped>

</style>
