<template>
  <perfect-scrollbar class="pe-3 h-100" :options="{ suppressScrollX: true, wheelPropagation: false }">
    <form class="h-100" @submit.stop.prevent>
      <div class="row">
        <div class="col-auto">
          <h6 class="fw-bold">{{ dictionary.carouselModule.configBaseLabel }}</h6>
        </div>
        <div class="col">
          <hr class="my-2">
        </div>
      </div>
      <div class="row mb-3 justify-content-center">
        <div class="col-auto">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="CarouselConfigLoopingSwitch" v-model="looping">
            <label class="form-check-label" for="CarouselConfigLoopingSwitch">{{ dictionary.carouselModule.configLoopingLabel }}</label>
          </div>
        </div>
        <div class="col-auto">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="CarouselConfigPaginationSwitch" v-model="pagination">
            <label class="form-check-label" for="CarouselConfigPaginationSwitch">{{ dictionary.carouselModule.configPaginationLabel }}</label>
          </div>
        </div>
        <div class="col-auto">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="CarouselConfigAutoplaySwitch" v-model="autoplay">
            <label class="form-check-label" for="CarouselConfigAutoplaySwitch">{{ dictionary.carouselModule.configAutoplayLabel }}</label>
          </div>
        </div>
        <div class="col-auto">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="CarouselConfigFreeModeCheckbox" v-model="freeMode">
            <label class="form-check-label" for="CarouselConfigFreeModeCheckbox">{{ dictionary.carouselModule.configFreeModeLabel }}</label>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">{{ dictionary.carouselModule.configSlidesPerViewLabel }}</label>
        </div>
        <div class="col">
          <vue-slider :min="1" :max="50" v-model="slidesPerView"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">{{ dictionary.carouselModule.configMobileSlidesPerViewLabel }}</label>
        </div>
        <div class="col">
          <vue-slider :min="1" :max="50" v-model="mobileSlidesPerView"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">{{ dictionary.carouselModule.configSlidesToMoveLabel }}</label>
        </div>
        <div class="col">
          <vue-slider :min="1" :max="10" v-model="slideNumber"/>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">{{ dictionary.carouselModule.configHeightLabel }}</label>
        </div>
        <div class="col-auto">
          <div class="input-group">
            <input type="number" class="form-control" @change="carouselHeight = parseInt($event.target.value)"
                   :value="carouselHeight">
            <div class="input-group-append">
              <span class="input-group-text">px</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <label class="form-label">{{ dictionary.carouselModule.configSlideSpeedLabel }}</label>
        </div>
        <div class="col-auto">
          <div class="input-group">
            <input type="number" class="form-control" v-model="slideSpeed">
            <div class="input-group-append">
              <span class="input-group-text">sec</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3 mt-4">
        <div class="col-auto">
          <h6 class="fw-bold">{{ dictionary.carouselModule.configNavConfigLabel }}</h6>
        </div>
        <div class="col">
          <hr class="my-2">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-auto">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="CarouselConfigArrowLeftCheckbox" v-model="arrowLeft">
            <label class="form-check-label" for="CarouselConfigArrowLeftCheckbox">{{ dictionary.carouselModule.configArrowLeftLabel }}</label>
          </div>
        </div>
        <div class="col-auto">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="CarouselConfigArrowRightCheckbox" v-model="arrowRight">
            <label class="form-check-label" for="CarouselConfigArrowLeftCheckbox">{{ dictionary.carouselModule.configArrowRightLabel }}</label>
          </div>
        </div>
      </div>
      <div class="row mb-3" v-if="arrowLeft || arrowRight">
        <div class="col-auto">
          <label class="form-label">{{ dictionary.carouselModule.configArrowColorLabel }}</label>
        </div>
        <div class="col">
          <InputColorPicker v-model="navArrowColor"/>
        </div>
      </div>
      <div class="row my-3" v-if="carouselContent.autoplay">
        <div class="col-auto">
          <h6 class="fw-bold">{{ dictionary.carouselModule.configAutoplayConfigLabel }}</h6>
        </div>
        <div class="col">
          <hr class="my-2">
        </div>
      </div>
      <div class="row mb-3" v-if="carouselContent.autoplay">
        <div class="col">
          <label class="form-label">{{ dictionary.carouselModule.configAutoplaySpeedLabel }}</label>
        </div>
        <div class="col-auto">
          <div class="input-group">
            <input type="number" class="form-control" v-model="autoplaySpeed">
            <div class="input-group-append">
              <span class="input-group-text">ms</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3" v-if="carouselContent.autoplay">
        <div class="col">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="CarouselConfigAutoplayDirectionSwitch" v-model="autoplayDirection">
            <label class="form-check-label" for="CarouselConfigAutoplayDirectionSwitch">{{ dictionary.carouselModule.configAutoplayDirectionLabel }}</label>
          </div>
        </div>
      </div>
    </form>
  </perfect-scrollbar>
</template>

<script>

import { mapGetters } from 'vuex';
import VueSlider from 'vue-slider-component';
import InputColorPicker from '../Utilities/InputColorPicker.vue';

export default {
  name: 'CarouselConfig',
  components: { InputColorPicker, VueSlider },
  model: {
    prop: 'carouselContent',
    event: 'change',
  },
  props: {
    carouselContent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    arrowRight: {
      get()
      {
        return this.carouselContent.arrowRight || false;
      },
      set(newArrowRight)
      {
        this.$emit('change', { ...this.carouselContent, arrowRight: newArrowRight });
      },
    },
    arrowLeft: {
      get()
      {
        return this.carouselContent.arrowLeft || false;
      },
      set(newArrowLeft)
      {
        this.$emit('change', { ...this.carouselContent, arrowLeft: newArrowLeft });
      },
    },
    freeMode: {
      get()
      {
        return this.carouselContent.freeMode || false;
      },
      set(newFreeMode)
      {
        this.$emit('change', { ...this.carouselContent, freeMode: newFreeMode });
      },
    },
    slidesPerView: {
      get()
      {
        return this.carouselContent.slidesPerView || 1;
      },
      set(newSlidesPerView)
      {
        this.$emit('change', { ...this.carouselContent, slidesPerView: newSlidesPerView });
      },
    },
    mobileSlidesPerView: {
      get()
      {
        return this.carouselContent.mobileSlidesPerView || 1;
      },
      set(newMobileSlidesPerView)
      {
        this.$emit('change', { ...this.carouselContent, mobileSlidesPerView: newMobileSlidesPerView });
      },
    },
    autoplaySpeed: {
      get()
      {
        return this.carouselContent.autoplaySpeed || 2;
      },
      set(newAutoplaySpeed)
      {
        this.$emit('change', { ...this.carouselContent, autoplaySpeed: newAutoplaySpeed });
      },
    },
    slideNumber: {
      get()
      {
        return this.carouselContent.slideNumber || 1;
      },
      set(newSlideNumber)
      {
        this.$emit('change', { ...this.carouselContent, slideNumber: newSlideNumber });
      },
    },
    carouselHeight: {
      get()
      {
        return this.carouselContent.carouselHeight || 0;
      },
      set(newCarouselHeight)
      {
        this.$emit('change', { ...this.carouselContent, carouselHeight: !Number.isNaN(newCarouselHeight) ? newCarouselHeight : 0 });
      },
    },
    slideSpeed: {
      get()
      {
        return this.carouselContent.slideSpeed || 1;
      },
      set(newSlideSpeed)
      {
        this.$emit('change', { ...this.carouselContent, slideSpeed: newSlideSpeed });
      },
    },
    looping: {
      get()
      {
        return this.carouselContent.looping || false;
      },
      set(newLooping)
      {
        this.$emit('change', { ...this.carouselContent, looping: newLooping });
      },
    },
    pagination: {
      get()
      {
        return this.carouselContent.pagination || false;
      },
      set(newPagination)
      {
        this.$emit('change', { ...this.carouselContent, pagination: newPagination });
      },
    },
    autoplay: {
      get()
      {
        return this.carouselContent.autoplay || false;
      },
      set(newAutoplay)
      {
        this.$emit('change', { ...this.carouselContent, autoplay: newAutoplay });
      },
    },
    autoplayDirection: {
      get()
      {
        return this.carouselContent.autoplayDirection || false;
      },
      set(newAutoplayDirection)
      {
        this.$emit('change', { ...this.carouselContent, autoplayDirection: newAutoplayDirection });
      },
    },
    navArrowColor: {
      get()
      {
        return this.carouselContent.navArrowColor || { hex8: '#000000FF' };
      },
      set(newNavArrowColor)
      {
        this.$emit('change', { ...this.carouselContent, navArrowColor: newNavArrowColor });
      },
    },
    id: {
      get()
      {
        return this.carouselContent.id || this.$chance.string(this.$newIDParams);
      },
      set(newId)
      {
        this.$emit('change', { ...this.carouselContent, id: newId });
      },
    },
  },
};
</script>

<style scoped>

</style>
