<template>
  <modal :name="id" width="90%" height="90%" @scroll.prevent>
    <Card class="h-100">
      <template v-slot:header-left><h6 class="text-white mb-0" v-html="title"></h6></template>
      <template v-slot:header-right>
        <button type="button" class="btn-close btn-close-white" aria-label="Close" @click="close()"></button>
      </template>
      <CarouselBuilder v-model="carouselData" />
    </Card>
  </modal>
</template>

<script>

import { mapGetters } from 'vuex';
import Card from '../Utilities/Card.vue';
import CarouselBuilder from '../CarouselBuilder/CarouselBuilder.vue';

export default {
  name: 'CarouselModal',
  components: { CarouselBuilder, Card },
  model: {
    prop: 'carouselContent',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Editeur de formulaire',
    },
    carouselContent: {
      type: Object,
      required: true,
    },
    computedStyle: {
      type: Object,
      required: false,
    },
  },
  data()
  {
    return {
      id: '',
    };
  },
  mounted()
  {
    if (!this.id)
      this.id = `carouselEditorModal${this.$chance.string(this.$newIDParams)}`;
  },
  computed: {
    ...mapGetters(['dictionary']),
    carouselData: {
      get()
      {
        return this.carouselContent || {};
      },
      set(newContent)
      {
        this.$emit('change', newContent);
      },
    },
  },
  methods: {
    open()
    {
      this.$modal.show(this.id);
    },
    close()
    {
      this.$modal.hide(this.id);
    },
  },
};

</script>

<style>

</style>
