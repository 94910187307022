<template>
  <select
      v-if="formItem.format === 'select'"
      :id="`${id}-input`"
      :name="dataName + `${(dataName === 'workshops-agendas') ? '[]' : ''}`"
      :required="!!required"
      :readonly="!readonly ? undefined : ''"
      :disabled="!readonly ? undefined : ''"
      value=""
      class="form-select">
    <option v-if="placeholder" selected disabled value="">{{ placeholder }}</option>
    <option
        v-for="(option, optionIdx) in multipleChoicesOptions"
        :key="`${id}-option-${optionIdx}`"
        :value="option.value"
        v-html="option.label"></option>
  </select>
  <div v-else-if="formItem.format === 'radio'">
    <template v-if="!formItem.optionsOrganisation">
      <div class="form-check"
           v-for="(option, optionIdx) in multipleChoicesOptions"
           :key="`${id}-option-${optionIdx}`">
        <input
            :name="dataName + `${(dataName === 'workshops-agendas') ? '[]' : ''}`"
            :id="`${id}Radios${optionIdx}`"
            :value="option.value"
            :required="!!required"
            :readonly="!readonly ? undefined : ''"
            :disabled="!readonly ? undefined : ''"
            class="form-check-input"
            type="radio">
        <label class="form-check-label" :for="`${id}-option-${optionIdx}`" v-html="option.label">
        </label>
        <small v-if="hasQuotasOnData && quotas" class="quotas-text" :style="computedColor"><span class="ms-2 quota-text-full" style="display: none;" v-html="quotasTextFull"></span><span class="ms-2 quota-text-available" v-html="quotasText"></span> <span class="quotas">{{ optionIdx }}</span></small>
      </div>
    </template>
    <template v-else>
      <div class="row mx-0" :class="choicesAlignment">
        <div class="form-check col-auto"
             v-for="(option, optionIdx) in multipleChoicesOptions"
             :key="`${id}-option-${optionIdx}`">
          <input
              :name="dataName + `${(dataName === 'workshops-agendas') ? '[]' : ''}`"
              :id="`${id}Radios${optionIdx}`"
              :value="option.value"
              :required="!!required"
              :readonly="!readonly ? undefined : ''"
              :disabled="!readonly ? undefined : ''"
              class="form-check-input"
              type="radio">
          <label class="form-check-label" :for="`${id}-option-${optionIdx}`" v-html="option.label">
          </label>
          <small v-if="hasQuotasOnData && quotas" class="quotas-text" :style="computedColor"><span class="ms-2 quota-text-full" style="display: none;" v-html="quotasTextFull"></span><span class="ms-2 quota-text-available" v-html="quotasText"></span> <span class="quotas">{{ optionIdx }}</span></small>
        </div>
      </div>
    </template>
  </div>
  <div v-else-if="formItem.format === 'checkbox'">
    <template v-if="!formItem.optionsOrganisation">
      <div class="form-check checkbox-group"
           :data-min="formItem.min"
           :data-max="formItem.max"
           v-for="(option, optionIdx) in multipleChoicesOptions"
           :key="`${id}-option-${optionIdx}`">
        <input
            class="form-check-input"
            type="checkbox"
            :value="option.value"
            :name="dataName + `${(multipleChoicesOptions.length > 1 || dataName === 'workshops-agendas') ? '[]' : ''}`"
            :id="`${id}-option-${optionIdx}`"
            :required="!!required"
            :readonly="!readonly ? undefined : ''"
            :disabled="!readonly ? undefined : ''">
        <label class="form-check-label" :for="`${id}-option-${optionIdx}`" v-html="checkboxLabel(option)">
        </label>
        <small v-if="hasQuotasOnData && quotas" class="quotas-text" :style="computedColor"><span class="ms-2 quota-text-full" style="display: none;" v-html="quotasTextFull"></span><span class="ms-2 quota-text-available" v-html="quotasText"></span> <span class="quotas">{{ optionIdx }}</span></small>
      </div>
    </template>
    <template v-else>
      <div class="row mx-0" :class="choicesAlignment">
        <div
            v-for="(option, optionIdx) in multipleChoicesOptions"
            :key="`${id}-option-${optionIdx}`"
             :data-min="formItem.min || undefined"
             :data-max="formItem.max || undefined"
             class="form-check checkbox-group col-auto">
          <input
              :value="option.value"
              :name="dataName + `${(multipleChoicesOptions.length > 1 || dataName === 'workshops-agendas') ? '[]' : ''}`"
              :id="`${id}-option-${optionIdx}`"
              :required="!!required"
              :readonly="!readonly ? undefined : ''"
              :disabled="!readonly ? undefined : ''"
              class="form-check-input"
              type="checkbox">
          <label class="form-check-label" :for="`${id}-option-${optionIdx}`" v-html="checkboxLabel(option)">
          </label>
          <small v-if="hasQuotasOnData && quotas" class="quotas-text" :style="computedColor"><span class="ms-2 quota-text-full" style="display: none;" v-html="quotasTextFull"></span><span class="ms-2 quota-text-available" v-html="quotasText"></span> <span class="quotas">{{ optionIdx }}</span></small>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'FormMultiplesChoices',
  props: {
    formItem: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    isAccField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['eventData', 'styleFromObject']),
    hasQuotasOnData() {
      return this.dataName !== '' && this.eventData[this.dataName] &&
          this.eventData[this.dataName].quotas &&
          this.eventData[this.dataName].quotas.some((element) => element > 0);
    },
    placeholder()
    {
      return this.formItem.placeholder || '';
    },
    required()
    {
      return this.formItem.required || false;
    },
    readonly()
    {
      return this.formItem.readonly || false;
    },
    quotas()
    {
      return this.formItem.quotas || false;
    },
    quotasText()
    {
      return (this.formItem.quotasData && this.formItem.quotasData.content) ? this.formItem.quotasData.content : '';
    },
    quotasTextFull()
    {
      return (this.formItem.quotasData && this.formItem.quotasData.contentFull) ? this.formItem.quotasData.contentFull : '';
    },
    multipleChoicesOptions()
    {
      return this.formItem.options || [];
    },
    dataName()
    {
      if (this.isAccField)
        return `${this.formItem.data || ''}_acc_1`;
      return this.formItem.data || '';
    },
    choicesAlignment()
    {
      if (!this.formItem.style)
        return 'justify-content-start';
      switch (this.formItem.style.alignment)
      {
        case 'text-start': return 'justify-content-start';
        case 'text-center': return 'justify-content-center';
        case 'text-end': return 'justify-content-end';
        default: return 'justify-content-start';
      }
    },
    computedColor()
    {
      const out = this.styleFromObject(this.formItem.quotasData || {});
      if (!out.color)
        out.color = '#A6A6A6FF';
      return out;
    },
  },
  methods: {
    checkboxLabel(option)
    {
      if (this.required)
        return `${option.label || ''}${(this.formItem.label === '') ? '<span v-if="required" class="text-danger ms-1">*</span>' : ''}`;
      return option.label || '';
    },
  },
};

</script>

<style scoped>

</style>
