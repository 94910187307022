<template>
  <div class="row">
    <div class="col-12">
      <draggable :list="draggableItems" :group="{ name: 'formItems', pull: 'clone', put: false }" :clone="onCloneItem" tag="ul" class="list-group">
        <li class="list-group-item" v-for="(item, itemIdx) in draggableItems" :key="`dndFormItem-${itemIdx}`">
          <div class="row cursor-grab">
            <div class="col-1 text-center"><i :class="item.icon"></i></div>
            <div v-if="item.type === 'text' || item.type === 'code'" class="col" v-html="item.html"></div>
            <div v-else class="col" v-html="item.label"></div>
            <div class="col-1"><i class="fas fa-arrows-alt"></i></div>
          </div>
        </li>
      </draggable>
    </div>
  </div>
</template>

<script>

import draggable from 'vuedraggable';
import FormItemDefaultValues from '../../configuration/FormItemDefaultValues.json';

export default {
  name: 'DndFormItems',
  components: {
    draggable,
  },
  computed: {
    draggableItems()
    {
      return FormItemDefaultValues;
    },
  },
  methods: {
    onCloneItem(obj)
    {
      const rID = this.$chance.string(this.$newIDParams);
      if (obj.type === 'group')
        return { ...obj, id: `formGroup-${rID}`, innerItems: [] };
      return { ...obj, id: `formItem-${rID}` };
    },
  },
};

</script>

<style scoped>

</style>
