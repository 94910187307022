<template>
  <div class="sidebar bg-light position-relative" :class="folded ? 'sidebar-folded' : 'sidebar-expanded'">
    <button v-if="!folded" class="position-absolute btn btn-primary btn-raised fold-button" @click="toggleFold">
      <i class="fa fa-chevron-left pull-right text-dark"></i>
    </button>
    <Tabs class="pt-1 h-75 pb-2" ref="tabs" v-if="!folded" :tabs="getTabs">
      <template v-slot:slot-0 v-if="!folded">
        <card v-if="isRowInHeader(selectedRow)" title="Header" class="h-100" :titleSize="6">
          <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
            <HeaderConfig style="overflow-x: hidden" class="me-0"/>
          </perfect-scrollbar>
        </card>
        <card v-else-if="isRowInFooter(selectedRow)" title="Footer" class="h-100" :titleSize="6">
          <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
            <FooterConfig style="overflow-x: hidden" class="me-0"/>
          </perfect-scrollbar>
        </card>
        <card v-else :title="dictionary.leftBar.bodyTitle" class="h-100" :titleSize="6">
          <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
            <BodyConfig style="overflow-x: hidden" class="me-0"/>
          </perfect-scrollbar>
        </card>
      </template>
      <template v-slot:slot-1 v-if="!folded">
        <card :title="dictionary.leftBar.rowTitle" class="h-100" :titleSize="6">
          <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
            <RowConfig style="overflow-x: hidden" class="me-0"/>
          </perfect-scrollbar>
        </card>
      </template>
      <template v-slot:slot-2 v-if="!folded">
        <card :title="dictionary.leftBar.columnTitle" class="h-100" :titleSize="6">
          <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
            <ColumnConfig style="overflow-x: hidden" class="me-0"/>
          </perfect-scrollbar>
        </card>
      </template>
      <template v-slot:slot-3 v-if="!folded">
        <card :title="dictionary.leftBar.contentTitle" class="h-100" :titleSize="6">
          <perfect-scrollbar class="h-100 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
            <ContentEditor style="overflow-x: hidden" class="me-0"/>
          </perfect-scrollbar>
        </card>
      </template>
    </Tabs>
    <div v-if="!folded" class="h-25 px-3">
      <AdvancedConfiguration/>
    </div>
    <div v-else>
      <div class="text-center">
        <button class="my-3 btn btn-primary btn-raised pt-2" @click="toggleFold">
          <i class="fa fa-chevron-right pull-right text-dark"></i>
        </button>
        <div class="card-header text-center px-0 bg-secondary pt-3 cursor-pointer" @click="toggleFold">
          <h5 class="fa fa-pager text-light" ></h5>
        </div>
        <div class="card-header text-center px-0 bg-secondary pt-3 cursor-pointer" @click="toggleFold">
          <h5 class="fa fa-grip-lines text-light"></h5>
        </div>
        <div class="card-header text-center px-0 bg-secondary pt-3 cursor-pointer" @click="toggleFold">
          <h5 class="fa fa-columns text-light"></h5>
        </div>
        <div class="card-header text-center px-0 bg-secondary pt-3 cursor-pointer" @click="toggleFold">
          <h5 class="fa fa-box text-light"></h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import BodyConfig from './configs/BodyConfig.vue';
import AdvancedConfiguration from './configs/AdvancedConfiguration.vue';
import RowConfig from './configs/RowConfig.vue';
import ColumnConfig from './configs/ColumnConfig.vue';
import Tabs from '../../Utilities/Tabs.vue';
import ContentEditor from './configs/ContentEditor.vue';
import Card from '../../Utilities/Card.vue';
import HeaderConfig from './configs/HeaderConfig.vue';
import FooterConfig from './configs/FooterConfig.vue';

export default {
  name: 'LeftBar',
  components: {
    FooterConfig,
    HeaderConfig,
    ContentEditor,
    RowConfig,
    ColumnConfig,
    AdvancedConfiguration,
    BodyConfig,
    Tabs,
    Card,
  },
  data()
  {
    return {
      currentTab: 0,
      folded: false,
      mutationSubscriber: undefined,
    };
  },
  computed: {
    ...mapGetters(['dictionary', 'workerData', 'selectedRow', 'isRowInHeader', 'isRowInFooter']),
    getTabs()
    {
      const out = [
        `<p class="text-secondary pb-0 mb-0"><span class="fa fa-grip-lines"></span><br><span class="small">${this.dictionary.leftBar.rowTitle}</span></p>`,
        `<p class="text-secondary pb-0 mb-0"><span class="fa fa-columns"></span><br><span class="small">${this.dictionary.leftBar.columnTitle}</span></p>`,
        `<p class="text-secondary pb-0 mb-0"><span class="fa fa-box"></span><br><span class="small">${this.dictionary.leftBar.contentTitle}</span></p>`,
      ];
      if (this.isRowInHeader(this.selectedRow))
        out.unshift('<p class="text-secondary pb-0 mb-0"><span class="fa fa-pager"></span><br><span class="small">Header</span></p>');
      else if (this.isRowInFooter(this.selectedRow))
        out.unshift('<p class="text-secondary pb-0 mb-0"><span class="fa fa-pager"></span><br><span class="small">Footer</span></p>');
      else
        out.unshift(`<p class="text-secondary pb-0 mb-0"><span class="fa fa-pager"></span><br><span class="small">${this.dictionary.leftBar.bodyTitle}</span></p>`);
      return out;
    },
  },
  methods: {
    toggleFold()
    {
      this.folded = !this.folded;
    },
    onEditColumn(col)
    {
      // if (this.workerData.selectedColumnID === undefined || this.workerData.selectedColumnID === '')
      this.$refs.tabs.setActiveTab(3); // Content tab
    },
  },
  mounted()
  {
    if (this.mutationSubscriber)
      this.mutationSubscriber();
    this.mutationSubscriber = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setSelectedColumnID' && !this.folded)
      {
        if (!state.workerData.selectedColumnID)
          this.$refs.tabs.setActiveTab(0); // Page tab
        else if (this.$refs.tabs.activeTab === 0)
          this.$refs.tabs.setActiveTab(2); // Column Tab
      }
    });
    this.$EventBus.$on('editColumn', this.onEditColumn);
  },
  beforeDestroy()
  {
    if (this.mutationSubscriber)
      this.mutationSubscriber();
    this.$EventBus.$off('editColumn', this.onEditColumn);
  },
};

</script>

<style scoped>

.sidebar {
  padding: 0;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}

.sidebar-expanded {
  width: 340px;
  transition: max-height 0.15s ease-out;
  transition-property: width;
}

.sidebar-folded {
  width: 60px;
  transition: max-height 0.25s ease-out;
  transition-duration: 0.15s;
  transition-property: width;
}

.fold-button {
  top: 5px;
  right: -45px;
  z-index: 1000;
}

</style>
