<template>
  <!-- Niveau 0 de profondeur, pas dans un groupe -->
  <form v-if="depth <= 0" @submit.stop.prevent>
    <!-- Message formulaire vide -->
    <div class="my-5 text-center" v-if="!deploymentMode && formItems.length <= 0">
      <h6 class="fab fa-wpforms fa-2x mb-2"></h6><br>
      <label class="text-muted">Ce formulaire ne comporte pas encore de champs</label>
    </div>
    <template v-for="(item, itemIdx) in formItems">
      <template v-if="!item.hidden">
        <FormItem v-if="item.type !== 'group'" :key="`FormItem-${itemIdx}`" :isAccField="false" :formItem="item" :deploymentMode="deploymentMode"/>
        <FormItemList v-else :formItems="item.innerItems || []" :key="`FormItem-${itemIdx}`" :depth="depth + 1" :group="item" :deploymentMode="deploymentMode"/>
      </template>
    </template>
    <!-- champs cachées -->
    <div v-for="(hidden, hiddenIdx) in hiddenFields" :key="`hiddenField-${depth}-${hiddenIdx}`" :id="hidden.id ? hidden.id : `hiddenField-${depth}-${hiddenIdx}`">
      <input type="hidden" :name="hidden.data || ''" :value="hidden.value || ''" ignore-field="false">
    </div>
    <!-- Alertes -->
    <FormAlertsContent :alerts="alerts" :alreadyRegisteredConfig="alreadyRegisteredConfig"/>
  </form>
  <!-- Sous groupes -->
  <div v-else :class="groupComputedClass" :style="groupComputedStyle" :id="groupId" :data-max-acc="maxAcc">
    <!-- groupe d'accompagnants -->
    <div v-if="accMode" :class="accGroupItemsClass" data-acc-index="0">
      <template v-for="(item, itemIdx) in formItems">
        <template v-if="!item.hidden">
          <FormItem v-if="item.type !== 'group'" :key="`FormItem-${itemIdx}`" :class="formItemFlexClasses" :isAccField="forceAcc || accMode" :formItem="item" :deploymentMode="deploymentMode"/>
          <FormItemList v-else :forceAcc="true" :key="`FormItem-${itemIdx}`" :class="formItemFlexClasses" :formItems="item.innerItems || []" :depth="depth + 1" :group="item" :deploymentMode="deploymentMode"/>
        </template>
      </template>
      <!-- bouton de suppression des accompagnants en haut a droite, optionel -->
      <button v-if="removeButtonType === 'topRight' && deploymentMode" :style="deploymentMode ? 'display: none;' : ''" :onclick="deploymentMode ? `removeAccV2('#${groupId}', 0)` : ''" type="button" :class="removeButtonColor === 'white' ? 'btn-close-white' : ''" class="btn-close position-absolute removeAccSideBtn" style="top: 0; right: -40px;" aria-label="Close"></button>
    </div>
    <!-- groupe normal -->
    <template v-else v-for="(item, itemIdx) in formItems">
      <template v-if="!item.hidden">
        <FormItem v-if="item.type !== 'group'" :class="formItemFlexClasses" :key="`FormItem-${itemIdx}`" :isAccField="forceAcc || accMode" :formItem="item" :deploymentMode="deploymentMode"/>
        <FormItemList v-else :forceAcc="forceAcc" :class="formItemFlexClasses" :key="`FormItem-${itemIdx}`" :formItems="item.innerItems || []" :depth="depth + 1" :group="item" :deploymentMode="deploymentMode"/>
      </template>
    </template>
    <!-- boutons de suppression des accompagnants en pied de groupe -->
    <div class="row justify-content-center addAccButtons" v-if="showAccButton">
      <div v-if="removeButtonType === 'bottom'" class="col-auto bt-remove-acc" style="display: none">
        <button type="button" :style="accButtonStyle" class="btn btn-primary btn-raised" :onclick="deploymentMode ? `removeAccV2('#${groupId}')` : ''" v-html="removeAccLabel"></button>
      </div>
      <div class="col-auto bt-add-acc">
        <button type="button" :style="accButtonStyle" class="btn btn-primary btn-raised" :onclick="deploymentMode ? `addAccV2('#${groupId}')` : ''" v-html="addAccLabel"></button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import FormItem from './FormItem.vue';
import FormAlertsContent from '../FormAlertsContent.vue';

export default {
  name: 'FormItemList',
  components: {
    FormAlertsContent,
    FormItem,
  },
  props: {
    formItems: {
      type: Array,
      required: true,
    },
    hiddenFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    alerts: {
      type: Object,
      required: false,
      default: () => {},
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    depth: {
      type: Number,
      required: false,
      default: 0,
    },
    group: {
      type: Object,
      required: false,
      default: undefined,
    },
    alreadyRegisteredConfig: {
      type: Object,
      required: false,
      default: () => {},
    },
    forceAcc: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['styleFromObject']),
    groupId()
    {
      if (this.group.id)
        return this.group.id;
      return `formGroup-${this.$chance.string(this.$newIDParams)}`;
    },
    maxAcc()
    {
      if (this.accMode)
        return this.group.maxAcc || 0;
      return undefined;
    },
    accMode()
    {
      if (this.group && this.group.accMode)
        return this.group.accMode;
      return false;
    },
    showAccButton()
    {
      return this.group.accMode && this.group.maxAcc !== 1;
    },
    removeAccLabel()
    {
      if (this.group && this.group.deleteButtonLabel)
        return this.group.deleteButtonLabel || '';
      return '';
    },
    removeButtonType()
    {
      if (this.group && this.group.removeBtnType)
        return this.group.removeBtnType || 'bottom';
      return 'bottom';
    },
    removeButtonColor()
    {
      if (this.group && this.group.removeBtnColor)
        return this.group.removeBtnColor || 'black';
      return 'black';
    },
    addAccLabel()
    {
      if (this.group && this.group.addButtonLabel)
        return this.group.addButtonLabel || '';
      return '';
    },
    accButtonStyle()
    {
      if (!this.group.buttonStyle)
        return {};
      const getterStyle = this.styleFromObject(this.group.buttonStyle || {});
      if (!getterStyle.color)
        getterStyle.color = 'black';
      return getterStyle;
    },
    groupComputedStyle()
    {
      const getterStyle = this.styleFromObject(this.group.style || {});
      return getterStyle || {};
    },
    groupFlexClasses()
    {
      let out = ' ';
      if (this.group.itemsDisposition !== 'column')
        return '';
      out += this.group.mobileItemsDisposition === 'row' ? 'd-md-flex' : 'd-flex';
      switch (this.group.columnAlignment)
      {
        case 'text-start': out += ' justify-content-start'; break;
        case 'text-center': out += ' justify-content-center'; break;
        case 'text-end': out += ' justify-content-end'; break;
        default: break;
      }
      return out;
    },
    formItemFlexClasses()
    {
      let out = '';
      if (!this.group)
        return '';
      switch (this.group.itemsWidth)
      {
        case 'grow': out += ' flex-grow-1'; break;
        case 'shrink': out += ' flex-shrink-1'; break;
        default: out += 'flex-shrink-1 flex-grow-1 flex-basis-1'; break;
      }
      return out;
    },
    groupComputedClass()
    {
      let out = 'form-group ';
      out += this.accMode ? 'accGroup ' : '';
      out += this.group.style ? this.group.style.alignment || 'text-left' : 'text-left';
      if (!this.accMode)
        out += this.groupFlexClasses;
      const classes = this.group.customClasses;
      (classes || []).forEach((cls) => { out += ` ${cls}`; });
      return out;
    },
    accGroupItemsClass()
    {
      let out = 'accItems accItemsTemplate position-relative';
      out += this.groupFlexClasses;
      if (this.removeButtonType === 'topRight' && this.deploymentMode)
        out += ' me-5';
      return out;
    },
  },
};

</script>

<style>

</style>
