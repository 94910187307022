<template>
  <form @submit.stop.prevent>
    <div class="mt-4">
      <div class="form-check form-switch mb-3">
        <input v-model="hidden" class="form-check-input" type="checkbox" id="FormItemHideSwitch">
        <label class="form-check-label" for="FormItemHideSwitch">Masquer ce champs</label>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col">
          <label class="form-label w-100">{{ dictionary.formModule.htmlCodeLabel }}</label>
        </div>
      </div>
      <div style="height: 500px;" class="mt-3">
        <codemirror
            class="h-100"
            style="border: 1px solid var(--bs-secondary)"
            v-model="html"
            :options="{ ...cmOptions }"
            @blur="onBlur"/>
      </div>
    </div>
  </form>
</template>

<script>

import { codemirror } from 'vue-codemirror';
import { mapGetters } from 'vuex';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/mode/xml/xml';
import 'codemirror/theme/darcula.css';
import 'codemirror/theme/monokai.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/edit/closetag';

export default {
  name: 'FormCodeConfig',
  components: {
    codemirror,
  },
  props: {
    formItem: {
      type: Object,
      required: true,
    },
  },
  data()
  {
    return {
      temporaryHtml: undefined,
      cmOptions: {
        tabSize: 4,
        mode: this.mode,
        theme: 'monokai',
        lineNumbers: true,
        line: true,
        autofocus: true,
        addons: 'show-hint',
        autoCloseTags: true,
        styleActiveLine: true,
      },
    };
  },
  computed: {
    ...mapGetters(['googleFonts', 'dictionary']),
    html: {
      get()
      {
        return this.temporaryHtml || `${this.formItem.html}`;
      },
      set(newHtml)
      {
        this.temporaryHtml = newHtml;
      },
    },
    hidden: {
      get()
      {
        return this.formItem.hidden || false;
      },
      set(newHidden)
      {
        this.$emit('change', { ...this.formItem, hidden: newHidden });
      },
    },
  },
  methods: {
    onBlur()
    {
      this.$emit('change', { ...this.formItem, html: (this.temporaryHtml) ? this.temporaryHtml : `${this.formItem.html}` });
    },
  },
};

</script>

<style scoped>

</style>
