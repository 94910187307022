<template>
  <div class="editorUIStyle">
    <h6 class="fw-bold">Paiement & Échec</h6>
    <StyleEditor v-model="paymentTextStyle" :properties="['fontWeight', 'fontFamily', 'fontSize', 'color', 'alignment']" class="ps-2" style="border-left: 2px solid var(--bs-primary);">
      <template v-slot:form-start>
        <h6 class="fw-bold text-primary">Paiement</h6>
        <div class="mb-3">
          <label>Text de l'étape de paiement<code-compatible-badge class="ms-2"/></label>
          <textarea class="form-control" :value="content.payText" @change="$emit('change', { ...content, payText: $event.target.value })"/>
        </div>
      </template>
    </StyleEditor>
    <StyleEditor v-model="paymentButtonStyle" :properties="['fontWeight', 'fontFamily', 'fontSize', 'color', 'alignment', 'backgroundColor', 'spacing','border', 'borderRadius']" class="ps-2" style="border-left: 2px solid var(--bs-primary);">
      <template v-slot:form-start>
        <h6 class="fw-bold text-primary">Bouton</h6>
        <div class="mb-3">
          <label>Text du bouton de paiement</label>
          <input class="form-control" :value="content.payButtonText" @change="$emit('change', { ...content, payButtonText: $event.target.value })">
        </div>
      </template>
    </StyleEditor>
    <StyleEditor v-model="failureTextStyle" :properties="['fontWeight', 'fontFamily', 'fontSize', 'color', 'alignment']" class="ps-2" style="border-left: 2px solid var(--bs-primary);">
      <template v-slot:form-start>
        <h6 class="fw-bold text-primary">Echec</h6>
        <div class="mb-3">
          <label>Text en cas d'échec<code-compatible-badge class="ms-2"/></label>
          <textarea class="form-control" :value="content.failureText" @change="$emit('change', { ...content, failureText: $event.target.value })"/>
        </div>
      </template>
    </StyleEditor>
    <hr>
    <h6 class="fw-bold">Validation et Facture</h6>
    <StyleEditor v-model="validationTextStyle" :properties="['fontWeight', 'fontFamily', 'fontSize', 'color', 'alignment']" class="ps-2" style="border-left: 2px solid var(--bs-success);">
      <template v-slot:form-start>
        <h6 class="fw-bold text-success">Textes</h6>
        <div class="mb-3">
          <label>Text de l'étape de validation<code-compatible-badge class="ms-2"/></label>
          <textarea class="form-control" :value="content.validText" @change="$emit('change', { ...content, validText: $event.target.value })"/>
        </div>
      </template>
    </StyleEditor>
    <StyleEditor v-model="validationButtonStyle" :properties="['fontWeight', 'fontFamily', 'fontSize', 'color', 'alignment', 'backgroundColor', 'spacing','border', 'borderRadius']" class="ps-2" style="border-left: 2px solid var(--bs-success);">
      <template v-slot:form-start>
        <h6 class="fw-bold text-success">Bouton</h6>
        <div class="mb-3">
          <label>Text du bouton de la facture</label>
          <input class="form-control" :value="content.validButtonText" @change="$emit('change', { ...content, validButtonText: $event.target.value })">
        </div>
      </template>
    </StyleEditor>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import StyleEditor from '../../../../Utilities/StyleEditor.vue';
import CodeCompatibleBadge from '../../../../Utilities/CodeCompatibleBadge.vue';

export default {
  name: 'PaymentConfig',
  components: { CodeCompatibleBadge, StyleEditor },
  model: {
    prop: 'content',
    event: 'change',
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    paymentTextStyle: {
      get()
      {
        return this.content.paymentTextStyle || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.content, paymentTextStyle: newStyle });
      },
    },
    failureTextStyle: {
      get()
      {
        return this.content.failureTextStyle || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.content, failureTextStyle: newStyle });
      },
    },
    paymentButtonStyle: {
      get()
      {
        return this.content.paymentButtonStyle || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.content, paymentButtonStyle: newStyle });
      },
    },
    validationTextStyle: {
      get()
      {
        return this.content.validationTextStyle || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.content, validationTextStyle: newStyle });
      },
    },
    validationButtonStyle: {
      get()
      {
        return this.content.validationButtonStyle || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.content, validationButtonStyle: newStyle });
      },
    },
  },
};

</script>

<style scoped>

</style>
