<template>
  <div class="mt-2">
    <div class="row d-flex align-items-center formBlockHeader m-0 py-2">
      <div class="col-auto">
        <i class="fas fa-grip-lines text-light formItemDragHandle ps-2 cursor-grab"></i>
      </div>
      <div class="col-auto ps-0">
        <button
            @click.prevent="$emit('editItem', item.id)"
            class="btn btn-success btn-sm btn-raised py-0 me-2 text-light"><i class="fas fa-pen"></i></button>
        <button @click.prevent="$refs.confirmationDeleteField.openContext();" class="btn btn-danger btn-sm btn-raised py-0 text-light"><i class="fas fa-trash"></i></button>
        <confirmationContext
            @confirm="$emit('removeItem', item.id)"
            :introMessage="`<h6>${dictionary.formModule.deleteGroupMessage}</h6>`"
            :confirmButtonContent="dictionary.confirm"
            :deniButtonContent="dictionary.no"
            ref="confirmationDeleteField"/>
      </div>
      <div class="ms-auto col-auto">
        <label class="text-muted">{{ item.id }}</label>
      </div>
      <div v-if="isAccMode" class="col-auto" v-tooltip.top="dictionary.formModule.accGroupLabel">
        <i class="fas fa-user-plus text-light"></i>
      </div>
      <div v-if="hasFilters" class="col-auto" v-tooltip.top="dictionary.formModule.inputIsFilteredMessage">
        <i class="fas fa-filter text-info"></i>
      </div>
      <div v-if="item.hidden" class="col-auto" v-tooltip.top="'Ce groupe est caché'">
        <i class="fas fa-eye-slash text-warning"></i>
      </div>
      <div v-if="item.itemsDisposition === 'column'" class="col-auto" v-tooltip.top="'Les éléments sont disposé en colonnes'">
        <i class="fas fa-columns text-success"></i>
      </div>
    </div>
    <div class="blockOverlay">
      <slot/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import confirmationContext from '../contexts/ConfirmationContext.vue';

export default {
  name: 'FormBlockOverlay',
  components: {
    confirmationContext,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary']),
    isAccMode()
    {
      return this.item.accMode || false;
    },
    hasFilters()
    {
      return (this.item.filters && this.item.filters.length > 0);
    },
  },
};

</script>

<style scoped>

.blockOverlay {
  border-left: 2px solid var(--bs-secondary);
}

.formBlockHeader {
  background-color: var(--bs-secondary);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

</style>
