<template>
  <form @submit.stop.prevent>
    <div class="mb-2">
      <label>{{ dictionary.formModule.dataLabel }}</label>
      <EventDataSelect v-model="data" :allowed-types="['String', 'StringMultiline', 'Photo', 'Link']" />
    </div>
    <div class="">
      <label for="FormItemLabelInput" class="form-label">{{ dictionary.formModule.inputLabelLabel }}<CodeCompatibleBadge class="ms-2" position="top"/></label>
      <div class="input-group">
        <input :value="formItem.label || ''" @change="$emit('change', { ...formItem, label: $event.target.value })" type="text" class="form-control" id="FormItemLabelInput">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{ layoutDisplayButton }}</button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" @click="layout = 'top'">{{ dictionary.formModule.labelTopPositionLabel }}</a></li>
          <li><a class="dropdown-item" @click="layout = 'left'">{{ dictionary.formModule.labelLeftPositionLabel }}</a></li>
        </ul>
      </div>
    </div>
    <div class="">
      <label for="FormItemPlaceholderInput" class="form-label">{{ dictionary.formModule.placeholderLabel }}<CodeCompatibleBadge class="ms-2" position="top"/></label>
      <input :value="formItem.placeholder || ''" @change="$emit('change', { ...formItem, placeholder: $event.target.value })" type="text" class="form-control" id="FormItemPlaceholderInput">
    </div>
    <div class="mb-2">
      <label for="FormItemSmallLabelInput" class="form-label">{{ dictionary.formModule.descriptionLabel }}</label>
      <div class="input-group">
        <input :value="formItem.smallLabel || ''" @change="$emit('change', { ...formItem, smallLabel: $event.target.value })" type="text" class="form-control" id="FormItemSmallLabelInput">
        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="true">{{ descriptionLayout === 'bottom' ? 'En dessous' : 'Au dessus' }}</button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" @click="descriptionLayout = 'top'">{{ dictionary.formModule.descTopPositionLabel }}</a></li>
          <li><a class="dropdown-item" @click="descriptionLayout = 'bottom'">{{ dictionary.formModule.descBottomPositionLabel }}</a></li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="required" class="form-check-input" type="checkbox" id="FormItemRequiredSwitch">
          <label class="form-check-label" for="FormItemRequiredSwitch">{{ dictionary.formModule.requiredLabel }}</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="readonly" class="form-check-input" type="checkbox" id="FormItemReadonlySwitch">
          <label class="form-check-label" for="FormItemReadonlySwitch">{{ dictionary.formModule.readonlyLabel }}</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="forceCropping" class="form-check-input" type="checkbox" id="FormItemForceCroppingSwitch">
          <label class="form-check-label" for="FormItemForceCroppingSwitch">{{ dictionary.formModule.forceCropLabel }}</label>
        </div>
      </div>
      <div class="col-auto">
        <div class="form-check form-switch">
          <input v-model="hidden" class="form-check-input" type="checkbox" id="FormItemHideSwitch">
          <label class="form-check-label" for="FormItemHideSwitch">Masquer ce champs</label>
        </div>
      </div>
    </div>
    <div class="mb-2">
      <label>Langue du widget d'upload</label>
      <select :value="formItem.widgetLanguage || ''" @change="$emit('change', { ...formItem, widgetLanguage: $event.target.value })" class="form-select">
        <option value="" selected>Francais</option>
        <option value="en">Anglais</option>
      </select>
    </div>
    <div class="mb-2">
      <label for="FormItemPathInput" class="form-label">{{ dictionary.formModule.folderPathLabel }}</label>
      <div class="input-group input-group-sm">
        <span class="input-group-text" id="inputGroup-sizing-sm">{{ eventID }}/website/</span>
        <input :value="formItem.directoryPath || ''" @change="$emit('change', { ...formItem, directoryPath: $event.target.value })" id="FormItemPathInput" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm">
      </div>
    </div>
    <div class="mb-2">
      <label for="FormItemPathInput" class="form-label">Restriction sur la taille</label>
      <div class="input-group input-group-w">
        <span class="input-group-text" id="inputGroup-sizing-w">Hauteur</span>
        <input placeholder="Pas de limite" v-model="limitHeight" id="FormItemHeightInput" type="text" class="form-control" aria-label="Sizing input" aria-describedby="inputGroup-sizing-sm">
      </div>
      <div class="input-group input-group-h mt-2">
        <span class="input-group-text" id="inputGroup-sizing-h">Largeur</span>
        <input placeholder="Pas de limite" v-model="limitWidth" id="FormItemWidthInput" type="text" class="form-control" aria-label="Sizing input" aria-describedby="inputGroup-sizing-sm">
      </div>
    </div>
    <div class="mb-2">
      <label for="FormItemPathInput" class="form-label">Restriction sur le poids</label>
      <div class="input-group input-group-w">
        <span class="input-group-text" id="inputGroup-sizing-size">Mo</span>
        <input placeholder="Pas de limite" v-model="limitSize" id="FormItemHeightInput" type="text" class="form-control" aria-label="Sizing input" aria-describedby="inputGroup-sizing-size">
      </div>
    </div>
    <div class="btn-group dropend" role="group">
      <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
        {{ dictionary.formModule.authorizedFileFormatsLabel }} <span class="badge bg-secondary mx-2">{{ extensions.length }}</span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
        <li><a @click="setAllExtensions" class="dropdown-item text-success" href="#">{{ dictionary.formModule.authorizeAllLabel }} <i class="fas fa-check-circle ms-3"></i></a></li>
        <li><hr class="dropdown-divider"></li>
        <li><a @click="setExtension('jpg')" :class="isExtensionAllowed('jpg') ? 'active' : ''" class="dropdown-item" href="#">JPEG<i v-if="isExtensionAllowed('jpg')" class="fas fa-check ms-3"></i></a></li>
        <li><a @click="setExtension('png')" :class="isExtensionAllowed('png') ? 'active' : ''" class="dropdown-item" href="#">PNG<i v-if="isExtensionAllowed('png')" class="fas fa-check ms-3"></i></a></li>
        <li><a @click="setExtension('gif')" :class="isExtensionAllowed('gif') ? 'active' : ''" class="dropdown-item" href="#">GIF<i v-if="isExtensionAllowed('gif')" class="fas fa-check ms-3"></i></a></li>
        <li><a @click="setExtension('svg')" :class="isExtensionAllowed('svg') ? 'active' : ''" class="dropdown-item" href="#">SVG<i v-if="isExtensionAllowed('svg')" class="fas fa-check ms-3"></i></a></li>
        <li><a @click="setExtension('pdf')" :class="isExtensionAllowed('pdf') ? 'active' : ''" class="dropdown-item" href="#">PDF<i v-if="isExtensionAllowed('pdf')" class="fas fa-check ms-3"></i></a></li></ul>
    </div>
    <StyleEditor v-model="btnStyle" :noButtons="true" :properties="['borderRadius', 'color', 'backgroundColor', 'border', 'alignment', 'hr']" class="my-4">
      <template v-slot:form-start >
        <div class="row">
          <div class="col-auto">
            <label class="text-secondary">{{ dictionary.formModule.buttonStyle }}</label>
          </div>
          <div class="col">
            <hr>
          </div>
        </div>
      </template>
    </StyleEditor>
  </form>
</template>

<script>

import { mapGetters } from 'vuex';
import CodeCompatibleBadge from '../../Utilities/CodeCompatibleBadge.vue';
import EventDataSelect from '../../Utilities/EventDataSelect.vue';
import StyleEditor from '../../Utilities/StyleEditor.vue';

export default {
  name: 'FormFileConfig',
  components: {
    CodeCompatibleBadge,
    EventDataSelect,
    StyleEditor,
  },
  props: {
    formItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['eventID', 'dictionary']),
    btnStyle: {
      get()
      {
        return this.formItem.btnStyle || {};
      },
      set(newStyle)
      {
        this.$emit('change', { ...this.formItem, btnStyle: newStyle });
      },
    },
    required: {
      get()
      {
        return this.formItem.required || '';
      },
      set(newRequired)
      {
        this.$emit('change', { ...this.formItem, required: newRequired });
      },
    },
    readonly: {
      get()
      {
        return this.formItem.readonly || '';
      },
      set(newReadonly)
      {
        this.$emit('change', { ...this.formItem, readonly: newReadonly });
      },
    },
    hidden: {
      get()
      {
        return this.formItem.hidden || false;
      },
      set(newHidden)
      {
        this.$emit('change', { ...this.formItem, hidden: newHidden });
      },
    },
    forceCropping: {
      get()
      {
        return this.formItem.forceCropping || '';
      },
      set(newForceCropping)
      {
        this.$emit('change', { ...this.formItem, forceCropping: newForceCropping });
      },
    },
    extensions: {
      get()
      {
        return this.formItem.extensions || [];
      },
      set(newExtensions)
      {
        this.$emit('change', { ...this.formItem, extensions: newExtensions });
      },
    },
    data: {
      get()
      {
        return this.formItem.data || '';
      },
      set(newData)
      {
        this.$emit('change', { ...this.formItem, data: newData });
      },
    },
    descriptionLayout: {
      get()
      {
        return this.formItem.descriptionLayout || 'bottom';
      },
      set(newDescriptionLayout)
      {
        this.$emit('change', { ...this.formItem, descriptionLayout: newDescriptionLayout });
      },
    },
    layout: {
      get()
      {
        return this.formItem.layout || '';
      },
      set(newLayout)
      {
        this.$emit('change', { ...this.formItem, layout: newLayout });
      },
    },
    layoutDisplayButton()
    {
      if (typeof this.layout === 'boolean')
        return (this.layout === true) ? this.dictionary.formModule.labelTopPositionLabel : this.dictionary.formModule.labelLeftPositionLabel;
      switch (this.layout)
      {
        case 'top': return this.dictionary.formModule.labelTopPositionLabel;
        case 'left': return this.dictionary.formModule.labelLeftPositionLabel;
        case 'floating': return 'Floating';
        default: return this.dictionary.formModule.labelTopPositionLabel;
      }
    },
    limitHeight:
    {
      get()
      {
        return this.formItem.limitHeight || 0;
      },
      set(newLimitHeight)
      {
        this.$emit('change', { ...this.formItem, limitHeight: newLimitHeight });
      },
    },
    limitWidth:
    {
      get()
      {
        return this.formItem.limitWidth || 0;
      },
      set(newLimitWidth)
      {
        this.$emit('change', { ...this.formItem, limitWidth: newLimitWidth });
      },
    },
    limitSize:
    {
      get()
      {
        return this.formItem.limitSize || 0;
      },
      set(newLimitSize)
      {
        this.$emit('change', { ...this.formItem, limitSize: newLimitSize });
      },
    },
  },
  methods: {
    isExtensionAllowed(ext)
    {
      return this.extensions.includes(ext);
    },
    setAllExtensions()
    {
      this.extensions = ['jpg', 'png', 'gif', 'svg', 'pdf'];
    },
    setExtension(ext)
    {
      if (this.isExtensionAllowed(ext))
      {
        const tmpExt = [...this.extensions];
        tmpExt.splice(tmpExt.indexOf(ext), 1);
        this.extensions = [...tmpExt];
      }
      else
        this.extensions = [...this.extensions, ext];
    },
  },
};

</script>

<style scoped>

</style>
