<template>
  <div class="row">
    <div class="col-12 text-center my-2">
      <div class="input-group">
        <span class="input-group-text"><i class="fas fa-search text-secondary"></i></span>
        <input v-model="modalSearchQuery" type="text" class="form-control">
      </div>
    </div>
    <div class="col-12">
      <ul v-if="Object.entries(modalList).length > 0" class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col text-center">
              <button class="btn btn-success text-light" @click="$refs.newModalCtx.openContext()"><i class="fas fa-plus me-2"></i>Ajouter</button>
              <NewModalContext ref="newModalCtx" @AddFilter="$emit('newModal', $event)"/>
            </div>
            <div class="col text-center">
              <button class="btn btn-outline-success" @click="importButtonClick"><i class="fas fa-upload me-2"></i>Importer</button>
            </div>
          </div>
        </li>
        <li v-for="modal in sortedModalList" :key="`${modal.id}-modal`" :class="(modal.id === selectedModal) ? 'active' : ''" class="list-group-item">
          <div class="row">
            <div class="col d-flex align-items-center">
              <p class="p-0 m-0">{{ modal.name || '' }}</p>
            </div>
            <div class="col-auto">
              <button class="btn btn-success text-light me-2" @click="$emit('selected', modal.id)"><i class="fas fa-pen"></i></button>
              <button class="btn btn-danger text-light" @click="$emit('delete', modal.id)"><i class="fas fa-trash"></i></button>
            </div>
          </div>
        </li>
      </ul>
      <p v-else class="small text-muted text-center my-3">Pas de modale pour le moment</p>
      <input accept="application/JSON" ref="importHiddenInput" type="file" @change="importModal" class="invisible">
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import NewModalContext from './NewModalContext.vue';

export default {
  name: 'ModalListView',
  components: { NewModalContext },
  data()
  {
    return {
      modalSearchQuery: '',
    };
  },
  computed: {
    sortedModalList()
    {
      const out = {};
      const tmp = { ...this.modalList };
      delete tmp.default;
      Object.entries(tmp).forEach(([id, modal]) => {
        modal.id = id;
      });
      if (!this.modalSearchQuery)
        return _.orderBy(tmp, 'name');
      Object.entries(tmp).forEach(([key, value]) => {
        if (key.toLowerCase().includes(this.modalSearchQuery.toLowerCase()) || (value.name && value.name.toLowerCase().includes(this.modalSearchQuery.toLowerCase())))
          out[key] = value;
      });
      return _.orderBy(out, 'name');
    },
  },
  methods: {
    importButtonClick()
    {
      this.$refs.importHiddenInput.click();
    },
    importModal(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object)
            this.$emit('importModal', object);
        } catch (ex)
        {
          console.log(`Invalid modal config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
  },
  props: {
    modalList: {
      type: Object,
      required: true,
    },
    selectedModal: {
      type: String,
      required: false,
      default: '',
    },
  },
};

</script>

<style scoped>

</style>
