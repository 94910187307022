<template>
  <div class="d-flex flex-column h-100">
    <div class="row">
      <div class="col-12">
        <form @submit.stop.prevent>
          <div class="mb-3">
            <label for="filterName">Nom du filtre</label>
            <input v-model="filterName" class="form-control" id="filterName">
          </div>
          <template v-if="value.conditions && value.conditions.length > 0">
            <div  class="mb-3">
              <hr>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col"><label>Liste des conditions</label></div>
                <div class="col-auto">
                  <select v-model="comparisonType" class="form-control">
                    <option value="union">Union ||</option>
                    <option value="intersection">Intersection &&</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </form>
      </div>
    </div>
    <perfect-scrollbar class="flex-grow-1 pe-3" :options="{ suppressScrollX: true, wheelPropagation: false }">
      <div class="row" v-if="!value.conditions || value.conditions.length <= 0">
        <div class="col-12 my-5">
          <h5 class="text-muted text-center" >
            Pas encore de conditions
          </h5>
        </div>
        <div class="col-auto ms-auto"><button @click="addCondition" class="btn btn-success text-light"><i class="fas fa-plus me-2"></i>Ajouter une condition</button></div>
        <div class="col-auto ps-0 me-auto">
          <button @click="importButtonClick" class="btn btn-info text-light"><i class="fas fa-download me-2"></i>Importer</button>
        </div>
        <input accept="application/JSON" ref="importHiddenInput" type="file" @change="importConditions" style="visibility: hidden">
      </div>
      <div class="row mb-2" v-for="(filter, filterIdx) in value.conditions || []" :key="filterIdx">
        <div  class="col-12">
          <div class="input-group px-0 mx-0">
            <EventDataSelect :value="filter.data" @change="setConditionData(filterIdx, $event)" :allowed-types="allowedDatas" :includeStatus="true"/>
            <button class="btn btn-outline-secondary dropdown-toggle disabled" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fas fa-equals"></i>
            </button>
            <ul class="dropdown-menu disabled">
              <li>
                <a class="dropdown-item" href="#"><i class="fas fa-equals"></i> {{ dictionary.formModule.equalLabel }}</a>
              </li>
            </ul>
            <input
                v-if="getDataValueType(filter.data || '') === 'input'"
                :placeholder="dictionary.empty"
                :value="filter.value"
                @change="setConditionValue(filterIdx, $event.target.value)"
                type="text"
                class="form-control">
            <select
                v-else
                :value="filter.value"
                @change="setConditionValue(filterIdx, $event.target.value)"
                class="form-select"
                id="filterValue">
              <option value="" selected>{{ dictionary.empty }}</option>
              <option
                  v-for="(ch, chIdx ) in  getChoicesForData(filter.data || '')"
                  :key="`value-opt-${chIdx}`"
                  :value="ch">
                {{ ch }}
              </option>
            </select>
            <button @click="removeCondition(filterIdx)" v-tooltip="'Supprimer ce filtre'" class="btn btn-danger"><i class="fas fa-trash text-light"></i></button>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="row" v-if="value.conditions && value.conditions.length > 0">
      <div class="col-auto ms-auto"><button @click="addCondition" class="btn btn-success text-light"><i class="fas fa-plus me-2"></i>Ajouter une condition</button></div>
      <div class="col-auto pe-1">
        <button @click="exportConditions" class="btn btn-primary text-light"><i class="fas fa-upload me-2"></i>Exporter</button>
      </div>
      <div class="col-auto ps-0">
        <button @click="importButtonClick" class="btn btn-info text-light"><i class="fas fa-download me-2"></i>Importer</button>
      </div>
      <input @change="importConditions" accept="application/JSON" ref="importHiddenInput" type="file" class="invisible">
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import EventDataSelect from '../../Utilities/EventDataSelect.vue';

export default {
  name: 'websiteFilterEditor',
  components: { EventDataSelect },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['dictionary', 'eventData']),
    allowedDatas()
    {
      return [
        'String',
        'Radioboxes',
        'Checkboxes',
        'StringMultiline',
        'Date',
        'DateTime',
        'Tab',
        'Photo',
        'Coloc',
        'Hotel',
        'Link',
        'List',
      ];
    },
    filterName: {
      get()
      {
        return this.value.name || '';
      },
      set(newName)
      {
        this.$emit('change', { ...this.value, name: newName });
      },
    },
    comparisonType: {
      get()
      {
        return this.value.comparisonType || 'union';
      },
      set(newType)
      {
        this.$emit('change', { ...this.value, comparisonType: newType });
      },
    },
  },
  methods: {
    addCondition()
    {
      const cond = _.cloneDeep(this.value.conditions || []);
      cond.push({ value: '', data: '' });
      this.$emit('change', { ...this.value, conditions: cond });
    },
    removeCondition(conditionIdx)
    {
      const cond = _.cloneDeep(this.value.conditions || []);
      cond.splice(conditionIdx, 1);
      this.$emit('change', { ...this.value, conditions: cond });
    },
    setConditionData(conditionIdx, data)
    {
      const cond = _.cloneDeep(this.value.conditions || []);
      cond[conditionIdx] = { data, value: '' };
      this.$emit('change', { ...this.value, conditions: cond });
    },
    setConditionValue(conditionIdx, value)
    {
      const cond = _.cloneDeep(this.value.conditions || []);
      cond[conditionIdx].value = value;
      this.$emit('change', { ...this.value, conditions: cond });
    },
    getChoicesForData(dataID)
    {
      if (dataID === '__status__')
        return [
          'Confirmed',
          'ToBeTreated',
          'Denied',
          'Waiting',
          'Deleted',
          'Invited',
        ];
      if (this.getDataValueType(dataID) !== 'select')
        return [];
      return this.eventData[dataID].choices || [];
    },
    getDataValueType(dataID)
    {
      if (dataID === '__status__')
        return 'select';
      if (!dataID || !this.eventData[dataID] || !this.eventData[dataID].type)
        return 'input';
      switch (this.eventData[dataID].type)
      {
        case 'Radioboxes': return 'select';
        case 'Checkboxes': return 'select';
        case 'List': return 'select';
        case 'Workshops-Agendas': return 'select';
        default: return 'input';
      }
    },
    exportConditions()
    {
      const jsonData = JSON.stringify(this.value.conditions || [], null, 4);
      const blob = new Blob([jsonData], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = `conditions_${this.$chance.string(this.$newIDParams)}.json`;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    importButtonClick()
    {
      this.$refs.importHiddenInput.click();
    },
    importConditions(ev)
    {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const object = JSON.parse(e.target.result);
          if (object)
            this.$emit('change', { ...this.value, conditions: object });
        } catch (ex)
        {
          console.log(`Invalid config file : ${ex.message}`);
        }
      };
      reader.readAsText(file);
    },
  },
};

</script>

<style scoped>

</style>
