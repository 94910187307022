<template>
  <StyleEditor @change="onImport($event)" :value="navbarContent" :properties="[]">
    <template v-slot:form-start>
      <div class="mb-3">
        <inputImageUpload title="Image du logo" :value="getNavbarProperty('websiteLogo', '')" @change="setNavbarProperty('websiteLogo', $event)"/>
      </div>
      <div class="mb-3">
        <ActionSelector :action="getNavbarProperty('logoAction', {})" @change="setNavbarProperty('logoAction', $event)"/>
      </div>
      <div class="mb-3">
        <input-color-picker label="Couleur du bouton mobile<i class='fas fa-bars fa-lg ms-2'></i>" :value="getNavbarProperty('burgerColor', {})" @change="setNavbarProperty('burgerColor', $event)"/>
      </div>
      <div class="mb-3">
        <AlignmentProperties label="Alignement du contenus" :no-default="true" :value="getNavbarProperty('elementsAlignment', 'text-start')" @change="setNavbarProperty('elementsAlignment', $event)"/>
      </div>
      <div class="mb-3">
        <label>Type de menu sur PC <i class="text-secondary fas fa-desktop"/></label>
        <select class="form-control" :value="getNavbarProperty('desktopMenuType', '')" @change="setNavbarProperty('desktopMenuType', $event.target.value)">
          <option value="">Bar de navigation</option>
          <option value="sidebar">Sidebar</option>
        </select>
      </div>
      <div class="mb-3">
        <label>Type de menu en mobile <i class="text-secondary fas fa-mobile"/></label>
        <select class="form-control" :value="getNavbarProperty('mobileMenuType', 'drop')" @change="setNavbarProperty('mobileMenuType', $event.target.value)">
          <option value="drop">Drop down</option>
          <option value="sidebar">Sidebar</option>
        </select>
      </div>
      <template v-if="getNavbarProperty('mobileMenuType', 'drop') === 'sidebar'">
        <div class="mb-3">
          <input-color-picker label="Couleur du fond de la sidebar" :value="getNavbarProperty('sidebarBackgroundColor', {})" @change="setNavbarProperty('sidebarBackgroundColor', $event)"/>
        </div>
        <div class="mb-3">
          <label>Emplacement de la Sidebar</label>
          <select class="form-control" :value="getNavbarProperty('sidebarPosition', 'right')" @change="setNavbarProperty('sidebarPosition', $event.target.value)">
            <option value="left">Gauche</option>
            <option value="right">Droite</option>
            <option value="top">Haut</option>
            <option value="bottom">Bas</option>
          </select>
        </div>
        <div class="mb-3">
          <label>Effet sur le fond du site</label>
          <select class="form-control" :value="getNavbarProperty('backdropEffect', '')" @change="setNavbarProperty('backdropEffect', $event.target.value)">
            <option value="">Pas d'effet</option>
            <option value="darken">Assombrir</option>
          </select>
        </div>
      </template>
    </template>
  </styleEditor>
</template>

<script>

import _ from 'lodash';
import InputImageUpload from '../Utilities/InputImageUpload.vue';
import ActionSelector from '../Utilities/ActionSelector.vue';
import InputColorPicker from '../Utilities/InputColorPicker.vue';
import AlignmentProperties from '../Utilities/AlignmentProperties.vue';
import StyleEditor from '../Utilities/StyleEditor.vue';

export default {
  name: 'NavbarMainConfig',
  components: {
    StyleEditor,
    AlignmentProperties,
    InputColorPicker,
    ActionSelector,
    InputImageUpload,
  },
  props: {
    navbarContent: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onImport(importedDatas)
    {
      const importedCopy = _.cloneDeep(importedDatas || {});
      delete importedCopy.items;
      delete importedCopy.type;
      this.$emit('change', { ...this.navbarContent, ...importedCopy });
    },
    getNavbarProperty(field, defaultValue)
    {
      return this.navbarContent[field] || defaultValue;
    },
    setNavbarProperty(field, newValue)
    {
      const newContent = { ...this.navbarContent };
      newContent[field] = newValue;
      this.$emit('change', { ...newContent });
    },
  },
};

</script>

<style scoped>

</style>
