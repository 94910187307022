<template>
  <CarouselPreview class="websiteContentSlider" style="pointer-events: none;" v-if="!deploymentMode" :carouselContent="content" :customHeight="true" />
  <div
      v-else
      class="netflix-slider animate__animated animate__zoomIn"
      :data-left="content.arrowLeft"
      :data-right="content.arrowRight"
      :data-color="content.navArrowColor.hex"
      :data-slideperview="content.slidesPerView"
      :data-mobileslideperview="content.mobileSlidesPerView"
      :data-slidetoslide="content.slideNumber"
      :data-speed="content.slideSpeed"
      :data-loop="content.looping"
      :data-autoplay="content.autoplay"
      :data-autoplay-speed="content.autoplaySpeed"
      :data-pagination="content.pagination"
      :idx="getId">
    <div
        :id="`swiper-container-${getId}`"
        :class="content.freeMode ? 'swiper-container-free-mode' : ''"
        :style="{ height: `${content.carouselHeight}px` }"
        class="swiper swiper-container">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide text-center" v-for="(image, index) in content.images" :key="index">
          <a
              v-if="image.targetType && image.targetType !== ''"
              :onclick="targetOnClick(image)"
              :target="newTab(image) ? '_blank' : undefined"
              :href="targetUrl(image)"
              class="cursor-pointer">
            <img :src="image.src" style="height: 90%;"/>
          </a>
          <img v-else :src="image.src" style="height: 90%;"/>
        </div>
      </div>
      <!-- If we need pagination -->
      <div v-if="content.pagination" class="swiper-pagination" :id="`swiper-pagination-${getId}`"></div>

      <!-- If we need navigation buttons -->
      <div v-if="content.arrowLeft" class="swiper-button-prev" :id="`swiper-button-prev-${getId}`" :style="{color: content.navArrowColor.hex}"></div>
      <div v-if="content.arrowRight" class="swiper-button-next" :id="`swiper-button-next-${getId}`" :style="{color: content.navArrowColor.hex}"></div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import CarouselPreview from '../CarouselBuilder/CarouselPreview.vue';

export default {
  name: 'CarouselContent',
  components: { CarouselPreview },
  props: {
    content: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: '',
    };
  },
  computed: {
    ...mapGetters(['preferredUrl', 'pageList']),
    externalUrl() {
      if (this.preferredUrl && typeof this.preferredUrl === 'string')
        return this.preferredUrl;
      return '';
    },
    getId() {
      if (!this.id) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.id = this.$chance.string(this.$newIDParams);
      }
      return this.id;
    },
  },
  methods: {
    targetUrl(media) {
      let pageName = '';
      if (media.targetType === 'page' && this.pageList[media.targetPage])
        pageName = this.pageList[media.targetPage].name || '';
      switch (media.targetType)
      {
        case 'page': return `${this.externalUrl}/${pageName}`;
        case 'url': return media.targetUrl;
        case 'row': return media.targetRow;
        case 'filter': return undefined;
        case 'modal': return undefined;
        case 'javascript': return undefined;
        default: return undefined;
      }
    },
    targetOnClick(media)
    {
      switch (media.targetType)
      {
        case 'url': return '';
        case 'row': return '';
        case 'filter': return `forceRowsFilter('${media.targetFilter || ''}')`;
        case 'modal': return `$('#modal-${media.targetModal}').modal('show')`;
        case 'javascript': return media.targetJavascript;
        default: return '#';
      }
    },
    newTab(media) {
      if (media.targetType === 'url')
        return media.newTab || false;
      return false;
    },
    isTargetUrl(media) {
      return media.isTargetUrl || false;
    },
  },
};
</script>

<style scoped>

</style>
