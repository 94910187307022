<template>
  <div v-if="deploymentMode">
    <div class="checkoutBlock d-none row">
      <h3 class="col-12" :class="computedClass('paymentTextStyle')" :style="computedStyle('paymentTextStyle')" v-html="content.payText"></h3>
      <div class="col-12" :class="computedClass('paymentButtonStyle')">
        <button :style="computedStyle('paymentButtonStyle')" class="btn btn-primary">{{ content.payButtonText }}</button>
      </div>
    </div>
    <div class="failureBlock d-none row">
      <h3 class="col-12" :class="computedClass('failureTextStyle')" :style="computedStyle('failureTextStyle')" v-html="content.failureText"></h3>
      <div class="col-12" :class="computedClass('paymentButtonStyle')">
        <button :style="computedStyle('paymentButtonStyle')" class="btn btn-primary">{{ content.payButtonText }}</button>
      </div>
    </div>
    <div class="invoiceBlock d-none row" >
      <h3 class="col-12" :class="computedClass('validationTextStyle')" :style="computedStyle('validationTextStyle')" v-html="content.payText"></h3>
      <div class="col-12" :class="computedClass('paymentButtonStyle')">
        <button :style="computedStyle('paymentButtonStyle')" class="btn btn-primary">{{ content.validButtonText }}</button>
      </div>
    </div>
    <div class="alreadyPay d-none row">
      <h3 class="col-12" :class="computedClass('validationTextStyle')" :style="computedStyle('validationTextStyle')" v-html="content.validText"></h3>
      <div class="col-12" :class="computedClass('validationButtonStyle')">
        <a :style="computedStyle('validationButtonStyle')" class="btn btn-primary" href="" target="_blank">{{ content.validButtonText }}</a>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="checkoutBlock row">
      <h3 class="col-12" :class="computedClass('paymentTextStyle')" :style="computedStyle('paymentTextStyle')" v-html="content.payText"></h3>
      <div :class="computedClass('paymentButtonStyle')" class="col-12">
        <button :style="computedStyle('paymentButtonStyle')" class="btn btn-primary">{{ content.payButtonText }}</button>
      </div>
    </div>
    <div class="failureBlock row">
      <h3 class="col-12" :class="computedClass('failureTextStyle')" :style="computedStyle('failureTextStyle')" v-html="content.failureText"></h3>
      <div :class="computedClass('paymentButtonStyle')" class="col-12">
        <button :style="computedStyle('paymentButtonStyle')" class="btn btn-primary">{{ content.payButtonText }}</button>
      </div>
    </div>
    <div class="alreadyPay row">
      <h3 class="col-12" :class="computedClass('validationTextStyle')" :style="computedStyle('validationTextStyle')" v-html="content.validText"></h3>
      <div class="col-12" :class="computedClass('validationButtonStyle')">
        <a :style="computedStyle('validationButtonStyle')" class="btn btn-primary" target="_blank" href="">{{ content.validButtonText }}</a>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'paymentContent',
  props: {
    content: {
      type: Object,
      required: true,
    },
    deploymentMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['styleFromObject']),
  },
  methods: {
    computedClass(object)
    {
      if (!this.content[object])
        return '';
      switch (this.content[object].alignment)
      {
        case 'default': return '';
        default: return this.content[object].alignment;
      }
    },
    computedStyle(object)
    {
      if (!this.content[object])
        return {};
      const out = this.styleFromObject(this.content[object] || {});
      if (!this.deploymentMode)
        out.pointerEvents = 'none';
      return out;
    },
  },
};

</script>

<style scoped>

</style>
