<template>
  <form class="text-center editorUIStyle" @submit.stop.prevent>
    <button class="btn btn-success btn-raised text-light w-100" @click.prevent="onEditClick"><i class="fas fa-pen me-2"></i>{{ dictionary.leftBar.navbarConfigText }}</button>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavbarConfig',
  model: {
    prop: 'content',
    event: 'change',
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(['dictionary', 'selectedColumn']),
  },
  methods: {
    onEditClick()
    {
      this.$EventBus.$emit('editColumn', this.selectedColumn);
    },
  },
};
</script>

<style scoped>

</style>
