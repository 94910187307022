import { firebaseAction } from 'vuexfire';
import firebaseReference from '../FirebaseReference';

export default {
  /**
   * Deploy the given website content (payload) to production by calling :
   * addCommitToHistory and setLiveWebsite one after the other
   * @param getters
   * @param dispatch
   * @param payload // the whole website content
   */
  async deployWebsiteContent({ getters, dispatch }, payload)
  {
    console.log('Deploying to production', payload.id);
    await dispatch('addCommitToHistory', payload);
    await dispatch('setLiveWebsite', payload);
    dispatch('cleanHistory', { commitID: payload.id });
  },

  /**
   * Set the "live" (production) website from the payload information
   * @param html: the website final html content
   * @param commitId: the corresponding commit ID
   * @param customCSS: the custom CSS
   * @param customJS: the custom Javascript
   * @param mainCSS: the CSS generated by the builder (html/body style)
   */
  setLiveWebsite: firebaseAction(async function callback({ getters },
    {
      id,
      css,
      js,
      pages,
      clearJS,
      mainCss,
      websiteFilters,
      filters,
      formDatas,
    })
  {
    console.log('set current website to', id);
    const fbRef = await firebaseReference(getters.tokenID);
    return fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('live')
      .set({
        pages: pages || {},
        commitId: id || '',
        customCSS: css || '',
        customJS: js || '',
        clearJS: clearJS || '',
        mainCSS: mainCss || '',
        websiteFilters: websiteFilters || {},
        filters: filters || {},
        formDatas: formDatas || {},
      });
  }),

  /**
   * Add a commit to the website history based on the payload
   * @param payload: {
   *   // no specific field except :
   *   date: the date when the commit is created
   * }
   */
  addCommitToHistory: firebaseAction(async function callback({ getters }, payload)
  {
    console.log('Add Commit to history', payload.date);
    const fbRef = await firebaseReference(getters.tokenID);
    return fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('websiteHistory')
      .child(payload.date)
      .set(payload);
  }),

  cleanHistory: firebaseAction(async function callback({ getters }, { commitID })
  {
    const fbRef = await firebaseReference(getters.tokenID);
    let lastElements = {};
    await fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('websiteHistory')
      .orderByKey()
      .limitToLast(getters.historyLimit)
      .once('value', (snapshot) => { lastElements = snapshot.val(); });
    if (Object.keys(lastElements).length < getters.historyLimit)
    {
      return {};
    }
    console.log(`Clean history, limit to ${getters.historyLimit} entries`);
    if (Object.values(lastElements)[Object.values(lastElements).length - 1].id !== commitID)
    {
      console.error('Error while cleaning history, last commit ID did not match');
      return {};
    }
    return fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('websiteHistory')
      .set(lastElements);
  }),

  setHistoryLimit: firebaseAction(async function callback({ getters }, payload)
  {
    const fbRef = await firebaseReference(getters.tokenID);
    return fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('historyLimit')
      .set(payload.value);
  }),

  /**
   * Return website's deployment history
   * @param getters
   * @returns All the past deployment
   */
  async getWebsiteHistory({ getters })
  {
    let value = {};
    const fbRef = await firebaseReference(getters.tokenID);
    await fbRef.ref('Storage')
      .child('WebsiteBuilder')
      .child(getters.path)
      .child('websiteHistory')
      .once('value', (snapshot) => { value = snapshot.val(); });
    return value;
  },
};
