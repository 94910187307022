<template>
  <div
      v-show="!isColHiddenByFilters || deploymentMode"
      :class="computedClasses"
      :style="computedStyle"
      :id="column.id"
      :data-aos="aos_enabled === true ? aos_type : undefined"
      :data-aos-duration="aos_enabled === true ? aos_duration : undefined"
      :data-aos-easing="aos_enabled === true ? aos_easing : undefined"
      :data-aos-mirror="aos_enabled === true ? aos_mirror : undefined"
      :data-aos-anchor-placement="aos_enabled === true ? anchorPlacement : undefined"
      :data-filter="filterId"
      @click="onClick">
    <template v-if="!isEmpty && !showTextEditor">
      <!-- Content -->
      <HtmlContent
          v-if="(contentType === 'code' || contentType === 'text')"
          :deploymentMode="deploymentMode"
          :content="column.content"/>
      <ImageContent
          v-else-if="contentType === 'media'"
          :deploymentMode="deploymentMode"
          :content="column.content"/>
      <FormContent
          v-else-if="contentType === 'form'"
          :deploymentMode="deploymentMode"
          :content="column.content"/>
      <Navbar2Content
          v-else-if="contentType === 'navbar-v2'"
          :deploymentMode="deploymentMode"
          :content="column.content"/>
      <SeparatorContent
          v-else-if="contentType === 'separator'"
          :deploymentMode="deploymentMode"
          :content="column.content"/>
      <ClockContent
          v-else-if="contentType === 'clock'"
          :id="column.id"
          :deploymentMode="deploymentMode"
          :content="column.content"/>
      <CarouselContent
          v-else-if="contentType === 'carousel'"
          :id="column.id"
          :deploymentMode="deploymentMode"
          :content="column.content" />
      <LegalInformationsContent
          v-else-if="contentType === 'legalInformations'"
          :id="column.id"
          :deploymentMode="deploymentMode"
          :content="column.content"/>
      <ButtonContent
          v-else-if="contentType === 'button'"
          :id="`btn-${column.id}`"
          :deploymentMode="deploymentMode"
          :content="column.content"/>
      <MappedImageContent
          v-else-if="contentType === 'mappedImage'"
          :deploymentMode="deploymentMode"
          :content="column.content"
          :isSelected="isSelected"/>
      <paymentContent
          v-else-if="contentType === 'payment'"
          :deploymentMode="deploymentMode"
          :content="column.content"/>
      <div v-else-if="!deploymentMode">
        <div class="row" :class="(!isSelected) ? 'text-muted' : ''">
          <div class="col-auto mx-auto my-5 text-center">
            <h1 class="fas fa-heart-broken fa-2x"></h1>
            <h6>Le contenu de type "{{ contentType }}" est invalide</h6>
            <p>
              C'est embarrassant ... remontez nous cet incident avec le bouton
              <button class="btn btn-outline-danger ms-2" style="pointer-events: none"><i class="fas fa-bug text-danger"></i></button>
            </p>
          </div>
        </div>
      </div>
    </template>
    <!-- Overlay & other - This content is not shown in deployment Mode-->
    <ColumnOverlay
        v-if="isSelected && !showTextEditor && !deploymentMode"
        :row="Row"
        :column="column"
        @toggleSpacing="displaySpacing = $event"
        class="w-100 h-100 position-absolute top-0 start-0"/>
    <template v-if="!isEmpty">
      <CkEditor5
          v-if="contentType === 'text' && showTextEditor && !deploymentMode"
          v-model="colHtmlContent"
          :importedFonts="googleFonts"
          @blur="showTextEditor = false"/>
      <NavbarBuilderModal
          v-if="contentType === 'navbar' || contentType === 'navbar-v2'"
          :title="`${dictionary.navbarEditor} <span class='ms-3 text-muted'>${column.id}</span>`"
          :computedStyle="computedStyle"
          v-model="colNavbarContent"
          ref="navbarEditorModal"/>
      <CustomCodeEditorModal
          v-if="contentType === 'code'"
          v-model="colHtmlContent"
          :preview="true"
          :previewBackgroundColor="computedStyle.backgroundColor"
          :title="`Code de la colonne <span class='ms-3 text-muted'>${column.id}</span>`"
          mode="text/html"
          ref="codeEditorModal"
          id="codeEditorModal"/>
      <FormBuilderModal
          v-if="contentType === 'form'"
          v-model="colFormContent"
          :title="`Editeur de formulaire <span class='ms-3 text-muted'>${column.id}</span>`"
          :computedStyle="computedStyle"
          ref="formBuilderModal"/>
      <CarouselModal
          v-if="contentType === 'carousel'"
          :title="`${dictionary.carouselModule.editorModalTitle} <span class='ms-3 text-muted'>${column.id}</span>`"
          v-model="colCarouselContent"
          ref="carouselEditorModal" />
      <MappedImageModal
          v-if="contentType === 'mappedImage'"
          :title="`<i class='fas fa-vr-cardboard me-3'></i>Editeur d'image Dynamique <span class='ms-3 text-muted'>${column.id}</span>`"
          v-model="colMappedImageContent"
          ref="mappedImageModal" />
    </template>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ColumnOverlay from '../Overlays/ColumnOverlay.vue';
import ImageContent from './ImageContent.vue';
import FormContent from './FormContent.vue';
import ClockContent from './ClockContent.vue';
import CkEditor5 from '../Utilities/CkEditor.vue';
import NavbarBuilderModal from '../Modals/NavbarBuilderModal.vue';
import HtmlContent from './HtmlContent.vue';
import CustomCodeEditorModal from '../Modals/CustomCodeEditorModal.vue';
import FormBuilderModal from '../Modals/FormBuilderModal.vue';
import SeparatorContent from './SeparatorContent.vue';
import CarouselContent from './CarouselContent.vue';
import CarouselModal from '../Modals/CarouselModal.vue';
import LegalInformationsContent from './LegalInformationsContent.vue';
import ButtonContent from './ButtonContent.vue';
import MappedImageContent from './MappedImageContent.vue';
import MappedImageModal from '../Modals/MappedImageModal/MappedImageModal.vue';
import PaymentContent from './paymentContent.vue';
import Navbar2Content from './Navbar2Content.vue';

export default {
  name: 'Column',
  components: {
    Navbar2Content,
    PaymentContent,
    MappedImageModal,
    MappedImageContent,
    ButtonContent,
    LegalInformationsContent,
    CarouselModal,
    ClockContent,
    SeparatorContent,
    FormBuilderModal,
    CustomCodeEditorModal,
    HtmlContent,
    NavbarBuilderModal,
    CkEditor5,
    FormContent,
    ImageContent,
    ColumnOverlay,
    CarouselContent,
  },
  props: {
    deploymentMode: {
      type: Boolean,
      required: false,
    },
    column: {
      type: Object,
      required: true,
    },
    Row: {
      type: Object,
      required: true,
    },
    isDndPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTextEditor: false,
      displaySpacing: false,
      clicks: 0,
      timer: undefined,
    };
  },
  mounted() {
    this.$EventBus.$on('editColumn', this.onEdit);
  },
  beforeDestroy() {
    this.$EventBus.$off('editColumn', this.onEdit);
  },
  computed: {
    ...mapGetters([
      'selectedColID',
      'isColSelectedByUser',
      'workerData',
      'styleFromObject',
      'googleFonts',
      'dictionary',
      'activeWebsiteFilter',
    ]),
    isSelected()
    {
      return this.selectedColID === (this.column.id || '');
    },
    filterId()
    {
      return this.column.filter || '';
    },
    isColHiddenByFilters()
    {
      if (!this.activeWebsiteFilter)
        return false;
      return (this.column.filter && this.column.filter !== this.activeWebsiteFilter);
    },
    computedStyle()
    {
      const out = this.styleFromObject(this.column.values || {});

      // special case : medias have paddings set to 0
      if (!this.isEmpty && this.contentType === 'media')
      {
        out.paddingRight = 0;
        out.paddingLeft = 0;
      }

      if (!this.deploymentMode)
      {
        const worker = this.isColSelectedByUser(this.column);
        if (worker && worker.id !== this.workerData.id)
          out.outline = `dashed 2px ${worker.color.primary}`;
      }
      if (this.isEmpty && !this.deploymentMode)
      {
        out.minHeight = '100px';
        out.outline = '#2faade dashed 1px';
        out.backgroundColor = (this.isDndPreview === false) ? 'rgba(222,242,250,0.85)' : 'rgba(238,148,5,0.70)';
      }
      return out;
    },
    isEmpty()
    {
      if (!this.column.content)
        return true;
      return this.column.content.type === undefined;
    },
    computedClasses()
    {
      let out = '';
      out += `${(!this.column.layout) ? 'col-auto' : `col-md-${this.column.layout}`}`;
      const align = this.column.values ? this.column.values.alignment : undefined;
      if (align && align !== 'default')
        out += ` ${align}`;
      if (this.column.values && this.column.values.customClasses)
      {
        this.column.values.customClasses.forEach((cls) => {
          out += ` ${cls}`;
        });
      }
      if (!this.deploymentMode)
        out += ' position-relative preview-column';
      return out;
    },
    contentType()
    {
      return (this.isEmpty) ? '' : this.column.content.type;
    },
    aos_enabled() {
      if (this.column.animations)
        return this.column.animations.enabled || false;
      return false;
    },
    aos_type() {
      if (this.column.animations)
        return this.column.animations.type || '';
      return '';
    },
    aos_mirror() {
      if (this.column.animations)
        return this.column.animations.mirror || false;
      return false;
    },
    aos_easing() {
      if (this.column.animations)
        return this.column.animations.easing || '';
      return '';
    },
    aos_duration() {
      if (this.column.animations)
        return this.column.animations.duration || '';
      return '';
    },
    anchorPlacement() {
      if (this.column.animations)
        return this.column.animations.anchorPlacement || '';
      return '';
    },
    colHtmlContent: {
      get() {
        return (!this.isEmpty) ? this.column.content.html : '';
      },
      set(newValue) {
        this.$store.commit('setColumnContent', { column: this.column, content: { ...this.column.content, html: newValue }, dbSync: true });
      },
    },
    colFormContent: {
      get() {
        return (!this.isEmpty) ? this.column.content : {};
      },
      set(newContent) {
        this.$store.commit('setColumnContent', { column: this.column, content: newContent, dbSync: true });
      },
    },
    colNavbarContent: {
      get() {
        return (!this.isEmpty) ? this.column.content : {};
      },
      set(newContent) {
        this.$store.commit('setColumnContent', { column: this.column, content: newContent, dbSync: true });
      },
    },
    colCarouselContent: {
      get() {
        return (!this.isEmpty) ? this.column.content : {};
      },
      set(newContent) {
        this.$store.commit('setColumnContent', { column: this.column, content: newContent, dbSync: true });
      },
    },
    colMappedImageContent: {
      get() {
        return (!this.isEmpty) ? this.column.content : {};
      },
      set(newContent) {
        this.$store.commit('setColumnContent', { column: this.column, content: newContent, dbSync: true });
      },
    },
  },
  methods: {
    onClick()
    {
      if (!this.deploymentMode && !this.isSelected)
        this.$store.commit('setSelectedColumnID', { selectedCol: this.column.id, dbSync: true });
      this.clicks = this.clicks + 1;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.clicks = 0;
        }, 500);
      } else {
        clearTimeout(this.timer);
        if (!this.deploymentMode && this.isSelected)
        {
          this.onEdit(this.column);
        }
        this.clicks = 0;
      }
    },
    onEdit(col)
    {
      const editedCol = col || this.column;
      if (!editedCol.content || !this.isSelected)
        return;
      switch (editedCol.content.type)
      {
        case 'text':
          this.showTextEditor = true;
          break;
        case 'code':
          this.$refs.codeEditorModal.open();
          break;
        case 'form':
          this.$refs.formBuilderModal.open();
          break;
        case 'navbar':
          this.$refs.navbarEditorModal.open();
          break;
        case 'navbar-v2':
          this.$refs.navbarEditorModal.open();
          break;
        case 'carousel':
          this.$refs.carouselEditorModal.open();
          break;
        case 'mappedImage':
          this.$refs.mappedImageModal.open();
          break;
        default: break;
      }
    },
  },
};

</script>

<style scoped>

</style>
